import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { getUserRole } from 'utils';
import { useOutsideClick } from 'hooks';
import Link from 'components/Link/Link';
import { AngleDownIcon, AngleUpIcon, LogoIcon } from 'assets/img';
import Button from 'components/Button/Button';
import ReactTooltip from 'react-tooltip';
import {
  corpAdmin,
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  freePersonalUser,
  personalUser,
  spAdmin,
  spAdminNotActivated,
  spTechAdmin,
  spTechUser,
  techAdmin,
} from 'consts';
import { getDomainStatus } from 'helpers';
import { daysBetweenDates } from 'components/AlertValidateDomain/utils/daysBetweenDates';
import NavbarItem from './components/NavbarItem/NavbarItem';
import Downloads from './components/Downloads/Downloads';
import styles from './Navbar.module.scss';

let MENU_ITEMS = ['workspace', 'company', 'customers'];

const Navbar = ({ user, brandingData }) => {
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isDownloadsOpen, setIsDownloadsOpen] = useState(false);
  const { pathname } = useLocation();
  const role = getUserRole(user);
  const downloadsRef = useRef();

  const { isDomainNotValidated } = getDomainStatus(user);
  const { finalTimeToValidateDomain, firstPaymentDone, domainWasAutovalidated } = user;
  const daysLeft = daysBetweenDates(finalTimeToValidateDomain);
  const blockLeftMenu = domainWasAutovalidated && ((!!finalTimeToValidateDomain && daysLeft < 1) || !firstPaymentDone);

  useOutsideClick(downloadsRef, () => setIsDownloadsOpen(false));

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const toggleDownloadsOpen = () => {
    setIsDownloadsOpen(!isDownloadsOpen);
  };

  useEffect(() => {
    if (pathname.includes('company-management')) {
      setIsCompanyOpen(true);
    }
  }, [pathname]);

  const onCompanyToggle = () => setIsCompanyOpen((value) => !value);
  const onNotCompanyClick = () => isCompanyOpen && setIsCompanyOpen(false);

  if ([spAdmin, spTechAdmin].includes(role)) {
    MENU_ITEMS = ['workspace', 'company', 'customers'];
  }
  if (role === techAdmin) {
    MENU_ITEMS = ['workspace', 'customers'];
  }
  if ([custAdmin, corpAdmin, corpTechAdmin].includes(role)) {
    MENU_ITEMS = ['workspace', 'company'];
  }
  if ([spTechUser, custUser, corpTechUser, personalUser, freePersonalUser, spAdminNotActivated].includes(role)) {
    MENU_ITEMS = ['workspace'];
  }

  return (
    <nav
      className={styles.navbar}
      style={
        brandingData?.backGroundColor && brandingData?.isActive ? { background: brandingData.backGroundColor } : {}
      }>
      <div className={styles.navbarMenu}>
        <Link to="/workspace" className={styles.navbarLogo}>
          {brandingData?.isActive && brandingData?.logo ? (
            <img
              src={`data:image/png;base64,${brandingData.logo}`}
              width="107px"
              alt="Custom Logo"
              className="logo_icon"
              style={{ height: '46px', width: 'auto', maxWidth: '15em', objectFit: 'contain' }}
            />
          ) : (
            <LogoIcon />
          )}
        </Link>
        <ul className={styles.navbarList}>
          {MENU_ITEMS.map((item) => (
            <NavbarItem
              key={item}
              itemId={item}
              role={role}
              domainStatus={user.domainStatus}
              isCompanyOpen={isCompanyOpen}
              onCompanyToggle={onCompanyToggle}
              onNotCompanyClick={onNotCompanyClick}
              blockLeftMenu={blockLeftMenu}
            />
          ))}
        </ul>
      </div>

      <div className={styles.navbarFooter}>
        <div className={styles.navbarFooterWrap}>
          <div
            className={cn(styles.navbarFooterCopyright, {
              [styles.inverted]: brandingData?.isActive && brandingData?.useInvertedColorScheme,
            })}>
            {brandingData?.isActive ? (
              <span>Powered by trugrid.com</span>
            ) : (
              <>
                <span>&copy;</span>
                <span>copyright {new Date().getFullYear()}</span>
              </>
            )}
          </div>
          {(role === personalUser || !isDomainNotValidated) && (
            <div
              className={cn(styles.navbarFooterDownloads, {
                [styles.inverted]: brandingData?.isActive && brandingData?.useInvertedColorScheme,
                [styles.disabled]: blockLeftMenu,
              })}
              ref={downloadsRef}>
              <Button
                variant="link"
                className={cn(styles.navbarFooterDownloadsBtn, { [styles.active]: isDownloadsOpen })}
                onClick={toggleDownloadsOpen}
                icon={isDownloadsOpen ? <AngleUpIcon /> : <AngleDownIcon />}>
                Downloads
              </Button>
              {isDownloadsOpen && <Downloads brandingData={brandingData} role={role} user={user} />}
            </div>
          )}
        </div>
      </div>
      <ReactTooltip place="right" type="light" effect="solid" isCapture delayShow={200} />
    </nav>
  );
};

Navbar.propTypes = {
  user: PropTypes.object.isRequired,
  brandingData: PropTypes.object,
};

export default Navbar;
