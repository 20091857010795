import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { queryFormatter, onChangePage } from 'utils';
import WorkspaceTable from 'pages/Workspace/components/WorkspaceTable/WorkspaceTable';
import WorkspaceHeader from 'pages/Workspace/components/WorkspaceHeader/WorkspaceHeader';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import AlertNoSentryInstalled from 'pages/Workspace/components/AlertNoSentryInstalled/AlertNoSentryInstalled';
import { Link, StatusMessage } from 'components';
import { spAdmin, spTechAdmin } from 'consts';

const WorkspaceDomains = ({
  domain,
  custMachines,
  baseUrl,
  onUpdate,
  onUpdateUser,
  userRole,
  loadingCurrent,
  setLoadingCurrent,
}) => {
  const { isDomainHybryd, isDomainOnPremise, isDomainAzure } = getDomainADStatus(domain);
  const sentryDeleted = isSentryDeleted(domain);

  const paginationData = useMemo(
    () => ({
      updateAction: onUpdate,
      errorAction: toast.error,
    }),
    [onUpdate],
  );

  useEffect(() => {
    if (custMachines?.queryConfig?.searchQuery) {
      const query = { ...custMachines.queryConfig, searchQuery: '' };
      const url = queryFormatter(baseUrl, query);

      onChangePage([url, query], paginationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDomains = () => {
    if (!custMachines.data.length && custMachines.pageMeta?.AllMachinesCount && !custMachines.queryConfig.searchQuery) {
      return (
        <StatusMessage header="No computers reporting in">
          Change view or{' '}
          <Link href="https://www.trugrid.com/docs/installsentry" external bold>
            add computers
          </Link>
        </StatusMessage>
      );
    }

    return (
      <WorkspaceTable
        tableData={custMachines}
        isComputers
        baseUrl={baseUrl}
        queryConfig={custMachines.queryConfig}
        onUpdate={onUpdate}
        onUpdateUser={onUpdateUser}
        loadingCurrent={loadingCurrent}
        setLoadingCurrent={setLoadingCurrent}
      />
    );
  };

  if (custMachines.isError) {
    return <StatusMessage error>{custMachines.error}</StatusMessage>;
  }

  return (
    <>
      {[spAdmin, spTechAdmin].includes(userRole) &&
        !isDomainAzure &&
        !!custMachines.pageMeta?.AllMachinesCount &&
        sentryDeleted && <AlertNoSentryInstalled userData={domain} />}
      <WorkspaceHeader
        isShowAll={isDomainAzure || ((isDomainHybryd || isDomainOnPremise) && sentryDeleted)}
        allValue={`${custMachines.queryConfig.kind} Computers`}
        baseUrl={baseUrl}
        queryConfig={custMachines.queryConfig}
        totalCount={custMachines.pageMeta.TotalCount}
        searchBy="Computer Name"
        onUpdate={onUpdate}
        hasSecureConnects={!!custMachines.pageMeta?.AllMachinesCount}
      />

      {renderDomains()}
    </>
  );
};

WorkspaceDomains.propTypes = {
  domain: PropTypes.object,
  custMachines: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  loadingCurrent: PropTypes.string,
  setLoadingCurrent: PropTypes.func,
};

export default WorkspaceDomains;
