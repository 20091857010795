import { formatPhoneNumber } from 'helpers';
import MoreButton from './MoreButton/MoreButton';
import ManageBtnMfa from './ManageBtnMfa/ManageBtnMfa';
import MfaDetailsCell from './MfaDetailsCell/MfaDetailsCell';
import MfaRoleCell from './MfaRoleCell/MfaRoleCell';
import MfaStatusCell from './MfaStatusCell/MfaStatusCell';
import MfaNameCell from './MfaNameCell/MfaNameCell';

export const tableColumns = (onModalOpen, isResetView, isAllView, isSetupView, isDisabledView, showEnableMfaModal, selectedUsers) => {
  if (isAllView) {
    return [
      {
        name: 'Name',
        checkbox: false,
        width: '35%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }) => <MfaNameCell displayName={displayName} upn={upn} />,
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '13%',
        data: (user) => <MfaRoleCell user={user} />,
      },
      {
        name: 'Status',
        checkbox: false,
        sortable: 'mfaStatus',
        width: '21%',
        data: ({ mfaStatus }) => <MfaStatusCell mfaStatus={mfaStatus} />,
      },
      {
        name: 'MFA Details',
        checkbox: false,
        sortable: 'setUpDate',
        tooltip: {
          tip: 'Sort by Date',
          id: 'sort-by-date',
        },
        width: '25%',
        data: (user) => <MfaDetailsCell user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '6%',
        data: (user) => (
          <MoreButton
            onModalOpen={onModalOpen}
            user={user}
            mfaStatus={user.mfaStatus}
          />
        ),
      },
    ];
  }

  if (isSetupView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        checkboxDisabled: ({ isAdmin }) => (isAdmin),
        width: '53%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }) => <MfaNameCell displayName={displayName} upn={upn} />,
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '29%',
        data: (user) => <MfaRoleCell user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user) => (!user.isAdmin && !selectedUsers.find((item) => item.userId === user.userId)) && (
          <ManageBtnMfa
            user={user}
            isSetupView={isSetupView}
            onModalOpen={onModalOpen}
            isHeaderBtn={false}
          />
        ),
      },
    ];
  }

  if (isResetView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        checkboxDisabled: ({ isAdmin }) => (isAdmin),
        width: '40%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }) => <MfaNameCell displayName={displayName} upn={upn} />,
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '13%',
        data: (user) => <MfaRoleCell user={user} />,
      },
      {
        name: 'MFA Details',
        checkbox: false,
        sortable: 'dateOfReset',
        tooltip: {
          tip: 'Sort by Date',
          id: 'sort-by-date',
        },
        width: '29%',
        data: (user) => <MfaDetailsCell isResetView={isResetView} user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user) => !selectedUsers.find((item) => item.userId === user.userId) && (
          <ManageBtnMfa
            user={user}
            isResetView={isResetView}
            onModalOpen={onModalOpen}
            isHeaderBtn={false}
          />
        ),
      },
    ];
  }

  if (isDisabledView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        width: '53%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }) => <MfaNameCell displayName={displayName} upn={upn} />,
      },
      {
        name: 'Phone Number',
        checkbox: false,
        sortable: 'phoneNumber',
        width: '29%',
        data: ({ phoneCountryCode, phoneNumber }) => formatPhoneNumber(phoneCountryCode, phoneNumber),
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user) => !selectedUsers.find((item) => item.userId === user.userId) && (
          <ManageBtnMfa
            isDisabled={showEnableMfaModal}
            user={user}
            isDisabledView={isDisabledView}
            onModalOpen={onModalOpen}
            isHeaderBtn={false}
          />
        ),
      },
    ];
  }

  return [
    {
      name: 'Name',
      checkbox: true,
      width: '40%',
      sortable: 'displayName',
      search: 'Name',
      data: ({ displayName, upn }) => <MfaNameCell displayName={displayName} upn={upn} />,
    },
    {
      name: 'Role',
      checkbox: false,
      sortable: 'isAdmin',
      width: '13%',
      data: (user) => <MfaRoleCell user={user} />,
    },
    {
      name: 'MFA Details',
      checkbox: false,
      sortable: 'setUpDate',
      tooltip: {
        tip: 'Sort by Date',
        id: 'sort-by-date',
      },
      width: '29%',
      data: (user) => <MfaDetailsCell user={user} />,
    },
    {
      name: '',
      checkbox: false,
      width: '18%',
      data: (user) => !selectedUsers.find((item) => item.userId === user.userId) && (
        <ManageBtnMfa
          user={user}
          onModalOpen={onModalOpen}
          isHeaderBtn={false}
        />
      ),
    },
  ];
};
