import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Modal } from 'components';
import { toast } from 'react-toastify';
import { queryFormatter } from 'utils';

const UninstallAgentsModal = ({
  uninstallAgentsModalShow,
  closeUninstallAgentsModalShow,
  selectedDeviceIds,
  queryConfig,
  baseUrl,
  onUpdateHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const uninstallAgents = () => {
    setIsLoading(true);

    axios
      .put('/api/AgentUninstallation/SetPendingDeleteStatus', selectedDeviceIds)
      .then(() => axios.get(queryFormatter(baseUrl, { ...queryConfig, pageNumber: 1 })))
      .then((resp) => {
        const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;
        const devicesNew = {
          data: resp.data,
          pageMeta,
          queryConfig: { ...queryConfig },
        };

        onUpdateHandler(devicesNew, true);
        toast.success('Uninstall initiated', 3000);
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => {
        setIsLoading(false);
        closeUninstallAgentsModalShow();
      });
  };

  return (
    <Modal
      isOpen={uninstallAgentsModalShow}
      onRequestClose={closeUninstallAgentsModalShow}
      contentLabel="Uninstall Agents from Devices"
      actionFunc={uninstallAgents}
      actionTitle="Uninstall"
      actionLoading={isLoading}
      style={{ content: { width: '39em' } }}>
      <p>
        Do you really want to uninstall <b>{selectedDeviceIds.length}</b> agent
        {selectedDeviceIds.length > 1 ? 's' : null}?
      </p>
    </Modal>
  );
};

UninstallAgentsModal.propTypes = {
  uninstallAgentsModalShow: PropTypes.bool,
  closeUninstallAgentsModalShow: PropTypes.func,
  selectedDeviceIds: PropTypes.array,
  queryConfig: PropTypes.object,
  baseUrl: PropTypes.string,
  onUpdateHandler: PropTypes.func,
};

export default UninstallAgentsModal;
