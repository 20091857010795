import { MoreDotsIcon } from 'assets/img';
import { DropdownArray } from 'components';
import PropTypes from 'prop-types';
import styles from './MoreButton.module.scss';

const MoreButton = ({ item, setShowTruScanDeleteModal, setModalData }) => {
  const { id, scannedDomain } = item;

  const onMoreChange = () => {
    setShowTruScanDeleteModal(true);
    setModalData({ recordId: id });
  };

  const options = [{ icon: 'delete', value: 'Delete Entry' }];

  return (
    <div className={styles.dropdownWrapper}>
      <DropdownArray
        id="more-action-dropdown"
        openIcon={false}
        icon={<MoreDotsIcon />}
        options={options}
        onChange={(value) => onMoreChange(value, scannedDomain, id)}
        className={styles.dropdownWrapperDropdown}
        optionsClassName={styles.dropdownWrapperDropdownOptions}
      />
    </div>
  );
};

MoreButton.propTypes = {
  item: PropTypes.object.isRequired,
  setShowTruScanDeleteModal: PropTypes.func,
  setModalData: PropTypes.func,
};

export default MoreButton;
