import { useEffect, useState } from 'react';
import { subscribeToResponseReceived, unsubscribeFromResponseReceived } from 'config/customAxios';
import { signOut } from 'helpers';
import styles from './idleBox.module.scss';

export const IDLE_TIMER = 15 * 60;

const IdleBox = () => {
  const [countDown, setCountDown] = useState(IDLE_TIMER);
  const [signedOut, setSignedOut] = useState(false);

  useEffect(() => {
    let timerId;
    let lastTime = Date.now();

    const updateTimer = () => {
      const elapsedTime = Math.floor((Date.now() - lastTime) / 1000);
      setCountDown((value) => Math.max(value - elapsedTime, 0));
      lastTime = Date.now();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(timerId);
      } else {
        updateTimer();
        timerId = setInterval(() => {
          setCountDown((value) => Math.max(value - 1, 0));
          lastTime = Date.now();
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initialize the timer and lastTime
    timerId = setInterval(() => {
      setCountDown((value) => Math.max(value - 1, 0));
      lastTime = Date.now();
    }, 1000);

    return () => {
      clearInterval(timerId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (countDown < 1) {
      setSignedOut(true);
      setCountDown(0);
      signOut();
    }
  }, [countDown]);

  useEffect(() => {
    const handleResponseReceived = () => {
      setCountDown(IDLE_TIMER);
    };
    subscribeToResponseReceived(handleResponseReceived);

    return () => unsubscribeFromResponseReceived(handleResponseReceived);
  }, [countDown]);

  const seconds = String(countDown % 60).padStart(2, '0');
  const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');

  return (
    <div className={styles.idle}>
      For your security, this website will be logged off after&nbsp;
      <b>{!signedOut ? `${minutes}:${seconds}` : '00:00'}</b>&nbsp;mins of inactivity
    </div>
  );
};

export default IdleBox;
