import PropTypes from 'prop-types';
import { ActivationCode, MsLogin, Link } from 'components';
import { DownloadIcon } from 'assets/img';
import styles from './SetupAzure.module.scss';

const SetupAzure = ({ isDomainAzure, user }) => (
  <div className={styles.setup}>
    <div className={styles.setupLink}>
      <div>
        <Link href={user?.downloadSecureConnectLink} bold external>
          <DownloadIcon />
          Download Secure Connect
        </Link>
      </div>
    </div>
    <div className={styles.setupActivate}>
      <div>
        Use code to activate TruGrid SecureConnect
        <ActivationCode
          activationCode={user.domainActivationCode}
          copyMessage="Activation code was saved to clipboard"
          className={styles.activationCode}
        />
      </div>
    </div>
    <div className={styles.setupMsLogin}>
      <div>
        Register your domain with Microsoft Entra ID
        <MsLogin isAzureAd={isDomainAzure} domainId={user.domainId} domainName={user.dnsDomainName} isActive />
      </div>
    </div>
  </div>
);

SetupAzure.propTypes = {
  isDomainAzure: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default SetupAzure;
