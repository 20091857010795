import { useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';

const Tooltip = ({ id, offset, content, place, className, wrapClassName, contentClassName, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const tooltipContainer = document.getElementById('tooltip-container');

  return (
    <>
      <div
        data-tip
        data-for={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={wrapClassName}>
        {children}
      </div>
      {showTooltip && (
        <>
          {ReactDOM.createPortal(
            <ReactTooltip
              id={id}
              offset={offset}
              className={cn(styles.tooltip, { [className]: className })}
              type="light"
              effect="solid"
              place={place || 'top'}>
              <div className={contentClassName}>{content}</div>
            </ReactTooltip>,
            tooltipContainer,
          )}
        </>
      )}
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  offset: PropTypes.any,
  content: PropTypes.any.isRequired,
  place: PropTypes.string,
  className: PropTypes.any,
  wrapClassName: PropTypes.any,
  contentClassName: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
