/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import { truncateString } from 'utils';

const SuccessfulMessage = ({ isCompanyPage, domain, selectedUsers }) => {
  let roleName = 'Customer Administrator';
  if (isCompanyPage) {
    if (domain.isServiceProvider) {
      roleName = 'Service Provider Tech Administrator';
    } else {
      roleName = 'Corporate Tech Administrator';
    }
  }

  if (selectedUsers.length === 1) {
    return selectedUsers[0].isAssigned
      ? `${truncateString(selectedUsers[0].name, false) || 'N/A'} is removed from ${roleName}`
      : `${truncateString(selectedUsers[0].name, false) || 'N/A'} is promoted to ${roleName}`;
  }
  const statuses = selectedUsers.reduce((prev, cur) => {
    cur.isAssigned ? prev.true++ : prev.false++;
    return prev;
  }, { true: 0, false: 0 });

  if (statuses.true && statuses.true === selectedUsers.length) {
    return `${selectedUsers.length} users are removed from ${roleName}`;
  }
  if (statuses.false && statuses.false === selectedUsers.length) {
    return `${selectedUsers.length} users are promoted to ${roleName}`;
  }
  return `${selectedUsers.length} users are updated`;
};

SuccessfulMessage.propTypes = {
  isCompanyPage: PropTypes.bool,
  domain: PropTypes.object,
  selectedUsers: PropTypes.array,
};

export default SuccessfulMessage;
