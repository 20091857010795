import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import { loginService } from 'services';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import styles from './DomainValidationResult.module.scss';
import { getStatusMessage } from './utils/getStatusMessage';

const EmailWhoisResult = () => {
  const history = useHistory();
  const { publicKey } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [status, setStatus] = useState('');

  useEffect(() => {
    loginService
      .validateByWhois(publicKey)
      .then(({ data }) => setStatus(data))
      .catch((err) => setStatus(err.response?.data.message || err.message))
      .finally(() => setIsLoading(false));
  }, [publicKey]);

  const onResendEmail = () => {
    setIsSending(true);
    loginService
      .resendWhoisValidationEmail(publicKey)
      .then(() => toast.success('Verification link was resent', 3000))
      .catch((err) => setError({ status: true, message: err.response?.data.message || err.message }))
      .finally(() => setIsSending(false));
  };

  const renderButton = () => {
    if (status === 'Your domain validated successfully') {
      return (
        <Button
          id="proceed-with-trugrid"
          className={styles.validationButton}
          onClick={() => window.location.replace('/')}
          size="48"
          variant="primary">
          Proceed to TruGrid
        </Button>
      );
    }
    if (status === 'Link is expired') {
      return (
        <Button
          id="resend-email"
          className={styles.validationButton}
          onClick={onResendEmail}
          size="48"
          variant="primary"
          isLoading={isSending}>
          Resend Email
        </Button>
      );
    }
    return (
      <Button
        id="login-next"
        className={styles.validationButton}
        onClick={() => history.replace('/')}
        size="48"
        variant="primary">
        Login
      </Button>
    );
  };

  if (isLoading) return <Loader />;

  if (error.status) {
    return <div className={styles.validationInfo}>The page is not available. Please try to login again</div>;
  }

  const { title, message } = getStatusMessage(status);

  return (
    <>
      <LoginHeader title={title} hideBack hideSteps />
      <div className={styles.validationInfo}>{message}</div>
      {renderButton()}
      {status === 'Link is expired' && (
        <div className={styles.validationWrap}>
          <Button variant="link" onClick={() => history.replace('/login')}>
            Back to Login
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailWhoisResult;
