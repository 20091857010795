import { useContext, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { UserContext } from 'utils';

import { setupMfaType } from 'helpers';
import SetupMfaOptions from './pages/SetupMfaOptions/SetupMfaOptions';
import SetupViaApp from './pages/SetupViaApp/SetupViaApp';
import SetupViaSms from './pages/SetupViaSms/SetupViaSms';
import SetupViaEmail from './pages/SetupViaEmail/SetupViaEmail';

const SetupMFA = () => {
  const { user } = useContext(UserContext);
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const isSetupMfaWithoutPhone = user.isMfaActive && !user.isPhoneMfaActive && !user.mustSetupMfa && setupMfaType(user.mfaType) === 'app';

  useEffect(() => {
    if (isSetupMfaWithoutPhone) {
      history.replace(`${url}/authentication-app/verify-phone`);
    }
  }, [history, isSetupMfaWithoutPhone, url]);

  const renderRoutes = useMemo(() => {
    if (isSetupMfaWithoutPhone) {
      return (
        <Switch>
          <Route path={`${url}/authentication-app`} component={SetupViaApp} />
          <Route path="*" render={() => <Redirect to={`${url}/authentication-app`} />} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path={path} exact component={SetupMfaOptions} />
        <Route path={`${url}/authentication-app`} component={SetupViaApp} />
        <Route path={`${url}/sms`} component={SetupViaSms} />
        <Route path={`${url}/email`} component={user.userEmail ? SetupViaEmail : () => <Redirect to={`${path}`} />} />
        <Route path="*" render={() => <Redirect to={path} />} />
      </Switch>
    );
  }, [isSetupMfaWithoutPhone, path, url, user.userEmail]);

  return renderRoutes;
};

export default SetupMFA;
