import PropTypes from 'prop-types';
import cn from 'classnames';
import { ExclamationIcon, MinusIcon, PlusIcon } from 'assets/img';
import { Button } from 'components';
import { corpAdmin, spAdmin } from 'consts';
import styles from './InputControls.module.scss';

const InputControls = ({
  warning,
  setWarning,
  licensesState,
  setLicensesState,
  licenses,
  role,
  typeFunc,
  id,
  minimumLicensesCount,
}) => {
  const onIncrease = (licenseType) => {
    const licensesNew = JSON.parse(JSON.stringify(licensesState));
    const totalPurchasedByType = `totalPurchased${licenseType.slice(8)}`;
    const totalCanceledByType = `canceled${licenseType.slice(8)}`;

    if (licensesNew[totalPurchasedByType] === 1000000) {
      if (!warning.isActive) {
        setWarning({
          isActive: true,
          field: [licenseType],
          content: (
            <>
              <ExclamationIcon />
              You can't buy more than 1000000 licenses
            </>
          ),
        });
        setTimeout(() => setWarning({ isActive: false }), 3000);
      }
      return;
    }

    if (licensesNew[totalCanceledByType] > licenses[totalCanceledByType]) {
      licensesNew[totalCanceledByType] -= 1;
    }
    licensesNew[totalPurchasedByType] += 1;
    licensesNew.spDomain[licenseType] += 1;

    setLicensesState(licensesNew);
  };

  const onDecrease = (licenseType) => {
    const licensesNew = JSON.parse(JSON.stringify(licensesState));
    const totalPurchasedByType = `totalPurchased${licenseType.slice(8)}`;
    const totalCanceledByType = `canceled${licenseType.slice(8)}`;

    if (licensesNew[totalPurchasedByType] > minimumLicensesCount) {
      if (licensesNew.spDomain[licenseType] <= licenses.spDomain[licenseType]) {
        licensesNew[totalCanceledByType] += 1;
      }
      licensesNew[totalPurchasedByType] -= 1;
      licensesNew.spDomain[licenseType] -= 1;

      setLicensesState(licensesNew);
    } else if (!warning.isActive) {
      setWarning({
        isActive: true,
        field: [licenseType],
        content: (
          <>
            <ExclamationIcon />
            You can not reduce the minimum number of licenses
          </>
        ),
      });
      setTimeout(() => setWarning({ isActive: false }), 3000);
    }
  };

  const onInputChange = (licenseType, isValid, value) => {
    if (isValid) {
      const licensesNew = JSON.parse(JSON.stringify(licensesState));
      const totalPurchasedByType = `totalPurchased${licenseType.slice(8)}`;
      const totalCanceledByType = `canceled${licenseType.slice(8)}`;
      const totalAllocatedByType = licensesState[`totalAllocated${licenseType.slice(8)}`];
      const initialAmountOfLicenses = licensesState.spDomain[licenseType];

      if (initialAmountOfLicenses > +value) {
        licensesNew[totalCanceledByType] += initialAmountOfLicenses - +value;
      }
      if (initialAmountOfLicenses <= +value && licensesNew[totalCanceledByType] > licenses[totalCanceledByType]) {
        const diff = +value - licensesNew.spDomain[licenseType];

        if (diff > licensesNew[totalCanceledByType]) {
          licensesNew[totalCanceledByType] = licenses[totalCanceledByType];
        } else {
          licensesNew[totalCanceledByType] -= diff;
        }
      }
      licensesNew[totalPurchasedByType] = +value + totalAllocatedByType;
      licensesNew.spDomain[licenseType] = value === '' ? value : +value;

      setLicensesState(licensesNew);
    }
  };

  const onInputBlur = (licenseType, value) => {
    if (value === '') {
      const licensesNew = JSON.parse(JSON.stringify(licensesState));
      licensesNew.spDomain[licenseType] = 0;
      setLicensesState(licensesNew);
    }
  };

  return (
    <div className={styles.controlsItem}>
      <h5 className={styles.controlsItemTitle}>{id === 'subs-primary-rdp-input' ? 'SecureRDP' : 'BitLocker'}</h5>
      <div className={styles.controlsItemWrap}>
        {[spAdmin, corpAdmin].includes(role) && (
          <Button
            variant="link"
            className={styles.controlsItemButton}
            id="decreasing-licenses-count"
            onClick={() => onDecrease(typeFunc)}
            isDisabled={!+licensesState.spDomain.numberOfRdpLicenses}>
            <MinusIcon />
          </Button>
        )}
        <span className={styles.controlsItemWrapInputWrap}>
          <input
            id={id}
            type="text"
            pattern="[0-9]*"
            maxLength="7"
            className={cn(styles.controlsItemWrapInput, {
              [styles.controlsItemWrapInputWarning]: warning.isActive && warning.field.includes(typeFunc),
            })}
            disabled={![spAdmin, corpAdmin].includes(role)}
            value={licensesState.spDomain[typeFunc]}
            onChange={(e) => onInputChange(typeFunc, e.target.validity.valid, e.target.value)}
            onBlur={(e) => onInputBlur(typeFunc, e.target.value)}
            onKeyUp={(e) => e.key === 'Enter' && e.target.blur()}
          />
        </span>
        {[spAdmin, corpAdmin].includes(role) && (
          <Button
            variant="link"
            className={styles.controlsItemButton}
            id="increasing-licenses-count"
            onClick={() => onIncrease(typeFunc)}>
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

InputControls.propTypes = {
  licenses: PropTypes.object,
  licensesState: PropTypes.object,
  role: PropTypes.string,
  setLicensesState: PropTypes.func,
  warning: PropTypes.object,
  setWarning: PropTypes.func,
  typeFunc: PropTypes.string,
  id: PropTypes.string,
  minimumLicensesCount: PropTypes.number,
};

export default InputControls;
