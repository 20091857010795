import PropTypes from 'prop-types';
import MsalProvider from 'providers/MsalProvider';
import { domainMsalConfig } from 'config/msal-config';
import { hideCrispChat } from 'helpers';
import DomainProvider from 'providers/DomainProvider';
import { useContext } from 'react';
import { BrandingContext, UserContext } from 'utils';
import { useLocation } from 'react-router-dom';
import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import LiveChat from '../LiveChat/LiveChat';
import styles from './Layout.module.scss';
import FreeTrialEndedModal from './FreeTrialEndedModal/FreeTrialEndedModal';

const Layout = ({ children }) => {
  const { user } = useContext(UserContext);
  const { brandingData } = useContext(BrandingContext);
  const { pathname } = useLocation();
  const { firstPaymentDone, domainWasAutovalidated } = user;
  const isProfilePages = pathname.includes('/profile');

  return (
    <DomainProvider>
      <MsalProvider config={domainMsalConfig}>
        {domainWasAutovalidated && (
          <>
            {!firstPaymentDone && !isProfilePages && <FreeTrialEndedModal user={user} />}
            {!firstPaymentDone && !isProfilePages && <div className={styles.layoutOverlay} />}
          </>
        )}
        <div className={styles.layoutContainer}>
          <Navbar user={user} brandingData={brandingData} />
          <div className={styles.layoutContent}>
            <Header user={user} brandingData={brandingData} />
            <div className={styles.layoutWrapper}>{children}</div>
          </div>
        </div>
        {hideCrispChat && <LiveChat user={user} brandingData={brandingData} />}
      </MsalProvider>
    </DomainProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
