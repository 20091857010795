import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, DropdownArray } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { techAdmin } from 'consts';
import styles from './SettingsHeader.module.scss';

const SettingsHeader = ({ role, domains, domainCurrent, isSaving, isMultipleSettings, isDisabled, onChangeDomain, onSaveSettings, onResetSettings }) => {
  const isTechAdmin = role === techAdmin;
  const history = useHistory();

  const goToPreviousPage = () => history.goBack();

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <Button id="settings-go-back" variant="link" icon={<ArrowLeftIcon />} onClick={goToPreviousPage} className={styles.headerLeftBackBtn} />
        <span className={styles.headerLeftTitle}>Settings</span>
        {isMultipleSettings && (
          <DropdownArray
            id="settings-domains-select"
            isSmall
            isSearch
            options={['My Settings', ...Object.values(domains).filter((d) => d.dnsName).map((d) => d.dnsName)]}
            defaultValue={domainCurrent}
            onChange={onChangeDomain}
            width="18.25em"
            className={styles.headerLeftDropdown}
            optionsClassName={styles.headerLeftDropdownOptions}
          />
        )}
      </div>
      <div className={styles.headerRight}>
        <Button
          type="button"
          id="settings-reset"
          size="40"
          variant="secondary"
          onClick={onResetSettings}
        >
          Reset All to Default
        </Button>
        {(isMultipleSettings && (!isTechAdmin || domainCurrent !== 'My Settings')) ? (
          <DropdownArray
            id="settings-save"
            isDisabled={isDisabled || isSaving}
            isButton
            btnTitle={isSaving ? 'Saving...' : 'Save'}
            options={['Save', 'Save As']}
            onChange={onSaveSettings}
            width="7.5em"
            optionsClassName={styles.headerRightDropdownOptions}
          />
        ) : (
          <Button
            type="button"
            id="settings-save"
            size="40"
            variant="primary"
            isDisabled={isDisabled}
            isLoading={isSaving}
            onClick={() => onSaveSettings('Save')}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

SettingsHeader.propTypes = {
  role: PropTypes.string,
  domains: PropTypes.object,
  domainCurrent: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isMultipleSettings: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChangeDomain: PropTypes.func.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
  onResetSettings: PropTypes.func.isRequired,
};

export default SettingsHeader;
