import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import styles from './HeaderActions.module.scss';

const HeaderActions = ({ selectedUsers, setSelectedUsers, rememberMeData, handleChangeRememberMeForUser, queryViewBy, isHeaderLoadingChanging }) => {
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  return (
    <div className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })} ref={counterRef}>
      <div className={styles.headerActionsCount}>{selectedUsers.length}/{rememberMeData.pageMeta?.TotalCount || 0} Selected</div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" size="40" onClick={() => setSelectedUsers([])}>Unselect All</Button>
        <Button variant="primary" className={styles.headerActionsButton} isLoading={isHeaderLoadingChanging} size="40" onClick={() => handleChangeRememberMeForUser(true)}>
          {queryViewBy === 'Enabled' ? 'Disable Remember Me' : 'Enable Remember Me'}
        </Button>
      </div>
    </div>
  );
};

HeaderActions.propTypes = {
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  rememberMeData: PropTypes.object,
  handleChangeRememberMeForUser: PropTypes.func,
  queryViewBy: PropTypes.string,
  isHeaderLoadingChanging: PropTypes.bool,
};

export default HeaderActions;
