import PropTypes from 'prop-types';
import { Button, StatusMessage, Table } from 'components';
import styles from './PhoneSetupTable.module.scss';
import { checkButtonAction, checkIsPhoneSetup } from '../../utils/phoneSetupUtils';

const PhoneSetupTable = ({
  phoneMgmtData,
  setPhoneMgmtData,
  baseUrl,
  selectedUsers,
  setSelectedUsers,
  toggleShowModalChangeUser,
}) => {
  const viewBy = phoneMgmtData?.queryConfig?.viewBy;

  const columns = [
    {
      checkbox: viewBy !== 'AllUsers' && viewBy !== 'UsersWithNoPhoneNumbers',
      name: 'Name',
      data: ({ displayName, upn }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Phone Number',
      data: ({ phoneNumber }) => phoneNumber || 'N/A',
      sortable: 'phoneNumber',
      width: '20%',
    },
    {
      name: 'Phone Setup',
      data: ({ phoneSetup }) => checkIsPhoneSetup(phoneSetup),
      sortable: 'phoneSetup',
      width: '20%',
    },
    {
      name: '',
      data: (user) => {
        const typeAction = checkButtonAction(user.phoneNumber, user.phoneSetup);
        const isShowButton = selectedUsers.length < 1 || !selectedUsers.includes(user);
        if (isShowButton) {
          return (
            <div className={styles.tableActions}>
              <Button variant="primary" size="32" onClick={() => toggleShowModalChangeUser(user, typeAction.type)}>
                {typeAction.text}
              </Button>
            </div>
          );
        }
      },
      width: '20%',
    },
  ];

  return phoneMgmtData?.data?.length < 1 && !phoneMgmtData?.queryConfig?.searchQuery ? (
    <div className={styles.statusMessage}>
      <StatusMessage header="No items available">Change view selection</StatusMessage>
    </div>
  ) : (
    <Table
      data={{
        data: phoneMgmtData.data,
        pageMeta: phoneMgmtData.pageMeta,
        queryConfig: phoneMgmtData.queryConfig,
      }}
      updateData={setPhoneMgmtData}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="phone-mgmt-table"
      dataKey="userId"
      baseUrl={baseUrl}
      className={styles.table}
    />
  );
};

PhoneSetupTable.propTypes = {
  phoneMgmtData: PropTypes.object,
  setPhoneMgmtData: PropTypes.func,
  baseUrl: PropTypes.string,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  toggleShowModalChangeUser: PropTypes.func,
};

export default PhoneSetupTable;
