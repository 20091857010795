import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { LoginContext } from 'utils';

const LoginProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInData, setSignInData] = useState(null);

  const contextValues = useMemo(() => ({
    email,
    setEmail,
    password,
    setPassword,
    signInData,
    setSignInData,
  }), [email, password, signInData]);

  return (
    <LoginContext.Provider value={contextValues}>
      {children}
    </LoginContext.Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.node,
};

export default LoginProvider;
