import PropTypes from 'prop-types';
import { Modal } from 'components';

const ToggleRemoteAppsModal = ({ toggleShowToggleRemoteAppsModal, showToggleRemoteAppsModal, type, onRemoteAppChange }) => (
  <Modal
    isOpen={showToggleRemoteAppsModal}
    onRequestClose={toggleShowToggleRemoteAppsModal}
    contentLabel={type ? 'Disable RemoteApp' : 'Enable RemoteApp'}
    actionFunc={onRemoteAppChange}
    actionTitle={type ? 'Disable' : 'Enable'}
  >
    <p>By clicking {type ? 'DISABLE' : 'ENABLE'} you will turn {type ? 'off' : 'on'} RemoteApp</p>
    <p>Changes may be applied in few minutes</p>
  </Modal>
);

ToggleRemoteAppsModal.propTypes = {
  toggleShowToggleRemoteAppsModal: PropTypes.func,
  showToggleRemoteAppsModal: PropTypes.bool,
  type: PropTypes.bool,
  onRemoteAppChange: PropTypes.func,
};

export default ToggleRemoteAppsModal;
