export const isAllChecked = (checkedSentries, sentries) => {
  const selectedSentriesIds = checkedSentries.map((i) => i.apiKey);
  const currentPageSentriesIds = sentries.map((i) => i.apiKey);

  if (currentPageSentriesIds.length === 0) {
    return 'empty';
  }

  const allSelected = currentPageSentriesIds.every((i) => selectedSentriesIds.includes(i));
  const someSelected = currentPageSentriesIds.some((i) => selectedSentriesIds.includes(i));

  if (allSelected) {
    return 'checked';
  }
  if (someSelected) {
    return 'partial';
  }
  return 'empty';
};

export const isOneChecked = (item, checkedSentries) => !!checkedSentries.find((value) => value.apiKey === item.apiKey);

export const onToggleAll = (isChecked, checkedSentries, sentries) => {
  let selectedSentriesNew = checkedSentries.map((i) => ({ ...i }));

  if (isChecked) {
    sentries.forEach((sentry) => {
      if (!selectedSentriesNew.some((i) => i.apiKey === sentry.apiKey) && sentry.isOnline) {
        selectedSentriesNew.push(sentry);
      }
    });
  } else {
    selectedSentriesNew = selectedSentriesNew.filter(
      (sentry) => !sentries.some((item) => item.apiKey === sentry.apiKey),
    );
  }
  return selectedSentriesNew;
};

export const onToggleOne = (checked, item, checkedSentries) => {
  if (checked) {
    return [...checkedSentries, item];
  }
  const selectedsentriesNew = checkedSentries.filter((selected) => selected.apiKey !== item.apiKey);
  return selectedsentriesNew;
};

export const checkIfAllSentriesAreOffline = (item) => {
  const isAllOnline = item.sentries.every((sentry) => !sentry.isOnline);
  return isAllOnline;
};

export const getNumberOfOutdatedSentries = (item) => {
  const outdatedSentries = item.sentries.length;
  return outdatedSentries;
};
