/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { queryFormatter, notify } from 'utils';
import { Loader } from 'components';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import AzureAdLoginStatus from './components/AzureAdLoginStatus/AzureAdLoginStatus';
import HeaderAction from './components/HeaderAction/HeaderAction';
import AzureAdLoginTitle from './components/AzureAdLoginTitle/AzureAdLoginTitle';
import AzureAdLoginTable from './components/AzureAdLoginTable/AzureAdLoginTable';
import NoUsers from './components/NoUsers/NoUsers';
import AzureAdLoginHeader from './components/AzureAdLoginHeader/AzureAdLoginHeader';
import { checkActionType } from './utils/azureAdLoginUtils';
import AzureAdLoginInfo from './components/AzureAdLoginInfo/AzureAdLoginInfo';
import styles from './AzureAdLogin.module.scss';

const AzureAdLogin = ({ domain, isCompanyPage }) => {
  const baseUrl = `/api/SecurityManagement/AzureAdLogin/Get?domainId=${domain.id}`;
  const [azureAdLoginData, setAzureAdLoginData] = useState();
  const [isAzureAdLoginEnabled, setIsAzureAdLoginEnabled] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showEnableModal, setShowEnableModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isHeaderBtnLoading, setIsHeaderBtnLoading] = useState(false);
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState([]);

  const toggleShowEnableModal = () => setShowEnableModal(!showEnableModal);

  const fetchData = useCallback(async () => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
        viewBy: 'All',
      };
      const res = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = res?.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
      if (res.status === 204) {
        setNoUsers(true);
      } else {
        setAzureAdLoginData({ data: res.data, pageMeta, queryConfig });
        setIsAzureAdLoginEnabled(res.data.isStaySignedInEnabled);
      }
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      setIsLoading(false);
    }
  }, [baseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeStaySignedInForUsers = async (isHeaderBtn, user) => {
    if (isHeaderBtn) {
      setIsHeaderBtnLoading(true);
    } else {
      setBtnLoadingCurrent((val) => [...val, user.userId]);
    }

    const url = '/api/SecurityManagement/AzureAdLogin/ChangeUserStatus';
    const selectedUserIds = isHeaderBtn ? selectedUsers.map((el) => el.userId) : [user.userId];
    try {
      await axios.post(url, selectedUserIds);
      const staySignedinDataNew = JSON.parse(JSON.stringify(azureAdLoginData));

      staySignedinDataNew.data.users.forEach((el) => {
        if (selectedUserIds.includes(el.userId)) {
          el.isAzureAdStaySignedInEnabled = !el.isAzureAdStaySignedInEnabled;
        }
      });

      setAzureAdLoginData(staySignedinDataNew);
      const notifyText = checkActionType(selectedUsers, user);
      notify.success(notifyText);
    } catch (err) {
      notify.error(err.message);
    } finally {
      if (isHeaderBtn) {
        setIsHeaderBtnLoading(false);
      } else {
        setBtnLoadingCurrent((val) => val.filter((i) => i !== user.userId));
      }
      if (selectedUsers.length) setSelectedUsers([]);
    }
  };

  return (
    <div className={styles.azureLogin}>
      <AzureAdLoginHeader domain={domain} isCompanyPage={isCompanyPage} />

      <AzureAdLoginTitle />
      {isLoading ? (
        <Loader />
      ) : noUsers ? (
        <NoUsers />
      ) : (
        <>
          {selectedUsers.length > 0 ? (
            <HeaderAction
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              onChangeStaySignedInForUsers={onChangeStaySignedInForUsers}
              azureAdLoginData={azureAdLoginData}
              isHeaderBtnLoading={isHeaderBtnLoading}
              btnLoadingCurrent={btnLoadingCurrent}
            />
          ) : (
            <AzureAdLoginStatus
              isAzureAdLoginEnabled={isAzureAdLoginEnabled}
              toggleShowEnableModal={toggleShowEnableModal}
            />
          )}

          {isAzureAdLoginEnabled ? (
            <AzureAdLoginTable
              azureAdLoginData={azureAdLoginData}
              setAzureAdLoginData={setAzureAdLoginData}
              baseUrl={baseUrl}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeStaySignedInForUsers={onChangeStaySignedInForUsers}
            />
          ) : (
            <AzureAdLoginInfo />
          )}
          {showEnableModal && (
            <ToggleEnableModal
              showEnableModal={showEnableModal}
              onRequestClose={toggleShowEnableModal}
              domain={domain}
              fetchData={fetchData}
              setAzureAdLoginData={setAzureAdLoginData}
              isAzureAdLoginEnabled={isAzureAdLoginEnabled}
              setIsAzureAdLoginEnabled={setIsAzureAdLoginEnabled}
            />
          )}
        </>
      )}
    </div>
  );
};

AzureAdLogin.propTypes = {
  domain: PropTypes.object.isRequired,
  isCompanyPage: PropTypes.bool,
};

export default AzureAdLogin;
