import PropTypes from 'prop-types';
import { DropdownArray, StatusMessage, SwitchCheckbox, Table } from 'components';
import { onChangeView } from 'utils';
import styles from './RememberDropdownTable.module.scss';

const RememberDropdownTable = ({
  rememberMeData,
  setRememberMeData,
  handleChangeRememberMeForUser,
  baseUrl,
  queryViewBy,
  selectedUsers,
  setSelectedUsers,
  userWhoseStatusChanging,
}) => {
  const paginationData = {
    queryConfig: rememberMeData?.queryConfig,
    updateAction: setRememberMeData,
    sortConfig: rememberMeData?.queryConfig?.orderBy?.split(/%20| /),
    baseUrl,
  };
  const columns = [
    {
      checkbox: queryViewBy !== 'All',
      name: 'User',
      data: ({ displayName, upn }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Remember Me Option',
      data: (user) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => handleChangeRememberMeForUser(false, user)}
            checked={user.isRememberMeEnabled}
            disabled={selectedUsers.length > 0 || userWhoseStatusChanging === user.userId}
          />
          {user.isRememberMeEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isRememberMeEnabled',
      width: '60%',
    },
  ];

  return (
    <>
      {selectedUsers.length <= 0 && (
        <div className={styles.dropdown}>
          <DropdownArray
            id="ra-app-select"
            isSmall={false}
            isSearch={false}
            isFilter
            label="View by:"
            options={['All Users', 'Remember Me Disabled', 'Remember Me Enabled']}
            defaultValueHtml={
              queryViewBy === 'All' ? (
                <>
                  All Users <b>({rememberMeData?.pageMeta?.TotalCount})</b>
                </>
              ) : (
                <>
                  Remember Me {queryViewBy} <b>({rememberMeData?.pageMeta?.TotalCount})</b>
                </>
              )
            }
            onChange={(value) => onChangeView(value, paginationData)}
            width="20.3125em"
          />
        </div>
      )}
      {rememberMeData?.data?.users.length < 1 && !rememberMeData?.queryConfig?.searchQuery ? (
        <div className={styles.statusMessage}>
          <StatusMessage header="No items available">Change view selection</StatusMessage>
        </div>
      ) : (
        <Table
          data={{
            data: rememberMeData.data.users,
            pageMeta: rememberMeData.pageMeta,
            queryConfig: rememberMeData.queryConfig,
          }}
          updateData={setRememberMeData}
          selectedItems={selectedUsers}
          setSelectedItems={setSelectedUsers}
          columns={columns}
          id="remember-me-table"
          dataKey="userId"
          baseUrl={baseUrl}
          className={styles.table}
        />
      )}
    </>
  );
};

RememberDropdownTable.propTypes = {
  rememberMeData: PropTypes.object,
  setRememberMeData: PropTypes.func,
  handleChangeRememberMeForUser: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  queryViewBy: PropTypes.string.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func,
  userWhoseStatusChanging: PropTypes.string,
};

export default RememberDropdownTable;
