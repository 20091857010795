import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatPhoneNumber, formattedDate, setupMfaType } from 'helpers';
import styles from './MfaDetailsCell.module.scss';
import { mfaInfo } from './utils/mfaInfo';

const MfaDetailsCell = ({ isResetView, user }) => {
  const { dateOfReset, setUpDate, phoneCountryCode, phoneNumber, userEmail, mfaType } = user;
  const mfaSetupType = setupMfaType(mfaType);
  const phone = formatPhoneNumber(phoneCountryCode, phoneNumber);
  let date = 'N/A';
  if (isResetView && dateOfReset) {
    date = formattedDate(dateOfReset);
  } else if (setUpDate) {
    date = formattedDate(setUpDate);
  }

  const mfa = mfaInfo(mfaSetupType, userEmail, phone);

  return (
    <div className={styles.mfaSetupType}>
      <div className={styles.mfaSetupTypeWrap}>
        <span className={styles.mfaSetupTypeLabel}>{isResetView ? 'Reset Date:' : 'Setup Date:'}</span>
        <span className={cn(styles.mfaSetupTypeValue, styles.date)}>{date}</span>
      </div>
      <div className={styles.mfaSetupTypeWrap}>
        <span className={styles.mfaSetupTypeLabel}>{isResetView ? 'Phone:' : 'MFA Type:'}</span>
        <span className={styles.mfaSetupTypeValue}>{isResetView ? phone : mfa}</span>
      </div>
    </div>
  );
};

MfaDetailsCell.propTypes = {
  isResetView: PropTypes.bool,
  user: PropTypes.object,
};

export default MfaDetailsCell;
