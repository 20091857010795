import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Modal } from 'components';

const ModalConfirmReset = ({ showModal, handleShowModal, signInData }) => {
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = () => {
    setLoading(true);
    const { userId } = signInData;
    axios({
      method: 'post',
      url: '/api/RegisteredUser/ForgotPassword',
      data: userId,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        toast.success('Reset link sent', 3000);
        handleShowModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.message, 3000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleShowModal}
      contentLabel="Password Reset"
      actionFunc={handleForgotPassword}
      actionTitle="Reset"
      actionLoading={loading}
    >
      <p>By clicking <strong>RESET</strong> you will reset you password. A link will be sent to your email address</p>
    </Modal>
  );
};

ModalConfirmReset.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  signInData: PropTypes.object.isRequired,
};

export default ModalConfirmReset;
