import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'components/Link/Link';
import { CompanyIcon, MinusIcon, PlusIcon } from 'assets/img';
import { useContext, useRef } from 'react';
import { corpAdmin, corpTechAdmin, custAdmin, spTechAdmin, techAdmin } from 'consts';
import { UserContext } from 'utils';
import styles from '../NavbarItem.module.scss';

const CompanyItems = ({
  location,
  role,
  isCompanyOpen,
  domainStatus,
  onCompanyToggle,
  brandingData,
  isBrandingActive,
  isInvertedColors,
  blockLeftMenu,
}) => {
  const color = isInvertedColors ? { color: '#000' } : { color: '#fff' };
  const { user } = useContext(UserContext);

  const nodeRef = useRef();
  let companyPages = [
    {
      label: 'Resource Assignment',
      link: '/company-management/resource-assignments',
    },
    {
      label: 'BitLocker Management',
      link: '/company-management/device-management',
    },
    {
      label: 'Active Directory Federation',
      link: '/company-management/active-directory-federation',
    },
    {
      label: 'TruScan ID Monitoring Reports',
      link: '/company-management/truscan-monitoring',
    },
    {
      label: 'Roles Management',
      link: '/company-management/roles-management',
    },
    {
      label: 'Security Management',
      link: '/company-management/security-management',
      subPages: [
        '/company-management/security-management/shortcut-management',
        '/company-management/security-management/timeout-management',
        '/company-management/security-management/remember-me',
      ],
    },
    {
      label: 'Domain Management',
      link: '/company-management/domain-management',
    },
    {
      label: 'Reports',
      link: '/company-management/reports',
    },
  ];

  if ([custAdmin, corpTechAdmin].includes(role)) {
    companyPages = companyPages.filter(
      (i) => !['Active Directory Federation', 'TruScan ID Monitoring Reports', 'Roles Management'].includes(i.label),
    );
  }
  if ([custAdmin, techAdmin].includes(role)) {
    companyPages = companyPages.filter((i) => !['Domain Management'].includes(i.label));
  }
  if ([spTechAdmin, techAdmin].includes(role)) {
    companyPages = companyPages.filter((i) => i.label !== 'Roles Management');
  }
  if (role === corpAdmin) {
    companyPages = companyPages.filter(
      (i) => !['Active Directory Federation', 'TruScan ID Monitoring Reports'].includes(i.label),
    );
  }
  if (!user?.isTruscanAvailable) {
    companyPages = companyPages.filter((i) => i.label !== 'TruScan ID Monitoring Reports');
  }

  return (
    <>
      <li
        className={cn(styles.item, {
          [styles.itemActive]: location.pathname.includes('company-management') && !isCompanyOpen,
          [styles.itemDisabled]: !domainStatus || blockLeftMenu,
          [styles.branding]: isBrandingActive && !isInvertedColors,
          [styles.inverted]: isBrandingActive && isInvertedColors,
        })}
        id="company-management"
        data-tip="Manage my company account and resources"
        data-iscapture="true"
        onClick={onCompanyToggle}>
        <span className={styles.itemLink}>
          <CompanyIcon />
          <span>Company</span>
          {isCompanyOpen ? <MinusIcon /> : <PlusIcon />}
        </span>
      </li>
      {isCompanyOpen && (
        <ul className={styles.itemSublist} ref={nodeRef}>
          {companyPages.map((item) => (
            <li
              key={item.label}
              className={cn(styles.item, {
                [styles.active]:
                  location.pathname.includes('company-management') &&
                  (location.pathname === item.link || item?.subPages?.includes(location.pathname)),
                [styles.branding]: isBrandingActive && !isInvertedColors,
                [styles.inverted]: isBrandingActive && isInvertedColors,
              })}>
              <span style={isBrandingActive && brandingData?.backGroundColor ? { background: color.color } : {}} />
              <Link
                to={item.link}
                id={item.label.split(' ').join('-').toLowerCase()}
                className={cn(styles.itemLink, styles.itemSublistLink)}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

CompanyItems.propTypes = {
  location: PropTypes.object,
  role: PropTypes.string,
  isCompanyOpen: PropTypes.bool,
  domainStatus: PropTypes.number,
  onCompanyToggle: PropTypes.func,
  brandingData: PropTypes.object,
  isBrandingActive: PropTypes.bool,
  isInvertedColors: PropTypes.bool,
  blockLeftMenu: PropTypes.bool,
};

export default CompanyItems;
