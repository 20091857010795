import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { queryFormatter } from 'utils';
import { Modal } from 'components';
import styles from './DeleteDevicesModal.module.scss';

const DeleteDevicesModal = ({
  deleteDevicesModalShow,
  closeDeleteDevicesModalShow,
  selectedDeviceIds,
  setSelectedDeviceIds,
  baseUrl,
  queryConfig,
  onUpdateHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteDevices = () => {
    setIsLoading(true);

    const params = {
      url: '/api/AgentUninstallation/DeleteDevices',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      data: selectedDeviceIds,
    };

    axios(params)
      .then(() => axios.get(queryFormatter(baseUrl, { ...queryConfig, pageNumber: 1 })))
      .then((resp) => {
        const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;
        const devicesNew = {
          data: resp.data,
          pageMeta,
          queryConfig: { ...queryConfig },
        };

        onUpdateHandler(devicesNew, true);
        if (selectedDeviceIds.length === 1) {
          toast.success('Device is deleted', 3000);
        } else {
          toast.success('Devices are deleted', 3000);
        }
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => {
        setIsLoading(false);
        closeDeleteDevicesModalShow();
        setSelectedDeviceIds([]);
      });
  };

  return (
    <Modal
      isOpen={deleteDevicesModalShow}
      onRequestClose={closeDeleteDevicesModalShow}
      contentLabel="Delete Devices"
      actionFunc={deleteDevices}
      actionTitle={isLoading ? 'Deleting...' : 'Delete'}
      variantBtn="deleteOutline"
      actionDisabled={isLoading}
      style={{ content: { width: '39em' } }}>
      <div className={styles.deleteDevicesInfo}>
        This DELETE feature is recommended for systems that have been re-imaged, decommissioned, lost, or which you
        cannot uninstall the TruGrid device software
      </div>
      <p>
        Are you sure you want to permanently remove <b>{selectedDeviceIds.length}</b> devices
        {selectedDeviceIds.length > 1 ? 'es' : null} ?
      </p>
    </Modal>
  );
};

DeleteDevicesModal.propTypes = {
  deleteDevicesModalShow: PropTypes.bool,
  closeDeleteDevicesModalShow: PropTypes.func,
  selectedDeviceIds: PropTypes.array,
  setSelectedDeviceIds: PropTypes.func,
  baseUrl: PropTypes.string,
  queryConfig: PropTypes.object,
  onUpdateHandler: PropTypes.func,
};

export default DeleteDevicesModal;
