import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useState, useMemo, useEffect } from 'react';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { notify } from 'utils';
import { Loader } from 'components';
import styles from './SetupViaSms.module.scss';
import EnterPhoneNumber from './components/EnterPhoneNumber/EnterPhoneNumber';
import VerificationCode from './components/VerificationCode/VerificationCode';

const SetupViaSms = () => {
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [countriesList, setCountriesList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pinHash, setPinHash] = useState('');
  const [phoneMask, setPhoneMask] = useState('');

  useEffect(() => {
    loginService.getCountriesList()
      .then((resp) => setCountriesList(JSON.parse(resp.data)))
      .catch((err) => notify.error(err.response?.data || err.message))
      .finally(() => setIsLoading(false));
  }, []);

  const dialCode = useMemo(() => countriesList[currentCountry]?.dial_code, [countriesList, currentCountry]);

  return (
    <div className={styles.setupMfa}>
      <LoginHeader title="Verify Your Phone" stepCount="2" currentStep={pathname === '/setup-mfa/sms' ? '1' : '2'} />
      {isLoading ? (
        <Loader style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <Switch>
          <Route path={path} exact>
            <EnterPhoneNumber
              countriesList={countriesList}
              dialCode={dialCode}
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setPinHash={setPinHash}
              setPhoneMask={setPhoneMask}
            />
          </Route>
          <Route path={`${url}/validation-code`}>
            <VerificationCode
              phoneNumber={phoneNumber}
              dialCode={dialCode}
              pinHash={pinHash}
              setPinHash={setPinHash}
              phoneMask={phoneMask}
              setPhoneMask={setPhoneMask}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default SetupViaSms;
