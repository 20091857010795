import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { PlusIcon } from 'assets/img';
import { Button } from 'components';
import { truncateString } from 'utils';
import styles from './AddNewDomain.module.scss';

const AddNewDomain = ({ domains, fetchData, user }) => {
  const [newDomainName, setNewDomainName] = useState('');
  const [addDomainState, setAddDomainState] = useState('disabled');

  const onNewDomainChange = (value) => {
    setNewDomainName(value);
    // check if value has a dot and text after
    const isValid = value.split('.')[1]?.length;
    setAddDomainState(isValid ? '' : 'disabled');
  };

  const onNewDomainAdd = () => {
    const domainNames = domains.data.map((d) => d.dnsName);

    if (domainNames.includes(newDomainName)) {
      toast.error(`${newDomainName} already exists`, 3000);
    } else {
      setAddDomainState('loading');

      const url = `/api/Domains/CreateNotValidCustomerDomain?domainId=${user.domainId}`;

      axios.post(url, { validDnsName: newDomainName })
        .then(() => {
          fetchData({ ...domains.queryConfig, pageNumber: 1 });
          toast.success(`Domain ${truncateString(newDomainName, false)} has been added`, 3000);
        })
        .catch((err) => (err.response?.data ? toast.warn(err.response.data, 3000) : toast.error(err.message, 3000)))
        .finally(() => {
          setNewDomainName('');
          setAddDomainState('disabled');
        });
    }
  };

  const onNewDomainKeyDown = (event) => {
    const isValid = newDomainName?.split('.')[1]?.length;
    if (event.key === 'Enter' && isValid) {
      onNewDomainAdd();
    }
  };

  return (
    <div className={styles.addDomain}>
      <div className={styles.addDomainWrap}>
        <input
          type="search"
          id="customers-new-domain"
          className={styles.addDomainInput}
          placeholder="Add New Domain"
          value={newDomainName}
          onChange={(e) => onNewDomainChange(e.target.value)}
          onKeyDown={onNewDomainKeyDown}
        />
        <PlusIcon />
      </div>
      <Button
        type="button"
        id="save-new-customer-domain"
        variant="primary"
        size="40"
        outline
        isLoading={addDomainState === 'loading'}
        isDisabled={addDomainState === 'disabled'}
        onClick={onNewDomainAdd}
      >
        Save
      </Button>
    </div>
  );
};

AddNewDomain.propTypes = {
  domains: PropTypes.object,
  fetchData: PropTypes.func,
  user: PropTypes.object,
};

export default AddNewDomain;
