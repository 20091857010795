/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { MsalContext, getUserRole, UserContext } from 'utils';
import { AlertValidateDomain, Loader } from 'components';
import { getDomainStatus } from 'helpers';
import styles from './withCompany.module.scss';

export const withCompany = (Component) => () => {
  const { user, onUpdateUser } = useContext(UserContext);
  const { isLoading: isLoadingMs } = useContext(MsalContext);
  const role = getUserRole(user);
  const domainData = {
    id: user.domainId,
    dnsName: user.dnsDomainName,
    sentries: user.sentries,
    status: user.domainStatus,
    origin: user.domainOrigin,
    activationCode: user.domainActivationCode,
    isAppPublishingEnabled: user.isAppPublishingEnabled,
    isServiceProvider: user.spApplicationWasApproved,
    isCompanyPage: true,
  };

  if ('appPublishingCurrent' in user) {
    domainData.appPublishingCurrent = user.appPublishingCurrent;
  }

  const onUpdateAppPublishing = (status, isCurrent) => {
    const userNew = JSON.parse(JSON.stringify(user));
    if (isCurrent) {
      userNew.appPublishingCurrent = status;
    } else {
      userNew.isAppPublishingEnabled = status;
      delete userNew.appPublishingCurrent;
    }
    onUpdateUser(userNew);
  };

  const { isDomainNotActivated } = getDomainStatus(domainData);
  const { finalTimeToValidateDomain, domainWasAutovalidated } = user;

  if (!user.domainStatus) {
    return <Redirect to="/workspace" />;
  }

  if (isLoadingMs) {
    return (
      <div className={styles.company}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {domainWasAutovalidated && finalTimeToValidateDomain && <AlertValidateDomain />}
      <div className={styles.company}>
        <Component
          domain={domainData}
          isCompanyPage
          userRole={role}
          onUpdateAppPublishing={onUpdateAppPublishing}
          isDomainNotActivated={isDomainNotActivated}
        />
      </div>
    </>
  );
};
