import PropTypes from 'prop-types';
import styles from './LicensesControls.module.scss';
import InputControls from '../InputControls/InputControls';

const LicensesControls = ({
  licenses,
  licensesState,
  role,
  setLicensesState,
  warning,
  setWarning,
  minimumLicensesCount,
}) => (
  <div className={styles.controls}>
    <h6 className={styles.controlsTitle}>Primary Domain Licenses</h6>
    <div className={styles.controlsWrap}>
      <InputControls
        warning={warning}
        setWarning={setWarning}
        licensesState={licensesState}
        setLicensesState={setLicensesState}
        licenses={licenses}
        role={role}
        minimumLicensesCount={minimumLicensesCount}
        typeFunc="numberOfRdpLicenses"
        id="subs-primary-rdp-input"
      />
      <InputControls
        warning={warning}
        setWarning={setWarning}
        licensesState={licensesState}
        setLicensesState={setLicensesState}
        licenses={licenses}
        role={role}
        minimumLicensesCount={minimumLicensesCount}
        typeFunc="numberOfBitLockerLicenses"
        id="subs-primary-bl-input"
      />
    </div>
    <div className={styles.controlsWarning}>{warning.isActive ? warning.content : null}</div>
  </div>
);

LicensesControls.propTypes = {
  licenses: PropTypes.object,
  licensesState: PropTypes.object,
  role: PropTypes.string,
  setLicensesState: PropTypes.func,
  warning: PropTypes.object,
  setWarning: PropTypes.func,
  minimumLicensesCount: PropTypes.number,
};

export default LicensesControls;
