import PropTypes from 'prop-types';
import { Link, StatusMessage } from 'components';
import { getDomainADStatus } from 'helpers';

const NoComputersMsg = ({ domain }) => {
  const { isDomainAzure } = getDomainADStatus(domain);
  return (
    <StatusMessage header="No computers reporting in">
      {isDomainAzure ? (
        <p>Please&nbsp;<Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>add TruGrid SecureConnect</Link></p>
      ) : (
        <p>Please check Active Directory or&nbsp;<Link href="https://www.trugrid.com/docs/installsentry" external bold>add computers</Link></p>
      )}
    </StatusMessage>
  );
};

NoComputersMsg.propTypes = {
  domain: PropTypes.object,
};

export default NoComputersMsg;
