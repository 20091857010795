/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { queryFormatter, onChangePage } from 'utils';
import { Button, StatusMessage, Table } from 'components';
import AssignmentsRefresh from '../AssignmentsRefresh/AssignmentsRefresh';
import TableActions from './components/TableActions/TableActions';
import styles from './DesktopAssignments.module.scss';
import NoComputers from './components/NoComputers/NoComputers';

const DesktopAssignments = ({ deskAssignments, domainData, baseUrl, onUpdate, openManageAssignmentsModal }) => {
  const inputRef = useRef();
  const queryViewBy = deskAssignments?.queryConfig?.viewBy;
  const sortConfig = deskAssignments?.queryConfig.orderBy.split(/%20| /);
  const paginationData = {
    queryConfig: deskAssignments?.queryConfig,
    updateAction: onUpdate,
    sortConfig,
    inputRef,
    baseUrl,
  };

  useEffect(() => {
    if (deskAssignments?.queryConfig?.searchQuery) {
      const query = { ...deskAssignments.queryConfig, searchQuery: '' };
      onChangePage([queryFormatter(baseUrl, query), query], paginationData);
    }
  }, []);

  const onRefreshDataFromAd = () =>
    new Promise((resolve, reject) =>
      axios
        .get(queryFormatter(baseUrl, deskAssignments.queryConfig))
        .then((resp) => {
          onUpdate({
            data: resp.data,
            pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
            queryConfig: { ...deskAssignments.queryConfig },
          });
          return resolve();
        })
        .catch((err) => reject(err)),
    );

  if (deskAssignments.isError) {
    return <StatusMessage error>{deskAssignments.error}</StatusMessage>;
  }

  const columns = [
    {
      name: queryViewBy === 'Users' ? 'User' : 'Computer',
      sortable: 'name',
      width: '38%',
      data: ({ name }) => name || 'N/A',
    },
    {
      name: queryViewBy === 'Users' ? 'Domain / UPN' : 'Domain',
      sortable: 'domainName',
      width: '38%',
      data: ({ domainName }) => domainName || 'N/A',
    },
    {
      name: `Assigned ${queryViewBy === 'Users' ? 'Computers' : 'Users'}`,
      width: '24%',
      data: ({ assignmentsCount, name, resourceId, isPooled }) => (
        <div>
          <span>{assignmentsCount}</span>
          <Button
            type="button"
            id="manage-desktop-assignments"
            variant="primary"
            size="32"
            className={styles.assignmentsButton}
            onClick={() =>
              openManageAssignmentsModal({
                type: 'manage',
                assignType: 'Computer',
                domainId: domainData.id,
                viewBy: queryViewBy,
                resourceName: name || 'N/A',
                resourceId,
                isPooled,
              })
            }>
            Manage assignments
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <header className={styles.header}>
        <TableActions queryViewBy={queryViewBy} paginationData={paginationData} />
        <AssignmentsRefresh domainData={domainData} onUpdate={onRefreshDataFromAd} />
      </header>

      <div
        style={
          deskAssignments.data.length || deskAssignments.queryConfig.searchQuery ? {} : { height: 'calc(100% - 5.5em)' }
        }>
        <Table
          columns={columns}
          data={deskAssignments}
          updateData={onUpdate}
          dataKey="resourceId"
          noData={<NoComputers />}
          baseUrl={baseUrl}
          className={styles.assignmentsTable}
        />
      </div>
    </>
  );
};

DesktopAssignments.propTypes = {
  deskAssignments: PropTypes.object.isRequired,
  domainData: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  openManageAssignmentsModal: PropTypes.func,
};

export default DesktopAssignments;
