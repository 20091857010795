import PropTypes from 'prop-types';
import { Button, DropdownArray, Link } from 'components';
import { DownloadIcon, FileIcon, LoaderIcon } from 'assets/img';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { dateFormatterUtc } from 'helpers';
import { custAdmin, techAdmin } from 'consts';
import styles from './LicensesActions.module.scss';

const LicensesActions = ({
  isCompanyPage,
  selectedLicenseType,
  onChangeLicenseType,
  isLoading,
  licensesCount,
  userRole,
  setShowExportDetailsModal,
  isFastGenerate,
  licensingReportData,
  saveFile,
}) => (
  <div className={styles.licensesActions}>
    <div className={styles.licensesActionsFilter}>
      <DropdownArray
        id="reports-select-licenses"
        isSmall={false}
        isSearch={false}
        options={['SecureRDP', 'BitLocker']}
        defaultValue={selectedLicenseType}
        onChange={onChangeLicenseType}
        width="12em"
      />
      <LoaderIcon className={cn(styles.licensesActionsLoad, { [styles.licensesActionsLoadActive]: isLoading })} />
      {(isCompanyPage && userRole !== custAdmin) && (
        <span>Total Purchased Licenses <b id="licenses-count">({licensesCount})</b></span>
      )}
    </div>

    <div className={styles.licensesActionsButtons}>
      {![techAdmin, custAdmin].includes(userRole) && (
        <Link to="/profile/subscription" id="adjust-licensing" className={styles.licensesActionsButtonsLink}>Adjust Licensing</Link>
      )}
      {selectedLicenseType === 'SecureRDP' && (
      <>
        <Button
          className={styles.licensesActionsButtonsGenerate}
          id="generate-report"
          variant="secondary"
          size="32"
          isLoading={!isFastGenerate}
          onClick={() => setShowExportDetailsModal(true)}
          icon={isFastGenerate && <FileIcon />}
        >
          {isFastGenerate ? 'Generate Report' : 'Generating Report...'}
        </Button>
        {licensingReportData && (
        <>
          <Button
            type="button"
            id="download-report"
            variant="link"
            onClick={() => saveFile(licensingReportData)}
            className={styles.licensesActionsButtonsDownload}
          >
            <span data-for="download" data-tip={dateFormatterUtc(licensingReportData?.createdAt, true)}><DownloadIcon /></span>
          </Button>
          <ReactTooltip
            id="download"
            type="light"
            place="top"
            offset={{ left: 60 }}
            effect="solid"
            className={styles.setPassTooltipComponent}
          />
        </>
        )}
      </>
      )}
    </div>
  </div>
);

LicensesActions.propTypes = {
  isCompanyPage: PropTypes.bool,
  selectedLicenseType: PropTypes.string,
  onChangeLicenseType: PropTypes.func,
  isLoading: PropTypes.bool,
  licensesCount: PropTypes.number,
  userRole: PropTypes.string,
  setShowExportDetailsModal: PropTypes.func,
  isFastGenerate: PropTypes.bool,
  licensingReportData: PropTypes.object,
  saveFile: PropTypes.func,
};

export default LicensesActions;
