import { InitScanIcon } from 'assets/img';
import PropTypes from 'prop-types';
import styles from '../StatusCell.module.scss';

const CompromisedAccDiscovered = ({ item }) => {
  const { typeStr, totalLeakedCnt } = item;

  return (
    <div className={styles.statusCell}>
      <p className={styles.statusWrapper}><InitScanIcon /><span className={styles.initial}>{typeStr}</span></p>
      <p className={styles.statusCompleteRow}>{totalLeakedCnt} compromised accounts discovered</p>
      <p className={styles.statusTextRow}>
        <span>Click <b>Discovery Scan</b> and run a FREE TruScan Discovery report for an overview, or purchase a
          TruScan Full report for entire listing with 1 year of monitoring.
        </span>
      </p>
    </div>
  );
};

CompromisedAccDiscovered.propTypes = {
  item: PropTypes.object,
};

export default CompromisedAccDiscovered;
