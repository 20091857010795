import PropTypes from 'prop-types';
import { useDevicesEnrollment } from 'hooks';
import { FileIcon } from 'assets/img';
import { DropdownArray } from 'components';
import styles from './NoDevices.module.scss';

const NoDevices = ({ domainId }) => {
  const { isEnrollmentLoading, getEnrollmentFile } = useDevicesEnrollment(domainId);
  return (
    <div className={styles.noDevices}>
      <p className={styles.noDevicesTitle}>No devices reporting in</p>
      <p className={styles.noDevicesSubtitle}>Please install agent using Enrollment File below</p>
      <DropdownArray
        id="dm-devices-enroll"
        isButton
        isDisabled={isEnrollmentLoading}
        icon={<FileIcon />}
        btnTitle={isEnrollmentLoading ? 'Loading...' : 'Enrollment File'}
        options={[{ icon: 'download', value: 'Download' }, { icon: 'copy', value: 'Copy Link' }]}
        onChange={getEnrollmentFile}
        className={styles.devicesHeaderEnrollment}
        optionsClassName={styles.devicesHeaderEnrollmentDropdown}
        width="13em"
      />
    </div>
  );
};

NoDevices.propTypes = {
  domainId: PropTypes.string.isRequired,
};

export default NoDevices;
