import { Link, StatusMessage } from 'components';

const NoDomains = () => (
  <StatusMessage header="No domains added">
    Please{' '}
    <Link href="https://www.trugrid.com/docs/addtruscandomain" external bold>
      add a non-free domain
    </Link>{' '}
    for monitoring
  </StatusMessage>
);

export default NoDomains;
