import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../BrandedLinks.module.scss';

const Contact = ({ contactInformation }) => {
  const { contactInfo, address, city, state, zipCode, country } = contactInformation;

  return (
    <ul className={cn(styles.linksDropdownList, styles.contact)}>
      {contactInfo && (
        <li>{contactInfo}</li>
      )}
      {address && (
        <li><span>Address: </span><span>{address}</span></li>
      )}
      {city && (
        <li><span>City: </span><span>{city}</span></li>
      )}
      {state && (
        <li><span>State: </span><span>{state}</span></li>
      )}
      {zipCode && (
        <li><span>Zip Code: </span><span>{zipCode}</span></li>
      )}
      {country && (
        <li><span>Country: </span><span>{country}</span></li>
      )}
    </ul>
  );
};

Contact.propTypes = {
  contactInformation: PropTypes.object,
};

export default Contact;
