import { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'utils/contexts';
import ActiveDirectorySetup from 'components/ActiveDirectorySetup/ActiveDirectorySetup';
import NoSentryInstalled from './components/NoSentryInstalled/NoSentryInstalled';

const DomainStatusMsg = ({ domainData, isSentryDeleted, pageName }) => {
  const { user } = useContext(UserContext);
  const activationCode = domainData.activationCode || domainData.domainActivationCode;

  if (isSentryDeleted) {
    return (
      <NoSentryInstalled pageName={pageName} activationCode={activationCode} downloadSentryLink={user.downloadSentryLink} />
    );
  }
  return (
    <ActiveDirectorySetup domain={domainData} isCompanyPage={domainData?.isCompanyPage} />
  );
};

DomainStatusMsg.propTypes = {
  domainData: PropTypes.object.isRequired,
  isSentryDeleted: PropTypes.bool,
  pageName: PropTypes.string,
};

export default DomainStatusMsg;
