import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { LoginContext } from 'utils';
import ResetViaSMS from './pages/ResetViaSMS/ResetViaSMS';
import ResetViaEmail from './pages/ResetViaEmail/ResetViaEmail';

const ResetMfa = () => {
  const { url } = useRouteMatch();
  const { email, signInData } = useContext(LoginContext);
  const { isRegisteredUser: isAdmin, emailMask, phoneMask } = signInData;

  return (
    <Switch>
      <Route path={`${url}/sms`}>
        <ResetViaSMS
          email={email}
          phoneMask={phoneMask}
          isAdmin={isAdmin}
        />
      </Route>
      <Route path={`${url}/email`}>
        <ResetViaEmail
          emailMask={emailMask}
          isAdmin={isAdmin}
        />
      </Route>
      <Route path="*" render={() => <Redirect to="/login/sign-in" />} />
    </Switch>
  );
};

export default ResetMfa;
