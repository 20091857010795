import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { InfoActiveIcon, InfoIcon } from 'assets/img';
import styles from './MfaInfo.module.scss';

const MfaInfo = memo(({ inHead = false, name, promotedFromAdFederation, promotedFromRolesMngmt, index, isAllView }) => {
  let message;
  if (promotedFromRolesMngmt && promotedFromAdFederation) {
    message = 'Roles Management and Active Directory Federation';
  } else if (promotedFromRolesMngmt && !promotedFromAdFederation) {
    message = 'Roles Management';
  } else if (!promotedFromRolesMngmt && promotedFromAdFederation) {
    message = 'Active Directory Federation';
  }
  let CONTENT = {
    Enabled: `<ul><li><b>MFA is required</b></li><li>Admin promoted from <b>${message}</b></li></ul>`,
    'Setup Pending': `<ul><li><b>MFA setup is required</b></li><li>Admin promoted from <b>${message}</b></li></ul>`,
    Disabled: '<b>MFA Disabled:</b> Users with MFA disabled',
    'in Reset Mode': `<ul><li><b>MFA setup is required</b></li><li>Admin promoted from <b>${message}</b></li></ul>`,
    'inner table': 'MFA is required',
  };
  if (inHead) {
    CONTENT = {
      Enabled: '<b>MFA Enabled:</b> Users with MFA enabled',
      'Setup Pending': '<b>MFA Setup Pending:</b> Users who have not setup MFA',
      Disabled: '<b>MFA Disabled:</b> Users with MFA disabled',
      'in Reset Mode': '<b>MFA in Reset Mode:</b> Users who need to re-setup MFA',
      'inner table': 'MFA is required',
    };
  }
  const [isInfoActive, setIsInfoActive] = useState(false);
  const itemId = `smt-${name}-${index || 0}`;

  return (
    <>
      <div
        className={isAllView ? styles.smtWrapper : styles.smtHeadWrapper}
        onMouseEnter={() => setIsInfoActive(true)}
        onMouseLeave={() => setIsInfoActive(false)}
      >
        <div
          data-for={itemId}
          data-tip={CONTENT[name]}
          data-iscapture="true"
          className={isAllView ? styles.tooltip : styles.headTooltip}
        />
        { isInfoActive ? <InfoActiveIcon /> : <InfoIcon /> }
      </div>
      <ReactTooltip
        id={itemId}
        type="light"
        place="right"
        html
        effect="solid"
        className={styles.tooltipWrapper}
      />
    </>
  );
});

MfaInfo.displayName = 'MfaInfo';

MfaInfo.propTypes = {
  inHead: PropTypes.bool,
  name: PropTypes.string.isRequired,
  promotedFromAdFederation: PropTypes.bool,
  promotedFromRolesMngmt: PropTypes.bool,
  index: PropTypes.number,
  isAllView: PropTypes.bool,
};

export default MfaInfo;
