import { Button, TextInput } from 'components';
import { checkIsEmailValid } from 'helpers';
import BlockedErrorMsg from 'pages/Login/components/BlockedErrorMsg/BlockedErrorMsg';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { loginService } from 'services';
import { LoginContext, notify } from 'utils';
import styles from './LoginDefault.module.scss';

const LoginDefault = () => {
  const { email, setEmail, setSignInData } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState('');

  const history = useHistory();
  const { url } = useRouteMatch();

  const setEmailValue = (e) => {
    let { value } = e.target;
    value = value.replace(/\s/g, '');
    setEmail(value);
  };

  const clearEmailValue = () => {
    setEmail('');
  };

  const onEmailCheck = (e) => {
    e.preventDefault();
    const isEmailValueValid = checkIsEmailValid(email);

    if (isEmailValueValid) {
      setIsLoading(true);
      const { host } = window.location;
      const hostName = !process.env.REACT_APP_PUBLIC_URL.includes(host) ? host : null;
      loginService
        .login(email, hostName)
        .then(({ data }) => {
          setSignInData(data);
          setIsLoading(false);
          if (data.requiresEmailValidation) {
            history.push('/email-validation');
          } else if (data.hasTempPassword) {
            history.push(`${url}/temp-password`);
          } else if (data.isAzureAdUser) {
            history.push(`${url}/azure`);
          } else {
            history.push(`${url}/sign-in`);
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            notify.error(BlockedErrorMsg);
          } else if (err.response.status === 405) {
            notify.warning(
              'Please login at trugrid.net with your primary login in order to access TruGrid as an Administrator',
              5000,
            );
          } else if (err.response.status === 406) {
            setIsEmailError('Invalid login email address');
          } else {
            notify.error(err.response.data || err.message);
          }
          setIsLoading(false);
        });
    } else {
      setIsEmailError('Please enter a valid email address');
    }
  };

  return (
    <form method="POST" className={styles.login} onSubmit={onEmailCheck} noValidate autoComplete="off">
      <h3 className={styles.loginTitle}>Login</h3>
      <TextInput
        id="enter-your-login-email-address"
        name="email"
        type="email"
        placeholder="Enter Your Login Email"
        value={email}
        error={isEmailError}
        onChange={setEmailValue}
        onClear={clearEmailValue}
        onFocus={() => isEmailError && setIsEmailError('')}
        isDisabled={isLoading}
        autoComplete="email"
      />
      <Button
        type="submit"
        id="continue-login"
        size="48"
        variant="primary"
        isLoading={isLoading}
        className={styles.loginButton}>
        Continue
      </Button>
    </form>
  );
};

export default LoginDefault;
