/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { useContext, useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { Loader, ValidateDomainModal, Table, StatusMessage, Link } from 'components';
import { queryFormatter, getUserRole, UserContext, DomainContext, MsalContext, truncateString, notify } from 'utils';
import axios from 'config/customAxios';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import { custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import AlertSentryUpdate from 'pages/Workspace/components/AlertSentryUpdate/AlertSentryUpdate';
import AddNewDomain from './components/AddNewDomain/AddNewDomain';
import styles from './Customers.module.scss';
import DeleteDomainModal from './components/DeleteDomainModal/DeleteDomainModal';
import { tableColumns } from './components/tableColumns';

const Customers = () => {
  const { user } = useContext(UserContext);
  const { onUpdateDomain: onUpdateSelectedDomain, domains, onUpdateDomains: setDomains } = useContext(DomainContext);
  const { isLoading: isLoadingMs } = useContext(MsalContext);

  const [sentries, setSentries] = useState([]);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowUpdateModal = () => setShowUpdateModal(!showUpdateModal);

  const [isLoading, setIsLoading] = useState(true);
  const [infoStatusActive, setInfoStatusActive] = useState('');
  const [validateModalData, setValidateModalData] = useState(null);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [domainIdDelete, setDomainIdDelete] = useState('');
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);

  const toggleShowDeleteDomainModal = () => {
    setShowDeleteDomainModal(!showDeleteDomainModal);
  };

  const onValidateModalOpen = (data) => {
    setValidateModalData(data);
    setShowValidateModal(true);
  };

  const onValidateModalClose = () => {
    setShowValidateModal(false);
    setValidateModalData(null);
  };

  const role = getUserRole(user);
  const { showSentryUpdateMessageForAdAdmins } = user;
  const history = useHistory();
  const baseUrl = '/api/v2/Domains/GetCustomerDomains';

  const getDomainSentries = async () => {
    try {
      const { data } = await axios.get(`api/sentry/GetListOfOutdatedSentries?spDomainId=${user.domainId}`);
      setSentries(data);
    } catch (error) {
      notify.error(error?.response?.data || error.message);
    }
  };

  const fetchData = (queryConfig) =>
    new Promise((resolve) =>
      axios
        .get(queryFormatter(baseUrl, queryConfig))
        .then((resp) =>
          setDomains({
            data: resp.data,
            pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
            queryConfig,
          }),
        )
        .catch((err) => setDomains({ isError: true, error: err.message }))
        .finally(() => resolve()),
    );

  useEffect(() => {
    const fetchDomainsData = () => {
      if (user.domainStatus && !isLoadingMs) {
        Promise.all([
          fetchData({ pageNumber: 1, pageSize: 10, orderBy: 'dnsname asc', searchQuery: '' }),
          getDomainSentries(),
        ]).then(() => setIsLoading(false));
      }
    };
    fetchDomainsData();
  }, []);

  const onSelectDomain = (domain) => {
    const { isDomainActive } = getDomainStatus(domain);
    let page = 'resource-assignments';
    if ([spAdmin, spTechAdmin, techAdmin].includes(role) && isDomainActive) {
      page = 'customer-workspace';
    }

    history.push(`/customer-management/${domain.dnsName}/${page}`);
    onUpdateSelectedDomain(domain);
    setInfoStatusActive('');
  };

  const onUpdateValidationModal = (resp, domainId, domainName) => {
    const domainsNew = JSON.parse(JSON.stringify(domains));

    domainsNew.data.forEach((domain) => {
      if (domain.id === domainId) {
        domain.activationCode = resp.data.activationCode;
        domain.status = 1;
      }
    });

    setDomains(domainsNew);
    toast.success(`Domain ${truncateString(domainName, false)} has been validated`, 3000);
  };

  const onUpdateCustModal = () => {
    setIsLoading(true);
    fetchData({ pageNumber: 1, pageSize: 10, orderBy: 'dnsname asc', searchQuery: '' })
      .then(() => toast.success('Domain has been deleted', 3000))
      .finally(() => setIsLoading(false));
  };

  const selectDomain = (domain) => {
    const { isDomainNotValidated } = getDomainStatus(domain);
    if (!isDomainNotValidated) {
      onSelectDomain(domain);
    }
  };

  if (!user.domainStatus) return <Redirect to="/workspace" />;
  if (isLoading || isLoadingMs)
    return (
      <div className={styles.customers}>
        <Loader />
      </div>
    );

  if (domains?.isError) {
    return (
      <div className={styles.customers}>
        <StatusMessage error>{domains.error}</StatusMessage>
      </div>
    );
  }

  const showUserComputersColumn = domains.data.some((domain) => getDomainStatus(domain).isDomainActive);
  const showAppsColumn = domains.data.some(
    (domain) => !getDomainADStatus(domain).isDomainAzure && getDomainStatus(domain).isDomainActive,
  );

  const hasOutdatedSentry = sentries
    .map((i) => i.sentries)
    .flat()
    .some((sentry) => sentry.isOutdated);

  const notShownForAdAdmin = [spTechAdmin, custAdmin, techAdmin].includes(role) && !showSentryUpdateMessageForAdAdmins;
  const showSentryUpdateAlert =
    [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(role) && hasOutdatedSentry && !notShownForAdAdmin;

  return (
    <div className={styles.customers}>
      <div className={styles.customersDomains}>
        <header className={styles.customersDomainsHeader}>
          <div className={styles.customersDomainsTitle}>
            Customer Domains ({domains.pageMeta?.TotalCount || domains.data?.length || 0})
          </div>
          {role !== techAdmin && <AddNewDomain domains={domains} fetchData={fetchData} user={user} />}
        </header>
        {showSentryUpdateAlert && (
          <AlertSentryUpdate customerDomain sentries={sentries} toggleShowUpdateModal={toggleShowUpdateModal} />
        )}
        {!domains.data.length && !domains.queryConfig.searchQuery ? (
          <StatusMessage header="No Customer Domains added">
            Please{' '}
            <Link href="https://www.trugrid.com/docs/addcustomerdomain" external bold>
              add customer domains
            </Link>
          </StatusMessage>
        ) : (
          <Table
            columns={tableColumns(
              role,
              setDomainIdDelete,
              toggleShowDeleteDomainModal,
              onValidateModalOpen,
              infoStatusActive,
              setInfoStatusActive,
              showUserComputersColumn,
              showAppsColumn,
            )}
            data={domains}
            updateData={setDomains}
            dataKey="id"
            baseUrl={baseUrl}
            className={cn(styles.customersDomainsTable, {
              [styles.withApps]: showAppsColumn,
              [styles.withoutColumns]: !showUserComputersColumn,
            })}
            onClickRow={(domain) => selectDomain(domain)}
            classNameRow={(domain) => cn(styles.customersDomainsTableRow, { [styles.inactive]: domain.status === 0 })}
          />
        )}
      </div>

      {showDeleteDomainModal && (
        <DeleteDomainModal
          domainId={domainIdDelete}
          onUpdate={onUpdateCustModal}
          showDeleteDomainModal={showDeleteDomainModal}
          toggleShowDeleteDomainModal={toggleShowDeleteDomainModal}
        />
      )}

      {showValidateModal && (
        <ValidateDomainModal
          isOpen={showValidateModal}
          data={validateModalData}
          onUpdateModal={onUpdateValidationModal}
          onClose={onValidateModalClose}
          isCustomerDomain
        />
      )}

      {showUpdateModal && (
        <UpdateSentryModal
          isOpen={showUpdateModal}
          onRequestClose={toggleShowUpdateModal}
          sentries={sentries}
          customerDomain
        />
      )}
    </div>
  );
};

export default Customers;
