/* eslint-disable prefer-regex-literals */
import Link from 'components/Link/Link';
import { supportedBrowsers, isBrowserSupported, getBrowserSupport, isWindows } from './userAgent';

const xtnDescription = (xtnId) => ({
  OneClickConnector: {
    chrome: {
      name: 'TruGrid.com Browser Extension',
      xtnId,
      version: '1.0.5',
      browserVersionTested: '66.0.3359.181',
      fileNameCheck: 'nativeinstall.html',
      page: `https://chrome.google.com/webstore/detail/trugridcom-browser-extens/${xtnId}`,
      pageBranded: `https://chrome.google.com/webstore/detail/trugridcom-branded-browse/${xtnId}`,
      description: '',
    },
    opera: {
      name: 'Install Chrome Extensions',
      xtnId,
      version: '2.5.1',
      browserVersionTested: '67.0',
      fileNameCheck: 'manifest.json',
      page: 'https://addons.opera.com/en/extensions/details/install-chrome-extensions/',
      pageBranded: '',
      description: '',
    },
    firefox: {
      name: 'Breez ClickOnce',
      xtnId,
      version: '1.2',
      browserVersionTested: '57.0.4',
      fileNameCheck: 'manifest.json',
      page: 'https://addons.mozilla.org/en-US/firefox/addon/breez-clickonce/',
      pageBranded: '',
      description: '',
    },
  },
});

// check if OnClick extension for firefox is installed
const checkFFOnClickXtnInstalled = (xtnData, successCallback, errorCallback) => {
  const xtnItem = xtnData.OneClickConnector.firefox;
  const errorMsg = (
    <>
      <p>Your web browser is based on Firefox</p>
      <p>
        Please install this
        {' '}
        <a target="_blank" rel="noopener noreferrer" href={xtnItem.page}>browser extension</a>
        {' '}
        to continue
      </p>
    </>
  );

  return window.breezClickOnceInstalled ? successCallback() : errorCallback(errorMsg);
};

const checkChromeXtnInstalled = (xtnData, successCallback, errorCallback) => {
  const xtnItem = xtnData.OneClickConnector.chrome;
  // check if there is external branded host
  const isTgDns = (new RegExp('(trugrid.com|secureworkspace.io)$', 'i')).test(window.location.hostname);
  const pageUrlChrome = isTgDns ? xtnItem.page : xtnItem.pageBranded;
  let errorMsg = (
    <>
      <p>Your web browser is based on Google Chrome</p>
      <p>
        Please install this
        {' '}
        <Link href={pageUrlChrome} external bold title={xtnItem.name}>browser extension</Link>
        {' '}
        to continue
      </p>
    </>
  );

  if (isBrowserSupported(supportedBrowsers.opera)) {
    const xtnItemOpera = xtnData.OneClickConnector.opera;
    errorMsg = (
      <>
        <p>Your web browser is based on Google Chrome</p>
        <p>
          Please install this
          {' '}
          <Link href={pageUrlChrome} external bold title={xtnItem.name}>browser extension</Link>
          {' '}
          to continue
        </p>
        <p>
          To make Chrome installation easier please install this
          {' '}
          <Link href={xtnItemOpera.page} external bold title={xtnItemOpera.name}>add-on</Link>
        </p>
      </>
    );
  }
  return fetch(`chrome-extension://${xtnItem.xtnId}/${xtnItem.fileNameCheck}`)
    .then(() => successCallback())
    .catch(() => errorCallback(errorMsg));
};

// Get the links for missed ClickOnce extensions for current browser
export function checkClickOnceXtnLinks(xtnId, successCallback, errorCallback) {
  const xtnData = xtnDescription(xtnId);

  // Skip MS IE 11+ and Edge browsers - Assume it works good without extensions
  if (!isWindows() || getBrowserSupport().some((i) => [supportedBrowsers.ie11, supportedBrowsers.edge].includes(i))) return successCallback();

  if (isBrowserSupported(supportedBrowsers.firefox)) return checkFFOnClickXtnInstalled(xtnData, successCallback, errorCallback);

  if (isBrowserSupported(supportedBrowsers.chrome)) return checkChromeXtnInstalled(xtnData, successCallback, errorCallback);

  return errorCallback('We have detected you are using a non-compatible browser. You won\'t be able to fully utilize TruGrid in this case. We recommend you utilize either latest Edge, Internet Explorer, Chrome or Firefox when accessing TruGrid');
}
