/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { StatusMessage, Link } from 'components';
import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin } from 'consts';
import WorkspaceHeader from '../../WorkspaceHeader/WorkspaceHeader';
import WorkspaceTable from '../../WorkspaceTable/WorkspaceTable';

const WorkspaceMyApps = ({ myApps, baseUrl, role, onUpdate, onUpdateUser, loadingCurrent, setLoadingCurrent }) => {
  const isAdminRole = [spAdmin, spTechAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(role);

  const isEmptyScreen = !myApps.data.length && !myApps.queryConfig.searchQuery;

  const renderApps = () => {
    if (myApps.isError) return <StatusMessage error>{myApps.error}</StatusMessage>;

    if (!myApps.data.length && !myApps.queryConfig.searchQuery) {
      if (isAdminRole) {
        return (
          <StatusMessage header="No apps">
            Please go to{' '}
            <Link to={{ pathname: '/company-management/resource-assignments', search: '?tab=app' }} bold>
              Resource Assignment
            </Link>{' '}
            in order to manage applications
          </StatusMessage>
        );
      }
      return (
        <StatusMessage header="No apps assigned">
          Please contact your company administrator for assistance
        </StatusMessage>
      );
    }

    return (
      <WorkspaceTable
        tableData={myApps}
        isComputers={false}
        baseUrl={baseUrl}
        tableItems={myApps.data}
        queryConfig={myApps.queryConfig}
        onUpdate={onUpdate}
        onUpdateUser={onUpdateUser}
        loadingCurrent={loadingCurrent}
        setLoadingCurrent={setLoadingCurrent}
      />
    );
  };

  return (
    <>
      <WorkspaceHeader
        isShowAll
        isError={!!myApps.isError}
        baseUrl={baseUrl}
        queryConfig={myApps.queryConfig}
        totalCount={myApps.pageMeta?.TotalCount || 0}
        searchBy="App Title"
        allValue="Assigned Apps"
        onUpdate={onUpdate}
        isEmptyScreen={isEmptyScreen}
        isEmptyPage={isEmptyScreen}
      />

      {renderApps()}
    </>
  );
};

WorkspaceMyApps.propTypes = {
  myApps: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  role: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  loadingCurrent: PropTypes.string,
  setLoadingCurrent: PropTypes.func,
};

export default WorkspaceMyApps;
