/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { StatusMessage, Table } from 'components';
import ReactTooltip from 'react-tooltip';
import { onChangePage, queryFormatter } from 'utils';
import TableEmpty from './components/TableEmpty/TableEmpty';
import DevicesSearchFilter from './components/DevicesSearchFilter/DevicesSearchFilter';
import DeleteDevicesModal from './components/DeleteDevicesModal/DeleteDevicesModal';
import UninstallAgentsModal from './components/UninstallAgentsModal/UninstallAgentsModal';
import DevicesBulkActions from './components/DevicesBulkActions/DevicesBulkActions';
import { tableColumns } from './components/TableColumns';
import NoLicensesMessage from '../NoLicensesMessage/NoLicensesMessage';
import styles from './Devices.module.scss';

const Devices = ({ domainId, devices, isNoLicense, baseUrl, onUpdate, isVisibleNoLicensesMsg, isCustTechAdmin }) => {
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [modalSelectedIds, setModalSelectedIds] = useState([]);
  const [deleteDevicesModalShow, setDeleteDevicesModalShow] = useState(false);
  const [uninstallAgentsModalShow, setUninstallAgentsModalShow] = useState(false);
  const [isInfoActive, setIsInfoActive] = useState('');

  const devicesWithoutLicenses = devices.pageMeta.DevicesWithoutLicenses;

  const paginationData = {
    queryConfig: devices?.queryConfig,
    updateAction: onUpdate,
    baseUrl,
  };

  useEffect(() => {
    if (devices?.queryConfig?.searchQuery) {
      const query = { ...devices.queryConfig, searchQuery: '' };
      onChangePage([queryFormatter(baseUrl, query), query], paginationData);
    }
  }, []);

  const openDeleteDevicesModalShow = (deviceID) => {
    if (typeof deviceID === 'string') {
      setModalSelectedIds([deviceID]);
    } else {
      setModalSelectedIds(selectedDeviceIds.map((item) => item.deviceId));
    }
    setDeleteDevicesModalShow(true);
  };
  const closeDeleteDevicesModalShow = () => {
    setDeleteDevicesModalShow(false);
  };

  const openUninstallAgentsModalShow = (deviceID) => {
    if (typeof deviceID === 'string') {
      setModalSelectedIds([deviceID]);
    } else {
      setModalSelectedIds(selectedDeviceIds.map((item) => item.deviceId));
    }
    setUninstallAgentsModalShow(true);
  };

  const closeUninstallAgentsModalShow = () => {
    setUninstallAgentsModalShow(false);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { installationStatus } = devices.queryConfig || {};

  const onUpdateHandler = (devicesNew, isClear) => {
    if (isClear) {
      setSelectedDeviceIds([]);
    }
    onUpdate(devicesNew);
  };

  if (devices.isError) {
    return <StatusMessage error>{devices.error}</StatusMessage>;
  }

  const isDeviceWithoutLicense = ({ deviceId, hasLicense }) =>
    cn({
      [styles.tableRowUnlicensed]: !hasLicense,
      [styles.tableRowChecked]: selectedDeviceIds.includes(deviceId),
    });

  return (
    <>
      <div className={styles.devices}>
        {isNoLicense && <div className={styles.devicesTabBackdrop} />}
        {!!devicesWithoutLicenses && isVisibleNoLicensesMsg && (
          <NoLicensesMessage devicesWithoutLicenses={devicesWithoutLicenses} isCustTechAdmin={isCustTechAdmin} />
        )}
        {selectedDeviceIds.length ? (
          <DevicesBulkActions
            devices={devices}
            selectedDeviceIds={selectedDeviceIds}
            setSelectedDeviceIds={setSelectedDeviceIds}
            openUninstallAgentsModalShow={openUninstallAgentsModalShow}
            openDeleteDevicesModalShow={openDeleteDevicesModalShow}
          />
        ) : (
          <DevicesSearchFilter devices={devices} paginationData={paginationData} domainId={domainId} />
        )}
        <Table
          columns={tableColumns(
            isInfoActive,
            setIsInfoActive,
            installationStatus,
            openUninstallAgentsModalShow,
            openDeleteDevicesModalShow,
          )}
          noData={<TableEmpty isNoLicense={isNoLicense} />}
          data={devices}
          updateData={onUpdate}
          ruleForSelectItems={(d) => !(d.installationStatus === 2 && d.isOnline)}
          selectedItems={selectedDeviceIds}
          setSelectedItems={setSelectedDeviceIds}
          id="dm-devices-table"
          dataKey="deviceId"
          baseUrl={baseUrl}
          className={cn(styles.table, { [styles.allStatuses]: installationStatus === 'AllStatuses' })}
          classNameRow={(item) => isDeviceWithoutLicense(item)}
        />
        <ReactTooltip id="info-tooltip" type="light" place="right" html effect="solid" />
      </div>
      {deleteDevicesModalShow && (
        <DeleteDevicesModal
          deleteDevicesModalShow={deleteDevicesModalShow}
          closeDeleteDevicesModalShow={closeDeleteDevicesModalShow}
          selectedDeviceIds={modalSelectedIds}
          setSelectedDeviceIds={setSelectedDeviceIds}
          baseUrl={baseUrl}
          queryConfig={devices.queryConfig}
          onUpdateHandler={onUpdateHandler}
        />
      )}
      {uninstallAgentsModalShow && (
        <UninstallAgentsModal
          uninstallAgentsModalShow={uninstallAgentsModalShow}
          closeUninstallAgentsModalShow={closeUninstallAgentsModalShow}
          selectedDeviceIds={modalSelectedIds}
          queryConfig={devices.queryConfig}
          baseUrl={baseUrl}
          onUpdateHandler={onUpdateHandler}
        />
      )}
    </>
  );
};

Devices.propTypes = {
  domainId: PropTypes.string.isRequired,
  devices: PropTypes.object.isRequired,
  isNoLicense: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isVisibleNoLicensesMsg: PropTypes.bool,
  isCustTechAdmin: PropTypes.bool,
};

export default Devices;
