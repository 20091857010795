/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const domainMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = { scopes: ['User.Read'], prompt: 'select_account' };

export const apiRequest = {
  scopes: ['API-SCOPE'],
  forceRefresh: false,
  prompt: 'select_account',
};
