import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as signalR from 'aspnet-signalr-reactjs';
import { Link, Button, ResendCode, TextInput } from 'components';
import { useOutsideClick, useTimer } from 'hooks';
import { loginService } from 'services';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { setupMfaType } from 'helpers';
import { LoginContext, UserContext, notify } from 'utils';
import ModalConfirmReset from 'pages/Login/components/ModalConfirmReset/ModalConfirmReset';
import { mfaInputPlaceholder } from './utils/mfaInputPlaceholder';
import { resetMfaLink } from './utils/resetMfaLink';
import styles from './SignIn.module.scss';

const SignIn = () => {
  const { authUser } = useContext(UserContext);
  const { email, signInData, password, setPassword } = useContext(LoginContext);
  const {
    userId,
    isRegisteredUser,
    mfaType,
    isMfaEnabled,
    arePushNotificationsEnabled,
    isAdPasswordResetAvailable,
    isResetMfaAvailable,
    mfaShouldBeSetup,
  } = signInData;

  const [codeValue, setCodeValue] = useState('');
  const [passError, setPassError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingCode, setIsWaitingCode] = useState(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [countPassError, setCountPassError] = useState(0);
  const codeRef = useRef();
  const buttonRef = useRef();
  const history = useHistory();

  const { timeLeft, resetCountdown } = useTimer(0);

  useEffect(() => {
    if (countPassError > 2) {
      notify.error('You have exceeded the maximum number of unsuccessful password attempts. Please try again later');
      history.replace('/');
      return () => setCountPassError(0);
    }
  }, [countPassError, history]);

  const isBtnDisabled = signInData ? isMfaEnabled ? !(password && codeValue.length > 5) : !password : !email;

  const handleShowModalResetPassword = () => {
    setShowModalResetPassword(!showModalResetPassword);
  };

  const handleChangeSixDigitCode = (e) => {
    const { value, maxLength } = e.target;
    const code = value.slice(0, maxLength);
    setCodeValue(code);
  };

  const loginUser = async () => {
    try {
      await authUser();
      if (mfaShouldBeSetup) {
        return history.replace('/setup-mfa');
      }
      return history.replace('/workspace');
    } catch (error) {
      notify.error(error.message);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const onLoginSuccess = async (data) => {
      setIsLoading(false);
      try {
        if (data) {
          localStorage.setItem('TG.SSO.LOGIN', email);
          localStorage.setItem('TG.SSO.PWD', data);
        }
        await loginUser();
      } catch (error) {
        notify.error(error.message);
      }
    };

    const onLoginError = (error) => {
      setIsLoading(false);
      if (error.response && error.response.status === 403) {
        setCountPassError((prevState) => prevState + 1);
        if (error.response.data.loginErrorType === 1) {
          setPassError(error.response.data.message);
        }
        if (error.response.data.loginErrorType === 2) {
          setCodeError(error.response.data.message);
        }
      } else if (error.response && error.response.status === 406) {
        notify.error('Unable to connect to Active Directory');
      } else if (error.response && error.response.status === 409) {
        history.push('/login/update-password');
      } else {
        notify.error(error?.response?.data?.message || error.message);
      }
    };

    loginService.signIn(codeValue, password, userId)
      .then(({ data }) => onLoginSuccess(data))
      .catch(onLoginError);
  };

  const onClearPassValue = () => {
    setPassword('');
    setCodeValue('');
    setTimeout(() => {
      setCodeError('');
      setPassError('Password field is required');
    }, 0);
  };

  const onFocusSixDigitCode = () => {
    if (codeError) {
      setCodeError('');
    }
    if (timeLeft < 1) {
      setIsWaitingCode(true);
      loginService.sendSignInNotification(userId)
        .then(() => {
          if (setupMfaType(mfaType) === 'sms') {
            notify.success('SMS has been sent');
          }
          if (setupMfaType(mfaType) === 'email') {
            notify.success('Email has been sent');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            notify.error(err?.response?.data);
            history.replace('/login');
          } else if (err?.response?.data) {
            setCodeError(err.response.data.message);
          } else {
            notify.error(err.message);
          }
        })
        .finally(() => {
          setIsWaitingCode(false);
          resetCountdown(30);
        });
    }
  };

  useOutsideClick(codeRef, () => {
    if (codeValue && codeValue.length < 6) {
      setCodeError('Please enter at least 6 characters');
    }
  });

  useEffect(() => {
    if (arePushNotificationsEnabled && isMfaEnabled) {
      const connection = signalR.hubConnection(`${process.env.REACT_APP_PUBLIC_URL}`);
      const contosoChatHubProxy = connection.createHubProxy('synchronizationHub');

      contosoChatHubProxy.on('onApproveSignIn', (mfa) => {
        setCodeValue(mfa);
        setCodeError('');
        buttonRef.current.focus();
        setTimeout(() => buttonRef.current.click(), 500);
      });

      connection
        .start()
        .done(() => contosoChatHubProxy.invoke('registerClient', userId))
        .fail(() => notify.error('SignalR connection error'));

      return () => connection.stop();
    }
  }, [arePushNotificationsEnabled, isMfaEnabled, userId]);

  const handleKeyDownMfaCode = (e) => {
    const { key } = e;
    if (key === 'Enter' && codeValue.length === 6 && password) {
      onLogin(e);
    }
  };

  useEffect(() => setPassword(''), [setPassword]);

  const placeholder = mfaInputPlaceholder(mfaType);
  const linkResetMfa = resetMfaLink(mfaType);

  return (
    <>
      <LoginHeader title="Login" hideSteps idle />
      <div className={styles.login}>
        <TextInput
          id="enter-your-login-email"
          name="email"
          type="email"
          placeholder="Enter Your Login Email"
          value={email}
          isDisabled
          readOnly
        />
        <TextInput
          id="enter-your-password"
          type="password"
          name="password"
          placeholder="Enter Your Password"
          error={passError}
          value={password}
          autoCompleteOff={false}
          autoFocus
          hasView
          onChange={(e) => setPassword(e.target.value)}
          onFocus={() => passError && setPassError('')}
          onKeyDown={(e) => e.key === 'Enter' && password && !isMfaEnabled && onLogin(e)}
          onClear={onClearPassValue}
        />
        {isMfaEnabled && (
          <div>
            <TextInput
              id="enter-six-digit-code"
              type="number"
              name="mfa-code"
              placeholder={placeholder}
              ref={codeRef}
              tabIndex={!password ? '-1' : ''}
              isDisabled={!password}
              value={codeValue}
              error={codeError}
              errorNotAbsolute
              maxCharacters={6}
              onChange={handleChangeSixDigitCode}
              onFocus={onFocusSixDigitCode}
              onKeyDown={handleKeyDownMfaCode}
              onClear={() => setCodeValue('')}
            />
            {setupMfaType(mfaType) !== 'app' && (
              <div className={styles.loginResend}>
                <ResendCode countDown={timeLeft} count={30} message="Didn't receive a MFA code?" handleSend={onFocusSixDigitCode} isDisabled={isWaitingCode} />
              </div>
            )}
          </div>
        )}
        <Button
          ref={buttonRef}
          id="continue-sign-in"
          size="48"
          variant="primary"
          isLoading={isLoading}
          onClick={onLogin}
          isDisabled={isBtnDisabled}
        >
          {mfaShouldBeSetup ? 'Set Up Multi-Factor Authentication' : 'Login'}
        </Button>
      </div>

      <div className={styles.loginRow}>
        {isRegisteredUser && (
          <Button
            id="handle-show-modal-reset-password"
            onClick={handleShowModalResetPassword}
            variant="link"
            className={styles.loginLink}
          >
            Reset Password
          </Button>
        )}
        {isAdPasswordResetAvailable && (
          <Link to="/login/forgot-password" className={styles.loginLink}>
            Reset Password
          </Link>
        )}
        {(isMfaEnabled && isResetMfaAvailable) && (
          <Link to={linkResetMfa} className={styles.loginLink}>
            Reset MFA
          </Link>
        )}
      </div>

      {showModalResetPassword && (
        <ModalConfirmReset
          showModal={showModalResetPassword}
          handleShowModal={handleShowModalResetPassword}
          signInData={signInData}
        />
      )}
    </>
  );
};

export default SignIn;
