import PropTypes from 'prop-types';
import { InfoActiveIcon } from 'assets/img';
import { Link } from 'react-router-dom';
import styles from './NoLicensesMessage.module.scss';

const NoLicensesMessage = ({ devicesWithoutLicenses, isCustTechAdmin }) => (
  <div className={styles.noLicenses}>
    <InfoActiveIcon />
    <span className={styles.noLicensesMessage}>
      There {devicesWithoutLicenses > 1 ? 'are' : 'is'} <span className={styles.noLicensesCount}>{devicesWithoutLicenses}</span> {devicesWithoutLicenses > 1 ? 'devices that do not have licenses' : 'device that does not have license'}.
      {' '}
      {isCustTechAdmin ? (
        'Please contact your company administrator for assistance'
      ) : (
        <>Please <Link to="/profile/subscription" className={styles.noLicensesLink}>add licenses</Link></>
      )}
    </span>
  </div>
);

NoLicensesMessage.propTypes = {
  devicesWithoutLicenses: PropTypes.number,
  isCustTechAdmin: PropTypes.bool,
};

export default NoLicensesMessage;
