import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { Button } from 'components';
import { RefreshIcon, RefreshWhiteIcon, InfoIcon } from 'assets/img';
import { MsalContext } from 'utils';
import { getDomainADStatus } from 'helpers';
import ErrorModal from './components/ErrorModal/ErrorModal';
import SentryHostsInfoModal from './components/SentryHostsInfoModal/SentryHostsInfoModal';
import styles from './AssignmentsRefresh.module.scss';

const AssignmentsRefresh = ({ domainData, isAppsPage, title, onUpdate }) => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState();
  const [showSentryHostsInfoModal, setShowSentryHostsInfoModal] = useState(false);
  const { token, getTokenSilent } = useContext(MsalContext);
  const isSentriesHasOffline = domainData.sentries?.some((s) => !s.isOnline);
  const { isDomainAzure } = getDomainADStatus(domainData);

  const toggleShowErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  const toggleShowSentryHostsInfoModal = () => {
    setShowSentryHostsInfoModal(!showSentryHostsInfoModal);
  };

  const azureAdRefreshCall = async (domainId, authToken) => {
    try {
      await axios.post('/api/Domains/ForcedAzureAdDataRefresh', { domainId, authToken });
      onUpdate();
      toast.success('Data from Active Directory refreshed', 3000);
    } catch (err) {
      toast.error(err.response.data || err.message, 3000);
    } finally {
      setIsRefreshLoading(false);
    }
  };

  const onPremiseRefreshCall = (domainId) => {
    let url = `/api/Domains/ForcedAgentLocalAppsDataRefresh?domainId=${domainId}`;
    if (!isAppsPage) {
      url = `/api/Domains/ForcedAgentDataRefresh?domainId=${domainId}`;
    }

    axios.post(url)
      .then((resp) => {
        if (resp.status === 206) {
          toast.success(resp.data, 3000);
        } else {
          onUpdate();
          toast.success('Data from Active Directory refreshed', 3000);
        }
      })
      .catch((err) => {
        if (err.response.status === 406) {
          setErrorInfo(err.response.data);
          toggleShowErrorModal();
        } else {
          toast.error(err.response.data || err.message, 3000);
        }
      })
      .finally(() => setIsRefreshLoading(false));
  };

  const onRefreshDataFromAD = async () => {
    setIsRefreshLoading(true);
    try {
      if (isDomainAzure) {
        if (token && token[domainData.id]) {
          azureAdRefreshCall(domainData.id, token[domainData.id]);
        } else {
          try {
            await getTokenSilent(domainData.id, domainData.dnsName, azureAdRefreshCall);
          } catch (error) {
            toast.error(error.message, 3000);
            setIsRefreshLoading(false);
          }
        }
      } else {
        onPremiseRefreshCall(domainData.id);
      }
    } catch (err) {
      setIsRefreshLoading(false);
      toast.error(err?.response?.data || err.message, 3000);
    }
  };

  return (
    <>
      <div className={styles.refresh}>
        <div className={styles.refreshWrap}>
          <Button
            type="button"
            variant="primary"
            id="refresh-ad"
            size="32"
            outline
            rounded
            icon={isRefreshLoading ? <RefreshWhiteIcon /> : <RefreshIcon />}
            className={cn(styles.refreshWrapButton, { [styles.refreshWrapButtonLoading]: isRefreshLoading })}
            onClick={onRefreshDataFromAD}
          >
            {title || 'Refresh data from AD'}
          </Button>
        </div>
        {!isDomainAzure && (
          <InfoIcon
            id="show-sentry-hosts"
            className={isSentriesHasOffline ? cn(styles.refreshStatus, styles.refreshStatusOffline) : cn(styles.refreshStatus, styles.refreshStatusOnline)}
            onClick={toggleShowSentryHostsInfoModal}
          />
        )}
      </div>

      {showErrorModal && (
        <ErrorModal
          toggleShowErrorModal={toggleShowErrorModal}
          showErrorModal={showErrorModal}
          errorInfo={errorInfo}
        />
      )}

      {showSentryHostsInfoModal && (
        <SentryHostsInfoModal
          toggleShowSentryHostsInfoModal={toggleShowSentryHostsInfoModal}
          showSentryHostsInfoModal={showSentryHostsInfoModal}
          domainData={domainData}
        />
      )}

    </>
  );
};

AssignmentsRefresh.propTypes = {
  domainData: PropTypes.object.isRequired,
  isAppsPage: PropTypes.bool,
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

export default AssignmentsRefresh;
