import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, StatusMessage, Table } from 'components';
import { CancelCircleIcon } from 'assets/img';
import TotalLicenses from './components/TotalLicenses/TotalLicenses';
import InputControlsCustomer from './components/InputControlsCustomer/InputControlsCustomer';
import styles from './CustomerDomain.module.scss';

const CustomerDomain = ({ user, licenses, onUpdateLicenses }) => {
  const [activeRow, setActiveRow] = useState('');
  const [licensesState, setLicensesState] = useState(JSON.parse(JSON.stringify(licenses)));
  const [findDomainValue, setFindDomainValue] = useState('');
  const [warning, setWarning] = useState({ isActive: false });

  const checkEmptyRows = (domainId) => {
    const licensesStateNew = JSON.parse(JSON.stringify(licensesState));

    licensesStateNew.customerDomains.forEach((d) => {
      if (d.domainId === domainId) {
        if (d.numberOfRdpLicenses === '') d.numberOfRdpLicenses = 0;
        if (d.numberOfBitLockerLicenses === '') d.numberOfBitLockerLicenses = 0;
      }
    });

    setLicensesState(licensesStateNew);
    setActiveRow('');
  };

  const onCancelChanges = (domainId) => {
    const licensesStateNew = JSON.parse(JSON.stringify(licensesState));
    const domainInit = licenses.customerDomains.find((d) => d.domainId === domainId);
    licensesStateNew.customerDomains.forEach((d) => {
      if (d.domainId === domainId) {
        licensesStateNew.totalAllocatedRdpLicenses += domainInit.numberOfRdpLicenses - d.numberOfRdpLicenses;
        licensesStateNew.totalAllocatedBitLockerLicenses +=
          domainInit.numberOfBitLockerLicenses - d.numberOfBitLockerLicenses;
        licensesStateNew.spDomain.numberOfRdpLicenses += d.numberOfRdpLicenses - domainInit.numberOfRdpLicenses;
        licensesStateNew.spDomain.numberOfBitLockerLicenses +=
          d.numberOfBitLockerLicenses - domainInit.numberOfBitLockerLicenses;
        d.numberOfRdpLicenses = domainInit.numberOfRdpLicenses;
        d.numberOfBitLockerLicenses = domainInit.numberOfBitLockerLicenses;
      }
    });
    setLicensesState(licensesStateNew);
  };

  const licensesFindValue = () => {
    if (!findDomainValue) return { data: licensesState.customerDomains };
    let domainsNew = licensesState.customerDomains;
    domainsNew = domainsNew.filter((d) => d.domainName.toLowerCase().includes(findDomainValue.toLowerCase()));
    return { data: domainsNew, queryConfig: { searchQuery: findDomainValue } };
  };

  const columns = [
    {
      name: 'Domains',
      search: 'Domain Name',
      data: 'domainName',
      width: '36%',
      searchNotDebounce: setFindDomainValue,
    },
    {
      name: 'SecureRDP',
      data: (domain) =>
        activeRow === domain.domainId ? (
          <InputControlsCustomer
            initDomain={domain}
            warning={warning}
            setWarning={setWarning}
            licensesState={licensesState}
            setLicensesState={setLicensesState}
            licenses={licenses}
            typeFunc="numberOfRdpLicenses"
            initValue={domain.numberOfRdpLicenses}
            id={`subs-customer-${domain.domainName}-rdp-input`}
            user={user}
          />
        ) : (
          domain.numberOfRdpLicenses
        ),
      width: '22%',
    },
    {
      name: 'BitLocker',
      data: (domain) =>
        activeRow === domain.domainId ? (
          <InputControlsCustomer
            initDomain={domain}
            warning={warning}
            setWarning={setWarning}
            licensesState={licensesState}
            setLicensesState={setLicensesState}
            licenses={licenses}
            typeFunc="numberOfBitLockerLicenses"
            initValue={domain.numberOfBitLockerLicenses}
            id={`subs-customer-${domain.domainName}-bl-input`}
            user={user}
          />
        ) : (
          domain.numberOfBitLockerLicenses
        ),
      width: '22%',
    },
    {
      name: '',
      width: '20%',
      data: ({ domainName, domainId }) => {
        const areValuesChanged = (() => {
          const domainState = licensesState.customerDomains.find((d) => d.domainId === domainId);
          const domainInit = licenses.customerDomains.find((d) => d.domainId === domainId);
          return JSON.stringify(domainState) !== JSON.stringify(domainInit);
        })();
        return (
          !!areValuesChanged && (
            <Button
              id={`subs-customer-${domainName}-cancel-changes`}
              variant="link"
              icon={<CancelCircleIcon />}
              className={styles.cancelChanges}
              onClick={() => onCancelChanges(domainId)}>
              Cancel changes
            </Button>
          )
        );
      },
    },
  ];

  if (!licensesState.customerDomains && !findDomainValue) {
    return <StatusMessage>Domains list is empty</StatusMessage>;
  }

  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.backdrop} />}

      <TotalLicenses
        licensesState={licensesState}
        licenses={licenses}
        onUpdateLicenses={onUpdateLicenses}
        warning={warning}
      />

      <Table
        data={licensesFindValue()}
        columns={columns}
        pagination={false}
        dataKey="domainId"
        onMouseEnter={(e, { domainId }) => setActiveRow(domainId)}
        onMouseLeave={(e, { domainId }) => checkEmptyRows(domainId)}
        className={cn(styles.table, { [styles.tableEmpty]: !licensesFindValue()?.data.length && findDomainValue })}
      />
    </>
  );
};

CustomerDomain.propTypes = {
  user: PropTypes.object.isRequired,
  licenses: PropTypes.object,
  onUpdateLicenses: PropTypes.func,
};

export default CustomerDomain;
