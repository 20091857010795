import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Link.module.scss';

const LinkComponent = ({ href, id, to, external, bold, className, style, children }) => {
  if (external) {
    return (
      <a
        id={id}
        href={href}
        target={external && '_blank'}
        rel={external && 'noopener noreferrer'}
        className={cn(styles.link, {
          [styles.linkBold]: bold,
          [className]: className,
        })}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      id={id}
      className={cn(styles.link, {
        [styles.linkBold]: bold,
        [className]: className,
      })}
      style={style}
    >
      {children}
    </Link>
  );
};

LinkComponent.propTypes = {
  href: PropTypes.string,
  id: PropTypes.string,
  to: PropTypes.any,
  external: PropTypes.bool,
  bold: PropTypes.bool,
  className: PropTypes.any,
  style: PropTypes.any,
  children: PropTypes.node,
};

export default LinkComponent;
