import PropTypes from 'prop-types';
import cn from 'classnames';
import { Checkbox } from 'components';
import styles from './ReportColumns.module.scss';

const ReportColumns = ({
  checkBoxesChecked,
  toggleCheckBoxesChecked,
  type,
  isDomainAzure,
  isLeastOneDomainHasApps,
  checkedDomains,
}) => {
  const disableAppColumn = checkedDomains.every((el) => !el.isAppPublishingEnabled);

  const checkboxes = [
    {
      checked: true,
      label: isDomainAzure ? 'Users' : 'Users or User Groups',
      isDisabled: true,
      onChange: () => {},
      show: true,
    },
    {
      checked: checkBoxesChecked.application,
      label: 'Application',
      isDisabled: type !== 'userAssignments' || disableAppColumn,
      onChange: () => toggleCheckBoxesChecked('application'),
      show: type !== 'machineAssignmnets' && isLeastOneDomainHasApps,
    },
    {
      checked: checkBoxesChecked.computersComputerGroups,
      label: isDomainAzure ? 'Computers' : 'Computers or Computer Groups',
      isDisabled: type !== 'userAssignments' || isDomainAzure || disableAppColumn,
      onChange: () => toggleCheckBoxesChecked('computersComputerGroups'),
      show: type !== 'appAssignments',
    },
  ];

  if (type !== 'userAssignments' || (isDomainAzure && type === 'machineAssignmnets')) {
    checkboxes.reverse();
  }

  return (
    <div className={styles.list}>
      <div className={styles.listColums}>Columns</div>
      <div
        className={cn(styles.listContainer, {
          [styles.row]: type !== 'userAssignments' || isDomainAzure || !isLeastOneDomainHasApps,
        })}>
        {checkboxes
          .filter(({ show }) => show)
          .map(({ checked, isDisabled, label, onChange }) => (
            <div key={label} className={styles.listContainerItem}>
              <Checkbox
                className={styles.checkBoxWrapperCheckbox}
                checked={checked}
                isDisabled={isDisabled}
                label={label}
                onChange={onChange}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

ReportColumns.propTypes = {
  checkBoxesChecked: PropTypes.object,
  toggleCheckBoxesChecked: PropTypes.func,
  type: PropTypes.string,
  isDomainAzure: PropTypes.bool,
  isLeastOneDomainHasApps: PropTypes.bool,
  checkedDomains: PropTypes.array,
};

export default ReportColumns;
