import PropTypes from 'prop-types';
import styles from './NoData.module.scss';

const NoData = ({ users }) => (
  <div className={styles.noData}>
    <div className={styles.noDataMessage}>
      <p className={styles.noDataTitle}>No data reporting in</p>
      <p>Please check Active Directory or
        {' '}
        {users.value === 'no data' ? (
          <a href="https://www.trugrid.com/docs/setupad" className={styles.noDataLink} target="_blank" rel="noopener noreferrer">add users & customer domains</a>
        ) : (
          <a href="https://www.trugrid.com/docs/usersnotreportingin" className={styles.noDataLink} target="_blank" rel="noopener noreferrer">add users</a>
        )}
      </p>
    </div>
  </div>
);

NoData.propTypes = {
  users: PropTypes.object.isRequired,
};

export default NoData;
