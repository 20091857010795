import PropTypes from 'prop-types';
import { DropdownArray, SearchInput } from 'components';
import { onChangeView, onSearch } from 'utils';
import styles from './TableActions.module.scss';

const TableActions = ({ appPublishingInit, appPublishingCurrent, queryViewBy, paginationData, inputRef }) => (
  <div className={styles.appsHeader}>
    <div className={styles.appsHeaderWrap}>
      View by
      <DropdownArray
        id="ra-app-select"
        isSmall={false}
        isSearch={false}
        isFilter
        strightRight
        isDisabled={appPublishingInit && !appPublishingCurrent}
        options={['Users', 'Apps']}
        defaultValue={queryViewBy}
        onChange={(value) => onChangeView(value, paginationData)}
        className={styles.appsHeaderWrapDropdown}
        width="10.75em"
      />
      <SearchInput
        id="ra-app-search"
        ref={inputRef}
        strightLeft
        searchBy={queryViewBy === 'Users' ? 'Name' : 'Title'}
        onChange={(value) => onSearch(value, paginationData)}
      />
    </div>
  </div>
);

TableActions.propTypes = {
  appPublishingInit: PropTypes.bool,
  appPublishingCurrent: PropTypes.bool,
  queryViewBy: PropTypes.string,
  paginationData: PropTypes.object,
  inputRef: PropTypes.any,
};

export default TableActions;
