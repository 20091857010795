import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, StatusMessage, SwitchCheckbox, Table } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { useHistory, useRouteMatch } from 'react-router-dom';

import styles from './DomainsTable.module.scss';

const TYPES = {
  0: 'Inherited from Primary Domain',
  1: 'TruGrid Default',
  2: 'Custom',
};

const DomainsTable = ({
  domains,
  setDomains,
  baseUrl,
  selectedUsers,
  setSelectedUsers,
  queryViewBy,
  btnLoadingCurrent,
  changeDomainBrandingStatus,
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const goToCustomerManagement = (domain) => {
    history.push(`${url}/${domain.dnsName}`, { domain });
  };

  const domainType = (type) => {
    switch (type) {
      case 0:
        return <span className={cn(styles.tableType, styles.inherit)}>{TYPES[0]}</span>;
      case 1:
        return <span className={cn(styles.tableType, styles.default)}>{TYPES[1]}</span>;
      case 2:
        return <span className={cn(styles.tableType, styles.custom)}>{TYPES[2]}</span>;
      default:
        return <span className={cn(styles.tableType, styles.default)}>{TYPES[1]}</span>;
    }
  };

  const columns = [
    {
      name: 'Domain',
      sortable: 'dnsName',
      width: '45%',
      search: 'Domain',
      checkbox: queryViewBy !== 'All',
      data: ({ dnsName }) => <span className={styles.tableName}>{dnsName}</span> || 'N/A',
    },
    {
      name: 'Branding Status',
      width: '20%',
      sortable: 'status',
      data: (domain) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={domain.domainId}
            name={domain.domainId}
            onChange={() => changeDomainBrandingStatus(false, domain)}
            checked={domain.status}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(domain.domainId)}
          />
          {domain.status ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
    },
    {
      name: 'Branding Type',
      width: '22%',
      sortable: 'type',
      data: ({ type }) => domainType(type),
    },
    {
      name: '',
      width: '13%',
      data: (domain) => (
        <Button
          id="manage-assignments"
          type="button"
          variant="primary"
          size="32"
          icon={<ArrowLeftIcon />}
          iconPosition="right"
          onClick={() => goToCustomerManagement(domain)}
          className={styles.tableButton}>
          Manage
        </Button>
      ),
    },
  ];

  return domains?.data?.length < 1 && !domains?.queryConfig?.searchQuery ? (
    <div className={styles.statusMessage}>
      <StatusMessage header="No items available">Change view selection</StatusMessage>
    </div>
  ) : (
    <Table
      columns={columns}
      data={domains}
      updateData={setDomains}
      id="branding-cuatomers-table"
      dataKey="domainId"
      noData={!domains.data.length && domains.queryConfig.searchQuery}
      baseUrl={baseUrl}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      className={styles.table}
    />
  );
};

DomainsTable.propTypes = {
  domains: PropTypes.object,
  setDomains: PropTypes.func,
  baseUrl: PropTypes.string,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  queryViewBy: PropTypes.string,
  btnLoadingCurrent: PropTypes.array,
  changeDomainBrandingStatus: PropTypes.func,
};

export default DomainsTable;
