export const getFullScanDarkWebPaymentStatus = (status) => {
  const isNotPaid = status === 1;
  const isPaid = status === 2;
  const isCanceled = status === 3;

  return {
    isNotPaid,
    isPaid,
    isCanceled,
  };
};

export const getType = (type) => {
  const compromisedAccountsDiscovered = type === 0;
  const discoveryScanCompleted = type === 1;
  const fullScanCompleted = type === 2;

  return {
    compromisedAccountsDiscovered,
    discoveryScanCompleted,
    fullScanCompleted,
  };
};
