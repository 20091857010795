import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Checkbox, Modal, SwitchCheckbox } from 'components';
import { useOutsideClick } from 'hooks';
import { AngleUpIcon, AngleDownIcon } from 'assets/img';
import styles from './ModalResetAll.module.scss';

const ModalResetAll = ({ isOpen, onRequestClose, domains, userDomainId, domainCurrent, isDropdownRestricted, onResetAs }) => {
  const [isResetting, setIsResetting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredDomains, setFilteredDomains] = useState(domains);
  const [selectedDomains, setSelectedDomains] = useState(domainCurrent === 'My Settings' ? isDropdownRestricted ? domains : [] : domains.filter((d) => d.dnsName === domainCurrent));
  const [applyToMyself, setApplyToMyself] = useState(domainCurrent === 'My Settings');
  const [applyToDomains, setApplyToDomains] = useState(domainCurrent !== 'My Settings');
  const dropdownRef = useRef();
  const isBtnDisabled = (!applyToMyself && !applyToDomains) || (!applyToMyself && applyToDomains && !selectedDomains.length);

  const onCloseDropdown = () => {
    if (isOpen && filteredDomains.length !== domains.length) {
      setFilteredDomains(domains);
    }
    setIsDropdownOpen(false);
  };

  useOutsideClick(dropdownRef, () => isDropdownOpen && onCloseDropdown());

  const onChangeApply = (item, value) => {
    if (item === 'Apply to myself') {
      setApplyToMyself(value);
    } else {
      setApplyToDomains(value);
    }
  };

  const onSearch = (e) => {
    const { value } = e.target;
    const filtered = domains.filter((d) => d.dnsName.includes(value));

    if (value) {
      setFilteredDomains(filtered);
    } else {
      setFilteredDomains(domains);
    }
  };

  const onChangeDomain = (domainId, value) => {
    let selectedDomainsNew = [...selectedDomains];
    const selectedDomain = domains.find((d) => d.id === domainId);

    if (value) {
      selectedDomainsNew.push(selectedDomain);
    } else {
      selectedDomainsNew = selectedDomainsNew.filter((d) => d.id !== domainId);
    }

    setSelectedDomains(selectedDomainsNew);
  };

  const onReset = () => {
    setIsResetting(true);
    onResetAs(selectedDomains, applyToMyself, applyToDomains)
      .then(() => {
        setIsResetting(false);
        onRequestClose();
      });
  };

  const selectedDomainsRender = selectedDomains.length
    ? selectedDomains.map((d) => d.dnsName).join(', ')
    : <span style={{ color: '#848A92' }}>Select domains</span>;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Reset all settings to default"
      shouldCloseOnOverlayClick={false}
      actionFunc={onReset}
      actionTitle="Confirm"
      actionLoading={isResetting}
      actionDisabled={isBtnDisabled}
    >
      {['Apply to myself', 'Apply to selected domains'].map((item, index) => (
        <div key={item} className={styles.resetRow}>
          <span>{item}</span>
          <SwitchCheckbox
            id={`setting-${item}`}
            value={item}
            checked={index ? applyToDomains : applyToMyself}
            onChange={(e) => onChangeApply(item, e.target.checked)}
          />
        </div>
      ))}

      <div className={cn(styles.resetDropdown, { [styles.disabled]: !applyToDomains })} ref={dropdownRef}>
        <button
          id="setting-reset-all"
          type="button"
          disabled={isDropdownRestricted}
          className={cn(styles.resetDropdownSelect, { [styles.active]: isDropdownOpen, [styles.disabled]: isDropdownRestricted })}
          onClick={() => (isDropdownOpen ? onCloseDropdown() : setIsDropdownOpen(true))}
        >
          <span className={styles.resetDropdownSelectValue}>{selectedDomainsRender}</span>
          {!isDropdownRestricted && (
            isDropdownOpen ? <AngleUpIcon /> : <AngleDownIcon />
          )}
        </button>
        {isDropdownOpen && (
          <div className={styles.resetDropdownOptions}>
            <input id="setting-search-domain" type="search" className={styles.resetDropdownOptionsSearch} placeholder="Search..." onChange={onSearch} />
            {filteredDomains.length ? (
              <>
                <div className={cn(styles.resetDropdownOptionsRow, styles.selectAll)}>
                  <span className={styles.resetDropdownOptionsRowLabel}>Domains</span>
                  <Button id="toogle-select-domain" variant="link" className={styles.resetDropdownOptionsRowSelectAll} onClick={() => (selectedDomains.length === filteredDomains.length ? setSelectedDomains([]) : setSelectedDomains(domains))}>
                    {selectedDomains.length === filteredDomains.length ? 'Unselect All' : 'Select All'}
                  </Button>
                </div>
                {filteredDomains.filter((d) => d.dnsName).map((domain) => (
                  <div key={domain.id} className={cn(styles.resetDropdownOptionsRow, { [styles.userDomain]: domain.id === userDomainId })}>
                    <Checkbox
                      id={domain.id}
                      className={styles.resetDropdownOptionsCheckbox}
                      checked={selectedDomains.length ? !!selectedDomains.find((d) => d.id === domain.id) : false}
                      onChange={(e) => onChangeDomain(domain.id, e.target.checked)}
                      label={domain.dnsName}
                      labelPosition="left"
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.resetDropdownEmpty}>No results found</div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

ModalResetAll.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  domains: PropTypes.array,
  userDomainId: PropTypes.string,
  domainCurrent: PropTypes.string.isRequired,
  isDropdownRestricted: PropTypes.bool.isRequired,
  onResetAs: PropTypes.func.isRequired,
};

export default ModalResetAll;
