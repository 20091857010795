/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
const getConfigurationForThisYear = () => {
  const thisYear = new Date().getFullYear();
  const startRange = new Date(thisYear, 0, 1);
  const endRange = new Date(new Date().getTime());

  return {
    text: thisYear,
    value: 'this_year',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForLastYear = () => {
  const lastYear = new Date().getFullYear() - 1;
  const startRange = new Date(lastYear, 0, 1);
  const endRange = new Date(lastYear, 11, 31);

  return {
    text: lastYear,
    value: 'last_year',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForThisMonth = () => {
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const startRange = new Date(thisYear, thisMonth, 1);
  const endRange = new Date(new Date().getTime());
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(thisYear, thisMonth, 1));

  return {
    text: monthName,
    value: 'this_month',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForLastMonth = () => {
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  let lastMonth = new Date().getMonth() - 1;
  let year = new Date().getFullYear();

  if (lastMonth < 0) {
    lastMonth = 11;
    year--;
  }

  const startRange = new Date(year, lastMonth, 1);
  const endRange = new Date(new Date(thisYear, thisMonth, 1) - 1);// minus second from beginning of this month
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(year, lastMonth, 1));

  return {
    text: monthName,
    value: 'last_month',
    startDate: startRange,
    endDate: endRange,
  };
};

export const PrepareTimeRangeConfiguration = () => {
  const last7Days = {
    text: 'Last 7 days',
    value: '7_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
  };

  const last14Days = {
    text: 'Last 14 days',
    value: '14_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)),
  };

  const last30Days = {
    text: 'Last 30 days',
    value: '30_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)),
  };

  const last90Days = {
    text: 'Last 90 days',
    value: '90_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - (90 * 24 * 60 * 60 * 1000)),
  };

  const last365Days = {
    text: 'Last 365 days',
    value: '365_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - (365 * 24 * 60 * 60 * 1000)),
  };

  const thisYear = getConfigurationForThisYear();
  const lastYear = getConfigurationForLastYear();
  const thisMonth = getConfigurationForThisMonth();
  const pastMonth = getConfigurationForLastMonth();

  return [
    last7Days,
    last14Days,
    last30Days,
    last90Days,
    last365Days,
    thisYear,
    lastYear,
    thisMonth,
    pastMonth,
  ];
};
