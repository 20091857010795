import { loginService } from 'services';

export const signOut = async () => {
  await loginService.logout();
  window.location.replace('/');
};

export const checkIsEmailValid = (email) => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+[.]){1,}[a-zA-Z]{2,}$/.test(email);

export const checkIsPasswordValid = (password) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>_ ])(?=.{8,})/.test(password);

export const setupMfaType = (type) => {
  switch (type) {
    case 1:
      return 'app';
    case 2:
      return 'sms';
    case 3:
      return 'email';
    default:
      return 'none';
  }
};

export const mustSetupMfa = (userData) => {
  if (userData) {
    return userData.mustSetupMfa || (!userData.isPhoneMfaActive && userData.isMfaActive && setupMfaType(userData.mfaType) === 'app');
  }
  return false;
};

export const mustSetupPhone = (userData) => {
  if (userData) {
    return userData.shouldSetupPhoneForPasswordReset;
  }
  return false;
};

export const handleEmailValidation = (pathname) => {
  if (pathname.includes('emailvalidation')) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const customerId = params.get('customerId');
    return window.location.replace(`/#/email-validation/${customerId}`);
  }
};

export const isFormValid = (formValues, formErrors) => {
  const formErrorsNew = [...formErrors];
  const isNewPassValid = formValues[0].length > 0;
  const isConfirmPassValid = formValues[1] && formValues[0] === formValues[1];

  if (!isNewPassValid) {
    formErrorsNew[0] = ' ';
    formErrorsNew[1] = 'Password does not match requirements';
  }
  if (isNewPassValid && !isConfirmPassValid) {
    formErrorsNew[0] = ' ';
    formErrorsNew[1] = 'Passwords do not match';
  }

  return { errors: formErrorsNew, isValid: !formErrorsNew.some((i) => i) };
};
