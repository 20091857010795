/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Modal, Loader, SearchInput, StatusMessage } from 'components';
import { replaceSpecChar } from 'helpers';
import { truncateString } from 'utils';
import AssignmentsList from './components/AssignmentsList';
import styles from './ManageAssignmentsModal.module.scss';

const ManageAssignmentsModal = ({ showManageAssignmentsModal, closeManageAssignmentsModal, data, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [assignmentsInit, setAssignmentsInit] = useState(null);
  const [assignments, setAssignments] = useState(null);

  useEffect(() => {
    const appName = replaceSpecChar(data.resourceName);
    const url = data.assignType === 'Computer'
      ? `/api/ResourceAssignments/GetDesktopDirectAssignments?domainId=${data.domainId}&resourceId=${data.resourceId}&type=${data.viewBy}&isPooled=${data.isPooled}`
      : `/api/ResourceAssignments/GetAppDirectAssignments?domainId=${data.domainId}&resourceId=${data.resourceId}&type=${data.viewBy}&isPooled=${data.isPooled}&appName=${appName}`;

    axios.get(url)
      .then((resp) => {
        setAssignmentsInit(resp.data);
        setAssignments(JSON.parse(JSON.stringify(resp.data)));
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => setIsLoading(false));
  }, [data]);

  const onUpdateAssignments = () => {
    setIsUpdating(true);

    const assignmentsCountNew = assignments.reduce((prev, cur) => (cur.isAssigned ? ++prev : prev), 0);
    const appName = replaceSpecChar(data.resourceName);
    const url = data.assignType === 'Computer'
      ? `/api/ResourceAssignments/UpdateDesktopDirectAssignments?resourceId=${data.resourceId}&type=${data.viewBy}&isPooled=${data.isPooled}`
      : `/api/ResourceAssignments/UpdateAppDirectAssignments?resourceId=${data.resourceId}&type=${data.viewBy}&isPooled=${data.isPooled}&appName=${appName}`;

    axios.put(url, assignments)
      .then(() => {
        onUpdate(assignmentsCountNew, data.resourceId, data.assignType);
        toast.success(`Assignments for ${truncateString(data.resourceName, false)} have been updated`, 3000);
      })
      .catch((err) => {
        setAssignments(assignmentsInit.map((i) => ({ ...i })));
        toast.error(err.message, 3000);
      })
      .finally(() => closeManageAssignmentsModal());
  };

  const onToggleAll = (type) => {
    const assignmentsNew = assignmentsInit.map((i) => ({ ...i, isAssigned: type === 'select' }));
    setAssignments(assignmentsNew);
  };

  const onToggleItem = (resourceName, value) => {
    const assignmentsNew = assignments.map((i) => (i.resourceName === resourceName ? { ...i, isAssigned: value } : i));
    setAssignments(assignmentsNew);
  };

  const isDisabled = JSON.stringify(assignmentsInit) === JSON.stringify(assignments);

  return (
    <Modal
      isOpen={showManageAssignmentsModal}
      contentLabel="Manage Assignments"
      contentSubtitle={<>for <b>{data.resourceName}</b></>}
      onRequestClose={closeManageAssignmentsModal}
      actionLoading={isUpdating}
      actionDisabled={isDisabled}
      actionFunc={onUpdateAssignments}
      actionTitle="Update"
    >
      <div className={styles.assignmentsWrap}>
        {isLoading ? (
          <Loader />
        ) : (
          assignmentsInit.length ? (
            <>
              <SearchInput
                id="dm-modal-search"
                fullWidth
                searchBy={`${data.viewBy === 'Users' ? data.assignType : 'User'} Name`}
                isNotDebounce
                className={styles.assignmentsSearch}
                onChange={(value) => setSearchValue(value)}
              />
              <AssignmentsList
                assignments={assignments}
                assignmentsInit={assignmentsInit}
                searchValue={searchValue}
                data={data}
                onToggleAll={onToggleAll}
                onToggleItem={onToggleItem}
              />
            </>
          ) : (
            <StatusMessage>No resources reported in</StatusMessage>
          )
        )}
      </div>
    </Modal>
  );
};

ManageAssignmentsModal.propTypes = {
  showManageAssignmentsModal: PropTypes.bool,
  closeManageAssignmentsModal: PropTypes.func,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default ManageAssignmentsModal;
