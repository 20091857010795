import cn from 'classnames';
import { DatabaseIcon, DeviceIcon, InfoFilledIcon, InfoIcon, LoaderIcon } from 'assets/img';
import { SwitchCheckbox } from 'components';
import { encryptStatuses } from 'consts';
import styles from '../Encryptions.module.scss';

export const TableColumns = (isInfoActive, setIsInfoActive, isStatusLoading, onToggleSwitch, onOpenEncryptDetails, isDetailsLoading) => [
  {
    name: 'Hostname',
    sortable: 'hostname',
    width: '40%',
    data: ({ isOnline, hostname, isTpm, isTpmMessage }) => (
      <div className={styles.encryptsHostname}>
        <div className={styles.encryptsHostnameWrap}>
          <DeviceIcon className={cn({ [styles.encryptsHostnameOnline]: isOnline })} />
          <span className={styles.encryptsHostnameName}>{hostname}</span>
        </div>
        {isTpm && <div><span className={styles.encryptsHostnameAddInfo}>{isTpmMessage}</span></div>}
      </div>
    ),
  },
  {
    name: 'Encryption Status',
    width: '35%',
    data: ({ deviceId, isLockedDrive, encryptionStatus }) => {
      const { statusColor, statusTitle, StatusIcon } = encryptStatuses[encryptionStatus];
      return (
        (isStatusLoading === deviceId) ? (
          <LoaderIcon className={styles.encryptsStatusLoadIcon} />
        ) : (
          <div className={cn(styles.encryptsStatus, {
            [styles.encryptsStatusGrey]: statusColor === 'grey',
            [styles.encryptsStatusOrange]: statusColor === 'orange',
            [styles.encryptsStatusBlue]: statusColor === 'blue',
          })}
          >
            <StatusIcon className={styles.encryptsStatusIcon} />
            <span>{statusTitle}</span>
            {isLockedDrive && (
              <div
                className={styles.encryptsStatusWrap}
                onMouseEnter={() => setIsInfoActive(deviceId)}
                onMouseLeave={() => setIsInfoActive('')}
              >
                <div
                  data-tip="Please log into the Computer, select the locked Drive and enter a valid Password to unlock it"
                  data-for="info-tooltip"
                  data-place="bottom"
                  className={styles.encryptsStatusTooltip}
                />
                {isInfoActive === deviceId ? <InfoFilledIcon /> : <InfoIcon />}
              </div>
            )}
          </div>
        )
      );
    },
  },
  {
    name: 'Encryption Action',
    width: '20%',
    data: (encrypt) => {
      const oldEncrypt = JSON.parse(JSON.stringify(encrypt));
      const { isOnline, encryptionStatus } = encrypt;
      const isChecked = [1, 2, 4, 7].includes(encryptionStatus);
      return (
        <p className={cn(styles.encryptsAction, {
          [styles.encryptsActionChecked]: isChecked,
          [styles.encryptsActionDisabled]: !isOnline,
        })}
        >
          <SwitchCheckbox
            id="toggle-encrypt"
            checked={isChecked}
            disabled={!isOnline}
            onChange={(e) => onToggleSwitch(e.target.checked, oldEncrypt)}
          />
          {isChecked ? 'ON' : 'OFF'}
        </p>
      );
    },
  },
  {
    name: '',
    width: '5%',
    data: ({ deviceId, hostname }) => (
      (isDetailsLoading === deviceId) ? (
        <LoaderIcon className={styles.encryptsDetailsLoading} />
      ) : (
        <button
          data-tip="View Encryption Details"
          data-for="info-tooltip"
          data-place="left"
          type="button"
          onClick={() => onOpenEncryptDetails(deviceId, hostname)}
          className={styles.encryptsDetailsButton}
          id={`view-encryption-details-${deviceId}`}
        >
          <DatabaseIcon />
        </button>
      )
    ),
  },
];
