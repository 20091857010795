import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Loader, StatusMessage } from 'components';
import { notify, queryFormatter } from 'utils';
import axios from 'config/customAxios';
import PhoneSetupHeader from './components/PhoneSetupHeader/PhoneSetupHeader';
import styles from './PhoneSetupManagement.module.scss';
import PhoneSetupTitle from './components/PhoneSetupTitle/PhoneSetupTitle';
import ViewByFilter from './components/ViewByFilter/ViewByFilter';
import PhoneSetupTable from './components/PhoneSetupTable/PhoneSetupTable';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ModalChangeUser from './components/ModalChangeUser/ModalChangeUser';

const PhoneSetupManagement = ({ domain, isCompanyPage }) => {
  const [phoneMgmtData, setPhoneMgmtData] = useState({ data: [], pageMeta: null, queryConfig: null, error: null });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalButtonLoading, setIsModalButtonLoading] = useState(false);
  const baseUrl = `/api/SecurityManagement/AdPasswordReset/GetPhoneInformation?domainId=${domain.id}`;

  const [modalChangeUserData, setModalChangeUserData] = useState(null);
  const [showModalChangeUser, setShowModalChangeUser] = useState(false);
  const toggleShowModalChangeUser = (user, type) => {
    if (showModalChangeUser) {
      setShowModalChangeUser(false);
      setModalChangeUserData(null);
    } else {
      setModalChangeUserData({ users: Array.isArray(user) ? user : [user], type });
      setShowModalChangeUser(true);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const fetchData = useCallback(
    async (queryConfig) => {
      try {
        const res = await axios.get(queryFormatter(baseUrl, queryConfig));
        const pageMeta = res.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
        setPhoneMgmtData({ data: res.data, pageMeta, queryConfig });
        setIsLoading(false);
      } catch (error) {
        setPhoneMgmtData({ error: error.response?.data || error.message });
        setIsLoading(false);
      }
    },
    [baseUrl],
  );

  useEffect(() => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'displayName asc',
      searchQuery: '',
      viewBy: 'AllUsers',
    };
    fetchData(queryConfig);
  }, [fetchData]);

  const handleTogglePhoneSetup = async () => {
    try {
      const { users } = modalChangeUserData;
      const enabled = users[0].phoneSetup === 1;
      setIsModalButtonLoading(true);
      const userIds = users.length === 1 ? [users[0].userId] : users.map((user) => user.userId);
      await axios.post('/api/SecurityManagement/AdPasswordReset/ChangePhoneSetupState', {
        userIds,
        enabled: !enabled,
        domainId: domain.id,
      });
      await fetchData({
        ...phoneMgmtData.queryConfig,
        pageNumber: 1,
      });
      notify.success(
        users.length === 1
          ? `Phone number setup was ${enabled ? 'disabled' : 'enabled'} for ${users[0].displayName}`
          : `Phone number setup was ${enabled ? 'disabled' : 'enabled'} for selected users`,
      );
      setSelectedUsers([]);
      setIsModalButtonLoading(false);
      toggleShowModalChangeUser();
    } catch (error) {
      notify.error(error.response?.data?.message || error.message);
      setIsModalButtonLoading(false);
    }
  };

  const handleResetPhoneSetup = async () => {
    try {
      const { users } = modalChangeUserData;
      setIsModalButtonLoading(true);
      const userIds = users.length === 1 ? [users[0].userId] : users.map((user) => user.userId);
      await axios.post('/api/SecurityManagement/AdPasswordReset/ResetPhoneNumbers', {
        userIds,
        domainId: domain.id,
      });
      await fetchData({
        ...phoneMgmtData.queryConfig,
        pageNumber: 1,
      });
      notify.success(
        users.length === 1
          ? `Phone number was reset for ${users[0].displayName}`
          : 'Phone number was reset for selected users',
      );
      setSelectedUsers([]);
      setIsModalButtonLoading(false);
      toggleShowModalChangeUser();
    } catch (error) {
      notify.error(error.response?.data?.message || error.message);
      setIsModalButtonLoading(false);
    }
  };

  if (isLoading) return <Loader id="phone-setup-management-loader" />;

  if (phoneMgmtData.error) {
    return <StatusMessage error>{phoneMgmtData.error}</StatusMessage>;
  }

  return (
    <div className={styles.phoneSetup}>
      <PhoneSetupHeader domain={domain} isCompanyPage={isCompanyPage} />
      <PhoneSetupTitle />
      {!selectedUsers.length ? (
        <ViewByFilter setPhoneMgmtData={setPhoneMgmtData} phoneMgmtData={phoneMgmtData} baseUrl={baseUrl} />
      ) : (
        <HeaderActions
          phoneMgmtData={phoneMgmtData}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          toggleShowModalChangeUser={toggleShowModalChangeUser}
        />
      )}
      <PhoneSetupTable
        phoneMgmtData={phoneMgmtData}
        setPhoneMgmtData={setPhoneMgmtData}
        baseUrl={baseUrl}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        toggleShowModalChangeUser={toggleShowModalChangeUser}
      />
      {showModalChangeUser && (
        <ModalChangeUser
          isOpen={showModalChangeUser}
          onRequestClose={toggleShowModalChangeUser}
          isModalButtonLoading={isModalButtonLoading}
          modalChangeUserData={modalChangeUserData}
          handleResetPhoneSetup={handleResetPhoneSetup}
          handleTogglePhoneSetup={handleTogglePhoneSetup}
        />
      )}
    </div>
  );
};

PhoneSetupManagement.propTypes = {
  domain: PropTypes.object,
  isCompanyPage: PropTypes.bool,
};

export default PhoneSetupManagement;
