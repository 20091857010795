import PropTypes from 'prop-types';
import cn from 'classnames';
import { AlertWarning } from 'assets/img';
import styles from './Alert.module.scss';

const Alert = ({ title, children, type = 'warning', icon, action, absolute, className }) => (
  <div
    className={cn(styles.alert, {
      [styles.alertWithIcon]: type && icon,
      [styles.alertAbsolute]: absolute,
      [className]: className,
    })}>
    {title && (
      <div className={styles.alertTitle}>
        <div className={styles.alertIcon}>{type === 'warning' && icon && (icon || <AlertWarning />)}</div>
        <span>{title}</span>
      </div>
    )}
    {children && (
      <div className={styles.alertChildren}>
        <div className={styles.alertMessage}>
          <div className={styles.alertIcon}>{type === 'warning' && !title && icon && (icon || <AlertWarning />)}</div>
          <span>{children}</span>
        </div>
        {action && <div className={styles.alertAction}>{action}</div>}
      </div>
    )}
  </div>
);

Alert.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.any,
  icon: PropTypes.any,
  action: PropTypes.any,
  absolute: PropTypes.bool,
  className: PropTypes.any,
};

export default Alert;
