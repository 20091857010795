import PropTypes from 'prop-types';
import { Modal } from 'components';
import { needToShowResetPhone, userName } from './utils/resetMfaUtils';

const ResetMfaModal = ({ showResetMfaModal, toggleShowResetMfaModal, data, isLoading, onChangeMfa, setIsResetPhone, isResetPhone }) => {
  const showResetPhone = needToShowResetPhone(data.usersToUpdate);
  const user = userName(data.usersToUpdate);
  const resetMfa = () => onChangeMfa('Reset MFA', data, true);

  return (
    <Modal
      isOpen={showResetMfaModal}
      onRequestClose={() => toggleShowResetMfaModal(data.type)}
      contentLabel="Reset MFA"
      actionLoading={isLoading}
      actionTitle="Reset MFA"
      actionFunc={resetMfa}
      onChangeSwitcher={() => setIsResetPhone((value) => !value)}
      isSwitcherChecked={isResetPhone}
      switcherTitle={showResetPhone ? 'Also reset phone number' : null}
      style={{ content: { width: '35em' } }}
    >
      <div className="truncate-text">By clicking <b>RESET</b>, you will reset MFA for {user}</div>
    </Modal>
  );
};

ResetMfaModal.propTypes = {
  showResetMfaModal: PropTypes.bool,
  toggleShowResetMfaModal: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  onChangeMfa: PropTypes.func,
  setIsResetPhone: PropTypes.func,
  isResetPhone: PropTypes.bool,
};

export default ResetMfaModal;
