import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Button, DropdownArray, SearchInput } from 'components';
import { FileDownloadIcon, FileIcon } from 'assets/img';
import { onChangeView, onSearch, returnFileFromResponse } from 'utils';
import { useDevicesEnrollment } from 'hooks';
import DevicesFilter from '../../../DevicesFilter/DevicesFilter';
import styles from './DevicesFilters.module.scss';

const DevicesSearchFilter = ({ devices, paginationData, domainId, isEncryptions }) => {
  const getComplianceReport = () => {
    axios
      .get(`/api/ComplianceReport/Get?domainId=${domainId}`)
      .then((resp) => returnFileFromResponse(resp))
      .catch((err) => toast.error(err.message, 3000));
  };
  const { isEnrollmentLoading, getEnrollmentFile } = useDevicesEnrollment(domainId);

  const { deviceStatus, installationStatus } = devices.queryConfig || {};

  return (
    <div className={styles.devicesHeader}>
      <div className={styles.devicesHeaderWrap}>
        <DevicesFilter
          id="dm-devices-select"
          filterOptions={[deviceStatus, installationStatus].map((i) => (i.startsWith('All') ? `All ${i.slice(3)}` : i))}
          onFilter={(value) => onChangeView(value, paginationData)}
        />
        <SearchInput
          id="dm-devices-search"
          searchBy="Device Name"
          strightLeft
          onChange={(value) => onSearch(value, paginationData)}
        />
      </div>
      {!isEncryptions && (
        <div className={styles.devicesHeaderWrap}>
          <Button
            variant="secondary"
            id="compliance-report"
            size="40"
            icon={<FileDownloadIcon />}
            onClick={getComplianceReport}>
            Compliance Report
          </Button>
          <div className={styles.devicesHeaderEnrollment}>
            <DropdownArray
              id="dm-devices-enroll"
              isButton
              isDisabled={isEnrollmentLoading}
              icon={<FileIcon />}
              btnTitle={isEnrollmentLoading ? 'Loading...' : 'Enrollment File'}
              options={[
                { icon: 'download', value: 'Download', id: 'download-enrollment' },
                { icon: 'copy', value: 'Copy Link', id: 'copy-enrollment' },
              ]}
              onChange={getEnrollmentFile}
              className={styles.devicesHeaderEnrollmentDropdown}
              optionsClassName={styles.devicesHeaderEnrollmentDropdownOptions}
              width="13em"
            />
          </div>
        </div>
      )}
    </div>
  );
};

DevicesSearchFilter.propTypes = {
  devices: PropTypes.object,
  paginationData: PropTypes.object,
  domainId: PropTypes.string,
  isEncryptions: PropTypes.bool,
};

export default DevicesSearchFilter;
