import PropTypes from 'prop-types';
import { Table } from 'components';
import { dateFormatterUtc } from 'helpers';
import MoreButton from '../MoreButton/MoreButton';
import StatusCell from '../StatusCell/StatusCell';
import styles from '../../TruScanMonitoring.module.scss';

const TruScanTable = ({ leakedRecords, setShowTruScanDeleteModal, setModalData }) => {
  const columns = [
    {
      name: 'Scanned Domain',
      checkbox: false,
      width: '30%',
      data: ({ scannedDomain, email }) => (
        <div className={styles.nameCell}>
          <p className={styles.scannedDomain}>{scannedDomain.toLocaleString()}</p>
          <p className={styles.requestedBy}>
            Requested by: <span className={styles.email}>{email}</span>
          </p>
        </div>
      ),
    },
    {
      name: 'Last Scanned Date',
      checkbox: false,
      width: '20%',
      data: ({ lastScanAt }) => (
        <div className={styles.dateCell}>{lastScanAt ? dateFormatterUtc(lastScanAt) : 'N/A'}</div>
      ),
    },
    {
      name: 'Status',
      checkbox: false,
      width: '45%',
      data: (item) => <StatusCell item={item} />,
    },
    {
      name: '',
      checkbox: false,
      width: '5%',
      data: (item) => (
        <MoreButton item={item} setShowTruScanDeleteModal={setShowTruScanDeleteModal} setModalData={setModalData} />
      ),
    },
  ];

  return <Table columns={columns} data={{ data: leakedRecords }} id="truscan-table" dataKey="id" pagination={false} />;
};

TruScanTable.propTypes = {
  leakedRecords: PropTypes.array,
  setShowTruScanDeleteModal: PropTypes.func,
  setModalData: PropTypes.func,
};

export default TruScanTable;
