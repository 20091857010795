import { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './SwitchCheckbox.module.scss';

const SwitchCheckbox = ({ checked, id, disabled, name, onChange, className }) => (
  <label id={id} className={cn(styles.switchCheckbox, { [className]: className })}>
    <input
      type="checkbox"
      className={styles.switchCheckboxInput}
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <span className={styles.switchCheckboxSlider} />
  </label>
);

SwitchCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.any,
};

SwitchCheckbox.displayName = 'SwitchCheckbox';

export default memo(SwitchCheckbox);
