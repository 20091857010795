import PropTypes from 'prop-types';
import { CopyIcon, DownloadIcon, PassHideIcon, PassShowIcon } from 'assets/img';
import { Alert, Button } from 'components';
import { copyToClipboard } from 'utils';
import { useState } from 'react';
import styles from './AlertNoSentryInstalled.module.scss';

const AlertNoSentryInstalled = ({ userData }) => {
  const [isHidden, setIsHidden] = useState(true);
  const toggleShowCode = () => setIsHidden(!isHidden);

  return (
    <div className={styles.alertNoSentryInstalled}>
      <Alert type="warning">
        No Sentry agent currently installed. Please&nbsp;
        <a href="https://www.trugrid.com/downloads/sentry" className={styles.alertNoSentryInstalledButton}>
          <DownloadIcon /> download
        </a>
        &nbsp;Trugrid Sentry with activation code&nbsp;
        <div className={styles.alertNoSentryInstalledWrap}>
          <div className={styles.alertNoSentryInstalledInput} id="activation-code">
            {!isHidden ? userData.domainActivationCode || userData.activationCode : '•••••••••'}
          </div>
          <div className={styles.alertNoSentryInstalledButtons}>
            <Button
              type="button"
              id="copy-activation-code"
              variant="link"
              icon={isHidden ? <PassShowIcon /> : <PassHideIcon />}
              className={styles.alertNoSentryInstalledButtonIcon}
              onClick={toggleShowCode}
            />
            <Button
              type="button"
              id="copy-activation-code"
              variant="link"
              icon={<CopyIcon />}
              onClick={() =>
                copyToClipboard(
                  userData.domainActivationCode || userData.activationCode,
                  'The code copied to clipboard',
                )
              }
              className={styles.alertNoSentryInstalledButtonIcon}
            />
          </div>
        </div>
      </Alert>
    </div>
  );
};
AlertNoSentryInstalled.propTypes = {
  userData: PropTypes.object,
};

export default AlertNoSentryInstalled;
