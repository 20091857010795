import PropTypes from 'prop-types';
import { TriangleFilledIcon } from 'assets/img';
import { Alert, Button } from 'components';
import { corpTechAdmin, spTechAdmin } from 'consts';
import styles from './SuspendedMessage.module.scss';

const SuspendedMessage = ({ role, isReactivating, toggleShowReactivateModal }) => {
  if ([spTechAdmin, corpTechAdmin].includes(role)) {
    return (
      <Alert icon={<TriangleFilledIcon />} absolute>
        <div className={styles.alertMessage}>
          <p>Subscription suspended. Please contact your company administrator for assistance</p>
        </div>
      </Alert>
    );
  }
  return (
    <Alert
      absolute
      icon={<TriangleFilledIcon />}
      action={(
        <Button variant="primary" id="subs-reactivation" size="32" isLoading={isReactivating} style={{ width: '15.5em' }} onClick={toggleShowReactivateModal}>
          Reactivate subscription
        </Button>
        )}
    >
      <div className={styles.alertMessage}>
        <p>Subscription suspended</p>
        <p>Click <b>Reactivate subscription</b> to pay and update subscription</p>
      </div>
    </Alert>
  );
};

SuspendedMessage.propTypes = {
  role: PropTypes.string,
  isReactivating: PropTypes.bool,
  toggleShowReactivateModal: PropTypes.func,
};

export default SuspendedMessage;
