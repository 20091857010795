import PropTypes from 'prop-types';
import { DropdownArray, SearchInput } from 'components';
import { onChangeView, onSearch } from 'utils';
import styles from './TableActions.module.scss';

const TableActions = ({ queryViewBy, paginationData }) => (
  <div className={styles.tableActions}>
    View by
    <DropdownArray
      id="ra-desk-select"
      isSmall={false}
      isSearch={false}
      isFilter
      options={['Users', 'Computers']}
      defaultValue={queryViewBy}
      strightRight
      onChange={(value) => onChangeView(value, paginationData)}
      className={styles.tableActionsDropdown}
      width="11.75em"
    />
    <SearchInput
      id="ra_desk-search"
      searchBy={queryViewBy === 'Users' ? 'Name' : 'Title'}
      isShowAll
      strightLeft
      onChange={(value) => onSearch(value, paginationData)}
    />
  </div>
);

TableActions.propTypes = {
  queryViewBy: PropTypes.string,
  paginationData: PropTypes.object,
};

export default TableActions;
