import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Button } from 'components';
import TooltipInfo from '../../../TooltipInfo/TooltipInfo';
import styles from './TotalLicenses.module.scss';

const TotalLicenses = ({ licensesState, onUpdateLicenses, licenses, warning }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateAllocation = () => {
    setIsUpdating(true);

    const data = [{ ...licensesState.spDomain }, ...licensesState.customerDomains];

    axios.post('/api/Chargify/UpdateSubscription', data)
      .then((response) => {
        onUpdateLicenses(response.data);
        toast.success('Subscription Updated', 3000);
      })
      .catch(() => {
        onUpdateLicenses(JSON.parse(JSON.stringify(licenses)));
        toast.error('Subscription update failed', 3000);
      })
      .finally(() => setIsUpdating(false));
  };

  const rdpTooltipText = `
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Purchased Licenses: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalPurchasedRdpLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Allocated to Customers: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalAllocatedRdpLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Used by Primary Domain: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.spDomain.numberOfRdpLicenses}</span></div>
  `;

  const blTooltipText = `
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Purchased Licenses: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalPurchasedBitLockerLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Allocated to Customers: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalAllocatedBitLockerLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Used by Primary Domain: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.spDomain.numberOfBitLockerLicenses}</span></div>
  `;
  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.totalsSections} />}
      <div className={styles.totals}>
        <div className={styles.totalsSections}>
          <div className={styles.totalsSectionsWrapper}>
            <div className={cn(styles.totalsSection, { [styles.totalsSectionsWarning]: warning.isActive && warning.field === 'numberOfRdpLicenses' })}>
              <h4 className={styles.totalsSectionTitle}>SecureRDP Licenses</h4>
              <div className={styles.totalsSectionDate}>
                <TooltipInfo
                  name="rdpTotal"
                  content={rdpTooltipText}
                  position="bottom"
                  icon="question"
                  isHtml
                  className={styles.totalsSectionDateTooltip}
                />
                <div className={styles.totalsSectionDateAvailable}>
                  <b>{licensesState.spDomain.numberOfRdpLicenses}</b> Available for Allocation
                </div>
              </div>
            </div>

            <div className={cn(styles.totalsSection, { [styles.totalsSectionsWarning]: warning.isActive && warning.field === 'numberOfBitLockerLicenses' })}>
              <h4 className={styles.totalsSectionTitle}>BitLocker Licenses</h4>
              <div className={styles.totalsSectionDate}>
                <TooltipInfo
                  name=" blTotal"
                  content={blTooltipText}
                  position="bottom"
                  icon="question"
                  isHtml
                  className={styles.totalsSectionDateTooltip}
                />
                <div className={styles.totalsSectionDateAvailable}>
                  <b>{licensesState.spDomain.numberOfBitLockerLicenses}</b> Available for Allocation
                </div>
              </div>
            </div>
          </div>
          <div className={styles.totalsWarning}>
            {warning.isActive ? warning.content : null}
          </div>
        </div>
        <Button
          id="subs-customer-update"
          variant="primary"
          isLoading={isUpdating}
          isDisabled={JSON.stringify(licensesState.customerDomains) === JSON.stringify(licenses.customerDomains)}
          size="40"
          onClick={updateAllocation}
          style={{ width: '13em' }}
        >
          Update Allocation
        </Button>
      </div>
    </>
  );
};

TotalLicenses.propTypes = {
  licensesState: PropTypes.object,
  onUpdateLicenses: PropTypes.func,
  licenses: PropTypes.object,
  warning: PropTypes.object,
};

export default TotalLicenses;
