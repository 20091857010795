import { EncryptUnlockIcon, EncryptLockIcon, TriangleIcon } from 'assets/img';

export const encryptStatuses = {
  0: {
    StatusIcon: EncryptUnlockIcon, statusColor: 'grey', colorCode: '#525A64', checked: false, statusTitle: 'Fully Decrypted',
  },
  1: {
    StatusIcon: EncryptLockIcon, statusColor: 'blue', colorCode: '#4169B8', checked: true, statusTitle: 'Fully Encrypted',
  },
  2: {
    StatusIcon: EncryptLockIcon, statusColor: 'blue', colorCode: '#4169B8', checked: true, statusTitle: 'Encryption In Progress',
  },
  3: {
    StatusIcon: EncryptUnlockIcon, statusColor: 'grey', colorCode: '#525A64', checked: false, statusTitle: 'Decryption In Progress',
  },
  4: {
    StatusIcon: EncryptLockIcon, statusColor: 'blue', colorCode: '#4169B8', checked: true, statusTitle: 'Encryption Paused',
  },
  5: {
    StatusIcon: EncryptUnlockIcon, statusColor: 'grey', colorCode: '#525A64', checked: false, statusTitle: 'Decryption Paused',
  },
  6: {
    StatusIcon: EncryptLockIcon, statusColor: 'orange', colorCode: '#FA9903', checked: false, statusTitle: 'Partial Encryption',
  },
  7: {
    StatusIcon: EncryptLockIcon, statusColor: 'orange', colorCode: '#FA9903', checked: true, statusTitle: 'In Progress',
  },
  8: {
    StatusIcon: TriangleIcon, statusColor: 'grey', colorCode: '#525A64', checked: false, statusTitle: 'N/A',
  },
};
