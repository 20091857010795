import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { UserContext, MsalContext, queryFormatter } from 'utils';
import { AlertValidateDomain, Loader } from 'components';
import axios from 'config/customAxios';
import { getDomainStatus } from 'helpers';
import WorkspaceTabs from './components/WorkspaceTabs/WorkspaceTabs';
import Onboarding from './components/Onboarding/Onboarding';
import OnboardingPersonal from './components/OnboardingPersonal/OnboardingPersonal';
import AlertDomainNotConnected from './components/AlertDomainNotConnected/AlertDomainNotConnected';
import styles from './Workspace.module.scss';

const Workspace = () => {
  const { user, onUpdateUser } = useContext(UserContext);
  const [hasSecureConnects, setHasSecureConnects] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: msIsLoading } = useContext(MsalContext);
  const { isDomainNotActivated } = getDomainStatus(user);
  const { finalTimeToValidateDomain, domainWasAutovalidated } = user;

  useEffect(() => {
    if (isDomainNotActivated) {
      axios
        .get(
          queryFormatter('/api/v2/Machines/DomainMachines', {
            pageNumber: 1,
            pageSize: 10,
            kind: 'All',
            orderBy: 'hostname asc',
            searchQuery: '',
          }),
        )
        .then((res) => {
          if (res.data.length) {
            setHasSecureConnects(true);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [isDomainNotActivated]);

  if (msIsLoading || isLoading) {
    return (
      <div className={styles.workspace}>
        <Loader />
      </div>
    );
  }

  if (user.showOnboarding && !user.hasActiveCustomerDomains && !hasSecureConnects) {
    if (user.roles.some((role) => ['personaluser', 'freepersonaluser'].includes(role))) {
      return (
        <>
          {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
          <div className={styles.workspace}>
            <OnboardingPersonal user={user} />
          </div>
        </>
      );
    }
    return (
      <>
        {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
        <div className={styles.workspace}>
          <Onboarding user={user} onUpdateUser={onUpdateUser} />
        </div>
      </>
    );
  }

  return (
    <>
      {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
      {user.showOnboarding && hasSecureConnects && <AlertDomainNotConnected userData={user} />}
      <div className={cn(styles.workspace, { [styles.hasSecureConnects]: user.showOnboarding && hasSecureConnects })}>
        <WorkspaceTabs user={user} onUpdateUser={onUpdateUser} hasSecureConnects={hasSecureConnects} />
      </div>
    </>
  );
};

export default Workspace;
