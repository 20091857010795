import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { returnFileFromResponse, chartConfig, chartOptions } from 'utils';
import { Button, DropdownArray, StatusMessage } from 'components';
import { CalendarIcon, DownloadIcon } from 'assets/img';
import styles from './Authentication.module.scss';
import DropdownCheckUsers from './components/DropdownCheckUsers';
import 'chart.js/auto';

const Authentication = ({
  users,
  logins,
  selectedRange,
  selectedUsers,
  updateRange,
  updateUsers,
  apiOptions,
  timeRange,
}) => {
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  const downloadReport = () => {
    setIsCsvLoading(true);
    axios
      .post('/api/ActivityReport/Prepare', apiOptions)
      .then((resp) => returnFileFromResponse(resp))
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => setIsCsvLoading(false));
  };

  if (logins?.isError) {
    return <StatusMessage error>{logins.error}</StatusMessage>;
  }

  const stats = { successful: [], failed: [] };
  if (logins) {
    stats.successful = logins.map((item) => ({
      x: new Date(item.year, item.month, item.day),
      y: item.numberOfSuccessfullConnections,
    }));
    stats.failed = logins.map((item) => ({
      x: new Date(item.year, item.month, item.day),
      y: item.numberOfFailedConnections,
    }));
  }

  const data = chartConfig(stats.successful, stats.failed);
  const options = chartOptions(stats.successful);

  return (
    <>
      <div className={styles.authHeader}>
        <div className={styles.authHeaderWrap}>
          <DropdownArray
            id="reports-select-range"
            isSmall={false}
            isSearch={false}
            isFilter
            icon={<CalendarIcon />}
            options={timeRange.map((i) => i.text)}
            defaultValue={selectedRange}
            onChange={updateRange}
            width="12em"
          />
          <DropdownCheckUsers
            id="reports-select-users"
            options={users}
            optionsChecked={selectedUsers}
            valueKey="upn"
            checkKey="userId"
            defaultValue={
              <>
                All Users <b>({selectedUsers.length})</b>
              </>
            }
            onToggleCheck={updateUsers}
          />
        </div>

        <Button
          id="download-report"
          className={styles.downloadButton}
          variant="primary"
          onClick={downloadReport}
          isLoading={isCsvLoading}
          icon={!isCsvLoading ? <DownloadIcon /> : null}
          style={{ width: '15em' }}>
          Download CSV Report
        </Button>
      </div>

      <div className={styles.authChart}>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

Authentication.propTypes = {
  users: PropTypes.array.isRequired,
  logins: PropTypes.array.isRequired,
  selectedRange: PropTypes.string.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  updateRange: PropTypes.func.isRequired,
  updateUsers: PropTypes.func.isRequired,
  timeRange: PropTypes.array.isRequired,
  apiOptions: PropTypes.object.isRequired,
};

export default Authentication;
