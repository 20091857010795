import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import braintree from 'braintree-web-drop-in';
import BraintreeDropin from 'braintree-dropin-react';
import { Button, Loader, Modal } from 'components';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { LoginContext } from 'utils';
import { loginService } from 'services';
import styles from './PaymentModal.module.scss';

const PaymentModal = ({ isModalOpen, toggleShowModal, isLoading, formValues, registrationData }) => {
  const { setEmail } = useContext(LoginContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();

  const renderSubmitButton = (args) => (
    <>
      <Button
        onClick={toggleShowModal}
        variant="secondary"
        size="40"
      >
        Cancel
      </Button>
      <Button
        id="login-purchase-plan"
        onClick={args.onClick}
        isDisabled={args.isDisabled}
        isLoading={isDisabled}
        variant="primary"
        size="40"
        style={{ width: '11.3em' }}
      >
        {args.text}
      </Button>
    </>
  );
  const handlePaymentMethod = (payload) => {
    if (payload) {
      setIsDisabled(true);
      const {
        trialPeriod,
        monthlyPrice,
        monthlyMachineCount,
        annualPrice,
        annualMachineCount,
      } = registrationData;
      const [email, password, , firstName, lastName, plan] = formValues;
      const { nonce: paymentMethodNonce } = payload;
      const isMonthlyPaymentPlan = plan === 'monthly';

      loginService.registerPersonalUser(
        email,
        firstName,
        lastName,
        password,
        isMonthlyPaymentPlan,
        paymentMethodNonce,
        trialPeriod,
        monthlyPrice,
        monthlyMachineCount,
        annualPrice,
        annualMachineCount,
      )
        .then(() => {
          setIsDisabled(false);
          setEmail(email);
          history.push('/email-validation');
        })
        .catch((err) => {
          setIsDisabled(false);
          toast.error(err.response.data.message || err.message, 3000);
        });
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={toggleShowModal}
      contentLabel="Payment"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {formValues[5] === 'monthly' ? (
            <>
              <p>Total amount to pay: <strong>${registrationData?.monthlyPrice}</strong></p>
              <p>You will be charged automatically each month</p>
            </>
          ) : (
            <>
              <p>Total amount to pay: <strong>${registrationData?.annualPrice}</strong></p>
              <p>You will be charged automatically each year</p>
            </>
          )}
          <BraintreeDropin
            submitButtonText="Purchase Plan"
            braintree={braintree}
            authorizationToken={registrationData.clientToken}
            renderSubmitButton={renderSubmitButton}
            handlePaymentMethod={handlePaymentMethod}
            paypal={{ flow: 'vault', singleUse: false }}
            className={styles.buttonActions}
          />
        </>
      )}
    </Modal>
  );
};

PaymentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleShowModal: PropTypes.func,
  isLoading: PropTypes.bool,
  formValues: PropTypes.array,
  registrationData: PropTypes.object,
};

export default PaymentModal;
