import cn from 'classnames';
import PropTypes from 'prop-types';
import { CompletedCircleIcon, MailIcon, PhoneIcon } from 'assets/img';
import { Button } from 'components';
import styles from './ValidateDomain.module.scss';

const ValidateDomain = ({
  isDomainNotValidated,
  isDomainNotActivated,
  onModalOpen,
  emailValidationData,
  txtValidationData,
}) => (
  <div
    className={cn(styles.onboarding, {
      [styles.active]: isDomainNotValidated,
      [styles.completed]: isDomainNotActivated,
    })}>
    <div className={styles.onboardingTitle}>
      <span>1</span>
      <h5>Validate Your Domain</h5>
    </div>
    {isDomainNotValidated && (
      <div className={styles.onboardingAction}>
        <span>Select one option to validate your domain</span>{' '}
        <Button type="button" variant="link" icon={<MailIcon />} onClick={() => onModalOpen(emailValidationData)}>
          WHOIS Email
        </Button>
        <span>or</span>
        <Button type="button" variant="link" icon={<PhoneIcon />} onClick={() => onModalOpen(txtValidationData)}>
          DNS TXT Record
        </Button>
      </div>
    )}
    {isDomainNotActivated && <CompletedCircleIcon />}
  </div>
);

ValidateDomain.propTypes = {
  isDomainNotValidated: PropTypes.bool.isRequired,
  isDomainNotActivated: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  emailValidationData: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    email: PropTypes.string,
  }),
  txtValidationData: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    txt: PropTypes.string,
  }),
};

export default ValidateDomain;
