/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BrandingContext } from 'utils';
import { addAlpha, invertColor } from 'pages/Branding/helpers/colorsHelper';
import styles from './Button.module.scss';

const propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'delete', 'deleteOutline']),
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  tabIndex: PropTypes.string,
  size: PropTypes.oneOf(['32', '40', '48']),
  onClick: PropTypes.func,
  className: PropTypes.any,
};

const Button = forwardRef(
  (
    {
      id,
      size,
      className,
      type = 'button',
      variant = 'primary',
      rounded,
      outline,
      icon,
      iconPosition = 'left',
      isLoading,
      isDisabled,
      style,
      tabIndex,
      onClick,
      children,
    },
    ref,
  ) => {
    const { brandingData } = useContext(BrandingContext);
    const isInverted = invertColor(brandingData?.buttonColor);
    const buttonColor = brandingData?.buttonColor || '#4169b8';

    const colorStyles = {
      '--button-bg': buttonColor,
      '--button-border': buttonColor,
      '--button-color': isInverted ? '#000' : '#fff',
      '--button-bg-hover': addAlpha(buttonColor, 0.85),
      '--button-border-hover': addAlpha(buttonColor, 0.85),
      '--button-bg-active': addAlpha(buttonColor, 1),
      '--button-border-active': addAlpha(buttonColor, 1),
      '--button-bg-disabled': addAlpha(buttonColor, 1),
      '--button-border-disabled': addAlpha(buttonColor, 1),
      '--button-opacity': buttonColor ? '0.5' : '1',
    };

    const nodeRef = useRef();
    const btnClass = cn({
      [styles.button]: variant !== 'link',
      [styles.buttonSize32]: size === '32',
      [styles.buttonSize40]: size === '40',
      [styles.buttonSize48]: size === '48',
      [styles.buttonPrimary]: variant === 'primary',
      [styles.buttonSecondary]: variant === 'secondary',
      [styles.buttonDeleteOutline]: variant === 'deleteOutline',
      [styles.buttonDelete]: variant === 'delete',
      [styles.buttonLink]: variant === 'link',
      [styles.buttonRounded]: rounded,
      [styles.buttonOutline]: outline,
      [styles.buttonLoading]: isLoading,
      [styles.buttonDisabled]: isDisabled || isLoading,
      [styles.buttonIconRight]: iconPosition === 'right',
      [className]: className,
    });

    const brandingStyles = {
      ...colorStyles,
      ...style,
    };

    return (
      <button
        ref={ref}
        id={id}
        type={type === 'button' ? 'button' : 'submit'}
        onClick={onClick}
        style={brandingData?.isActive && variant === 'primary' && !outline ? brandingStyles : style}
        tabIndex={isDisabled || isLoading ? '-1' : tabIndex || ''}
        className={btnClass}
        disabled={isDisabled || isLoading}>
        {icon ? (
          <span className={styles.buttonIcon}>
            {isLoading && (
              <span className={styles.buttonLoader} ref={nodeRef}>
                <svg className={styles.spinner} viewBox="0 0 50 50">
                  <circle
                    className={styles.spinnerPath}
                    style={brandingData?.isActive && isInverted ? { stroke: '#000' } : { stroke: '#fff' }}
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  />
                </svg>
              </span>
            )}
            {icon} {children}
          </span>
        ) : (
          <span>
            {isLoading && (
              <span className={styles.buttonLoader} ref={nodeRef}>
                <svg id="loading" className={styles.spinner} viewBox="0 0 50 50">
                  <circle
                    className={styles.spinnerPath}
                    style={brandingData?.isActive && isInverted ? { stroke: '#000' } : { stroke: '#fff' }}
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  />
                </svg>
              </span>
            )}
            {children}
          </span>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
Button.propTypes = propTypes;

export default Button;
