import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, CountryListDropdown } from 'components';
import { loginService } from 'services';
import { notify } from 'utils';
import styles from './EnterPhoneNumber.module.scss';

const EnterPhoneNumber = ({ countriesList, dialCode, currentCountry, setCurrentCountry, phoneNumber, setPhoneNumber, setPinHash, setPhoneMask }) => {
  const [isSending, setIsSending] = useState(false);

  const history = useHistory();
  const { url } = useRouteMatch();

  const handleSetCurrentCountry = (value) => setCurrentCountry(value);

  const onSubmitPhoneNumber = (e) => {
    e.preventDefault();
    if ((phoneNumber && phoneNumber.length > 4)) {
      setIsSending(true);
      loginService.requestVerificationCodeBySms(phoneNumber.replace(/\D+/g, ''), dialCode)
        .then(({ data }) => {
          setPinHash(data.pinHash);
          setPhoneMask(data.phoneMask);
          notify.success('SMS has been sent');
          setIsSending(false);
          history.push(`${url}/validation-code`);
        })
        .catch((err) => {
          setIsSending(false);
          if (err?.response?.status === 409) {
            notify.error(err?.response?.data);
            history.replace('/setup-mfa');
          } else {
            notify.error(err.response?.data || err.message);
          }
        });
    }
  };

  return (
    <form method="POST" noValidate onSubmit={onSubmitPhoneNumber} className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>Receive a code via text to enter on the next screen</div>
      <CountryListDropdown
        id="phone-code-dd"
        label="Enter Your Phone Number"
        options={countriesList}
        currentCountry={currentCountry}
        onChange={handleSetCurrentCountry}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <Button
        type="submit"
        variant="primary"
        size="40"
        isLoading={isSending}
        isDisabled={!(phoneNumber && phoneNumber.length > 4)}
        className={styles.setupMfaButton}
      >
        Get a Code
      </Button>
    </form>
  );
};

EnterPhoneNumber.propTypes = {
  countriesList: PropTypes.object,
  dialCode: PropTypes.string,
  currentCountry: PropTypes.string,
  setCurrentCountry: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setPinHash: PropTypes.func,
  setPhoneMask: PropTypes.func,
};

export default EnterPhoneNumber;
