import PropTypes from 'prop-types';
import { Modal } from 'components';
import { toast } from 'react-toastify';

const DeleteCountryModal = ({ toggleShowDeleteCountryModal, showDeleteCountryModal, checkedCountries, deleteCountryName, changeAllowedList, isLoading }) => {
  const onDeleteCountry = () => {
    const newCheckedCountries = [...checkedCountries];
    const deleteCountryIndex = newCheckedCountries.findIndex((country) => country === deleteCountryName);
    newCheckedCountries.splice(deleteCountryIndex, 1);

    changeAllowedList(newCheckedCountries)
      .then((res) => {
        const successMsg = res.data.countries.length < 1 ? 'Geo-Blocking is disabled for domain' : `${deleteCountryName} is deleted from the Allowed List`;
        toast.success(successMsg, 3000);
      })
      .catch((err) => {
        toast.error(err.message, 3000);
      })
      .finally(() => {
        toggleShowDeleteCountryModal();
      });
  };

  return (
    <Modal
      isOpen={showDeleteCountryModal}
      onRequestClose={toggleShowDeleteCountryModal}
      contentLabel="Delete country?"
      actionLoading={isLoading}
      actionTitle="Delete"
      actionFunc={onDeleteCountry}
      variantBtn="deleteOutline"
    >
      {checkedCountries.length > 1 ? (
        <p>Do you really want to delete <strong>{deleteCountryName}</strong> from the allowed list?</p>
      ) : (
        <p>By deleting <strong>{deleteCountryName}</strong>, Geo-Blocking feature will be disabled. <br />Are you sure?</p>
      )}
    </Modal>
  );
};

DeleteCountryModal.propTypes = {
  toggleShowDeleteCountryModal: PropTypes.func,
  showDeleteCountryModal: PropTypes.bool,
  checkedCountries: PropTypes.array,
  deleteCountryName: PropTypes.string,
  changeAllowedList: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DeleteCountryModal;
