import { toast } from 'react-toastify';

const notify = {
  warning: (message, autoClose = 3000) => toast.warning(message, autoClose),
  error: (message, autoClose = 3000) => toast.error(message, autoClose),
  success: (message, autoClose = 3000) => toast.success(message, autoClose),
  info: (message, autoClose = 3000) => toast.info(message, autoClose),
};

export {
  notify,
};
