import axios from 'config/customAxios';

export const onSaveSettings = async (domainSettings, domains, domainCurrent, isCustomDomain) => {
  let link = '/api/Connectors/UpdateUserRdpConfigs';
  let data = domainSettings.modified;

  if (isCustomDomain) {
    link = '/api/Connectors/UpdateRdpConfigsForDomains';
    data = {
      Configuration: domainSettings.modified,
      DomainIds: [domains[domainCurrent].id],
    };
  }

  await axios.post(link, data);
  const domainSettingsNew = JSON.parse(JSON.stringify(domainSettings));
  domainSettingsNew.initial = { ...domainSettingsNew.modified };
  return domainSettingsNew;
};
