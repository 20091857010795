import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './StatusMessage.module.scss';

const StatusMessage = ({ header, absolute = true, disabled, error, children }) => (
  <div className={cn(styles.statusContainer, {
    [styles.statusContainerAbsolute]: absolute,
    [styles.statusContainerDisabled]: disabled,
    [styles.statusContainerError]: error,
  })}
  >
    {header && (<p className={styles.statusHeader}>{header}</p>)}
    {children}
  </div>
);

StatusMessage.propTypes = {
  header: PropTypes.string,
  absolute: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.any,
};

export default StatusMessage;
