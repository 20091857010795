import { Button, Modal } from 'components';
import PropTypes from 'prop-types';
import styles from './ModalConfirmChangeDomain.module.scss';

const ModalConfirmChangeDomain = ({
  isOpen,
  onRequestClose,
  onDiscardChanges,
  domainCurrent,
  onSaveSettings,
  isCustomDomain,
  domainToChange,
}) => {
  const saveSettings = () => {
    onSaveSettings(domainToChange, true);
  };
  const saveAsSettings = () => {
    onSaveSettings('Save As', domainToChange);
    onRequestClose();
  };
  const discardChanges = () => onDiscardChanges(domainToChange);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Save changes?"
      actionFunc={saveSettings}
      cancelButtonClass={styles.cancelButton}
      cancelAction={saveAsSettings}
      actionTitle="Save"
      variantBtn="primary"
      cancelTitle="Save As">
      <Button
        id="setting-discard-all"
        variant="deleteOutline"
        size="32"
        className={styles.discardButton}
        onClick={discardChanges}>
        Discard All
      </Button>
      Do you want to save changes for <strong>{domainCurrent}</strong> {isCustomDomain && 'domain'}?
    </Modal>
  );
};
ModalConfirmChangeDomain.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  domainCurrent: PropTypes.string,
  onSaveSettings: PropTypes.func,
  isCustomDomain: PropTypes.bool,
  domainToChange: PropTypes.string,
};

export default ModalConfirmChangeDomain;
