import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './utils/domExtensions';
import './styles/main.scss';
import UserProvider from 'providers/UserProvider';
import BrandingProvider from 'providers/BrandingProvider';
import LoginProvider from 'providers/LoginProvider';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <LoginProvider>
      <UserProvider>
        <BrandingProvider>
          <Router basename={`${window.location.pathname}#`}>
            <App />
          </Router>
        </BrandingProvider>
      </UserProvider>
    </LoginProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
