import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import { InfoIcon, QRCode, SecureIcon, SetupMail, Smartphone } from 'assets/img';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { UserContext } from 'utils';
import styles from './SetupMfaOptions.module.scss';

let MFA_SETUP_OPTIONS = [
  {
    id: 'authentication-app',
    name: 'Authentication app',
    info: 'Approve sign-in with a push notification or 6-digit code from your mobile phone',
    icon: <QRCode />,
  },
  {
    id: 'sms',
    name: 'Text message',
    info: 'Approve sign-in with a 6-digit code from your mobile phone',
    icon: <Smartphone />,
  },
  {
    id: 'email',
    name: 'Email',
    info: 'Approve sign-in with a 6-digit code sent to your email',
    icon: <SetupMail />,
  },
];

const SetupMfaOptions = () => {
  const { user } = useContext(UserContext);
  const [activeOption, setActiveOption] = useState('authentication-app');

  if (!user?.userEmail) {
    MFA_SETUP_OPTIONS = MFA_SETUP_OPTIONS.filter((option) => option.id !== 'email');
  }

  const history = useHistory();

  const changeSetupMfaOption = (id) => {
    setActiveOption(id);
  };
  const continueSetupMfa = () => {
    if (activeOption === 'authentication-app' && (!user.isPhoneMfaActive && !user.mustSetupMfa)) {
      return history.push(`/setup-mfa/${activeOption}/verify-phone`);
    }
    return history.push(`/setup-mfa/${activeOption}`);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const goBack = () => window.location.replace('/login');

  return (
    <div className={styles.setupMfa}>
      <LoginHeader title="Choose an option to setup MFA" hideSteps back={goBack} />
      <div className={styles.setupMfaInformation}>Multifactor Authentication (MFA) secures your account </div>
      <ul className={styles.setupMfaOptions}>
        {MFA_SETUP_OPTIONS.map(({ id, name, icon, info }) => (
          <button type="button" className={cn(styles.setupMfaOptionsItem, { [styles.active]: activeOption === id })} key={id}>
            {id === 'authentication-app' && (
              <span className={styles.setupMfaOptionsInfo}><SecureIcon /> Recommended. More secure</span>
            )}
            <Checkbox
              id={id}
              isCircle
              checked={activeOption === id}
              onChange={() => changeSetupMfaOption(id)}
              label={(
                <>
                  {icon}
                  <span className={styles.setupMfaOptionsItemName}>{name}</span>
                  <span
                    className={styles.setupMfaOptionsTooltipIcon}
                    data-for="mfa-tooltip"
                    data-tip={info}
                  >
                    <InfoIcon />
                  </span>
                </>
              )}
              labelPosition="left"
            />
          </button>
        ))}
      </ul>
      <Button variant="primary" size="48" className={styles.setupMfaButton} onClick={continueSetupMfa}>Continue</Button>
      <ReactTooltip
        className={styles.setupMfaOptionsTooltip}
        id="mfa-tooltip"
        type="light"
        place="top"
        effect="solid"
      />
    </div>
  );
};

export default SetupMfaOptions;
