/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, SearchInput } from 'components';
import { toast } from 'react-toastify';
import CountriesList from '../CountriesList/CountriesList';
import styles from './ManageCountriesModal.module.scss';

const ManageCountriesModal = ({
  changeAllowedList,
  isManageAllowedList,
  setIsManageAllowedList,
  typeEnableGeoBlocking,
  checkedCountries,
  setNewCheckedCountries,
  onToggleGeoBlocking,
  setShowManageCountriesModal,
  showManageCountriesModal,
  isLoading,
}) => {
  const initCheckedCountries = useRef(checkedCountries);

  useEffect(() => {
    initCheckedCountries.current = checkedCountries;
    return () => setIsManageAllowedList(false);
  }, []);

  const isDisabled = (initCheckedCountries.current.length === checkedCountries.length && initCheckedCountries.current.every((val, index) => val === checkedCountries[index])) || checkedCountries.length < 1;
  const [inputText, setInputText] = useState('');

  const inputHandler = (e) => {
    const lowerCase = e.toLowerCase();
    setInputText(lowerCase);
  };

  const toggleManageCountriesModal = () => {
    setShowManageCountriesModal(!showManageCountriesModal);
  };

  const onUpdateGeoBlocking = (newCheckedCountries) => {
    changeAllowedList(newCheckedCountries)
      .then((resp) => {
        if (resp.data.addedCountries.length && resp.data.removedCountries.length) {
          toast.success('Allowed List is updated', 3000);
        } else if (resp.data.removedCountries.length > 1) {
          toast.success(`${resp.data.removedCountries.length} countries are removed from the Allowed List`, 3000);
        } else if (resp.data.addedCountries.length > 1) {
          toast.success(`${resp.data.addedCountries.length} countries are added to the Allowed List`, 3000);
        } else if (resp.data.addedCountries.length === 1) {
          toast.success(`${resp.data.addedCountries} is added to the Allowed List`, 3000);
        } else if (resp.data.removedCountries.length === 1) {
          toast.success(`${resp.data.removedCountries} is deleted from the Allowed List`, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.message, 3000);
      })
      .finally(() => {
        toggleManageCountriesModal();
        setIsManageAllowedList(false);
      });
  };

  return (
    <Modal
      isOpen={showManageCountriesModal}
      onRequestClose={toggleManageCountriesModal}
      contentLabel={typeEnableGeoBlocking === 'priorList' || isManageAllowedList ? 'Add countries to Allowed list' : 'Enable Geo-Blocking for domain?'}
      actionLoading={isLoading}
      actionTitle={typeEnableGeoBlocking === 'priorList' || isManageAllowedList ? 'Update' : 'Enable'}
      actionFunc={() => (isManageAllowedList ? onUpdateGeoBlocking(checkedCountries) : onToggleGeoBlocking())}
      actionDisabled={isManageAllowedList ? isDisabled : !checkedCountries.length}
    >
      {typeEnableGeoBlocking === 'newList' && (
        <p className={styles.modalHeader}>Before enabling this option, add countries to allowed list </p>
      )}
      <SearchInput
        id="search-by-country"
        isNotDebounce
        fullWidth
        placeholder="Find Country"
        onChange={inputHandler}
        className={styles.modalSearch}
        inputClassName={styles.modalSearchInput}
      />
      <CountriesList
        initCheckedCountries={initCheckedCountries}
        typeEnableGeoBlocking={typeEnableGeoBlocking}
        checkedCountries={checkedCountries}
        setNewCheckedCountries={setNewCheckedCountries}
        inputText={inputText}
      />
    </Modal>
  );
};

ManageCountriesModal.propTypes = {
  showManageCountriesModal: PropTypes.bool,
  typeEnableGeoBlocking: PropTypes.string,
  checkedCountries: PropTypes.array,
  setNewCheckedCountries: PropTypes.func,
  isManageAllowedList: PropTypes.bool,
  setIsManageAllowedList: PropTypes.func,
  onToggleGeoBlocking: PropTypes.func,
  setShowManageCountriesModal: PropTypes.func,
  changeAllowedList: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ManageCountriesModal;
