import PropTypes from 'prop-types';
import { Modal } from 'components';
import { checkTypeAndUsers } from '../../utils/phoneSetupUtils';

const ModalChangeUser = ({
  isOpen,
  onRequestClose,
  isModalButtonLoading,
  modalChangeUserData,
  handleResetPhoneSetup,
  handleTogglePhoneSetup,
}) => {
  const { message, button, title, actionFunc } = checkTypeAndUsers(
    modalChangeUserData,
    handleResetPhoneSetup,
    handleTogglePhoneSetup,
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      actionFunc={actionFunc}
      actionTitle={button}
      actionLoading={isModalButtonLoading}>
      {message}
    </Modal>
  );
};

ModalChangeUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isModalButtonLoading: PropTypes.bool.isRequired,
  handleResetPhoneSetup: PropTypes.func.isRequired,
  modalChangeUserData: PropTypes.object.isRequired,
  handleTogglePhoneSetup: PropTypes.func.isRequired,
};

export default ModalChangeUser;
