import { useCallback, useState } from 'react';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { returnFileFromResponse, copyToClipboard } from 'utils';

export const useDevicesEnrollment = (domainId) => {
  const [isEnrollmentLoading, setIsEnrollmentLoading] = useState(false);
  const { host } = window.location;

  const copyEnrollLink = useCallback(() => {
    setIsEnrollmentLoading(true);
    axios.get(`/api/IoTAgent/GetDownloadData?domainId=${domainId}${!process.env.REACT_APP_PUBLIC_URL.includes(host) ? `&brandingHost=${host}` : ''}`)
      .then((resp) => copyToClipboard(resp.data.agents[0].downloadLink, 'The link copied to clipboard'))
      .then(() => setIsEnrollmentLoading(false));
  }, [domainId, host]);

  const downloadEnrollFile = useCallback(() => {
    setIsEnrollmentLoading(true);

    axios.get(`/api/IoTAgent/GetDownloadData?domainId=${domainId}${!process.env.REACT_APP_PUBLIC_URL.includes(host) ? `&brandingHost=${host}` : ''}`)
      .then((resp) => axios({ url: resp.data.agents[0].downloadLink, responseType: 'blob' }))
      .then((resp) => returnFileFromResponse(resp))
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => setIsEnrollmentLoading(false));
  }, [domainId, host]);

  const getEnrollmentFile = useCallback((value) => {
    if (value === 'Download') {
      downloadEnrollFile();
    } else {
      copyEnrollLink();
    }
  }, [copyEnrollLink, downloadEnrollFile]);

  return { isEnrollmentLoading, getEnrollmentFile };
};
