export const formatPhoneNumber = (phoneCountryCode, phoneNumber) => {
  if (phoneCountryCode && phoneNumber) {
    const end = phoneNumber.slice(-4);
    const start = (phoneNumber.slice(0, -4).length % 3 === 0)
      ? ` (${phoneNumber.slice(0, 3)}) `
      : ` (${phoneNumber.slice(0, 2)}) `;
    const middle = [...phoneNumber.slice(start.length - 4, -4)].map((c, i) => ((i + 1) % 3 === 0 ? `${c}-` : c));

    return `+${phoneCountryCode + start + middle.join('') + end}`;
  }
  return 'N/A';
};
