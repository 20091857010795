/* eslint-disable default-param-last */
import { useCallback, useState } from 'react';

export const useForm = (initialState = {}, onSubmit) => {
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = useCallback((e) => {
    e.persist();
    if (e.target.type === 'checkbox') {
      return setFormData({ ...formData, [e.target.name]: e.target.checked });
    }
    if (e.target.name === 'phoneNumber') {
      if (!/^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\d]*$/g.test(e.target.value)) return;
      return setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.maxLength !== -1) {
      return setFormData({ ...formData, [e.target.name]: e.target.value.slice(0, e.target.maxLength) });
    }
    return setFormData({ ...formData, [e.target.name]: e.target.value });
  }, [formData]);

  const handleClearValue = useCallback((name) => {
    setFormData({ ...formData, [name]: '' });
  }, [formData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    onSubmit?.(formData);
  }, [formData, onSubmit]);

  return { formData, handleInputChange, handleClearValue, handleSubmit };
};
