/* eslint-disable react/prop-types */
import { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoginContext } from 'utils';
import SignIn from './pages/SignIn/SignIn';
import LoginAzure from './pages/LoginAzure/LoginAzure';
import LoginDefault from './pages/LoginDefault/LoginDefault';
import UpdatePassword from './pages/UpdatePassword/UpdatePassword';
import ChangeTempPassword from './pages/ChangeTempPassword/ChangeTempPassword';
import ResetMfa from './pages/ResetMfa/ResetMfa';
import ResetPassword from './pages/ResetPassword/ResetPassword';

const Login = () => {
  const { signInData } = useContext(LoginContext);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    localStorage.removeItem('TG.SSO.LOGIN');
    localStorage.removeItem('TG.SSO.PWD');
  }, []);

  return (
    <Switch>
      <Route path={path} exact component={LoginDefault} />
      <Route path={`${url}/forgot-password`} component={ResetPassword} />
      {signInData && (
        <Switch>
          <Route path={`${url}/temp-password`} component={ChangeTempPassword} />
          <Route path={`${url}/sign-in`} component={SignIn} />
          <Route path={`${url}/azure`} component={LoginAzure} />
          <Route path={`${url}/update-password`} component={UpdatePassword} />
          <Route path={`${url}/reset-mfa`} component={ResetMfa} />
        </Switch>
      )}
      <Route path="*" render={() => <Redirect to="/login" />} />
    </Switch>
  );
};

export default Login;
