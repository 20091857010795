import { useState } from 'react';
import axios from 'config/customAxios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, StatusMessage, TextInput } from 'components';
import styles from './AccountInfo.module.scss';

const AccountInfo = ({ userData, isUpdateEmailHidden }) => {
  const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const domainPart = userData.email.match(/@(.*)/g)[0];

  const updateEmail = (e) => {
    e.target.blur();
    const email = newUsername + domainPart;

    axios
      .post(`/api/UserProfile/UpdateEmail?newEmail=${email}`)
      .then(({ data }) => {
        setNewEmail(data);
        toast.success('Confirmation email was sent to complete email update', 3000);
        setIsUpdateEmailOpen(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setEmailError(err.response?.data.message);
        } else {
          toast.error(err.response?.data.message || err.message);
        }
      });
  };

  if (userData.isError) {
    return <StatusMessage error>{userData.error}</StatusMessage>;
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.rowTitle}>Email Address</div>
        <div className={styles.rowValue} id="profile-email-address">
          {userData.email}
        </div>
        <div className={styles.rowAction}>
          {!isUpdateEmailHidden && (
            <Button
              type="button"
              size="32"
              variant="secondary"
              onClick={() => setIsUpdateEmailOpen(!isUpdateEmailOpen)}
              id="toogle-edit-email-address"
              style={{ transition: 'none' }}>
              {isUpdateEmailOpen ? 'Close' : 'Update Email'}
            </Button>
          )}
        </div>
        {(userData.requestedEmail || newEmail) && (
          <span className={styles.rowActionMessage}>
            Waiting for verification, email sent to {userData.requestedEmail || newEmail}
          </span>
        )}
      </div>
      {isUpdateEmailOpen && (
        <div className={styles.rowEmailEdit}>
          <p className={styles.rowEmailEditInfo}>
            To update, please enter your <span>new</span> email and click <b>Submit</b> to receive confirmation
          </p>
          <div className={styles.rowEmailEditInputWrapper}>
            <TextInput
              type="email"
              id="enter-your-username"
              placeholder="Enter your username"
              autoComplete="off"
              value={newUsername}
              error={emailError}
              onChange={(e) => setNewUsername(e.target.value)}
              onClear={() => setNewUsername('')}
              onFocus={() => setEmailError('')}
              onKeyDown={(e) => e.key === 'Enter' && newUsername && updateEmail(e)}
            />
            <div className={styles.domainName}>{domainPart}</div>
          </div>

          <Button
            size="32"
            variant="primary"
            id="change-profile-submit"
            isDisabled={!newUsername}
            onClick={updateEmail}
            className={styles.rowEmailEditButton}>
            Submit
          </Button>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.rowTitle}>Account Details</div>
        <div className={styles.rowValue}>{userData.displayName}</div>
        <div className={styles.rowAction} />
      </div>
    </>
  );
};

AccountInfo.propTypes = {
  userData: PropTypes.object.isRequired,
  isUpdateEmailHidden: PropTypes.bool.isRequired,
};

export default AccountInfo;
