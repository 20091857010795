import PropTypes from 'prop-types';
import { Modal, Link } from 'components';
import styles from './WorkspaceEula.module.scss';

const WorkspaceEula = ({ isOpen, onClose, onConfirm }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="LICENSE AGREEMENT"
    contentClass={styles.eula}
    cancelButtonClass={styles.eulaCancel}
    style={{ content: { width: '50%', height: '95%' } }}
    cancelTitle="I don't agree"
    actionTitle="I agree"
    actionFunc={onConfirm}
  >
    <div className={styles.eulaContent}>
      <h2>END-USER LICENSE AGREEMENT FOR TRUGRID - TruGrid Sentry and Connector</h2>
      <div className="eula-intro" style={{ fontWeight: '500' }}>
        <p>
          IMPORTANT - READ CAREFULLY: This end-user license Agreement ("EULA") is
          a legally binding license agreement between the entity or organization
          you represent, or you in your individual capacity on behalf of yourself
          ("you" or "your") and N.E. Desktop Software, Inc. (DBA: TruGrid), whose
          address is 1100 E Woodfield Rd, Schaumburg, IL 60173 ("TruGrid") for
          the TruGrid identified above and as further defined in this EULA.
        </p>
        <p>
          BY INSTALLING, COPYING, OR OTHERWISE USING THE SOFTWARE, SUCH ACTS
          INDICATE YOUR ACCEPTANCE OF THIS EULA AND YOUR AGREEMENT TO BE BOUND BY
          THE TERMS AND CONDITIONS OF THIS EULA. IF YOU DO NOT DESIRE TO ACCEPT
          THIS EULA OR AGREE TO THE TERMS OF THIS EULA, PLEASE SELECT "I DON'T
          AGEEE" WHEN PROMPTED AND DO NOT USE THIS SOFTWARE.
        </p>
        <p>
          THE SOFTWARE IS PROTECTED BY U.S. COPYRIGHT LAWS AND INTERNATIONAL
          COPYRIGHT TREATIES, AS WELL AS OTHER INTELLECTUAL PROPERTY LAWS AND
          TREATIES. TRUGRID AND/OR ITS SUPPLIERS OR LICENSORS, ARE AND SHALL
          REMAIN THE OWNERS OF ALL INTELLECTUAL PROPERTY RIGHTS IN THE SOFTWARE.
          THE SOFTWARE IS LICENSED TO YOU ON A NON-EXCLUSIVE, NON-ASSIGNABLE,
          NON-TRANSFERABLE, LIMITED, PERSONAL BASIS, NOT SOLD.
        </p>
        <p>
          TRUGRID STRIVES TO PROTECT THE SECURITY AND PRIVACY OF THE USERS OF
          ITS PRODUCTS. THE TRUGRID PRIVACY POLICY FOUND AT:
          <Link href="https://www.trugrid.com/privacy-policy/" external>app.trugrid.com/privacy</Link> IS INCORPORATED IN THIS EULA BY
          REFERENCE. WHEN YOU USE THE SOFTWARE, TRUGRID MAY COLLECT CERTAIN
          INFORMATION ABOUT YOUR COMPUTER THROUGH A CALL HOME SYSTEM OR
          OTHERWISE TO FACILITATE, EVALUATE AND VERIFY YOUR USE OF THE
          SOFTWARE WHICH MAY BE SENT TO A TRUGRID WEB OR THIRD-PARTY CLOUD
          SERVERS FOR STORAGE OR FURTHER PROCESSING BY TRUGRID AND/OR ITS
          PARTNERS OR SUBSIDIARIES OR AFFILIATES. THIS INFORMATION IS
          GENERALLY COLLECTED IN THE AGGREGATE FORM, WITHOUT IDENTIFYING ANY
          USER INDIVIDUALLY, ALTHOUGH THE IP ADDRESS, COMPUTER AND SESSION ID
          IN RELATION TO PURCHASES AND DOWNLOADS/INSTALLATIONS OF THE
          SOFTWARE MAY BE TRACKED AS PART OF TRUGRID'S CUSTOMER REVIEW,
          STATISTICAL ANALYSIS, FRAUD AND PIRACY PREVENTION EFFORTS. FOR
          EXAMPLE, TRUGRID MAY COLLECT INFORMATION SUCH AS THE NUMBER OF
          DOWNLOADS/INSTALLATIONS OR TRIALS OF THE SOFTWARE OR THE TYPE OF
          OPERATING SYSTEM USED, FOR NONIDENTIFYING STATISTICAL ANALYSIS AND
          FOR IMPROVING THE SOFTWARE, PROVIDING YOU WITH A MORE RELEVANT
          EXPERIENCE. TRUGRID MAY ALSO COLLECT CERTAIN INFORMATION, SUCH AS
          NAMES AND E-MAIL ADDRESSES IN CONNECTION WITH YOUR USE OF CERTAIN
          FEATURES OF THE SOFTWARE WHICH ALSO MAY BE SENT TO A TRUGRID WEB
          SERVER OR A THIRD-PARTY SERVERS TO PROVIDE YOU WITH YOUR REQUESTED
          ONLINE SERVICES. BY DOWNLOADING A TRIAL VERSION OF THE SOFTWARE,
          YOU CONSENT TO TRUGRID KEEPING YOU INFORMED VIA EMAIL AND/OR IN-APP
          NOTIFICATIONS ABOUT OUR PRODUCTS AND SERVICES. TO OPT OUT IN THE
          FUTURE, CLICK THE UNSUBSCRIBE LINK IN ANY MARKETING NOTIFICATION
          YOU RECEIVE FROM US. TO LEARN MORE ABOUT YOUR MARKETING CHOICES,
          READ OUR TRUGRID PRIVACY POLICY FOUND AT: <Link href="https://www.trugrid.com/privacy-policy/" external>app.trugrid.com/privacy</Link>
        </p>
        <p>
          AS INDICATED ABOVE, IF YOU DO NOT DESIRE TO ACCEPT THIS EULA OR
          AGREE TO THE TERMS OF THIS EULA, PLEASE DELETE AND DO NOT INSTALL
          THIS SOFTWARE. YOU ACKNOWLEDGE AND AGREE:
        </p>
        <ol>
          <li>
            THAT YOU ARE NOT AN OWNER OF THE SOFTWARE OR ANY COPIES OF THE SOFTWARE;
          </li>
          <li>
            THAT YOU ARE A MERE LICENSEE OF THE SOFTWARE AND ANY COPIES
            OF THE SOFTWARE WITHOUT ANY RIGHT TO FURTHER TRANSFER OR
            DISTRIBUTE THE SOFTWARE OR ANY COPIES OF THE SOFTWARE OR
            PROVIDE ACCESS TO THE SOFTWARE IN ANY MANNER WITHOUT
            TRUGRID'S PRIOR WRITTEN CONSENT;
          </li>
          <li>
            NOT TO CHALLENGE THE ENFORCEABILITY OR VALIDITY OF THIS EULA; AND
          </li>
          <li>
            NOT TO INITIATE ANY PROCEEDINGS INCONSISTENT WITH THE TERMS
            AND CONDITIONS OF THIS EULA. THE SOFTWARE INCLUDES
            TECHNOLOGY DESIGNED TO PREVENT UNAUTHORIZED USE AND
            COPYING. SUCH TECHNOLOGY MAY PREVENT YOUR USE OF THE
            SOFTWARE IF YOU DO NOT FOLLOW THE VALIDATION, INSTALLATION,
            AND/OR LICENSE MANAGEMENT PROCESS DESCRIBED IN THE SOFTWARE.
          </li>
        </ol>
      </div>

      <div className="eula-main">
        <p><b>Definitions</b></p>
        <ol>
          <li>
            "Computer" means a virtual or physical computer device that
            accepts information in digital or similar form and
            manipulates it for specific result based on a sequence of instructions.
          </li>
          <li>
            "Content" means any video, audio, data, music, still
            images, and any other output of the Software and any video,
            audio, data, music, still images, or other input into the Software.
          </li>
          <li>
            "Device" means a computer hardware system (whether physical
            or virtual) with a storage device or a mobile device
            capable of running the Software. A hardware partition or blade is a device.
          </li>
          <li>
            "Internal Network" means a private, proprietary network
            resource accessible only by employees and individual
            contractors (i.e., temporary employees) of a specific
            corporation or similar business entity. Internal Network
            does not include portions of the internet or any other
            network community open to the public, such as membership or
            subscription driven groups, associations, and similar organizations.
          </li>
          <li>
            "Online Services" means any text, image, video, audio, data processing or hosting services.
          </li>
          <li>
            "Resellers and Distributors" means a TruGrid authorized
            partner, e-merchant, or a portal, within which TruGrid manages its Software channel.
          </li>
          <li>
            "Software" means use of a copy of computer software
            identified above and associated media, Library Content and
            Online Services and a copy of the printed materials and may
            include "online" or electronic documentation as well as
            licensed third-party Content. The Software does not include source code.
          </li>
          <li>
            "Trial Version" means a version of the Software which may
            have limited features and is limited in terms of the length
            of time the Software may be used and will cease operating
            after a predetermined amount of time ("the Trial Period")
            due to an internal mechanism within the Trial Version. The
            Software will notify you that the Software is in trial
            mode, which may include, but would not be limited to, watermarking.
          </li>
          <li>"Your Content" means the Content resulting from your use of the Software. </li>
        </ol>

        <ol>
          <li><b>Grant of License and Restrictions.</b> <br />
            <p>The following section applies to all versions of the Software unless otherwise noted.</p>
            <ol>
              <li><b>General Grant.</b> If you obtained the Software and any
                required Software from TruGrid or one of its authorized
                Resellers or Distributors and so long as you comply with
                the terms and conditions of this EULA as a condition to the
                license granted herein, TruGrid hereby grants you a
                non-exclusive, <b>non-transferable</b>, limited personal license
                to install and run copies of the Software in a manner
                consistent with its design, documentation, and your order.
                You may only use the Software according to the subscription
                ordered by you and granted by TruGrid. TruGrid offers
                several subscription types to meet the needs of its customers.
              </li>
              <li><b>General and Cross Platform Use.</b> Except as otherwise
                provided in the EULA and so long as you comply with the
                terms and conditions of this EULA as a condition to the
                license granted herein, you are licensed to install and run
                copies of the Software for use only by you for your
                internal business or individual use only, whichever is
                applicable. The components of the Software are licensed as
                a single unit, and you may not separate or virtualize the
                components and install them on different devices. By way of
                clarity, if this license has been entered into by an entity
                or organization, the copies of the Software licensed
                hereunder may only be used for the internal business
                purposes of the licensee entity or organization.
              </li>
              <li><b>Distribution from Server.</b> So long as you comply with the
                terms and conditions of this EULA as a condition to the
                license granted herein, you are licensed to copy an image
                of the Software onto Computer file server(s) within your
                Internal Network for the purpose of downloading and
                installing the Software onto devices within the same
                Internal Network for use as permitted by Section 1.1 and
                1.2 above.
              </li>
              <li><b>Server use.</b> So long as you comply with the terms and
                conditions of this EULA as a condition to the license
                granted herein, you are licensed to install copies of the
                Software on Computer file server(s) within your Internal
                Network only for use of the Software initiated by an
                individual from a Computer within the same Internal Network
                as permitted by Sections 1.1 and 1.2 of the EULA. The total
                number of users (not the concurrent number of users) able
                to use the Software on such Computer file servers(s) may
                not exceed the number permitted by the subscription ordered
                by you.
              </li>
              <li><b>Restrictions on Secondary Use.</b> If the Software was obtained
                under a TruGrid license program by any licensee, the
                Software under Section 1.2 must be used solely for the
                benefit of the licensee.
              </li>
              <li><b>Multiple Copies.</b> So long as you comply with the terms and
                conditions of this EULA as a condition to the license
                granted herein, you are licensed to make a reasonable
                number of backup copies of the Software, provided, your
                backup copies are licensed for your use when you install
                them on other devices on your network.
              </li>
              <li><b>Multiple Copies.</b> So long as you comply with the terms and
                conditions of this EULA as a condition to the license
                granted herein, your end users and employees are licensed
                to use TruGrid Connector to make connections through
                TruGrid Sentry.
              </li>
              <li><b>Integrity of 3rd Party Content and Software.</b> Your use of
                the Software may be impaired by digital rights management
                systems to protect the integrity of certain third-party
                Content and the Software. You may not use any Computer or
                hardware designed to defeat any copy-protection device,
                should the Content or Software you have licensed utilize
                such protection. You may not remove or alter any trademark,
                trade names, product names, logos, copyright notices,
                legends, symbols, labels, or watermarking in any 3rd party
                Content or the Software, except to the extent as expressly
                indicated in the 3rd party Content or Software.
              </li>
            </ol>
          </li>

          <li><b>Description of other Rights and Limitations.</b>
            <p>TruGrid's grant to you of a non-exclusive, nontransferable, limited
              personal license to install and use the Software in a manner
              consistent with its design, documentation, and your order, is
              further conditioned upon your compliance with all of the following conditions:
            </p>
            <ol>
              <li>Limitations on Reverse Engineering, Decompilation, and
                Disassembly. You may not reverse engineer, decompile,
                translate, localize, or disassemble the Software.
              </li>
              <li>Separation of Components. The Software is licensed as a
                single product. Its component parts may not be separated
                for use on more than one Computer. You may not unbundle or
                repackage the Software for distribution.
              </li>
              <li>No Rental. You may not rent, license, assign, transfer,
                time share, host, lend, sublicense, distribute, or lease
                the Software, or any access code or any licenses associated
                with the same for any purpose, except as otherwise
                expressly provided in this EULA.
              </li>
              <li>No Software License Transfers.</li>
              <li>You may not transfer this license under any circumstances
                without TruGrid's prior written consent/approval, any such
                consented to or approved transferee must acknowledge,
                accept, and agree in writing to be bound by the terms and
                conditions of this EULA.
              </li>
              <li>You may not transfer licenses on a short-term basis or
                transfer temporary rights to use the Software without
                TruGrid's prior written consent/approval.
              </li>
              <li>The Software and any copies of the Activation codes related
                to the Software and TruGrid's trademarks may not be copied,
                reproduced, distributed, used, posted, modified, adapted,
                prepare derivatives of, or displayed in any form or manner
                on any online auction sites or any other site or material
                of any kind without the express prior written permission of
                TruGrid.
              </li>
            </ol>
          </li>
        </ol>

        <p>Any transfer or posting made in violation of the requirements,
          conditions, or restrictions of this section will be void and may
          result in termination of this EULA.
        </p>

        <ol>
          <ol start="8">
            <li><b>Term and Termination.</b> The term of this EULA granted to you
              for the use of the Software is concurrent with your active
              subscription. Without prejudice to any other rights,
              TruGrid may terminate this EULA if you fail to comply with
              any of the terms and conditions of this EULA, which
              includes, but would not be limited to, your unauthorized
              reproduction, copying, distribution, modification,
              adaptation or the making of a derivative of or display of
              the Software or any of the Activation codes associated with
              the same. In such event, upon such termination you must
              uninstall and destroy all copies of the Software and any
              Activation codes associated with the same and all its
              component parts and you must provide TruGrid in writing
              your certification as to the same.
            </li>
            <li><b>Development License Conditions and Restrictions.</b>
              <span>The
                following conditions and restrictions apply to you, as a
                licensee, if you develop application software that utilizes
                the Software in a commercially distributed application:
              </span>
            </li>
          </ol>
        </ol>

        <ol>
          <ol>
            <ol style={{ listStyle: 'upper-alpha' }}>
              <li>you must purchase a license of Software for each copy of
                the application software licensed or rented;
              </li>
              <li>the Software must not be the sole or primary component of
                such application software;
              </li>
              <li>you must give proper acknowledgment of the copyright of
                TruGrid;
              </li>
              <li>you must not use the Software to create a software
                application that competes with or is intended to compete
                with, directly or indirectly, the Software; and
              </li>
              <li>the application software must add significant value to the
                Software.
              </li>
            </ol>
          </ol>
        </ol>

        <p>
          You must obtain the prior written permission from TruGrid as to all
          other uses of the Software that fall outside the scope of this EULA.
        </p>

        <ol start="10">
          <ol start="10">
            <li><b>Software for Windows, Mac, iOS, Android.</b> The Software which
              is the subject matter of this EULA is for use and/or runs
              on Windows, Mac, iOS, and Android operating systems, and
              may not be operable on any other systems.
            </li>
            <li><b>Trial Version.</b> This section and its subparagraphs apply, in
              addition to the license granted in Section 1 above, and
              solely if you have received a valid copy of a Trial Version
              of the Software from TruGrid.
            </li>
            <li>Subject to the terms and conditions of this EULA, TruGrid
              grants to you a non-exclusive license to install copies of
              the Trial Version strictly for your internal evaluation and
              review purposes and not for production purposes.
            </li>
            <li><span>You shall not:</span></li>
          </ol>
        </ol>

        <ol>
          <ol>
            <ol style={{ listStyle: 'upper-alpha' }}>
              <li>in the aggregate install or use more than one copy of the
                Trial Version;
              </li>
              <li>download the Trial Version under more than one username,
                email address, or company name, if applicable;
              </li>
              <li>alter the contents of a hard drive or computer system to
                enable the use of the Trial Version for an aggregate period
                in excess of the Trial period for which you may evaluate
                the Trial Version;
              </li>
              <li>disclose the results, if any, of the Software performance
                benchmarks obtained using the Trial Version to any third
                party without TruGrid's prior written consent; or
              </li>
              <li>use the Trial Version for a purpose other than the sole
                purpose of determining whether to purchase a license to the
                Software. Notwithstanding the foregoing, you are strictly
                prohibited from installing or using a copy of the Trial
                Version for any commercial purposes.
              </li>
            </ol>
          </ol>
        </ol>

        <ol start="14">
          <ol start="14">
            <li>
              <span>Your licensed rights to install and use a copy of the Trial
                Version under this section will immediately terminate upon
                the earlier of:
              </span>
            </li>
          </ol>
        </ol>

        <ol>
          <ol>
            <ol style={{ listStyle: 'upper-alpha' }}>
              <li>the expiration of the Trial Period, or
              </li>
              <li>such time that you purchase a license to the Software.
                TruGrid reserves the right to terminate your license to a
                copy of the Trial Version at any time in its sole
                discretion. To the extent that any provision in this
                section conflicts with any other term or condition in this
                EULA, this section shall supersede such other terms and
                conditions with respect to the Trial Version only, but only
                to the extent necessary to resolve the conflict.
              </li>
            </ol>
          </ol>
        </ol>

        <p>
          <strong>
            YOU ACKNOWLEDGE THAT THE TRIAL VERSION MAY HAVE LIMITED FEATURES,
            FUNCTION FOR A LIMITED PERIOD OF TIME, AND/OR HAVE OTHER
            LIMITATIONS NOT PRESENT IN A NON-TRIAL VERSION OF THE SOFTWARE.
            NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS EULA, THE TRIAL
            VERSION IS PROVIDED TO YOU ON AN "AS IS" BASIS, AND TRUGRID
            DISCLAIMS ANY WARRANTY OR LIABILITY OBLIGATIONS TO YOU OF ANY KIND.
          </strong>
        </p>

        <ol start="3">
          <li><b>Intellectual property Rights.</b> All rights, title, and
            interests, including, but not limited to, all copyrights,
            trade secret rights, patent and trademark rights, whether
            foreign or domestic, in and to the Software (including, but
            not limited to, any source or object code, images,
            photographs, animations, video, audio, music, text, and
            apps incorporated into the Software), the accompanying
            printed materials, and any copies of the Software, are
            owned by and shall remain the sole property of TruGrid or
            its suppliers or licensors.
          </li>
          <li><b>Injunctive Relief.</b>
          </li>
          <li><b>Multiple Environments/Multiple Platforms/Dual-Media Software.</b>
          </li>
          <li><b>U.S. Government Restricted Rights.</b>
          </li>
          <li><b>Customer Remedies.</b>
          </li>
          <li>
            <b>NO WARRANTIES. TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, TRUGRID AND ITS SUPPLIERS, AND LICENSORS
              DISCLAIM ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, OF
              ACCURACY, QUIET ENJOYMENT, NON-INFRINGEMENT OF THIRD-PARTY
              RIGHTS WITH REGARD TO THE SOFTWARE, CONTENT, OR LIBRARY
              CONTENT AND ANY ACCOMPANYING HARDWARE.
            </b>
          </li>
          <li>
            <b>NO LIABILITY FOR CONSEQUENTIAL DAMAGES. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
              TRUGRID OR ITS SUPPLIERS, OR LICENSORS, BE LIABLE FOR ANY
              SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES
              WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS
              OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF
              BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING
              OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE,
              CONTENT, OR LIBRARY CONTENT, EVEN IF TRUGRID HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME
              STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
              DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </b>
          </li>
          <li><b>Content and Indemnity.</b> You acknowledge and understand your
            Content is your sole responsibility including making and
            keeping back-up copies of your Content and your use and
            distribution of your Content is at your sole discretion and
            risk.
          </li>
        </ol>

        <ol>
          <ol>
            <li><b>Sole Responsibility.</b> You shall be solely responsible for
              any claims or causes of action that may arise in connection
              with such use and distribution of your Content.
              Notwithstanding anything to the contrary herein, TruGrid
              has no responsibility or liability for the deletion or
              accuracy of your Content or any other Content, the failure
              to store, transmit or receive transmission of your Content
              or any other Content (whether processed by the Online
              Service), or the security, privacy, storage, or
              transmission of other communications originating with or
              involving use of the Software. Certain features of the
              Software enable you to specify the level at which the
              Software may restrict access to your Content. You are
              solely responsible for applying the appropriate level of
              access to your Content. You further agree to defend,
              indemnify and hold TruGrid, its affiliates, subsidiaries,
              officers, directors, employees, consultants, agents,
              suppliers, licensors, Resellers, and Distributors from any
              and all third party claims, whether foreign or domestic,
              and any and all liability, damages and/or costs including,
              but not limited to, reasonable attorney fees, arising from
              your use of the Software and your Content and the
              distribution of the same, your violation of this EULA, or
              the infringement or violation by you or any other user of
              the Software, of any agreement of any kind or any
              intellectual property or other right, including but not
              limited to, patent, trademark, copyright or trade secret
              rights of any person or entity or any claims that may or
              are alleged to result in the invasion of privacy, slander,
              libel or for infringement on a person's rights of publicity
              due to the publication or distribution of your Content.
            </li>
            <li><b>Ownership of Content, License, and Restrictions.</b> Title and
              intellectual property rights in and to any Content,
              displayed by, reproduced, recorded, or accessed through the
              Software, belongs to the respective Content owners. Such
              Content may be protected by copyright or other intellectual
              property laws and treaties, and may be subject to terms of
              use of the third party providing or displaying such
              Content. This EULA does not grant you any rights to use
              such Content other than as expressly indicated in the
              Software, this EULA, or any Addendums to the same. The
              Software may be used to reproduce or record your Content,
              so long as such use is limited to the reproduction of
              non-copyrightable Content, Content which you own the
              intellectual property rights in and to the same, or Content
              which you are authorized or legally permitted to reproduce,
              record or display.
            </li>
            <li><b>Your Representations and Warranties Regarding Your Content.</b>
              <span>You represent and warrant that:</span>
            </li>
          </ol>
        </ol>
        <ol>
          <ol>
            <ol style={{ listStyle: 'upper-alpha' }}>
              <li>you are the owner, licensor, or authorized user of all your
                Content; and
              </li>
              <li>
                <span>you will not upload, record, publish, post, link to, or otherwise transmit or distribute Content that</span>
              </li>
            </ol>
          </ol>
        </ol>

        <ol>
          <ol>
            <ol>
              <ol style={{ listStyle: 'upper-roman' }}>
                <li>advocates, promotes, incites, instructs, assists or
                  otherwise encourages violence or any illegal activities;
                </li>
                <li>infringes or violates the copyright, patent, trademark,
                  service mark, trade name, trade secret, or other
                  intellectual property rights of any third party or TruGrid,
                  or any rights of publicity or privacy of any party, whether
                  foreign or domestic;
                </li>
                <li>attempts to mislead others about your identity or the
                  origin of a message or other communication, or impersonates
                  or otherwise misrepresents your affiliation with any other
                  person or entity, or is otherwise materially false,
                  misleading, or inaccurate;
                </li>
                <li>promotes, solicits, or comprises inappropriate, harassing,
                  abusive, profane, defamatory, libelous, threatening,
                  hateful, obscene, indecent, vulgar, pornographic, or
                  otherwise objectionable or unlawful content or activity;
                </li>
                <li>is harmful to minors;</li>
                <li>contains any viruses, Trojan horses, worms, time bombs, or
                  any other similar software, data, or programs that may
                  damage, detrimentally interfere with, surreptitiously
                  intercept, or expropriate any system, data, information, or
                  property of another; or
                </li>
                <li>violates any law, statute, ordinance, or regulation
                  (including, without limitation, the laws and regulations
                  governing export control, unfair competition,
                  anti-discrimination, or false advertising).
                </li>
              </ol>
            </ol>
          </ol>
        </ol>

        <ol start="4">
          <ol start="4">
            <li><b>Use of Your Content with the Online Services.</b> Again, for
              clarity and as indicated in 10.2 of this EULA, you retain
              all of your ownership rights in your Content. By submitting
              Content, which you acknowledge will be transmitted to
              TruGrid's servers for such processing, you hereby grant to
              TruGrid an unrestricted, irrevocable, perpetual,
              non-exclusive, fully-paid and royalty-free, license to use
              your Content and/or data to improve the quality of the
              Online Services. The above licenses granted by you in your
              Content that you submit to the Online Services will
              terminate within a commercially reasonable time after you
              remove or delete your Content from the Online Services. You
              understand and agree, however, that TruGrid may retain, but
              not display, distribute, or perform, server copies of your
              Content that have been removed or deleted. For further
              clarity, notwithstanding the license granted under this
              section, TruGrid shall not use or promote any of your
              unlisted or private Content.
            </li>
            <li>
              <b>TruGrid's Access to Your Content</b>
              <span>You acknowledge that the
                Online Service is automated (e.g., your Content is uploaded
                using software tools and TruGrid servers) and that TruGrid
                personnel will not access, view, or inspect your Content,
                except as reasonably necessary to perform the Services,
                including but not limited to, the following:
              </span>
            </li>
          </ol>
        </ol>

        <ol>
          <ol>
            <ol style={{ listStyle: 'upper-alpha' }}>
              <li>respond to support requests;</li>
              <li>detect, prevent, or otherwise address fraud, security, or
                technical issues;
              </li>
              <li>as deemed necessary or advisable by TruGrid in good faith
                to conform to legal requirements or comply with legal
                process; or
              </li>
              <li>enforce this EULA, including investigation of potential
                violations hereof.
              </li>
            </ol>
          </ol>
        </ol>

        <ol start="11">
          <li><b>Survival.</b> The conditions, restrictions and obligations as
            contained in this EULA which you have voluntarily agreed to
            shall survive any expiration, termination, or cancellation
            of this EULA, and shall continue and remain in effect to
            bind you, and your employees, agents, successors, heirs and
            assigns to the extent necessary to fulfill their essential
            purposes.
          </li>
          <li><b>Integration.</b> This EULA and the applicable license order and
            Addendums sets forth the entire Agreement and understanding
            between the parties as to the subject matter of this EULA
            and supersedes all prior discussions, representations, and
            amendments of understandings of every kind and nature
            between the parties.
          </li>
          <li><b>Amendments.</b> Except as otherwise provided in this EULA, this
            EULA may be amended from time to time by TruGrid. You must
            accept any newly amended terms and conditions of this EULA
            before installing, copying, or otherwise using the newly
            released Software. Except as otherwise provided in this
            section of this EULA, this EULA may not be amended,
            altered, or any of its provisions waived on behalf of
            either party, except in writing executed by both parties'
            duly authorized agent. Notwithstanding the provisions of
            this section (Amendments), TruGrid may amend its privacy
            policy at any time by posting a new version of its privacy
            policy at its web site.
          </li>
          <li><b>Assignability.</b> Except as otherwise expressly provided under
            this EULA, this EULA and the rights and duties under this
            EULA may not be assigned by you without the prior written
            consent of TruGrid.
          </li>
          <li><b>Benefit.</b> Except to the extent forbidden in this EULA, this
            EULA shall be binding upon and inure to the benefit of the
            respective successors and assigns of the parties.
          </li>
          <li><b>Captions.</b>Captions contained in this EULA are inserted for
            reference and in no way define, limit, extend or describe
            the scope of the EULA or intent of any provision in the
            EULA.
          </li>
          <li><b>Severability.</b> If any provision of this EULA becomes or is
            declared by a court of competent jurisdiction to be
            illegal, unenforceable, or void, this EULA shall continue
            in full force and effect without said provision; provided,
            however, that no such severability shall be effective if it
            materially changes the economic benefit of the EULA to the
            other party.
          </li>
          <li><b>Authority to Accept.</b> You warrant and represent to TruGrid
            that this EULA shall be binding upon you, and that the
            individual agreeing to be bound under the terms and
            conditions of this EULA is authorized or has been empowered
            to do so.
          </li>
          <li><b>Third Party Beneficiary.</b> You acknowledge and agree that
            TruGrid's suppliers and licensors (and/or TruGrid if you
            obtained the Software from any party other than TruGrid)
            are third party beneficiaries of this EULA, with the right
            to enforce the obligations set forth herein with regard to
            the respective technology, applicable software or Content
            of such suppliers, licensors and/or TruGrid.
          </li>
          <li><b>Third Party Acknowledgement and Terms.</b> The Software
            licensed under the terms and conditions of this EULA may
            use or include third party components, Content, other
            copyrighted material, and/or open-source software which may
            be subject to certain "open source" or "free software"
            licenses ("Open-Source Software"). Acknowledgments,
            licensing terms and additional disclaimers for such
            components, Content, materials, or Open Source Software are
            contained in the "online" electronic documentation for the
            Software, including without limitation, a text file or may
            otherwise accompany the same, or are contained in Addendums
            to this EULA, and your use of such components, Content,
            other materials, and/or Open Source Software is governed by
            their respective terms and conditions and nothing in this
            EULA limits your rights under or grants you rights that
            supersede the terms and conditions of any such applicable
            third party terms and conditions for such third party
            components, Content, materials, and/or Open Source
            Software.
          </li>
          <li><b>Miscellaneous.</b> This EULA is governed by the laws of the
            State of Illinois, U.S.A. to the extent that U.S. federal
            laws are not applicable, without regard to their conflicts
            of law principles. You agree to the exclusive jurisdiction
            of the Courts of the State of Illinois, U.S.A. and the
            United States district court located or has a presence in
            Cook County, Illinois U.S.A. This Agreement has been
            prepared in the English language and such version shall be
            controlling in all respects and any non-English version of
            this Agreement is solely for accommodation purposes. The
            parties to this Agreement waive personal service of any and
            all process upon them and consent that all such service of
            process be made by registered mail and shall be deemed to
            be completed five (5) business days after the same shall
            have been deposited in the United States mail, postage
            prepaid. You waive any objection based on inconvenient
            forum and any objection to venue of any action instituted
            under this EULA by TruGrid in any jurisdiction. This EULA
            shall not be governed by the United Nations' Convention on
            Contracts for the International sale of Goods, the
            application of which is expressly excluded. If you are a
            federal, state, or local government entity in the United
            States using the Software in your official capacity and
            legally unable to accept the controlling law, jurisdiction,
            or venue clauses above, then those clauses do not apply to
            you. For such U.S. federal government entities, this EULA
            and any action related thereto will be governed by the laws
            of the United States of America (without reference to
            conflict of laws) and, in the absence of federal law and to
            the extent permitted under federal law, the laws of the
            State of Illinois (excluding choice of law).
          </li>
        </ol>

        <p>Should you have any questions, complaints or claims with respect to the
          Software, or if you desire to contact TruGrid for any reason, please
          contact the TruGrid partner serving your country, or write:
        </p>

        <p>TruGrid, Inc., 1100 E Woodfield Rd, Schaumburg, IL USA</p>
        <p><b>Phone:</b> 1-800-211-8332</p>
        <p><b>Email:</b> help@trugrid.com</p>
        <p>
          <b>AS STATED ABOVE, PAYING THE SUBSCRIPTION FEE FOR THE SOFTWARE WITHOUT
            PROMPTLY REQUESTING A REFUND OF SUCH FEE OR INSTALLING, COPYING, OR
            OTHERWISE USING THE SOFTWARE, INDICATES YOUR ACCEPTANCE OF THIS EULA
            AND YOUR AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS EULA
            AS A LICENCEE.
          </b>
        </p>
        <p>N.E. Desktop Software, Inc, TruGrid, TruGrid Sentry, TruGrid Connector, are registered trademarks of N.E. Desktop
          Software, Inc. in the United States and/or other countries. Mac is a trademark owned by Apple, Inc. Twitter and
          related design logo are registered marks of Twitter, Inc. All other trademarks are the property of their
          respective owners.
        </p>
        <p>
          <b>IF YOU DO NOT AGREE TO THE FOREGOING TERMS AND CONDITIONS, YOU DO NOT HAVE A RIGHT TO DOWNLOAD, INSTALL OR USE THE LICENSED SOFTWARE.</b>
        </p>
      </div>
    </div>
  </Modal>
);

WorkspaceEula.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default WorkspaceEula;
