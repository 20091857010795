import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Modal } from 'components';
import { useState } from 'react';
import { toast } from 'react-toastify';

const DeleteDomainModal = ({ domainId, onUpdate, showDeleteDomainModal, toggleShowDeleteDomainModal }) => {
  const [isBtnAction, setIsBtnAction] = useState(false);
  const onDeleteDomain = () => {
    setIsBtnAction(true);

    axios.delete(`/api/Domains/Delete?domainId=${domainId}`)
      .then(() => onUpdate())
      .catch((error) => toast.error(error.message, 3000))
      .finally(() => toggleShowDeleteDomainModal());
  };

  return (
    <Modal
      isOpen={showDeleteDomainModal}
      onRequestClose={toggleShowDeleteDomainModal}
      contentLabel="Delete domain"
      actionFunc={onDeleteDomain}
      actionTitle="Delete"
      variantBtn="deleteOutline"
      actionLoading={isBtnAction}
    >
      Do you really want to delete this customer domain?
    </Modal>
  );
};

DeleteDomainModal.propTypes = {
  domainId: PropTypes.string,
  onUpdate: PropTypes.func,
  showDeleteDomainModal: PropTypes.bool,
  toggleShowDeleteDomainModal: PropTypes.func,
};

export default DeleteDomainModal;
