/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Link, StatusMessage, Table } from 'components';
import { useObserver } from 'hooks';
import { onChangePage, queryFormatter } from 'utils';
import { tableColumns } from './components/tableColumns';
import MfaHeader from './components/MfaHeader/MfaHeader';
import ToggleEnableMfaModal from './components/ToggleEnableMfaModal/ToggleEnableMfaModal';
import ResetMfaModal from './components/ResetMfaModal/ResetMfaModal';
import styles from './MfaMgmt.module.scss';

const MfaMgmt = ({ mfaData, baseUrl, onUpdate }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isResetPhone, setIsResetPhone] = useState(true);
  const counterRef = useRef();
  const [showEnableMfaModal, setShowEnableMfaModal] = useState(false);
  const [showDisableMfaModal, setShowDisableMfaModal] = useState(false);
  const [showResetMfaModal, setShowResetMfaModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (mfaData.isError) {
    return <StatusMessage error>{mfaData.error}</StatusMessage>;
  }

  if (mfaData.isNoData) {
    return (
      <StatusMessage header="No users reporting in">
        <p>
          Please check Active Directory or{' '}
          <Link href="https://www.trugrid.com/docs/usersnotreportingin" external bold>
            add users
          </Link>
        </p>
      </StatusMessage>
    );
  }

  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  const isSetupView = mfaData.queryConfig.viewBy.toLowerCase() === 'setuppending';
  const isDisabledView = mfaData.queryConfig.viewBy.toLowerCase() === 'disabled';
  const isResetView = mfaData.queryConfig.viewBy.toLowerCase() === 'inresetmode';
  const isAllView = mfaData.queryConfig.viewBy.toLowerCase() === 'all';
  const currentPageUserIds =
    isSetupView || isResetView
      ? mfaData.data.filter((i) => !i.isAdmin).map((i) => i.userId)
      : mfaData.data.map((i) => i.userId);

  useEffect(() => {
    if (mfaData?.queryConfig?.searchQuery) {
      const query = { ...mfaData.queryConfig, searchQuery: '' };
      onChangePage([queryFormatter(baseUrl, query), query], { updateAction: onUpdate });
    }
  }, []);

  const toggleModal = (type) => {
    if (type === 'Disable MFA') {
      setShowDisableMfaModal(!showDisableMfaModal);
    }
    if (type === 'Enable MFA') {
      setShowEnableMfaModal(!showEnableMfaModal);
    }
    if (type === 'Reset MFA') {
      setIsResetPhone(true);
      setShowResetMfaModal(!showResetMfaModal);
    }
  };

  const onModalOpen = (type, user, isHeaderBtn, event) => {
    if (event && isHeaderBtn) event.target.blur();

    const usersToUpdate = isHeaderBtn ? selectedUsers : [{ ...user }];

    toggleModal(type);
    setModalData({ type, usersToUpdate });
  };

  const onChangeMfa = (type, user, isResetMfa) => {
    setIsLoading(true);

    const isEnableMfa = type !== 'Disable MFA';

    const url = isResetMfa
      ? `/api/SecurityManagement/Mfa/ResetMfa?resetPhone=${isResetPhone}`
      : `/api/SecurityManagement/Mfa/ChangeMfaStatus?switchState=${isEnableMfa}`;

    const data = user ? [...user.usersToUpdate] : modalData ? modalData.usersToUpdate : selectedUsers;
    const queryConfig = { ...mfaData.queryConfig, pageNumber: 1 };
    const lastWord = data.length > 1 ? 'users' : 'user';

    axios
      .post(url, data)
      .then(() => axios.get(queryFormatter(baseUrl, queryConfig)))
      .then((resp) => {
        onUpdate({
          data: resp.data,
          pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
          queryConfig,
        });

        const message = isResetMfa
          ? 'MFA reset'
          : `MFA is ${isEnableMfa ? 'enabled' : 'disabled'} for selected ${lastWord}`;
        toggleModal(user.type);
        toast.success(message, 3000);
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => {
        if (!user) {
          toggleModal(user.type);
        }
        setIsLoading(false);
        if (selectedUsers.length) setSelectedUsers([]);
      });
  };

  const onCloseDropdown = (element) => {
    const dropdown = element.querySelector('.dropdown-component__select');
    if (dropdown?.classList.contains('active')) dropdown.click();
  };

  return (
    <>
      <MfaHeader
        mfaData={mfaData}
        onUpdate={onUpdate}
        baseUrl={baseUrl}
        counterRef={counterRef}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onModalOpen={onModalOpen}
        isSticky={isSticky}
        isSetupView={isSetupView}
        isDisabledView={isDisabledView}
        isResetView={isResetView}
        isAllView={isAllView}
      />

      <div className={styles.content}>
        {!mfaData.data.length && !mfaData.queryConfig.searchQuery ? (
          <StatusMessage header="No items available">Change view selection</StatusMessage>
        ) : (
          <Table
            id="mfa-mgmt-table"
            columns={tableColumns(
              onModalOpen,
              isResetView,
              isAllView,
              isSetupView,
              isDisabledView,
              showEnableMfaModal,
              selectedUsers,
            )}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            ruleForSelectItems={(a) => !((isSetupView || isResetView) && a.isAdmin)}
            ruleForToggleAll={currentPageUserIds}
            data={mfaData}
            updateData={onUpdate}
            baseUrl={baseUrl}
            dataKey="userId"
            noData={!mfaData.data.length && mfaData.queryConfig.searchQuery}
            className={styles.table}
            onMouseLeave={(e) => onCloseDropdown(e.target.parentNode)}
          />
        )}
      </div>

      {(showDisableMfaModal || showEnableMfaModal) && (
        <ToggleEnableMfaModal
          showEnableMfaModal={showEnableMfaModal}
          showDisableMfaModal={showDisableMfaModal}
          toggleShowMfaModal={toggleModal}
          data={modalData}
          onChangeMfa={onChangeMfa}
          isLoading={isLoading}
        />
      )}

      {showResetMfaModal && (
        <ResetMfaModal
          showResetMfaModal={showResetMfaModal}
          toggleShowResetMfaModal={toggleModal}
          data={modalData}
          onChangeMfa={onChangeMfa}
          setIsResetPhone={setIsResetPhone}
          isResetPhone={isResetPhone}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

MfaMgmt.propTypes = {
  mfaData: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default MfaMgmt;
