import { useState } from 'react';
import axios from 'config/customAxios';
import PropTypes from 'prop-types';
import { dateFormatterShort } from 'helpers';
import { ExclamationIcon } from 'assets/img';
import { corpAdmin, corpTechAdmin, freePersonalUser, personalUser, spTechAdmin } from 'consts';
import { notify } from 'utils';
import LicensesPersonal from './components/LicensesPersonal/LicensesPersonal';
import LicensesInfo from './components/LicensesInfo/LicensesInfo';
import TotalLicenses from './components/TotalLicenses/TotalLicenses';
import LicensesControls from './components/LicensesControls/LicensesControls';
import LicensesLinks from './components/LicensesLinks/LicensesLinks';
import { checkLicenses } from '../../utils/checkLicenses';
import styles from './PrimaryDomain.module.scss';

const PrimaryDomain = ({ role, licenses, email, onUpdateLicenses }) => {
  const [licensesState, setLicensesState] = useState(JSON.parse(JSON.stringify(licenses)));
  const [isUpdating, setIsUpdating] = useState(false);
  const [warning, setWarning] = useState({ isActive: false });
  const minimumLicensesCount = role === corpAdmin ? 20 : 10;

  const updateSubscription = () => {
    const { isLessRdp, isLessBitlocker, isMoreRdp, isMoreBitlocker } = checkLicenses(
      licenses,
      licensesState,
      minimumLicensesCount,
    );

    if (isLessRdp || isLessBitlocker || isMoreRdp || isMoreBitlocker) {
      const fieldNew = [];
      if (isLessRdp || isMoreRdp) fieldNew.push('numberOfRdpLicenses');
      if (isLessBitlocker || isMoreBitlocker) fieldNew.push('numberOfBitLockerLicenses');

      let message = '';
      if (isLessRdp || isLessBitlocker) message = "You can't reduce minimum number of licenses";
      if (isMoreRdp || isMoreBitlocker) message = "You can't buy more than 1000000 licenses";

      if (!warning.isActive) {
        setWarning({
          isActive: true,
          field: fieldNew,
          content: (
            <>
              <ExclamationIcon />
              {message}
            </>
          ),
        });
        setTimeout(() => setWarning({ isActive: false }), 3000);
      }
    } else {
      setIsUpdating(true);

      const data = [{ ...licensesState.spDomain }, ...licensesState.customerDomains];

      axios
        .post('/api/Chargify/UpdateSubscription', data)
        .then((response) => {
          onUpdateLicenses(response.data);
          notify.success('Subscription Updated');
        })
        .catch((error) => {
          onUpdateLicenses(JSON.parse(JSON.stringify(licenses)));
          notify.error(error?.response?.data || error.message);
        })
        .finally(() => setIsUpdating(false));
    }
  };

  const canceledLicensesMsg = `License(s) will expire on <b>${dateFormatterShort(licenses.nextBillingDate)}</b>`;

  if ([personalUser, freePersonalUser].includes(role)) {
    return <LicensesPersonal licenses={licenses} email={email} />;
  }

  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.backdrop} />}

      {![spTechAdmin, corpTechAdmin].includes(role) && (
        <LicensesInfo
          licenses={licenses}
          licensesState={licensesState}
          isUpdating={isUpdating}
          updateSubscription={updateSubscription}
        />
      )}

      <div className={styles.wrapper}>
        <TotalLicenses licensesState={licensesState} role={role} canceledLicensesMsg={canceledLicensesMsg} />
        <LicensesControls
          licenses={licenses}
          licensesState={licensesState}
          role={role}
          setLicensesState={setLicensesState}
          warning={warning}
          setWarning={setWarning}
          minimumLicensesCount={minimumLicensesCount}
        />
      </div>

      {![spTechAdmin, corpTechAdmin].includes(role) && (
        <LicensesLinks licenses={licenses} showAllocationMessage={licensesState.showAllocationMessage} />
      )}
    </>
  );
};

PrimaryDomain.propTypes = {
  role: PropTypes.string.isRequired,
  licenses: PropTypes.object,
  email: PropTypes.string.isRequired,
  onUpdateLicenses: PropTypes.func,
};

export default PrimaryDomain;
