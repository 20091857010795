export const getMfaStatus = (status) => {
  const isMfaEnabled = status === 0;
  const isMfaSetupPending = status === 1;
  const isMfaDisabled = status === 2;
  const isMfaResetMode = status === 3;

  return {
    isMfaEnabled,
    isMfaSetupPending,
    isMfaDisabled,
    isMfaResetMode,
  };
};
