import styles from './TimeoutHeader.module.scss';

const TimeoutHeader = () => (
  <div className={styles.timeoutHeader}>
    <h4 className={styles.timeoutHeaderTitle}>Session Timeout is not currently enabled</h4>
    <p className={styles.timeoutHeaderDesc}>Session timeout signs users out of Desktop Connectors after a period of inactivity</p>
  </div>
);

export default TimeoutHeader;
