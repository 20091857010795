export const checkIsPhoneSetup = (phoneSetup) => {
  if (phoneSetup === 0) {
    return 'N/A';
  }
  if (phoneSetup === 1) {
    return 'Enabled';
  }
  if (phoneSetup === 2) {
    return 'Disabled';
  }
};

export const checkButtonAction = (phoneNumber, phoneSetup) => {
  if (phoneNumber !== 'N/A') {
    return {
      type: 'ResetPhoneSetup',
      text: 'Reset Phone Number',
    };
  }
  if (phoneNumber === 'N/A' && phoneSetup === 2) {
    return {
      type: 'PhoneSetupEnabled',
      text: 'Enable Phone Setup',
    };
  }
  if (phoneNumber === 'N/A' && phoneSetup === 1) {
    return {
      type: 'PhoneSetupDisabled',
      text: 'Disable Phone Setup',
    };
  }
};

export const headerButtonAction = (viewBy) => {
  if (viewBy === 'WithPhoneNumber') {
    return { label: 'Reset Phone', action: 'ResetPhoneSetup' };
  }
  if (viewBy === 'PhoneSetupEnabled') {
    return { label: 'Disable Phone Setup', action: 'PhoneSetupDisabled' };
  }
  if (viewBy === 'PhoneSetupDisabled') {
    return { label: 'Enable Phone Setup', action: 'PhoneSetupEnabled' };
  }
  return { label: 'Reset Phone', action: 'ResetPhoneSetup' };
};

export const checkTypeAndUsers = (modalChangeUserData, handleResetPhoneSetup, handleTogglePhoneSetup) => {
  if (modalChangeUserData.type === 'ResetPhoneSetup') {
    return modalChangeUserData.users.length > 1
      ? {
          message: (
            <>
              By clicking <b>RESET PHONE</b>, selected users will be able to set up a phone number on next login
            </>
          ),
          button: 'Reset Phone',
          title: 'Reset Phone Number?',
          actionFunc: handleResetPhoneSetup,
        }
      : {
          message: (
            <>
              By clicking <b>RESET PHONE</b>, <b>{modalChangeUserData.users[0].displayName}</b> will be able to set up a
              phone number on next login
            </>
          ),
          button: 'Reset Phone',
          title: 'Reset Phone Number?',
          actionFunc: handleResetPhoneSetup,
        };
  }
  if (modalChangeUserData.type === 'PhoneSetupDisabled') {
    return modalChangeUserData.users.length > 1
      ? {
          message: (
            <>
              By clicking <b>DISABLE SETUP</b>, selected users will not be able to set up a phone number on next login
            </>
          ),
          button: 'Disable Setup',
          title: 'Disable Phone Setup?',
          actionFunc: handleTogglePhoneSetup,
        }
      : {
          message: (
            <>
              By clicking <b>DISABLE SETUP</b>, <b>{modalChangeUserData.users[0].displayName}</b> will not be able to
              set up a phone number on next login
            </>
          ),
          button: 'Disable Setup',
          title: 'Disable Phone Setup?',
          actionFunc: handleTogglePhoneSetup,
        };
  }
  if (modalChangeUserData.type === 'PhoneSetupEnabled') {
    return modalChangeUserData.users.length > 1
      ? {
          message: (
            <>
              By clicking <b>ENABLE SETUP</b>, selected users will be able to set up a phone number on next login
            </>
          ),
          button: 'Enable Setup',
          title: 'Enable Phone Setup?',
          actionFunc: handleTogglePhoneSetup,
        }
      : {
          message: (
            <>
              By clicking <b>ENABLE SETUP</b>, <b>{modalChangeUserData.users[0].displayName}</b> will be able to set up
              a phone number on next login
            </>
          ),
          button: 'Enable Setup',
          title: 'Enable Phone Setup?',
          actionFunc: handleTogglePhoneSetup,
        };
  }
};
