import { checkIsPasswordValid } from 'helpers';

export const isFormValid = (formData, formErrors, callback) => {
  const { newPassword } = formData;
  const confirmPassword = formData.confirmNewPassword;
  const formErrorsNew = { ...formErrors };

  const isNewPassValid = checkIsPasswordValid(newPassword);
  const isConfirmPassValid = confirmPassword && newPassword === confirmPassword;

  if (!isNewPassValid) {
    formErrorsNew.newPassword = ' ';
    formErrorsNew.confirmNewPassword = 'Password does not match requirements';
  }
  if (isNewPassValid && !isConfirmPassValid) {
    formErrorsNew.newPassword = ' ';
    formErrorsNew.confirmNewPassword = 'Passwords do not match';
  }

  if (Object.values(formErrorsNew).length) {
    callback(formErrorsNew);
  }

  return !Object.values(formErrorsNew).some((i) => i);
};
