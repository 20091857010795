import PropTypes from 'prop-types';
import { useContext } from 'react';
import { DownloadIcon, ExternalLinkIcon } from 'assets/img';
import { Alert } from 'components';
import { MsalContext } from 'utils';
import { toast } from 'react-toastify';
import styles from './AlertDomainNotConnected.module.scss';

const AlertDomainNotConnected = ({ userData }) => {
  const { connectAzureAD } = useContext(MsalContext);
  const { domainId, dnsDomainName: domainName } = userData;

  const onLogin = async () => {
    try {
      await connectAzureAD({
        scopes: ['User.Read.All', 'Group.Read.All'],
        prompt: 'select_account',
        state: JSON.stringify({ domainId, domainName, isUpdateUser: true }),
      });
      toast.success('Successfully connected', 3000);
    } catch (err) {
      toast.error(err?.response?.data || err.message, 3000);
    }
  };

  return (
    <Alert title="You are not connected to the authentication source" type="warning" className={styles.alertAzure}>
      Please connect to&nbsp;
      <button type="button" id="connect-to-azure-ad" onClick={onLogin} className={styles.alertAzureConnect}>
        Microsoft Entra ID&nbsp;
        <ExternalLinkIcon />
      </button>
      &nbsp;or&nbsp;
      <a href={userData.downloadSentryLink} className={styles.alertAzureConnect}>
        Install Sentry&nbsp;
        <DownloadIcon />
      </a>
      &nbsp; to conect to Active Directory
    </Alert>
  );
};

AlertDomainNotConnected.propTypes = {
  userData: PropTypes.object,
};

export default AlertDomainNotConnected;
