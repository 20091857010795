import PropTypes from 'prop-types';
import { SwitchCheckbox, Table } from 'components';
import { TooltipIcon } from 'assets/img';
import styles from './ShortcutMgmtTable.module.scss';
import EmptyTable from '../EmptyTable/EmptyTable';

const ShortcutMgmtTable = ({
  shortcutData,
  setShortcutData,
  baseUrl,
  selectedUsers,
  setSelectedUsers,
  btnLoadingCurrent,
  onChangeShortcutForUser,
  isDomainOnPremise,
}) => {
  const { desktopShortcutState } = shortcutData?.queryConfig || {};

  const columns = [
    {
      checkbox: !!(!isDomainOnPremise || desktopShortcutState),
      name: 'User',
      data: ({ displayName, upn }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Desktop Shortcut',
      data: (user) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeShortcutForUser(false, user, 'isDesktopShortcutEnabled')}
            checked={user.isDesktopShortcutEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isDesktopShortcutEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isDesktopShortcutEnabled',
      width: '30%',
    },
    {
      name: 'MFA Code Required',
      data: (user) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeShortcutForUser(false, user, 'isShortcutMfaRequired')}
            checked={user.isShortcutMfaRequired}
            disabled={
              selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId) || !user.isDesktopShortcutEnabled
            }
          />
          {user.isShortcutMfaRequired ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isShortcutMfaRequired',
      width: '30%',
      disabled: !isDomainOnPremise,
      extraContent: (
        <span
          className={styles.tooltip}
          data-tip="Secure Desktop Shortcuts by enabling MFA Code"
          data-for="info-tooltip">
          <TooltipIcon />
        </span>
      ),
    },
  ];

  return (
    <Table
      data={{
        data: shortcutData.data.users,
        pageMeta: shortcutData.pageMeta,
        queryConfig: shortcutData.queryConfig,
      }}
      noData={<EmptyTable />}
      updateData={setShortcutData}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="shrotcut-mgmt-table"
      dataKey="userId"
      baseUrl={baseUrl}
      className={styles.table}
    />
  );
};

ShortcutMgmtTable.propTypes = {
  shortcutData: PropTypes.object.isRequired,
  setShortcutData: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  btnLoadingCurrent: PropTypes.array,
  onChangeShortcutForUser: PropTypes.func,
  isDomainOnPremise: PropTypes.bool,
};

export default ShortcutMgmtTable;
