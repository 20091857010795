import PropTypes from 'prop-types';
import { useContext } from 'react';
import { BrandingContext } from 'utils';
import { LogoIcon } from 'assets/img';
import BrandedLinks from 'components/BrandedLinks/BrandedLinks';
import styles from './LoginLayout.module.scss';

const LoginLayout = ({ children }) => {
  const { brandingData } = useContext(BrandingContext);

  return (
    <div className={styles.login}>
      <div className={styles.loginHeader} style={(brandingData?.isActive && brandingData?.backGroundColor) ? { background: brandingData?.backGroundColor } : {}} />
      <div className={styles.loginWrap}>
        <div className={styles.loginLogo}>
          {(brandingData?.isActive && brandingData?.logo) ? (
            <img
              src={`data:image/png;base64,${brandingData?.logo}`}
              width="107px"
              alt="Custom Logo"
              className="logo_icon"
              style={{ height: '46px', width: 'auto', maxWidth: '26em', objectFit: 'contain' }}
            />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div className={styles.loginContainer}>
          {children}
        </div>
      </div>
      {brandingData?.isActive && (
        <div className={styles.loginRow}>
          <BrandedLinks isLoginPages contactInformation={brandingData?.contactInformation} />
        </div>
      )}
      {brandingData?.isActive && (
        <div className={styles.loginCopyright}>Powered by trugrid.com</div>
      )}
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
