/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { DomainStatusMsg, Loader, StatusMessage, Table } from 'components';
import { getDomainStatus, isSentryDeleted } from 'helpers';
import { queryFormatter } from 'utils';
import { CheckIcon, MinusIcon } from 'assets/img';
import { corpAdmin } from 'consts';
import UpdateUserModal from './components/UpdateUserModal/UpdateUserModal';
import SuccessfulMessage from './components/SuccessfulMessage/SuccessfulMessage';
import RolesHeader from './components/RolesHeader/RolesHeader';
import NoUsers from './components/NoUsers/NoUsers';
import styles from './RolesMgmt.module.scss';

const RolesMgmt = ({ domain, isCompanyPage, userRole, isDomainNotActivated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [assignments, setAssignments] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const toggleUpdateUserModal = () => {
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const { isDomainActive } = getDomainStatus(domain);
  const sentryDeleted = isSentryDeleted(domain);

  const baseUrl = `/api/RoleAssignments/GetRoleAssignments?domainId=${domain.id}`;

  const totalRef = useRef(0);
  const fetchData = useCallback(() => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'name asc',
      searchQuery: '',
    };

    axios
      .get(queryFormatter(baseUrl, queryConfig))
      .then((resp) => {
        const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;

        if (pageMeta) totalRef.current = pageMeta?.TotalCount;
        setAssignments({ data: resp.data, pageMeta, queryConfig });
      })
      .catch((err) => setAssignments({ isError: true, message: err.message }))
      .finally(() => setIsLoading(false));
  }, [baseUrl]);

  useEffect(() => {
    if (isDomainActive && !sentryDeleted) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [fetchData, isDomainActive, sentryDeleted]);

  const onUpdateUsers = () => {
    setIsUpdating(true);

    const url = `/api/RoleAssignments/UpdateRoleAssignments?domainId=${domain.id}`;
    const userIdList = selectedUsers.map((user) => user.userId);

    axios
      .put(queryFormatter(url, assignments.queryConfig), userIdList)
      .then((resp) => {
        setAssignments({
          data: resp.data,
          pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
          queryConfig: assignments.queryConfig,
        });
        setSelectedUsers([]);
        toast.success(
          <SuccessfulMessage isCompanyPage={isCompanyPage} domain={domain} selectedUsers={selectedUsers} />,
          3000,
        );
        fetchData();
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => {
        setIsUpdating(false);
        if (showUpdateUserModal) toggleUpdateUserModal();
      });
  };

  const onUpdateUserRoles = (e) => {
    e.target.blur();
    if (selectedUsers.some((i) => !i.isMfaEnabled && !i.isAssigned)) {
      toggleUpdateUserModal();
    } else {
      onUpdateUsers();
    }
  };

  const unselectAllUsers = () => {
    setSelectedUsers([]);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isDomainNotActivated || sentryDeleted) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Roles Management" />;
  }

  if (assignments?.isError) {
    return <StatusMessage error>{assignments.error}</StatusMessage>;
  }

  if (!assignments.data.length && !assignments.queryConfig.searchQuery) {
    return <NoUsers />;
  }

  const columns = [
    {
      name: 'User Name',
      sortable: 'name',
      checkbox: true,
      width: '50%',
      data: ({ name }) => name || 'N/A',
    },
    {
      name: 'Domain Name',
      sortable: 'upn',
      checkbox: false,
      width: '35%',
      data: 'upn',
    },
    {
      name: 'Role',
      sortable: 'isAssigned',
      checkbox: false,
      width: '15%',
      data: ({ isAssigned }) =>
        isAssigned ? (
          <div className={cn(styles.rolesRole, styles.rolesRoleAdmin)}>
            <CheckIcon />
            Admin
          </div>
        ) : (
          <div className={cn(styles.rolesRole, styles.rolesRoleNA)}>
            <MinusIcon />
            N/A
          </div>
        ),
    },
  ];

  const pageTitle =
    userRole === corpAdmin
      ? 'Assign Corporate Tech Administrator'
      : isCompanyPage
      ? 'Assign Service Provider Tech Administrators'
      : 'Assign Customer Administrators';

  return (
    <div className={styles.roles}>
      <h3 className={styles.rolesTitle}>{pageTitle}</h3>
      <RolesHeader
        assignments={assignments}
        setAssignments={setAssignments}
        baseUrl={baseUrl}
        selectedUsers={selectedUsers}
        totalRef={totalRef}
        unselectAllUsers={unselectAllUsers}
        isUpdating={isUpdating}
        onUpdateUserRoles={onUpdateUserRoles}
      />
      <Table
        columns={columns}
        data={assignments}
        updateData={setAssignments}
        selectedItems={selectedUsers}
        setSelectedItems={setSelectedUsers}
        id="roles-mgmt-table"
        dataKey="userId"
        className={styles.rolesTable}
        baseUrl={baseUrl}
      />

      {showUpdateUserModal && (
        <UpdateUserModal
          showUpdateUserModal={showUpdateUserModal}
          toggleUpdateUserModal={toggleUpdateUserModal}
          selectedUsers={selectedUsers}
          isUpdating={isUpdating}
          onUpdateUsers={onUpdateUsers}
        />
      )}
    </div>
  );
};

RolesMgmt.propTypes = {
  domain: PropTypes.object.isRequired,
  isCompanyPage: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  isDomainNotActivated: PropTypes.bool,
};

export default RolesMgmt;
