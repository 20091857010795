/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import { notify, queryFormatter, truncateString } from 'utils';
import axios from 'config/customAxios';
import EnableToggleModal from './components/EnableToggleModal/EnableToggleModal';
import NoUsers from './components/NoUsers/NoUsers';
import RememberMeStatus from './components/RememberMeStatus/RememberMeStatus';
import RememberMeTitle from './components/RememberTitle/RememberTitle';
import RememberMeHeader from './components/RememberMeHeader/RememberMeHeader';
import RememberDropdownTable from './components/RememberDropdownTable/RememberDropdownTable';
import styles from './RememberMe.module.scss';
import EnableRememberMeForUserModal from './components/EnableRememberMeForUserModal/EnableRememberMeForUserModal';
import HeaderActions from './components/HeaderAction/HeaderActions';

const RememberMe = ({ domain, isCompanyPage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rememberMeData, setRememberMeData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isRememberMeEnabled, setIsRememberMeEnabled] = useState(false);
  const [isShowToggleModal, setIsShowToggleModal] = useState(false);
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [noUsers, setNoUsers] = useState(false);

  const [userWhoseStatusChanging, setUserWhoseStatusChanging] = useState('');
  const [isChangingRememberMe, setIsChangingRememberMe] = useState(false);
  const [isHeaderLoadingChanging, setIsHeaderLoadingChanging] = useState(false);
  const [isShowEnableRememberMeForUser, setIsShowEnableRememberMeForUser] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(null);

  const handleShowEnableRememberMeForUser = (user) => {
    setConfirmModalData(user);
    setIsShowEnableRememberMeForUser(true);
  };

  const handleCloseEnableRememberMeForUser = () => {
    setIsShowEnableRememberMeForUser(false);
    setConfirmModalData(null);
  };

  const baseUrl = `/api/SecurityManagement/RememberMe/Get?domainId=${domain.id}`;
  const queryViewBy = rememberMeData?.queryConfig?.viewBy;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
        viewBy: 'All',
      };
      const res = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = res?.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
      if (res.status === 204) {
        setNoUsers(true);
      } else {
        setRememberMeData({ data: res.data, pageMeta, queryConfig });
        setIsRememberMeEnabled(res.data.isRememberMeEnabled);
      }
    } catch (err) {
      setRememberMeData({ isError: true, error: err?.response?.data || err.message });
    } finally {
      setIsLoading(false);
    }
  }, [baseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = async (queryConfig) => {
    try {
      const res = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = res?.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
      setRememberMeData({ data: res.data, pageMeta, queryConfig });
    } catch (err) {
      setRememberMeData({ isError: true, error: err?.response?.data || err.message });
    }
  };

  const handleShowToggleModal = () => {
    setIsShowToggleModal(!isShowToggleModal);
  };

  const onToggleRememberMe = async () => {
    try {
      setIsToggleLoading(true);
      await axios.post('/api/SecurityManagement/RememberMe/ChangeDomainStatus', domain.id);
      if (isRememberMeEnabled) {
        setIsRememberMeEnabled(false);
        setRememberMeData({});
        notify.success('Remember Me is disabled for domain');
      } else {
        await fetchData();
        notify.success('Remember Me is enabled for domain');
      }
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      handleShowToggleModal();
      setIsToggleLoading(false);
    }
  };

  const changeRememberMeStatus = async (selectedUserIds) => {
    await axios.post('/api/SecurityManagement/RememberMe/ChangeUsersStatus', selectedUserIds);
    const queryConfig = { ...rememberMeData.queryConfig, pageNumber: 1 };
    await updateData(queryConfig);
    setSelectedUsers([]);
  };

  const handleChangeRememberMeForUser = async (isHeaderBtn, user) => {
    const selectedUserIds = isHeaderBtn ? selectedUsers.map((item) => item.userId) : [user.userId];
    if (isHeaderBtn) {
      const actionType = selectedUsers.every((i) => i.isRememberMeEnabled) ? 'disabled' : 'enabled';
      const hasUserWithDisabledMfa = selectedUsers.some((i) => !i.isMfaEnabled);
      if (hasUserWithDisabledMfa && actionType === 'enabled') {
        return handleShowEnableRememberMeForUser(selectedUsers);
      }
      setIsHeaderLoadingChanging(true);
      await changeRememberMeStatus(selectedUserIds);
      if (selectedUsers.length > 1) {
        notify.success(`Remember Me is ${actionType} for selected users`);
      } else {
        notify.success(`Remember Me is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`);
      }
      setIsHeaderLoadingChanging(false);
    }
    if (!isHeaderBtn) {
      if (user.isRememberMeEnabled) {
        setUserWhoseStatusChanging(user.userId);
        await changeRememberMeStatus(selectedUserIds);
        notify.success(`Remember Me is disabled for ${truncateString(user.displayName, false)}`);
        setUserWhoseStatusChanging('');
      } else if (!user.isMfaEnabled) {
        handleShowEnableRememberMeForUser(user);
      } else {
        setUserWhoseStatusChanging(user.userId);
        await changeRememberMeStatus(selectedUserIds);
        notify.success(`Remember Me is enabled for ${truncateString(user.displayName, false)}`);
        setUserWhoseStatusChanging('');
      }
    }
  };

  const handleConfirmEnableRememberMe = async () => {
    setIsChangingRememberMe(true);
    const selectedUserIds =
      confirmModalData.length >= 1 ? confirmModalData.map((item) => item.userId) : [confirmModalData.userId];
    await changeRememberMeStatus(selectedUserIds);
    if (selectedUsers.length >= 1) {
      if (selectedUsers.length > 1) {
        const actionType = confirmModalData.every((i) => i.isRememberMeEnabled) ? 'disabled' : 'enabled';
        notify.success(`Remember Me is ${actionType} for selected users`);
      } else {
        const actionType = confirmModalData.every((i) => i.isRememberMeEnabled) ? 'disabled' : 'enabled';
        notify.success(`Remember Me is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`);
      }
    } else {
      notify.success(
        `Remember Me is ${confirmModalData.isRememberMeEnabled ? 'disabled' : 'enabled'} for ${truncateString(
          confirmModalData.displayName,
          false,
        )}`,
      );
    }
    handleCloseEnableRememberMeForUser();
    setIsChangingRememberMe(false);
  };

  return (
    <div className={styles.remember}>
      <RememberMeTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader />
      ) : noUsers ? (
        <NoUsers />
      ) : (
        <>
          <RememberMeStatus
            handleShowToggleModal={handleShowToggleModal}
            isRememberMeEnabled={isRememberMeEnabled}
            selectedUsers={selectedUsers}
          />
          {!isRememberMeEnabled ? (
            <RememberMeHeader />
          ) : (
            <>
              {selectedUsers.length > 0 && (
                <HeaderActions
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  rememberMeData={rememberMeData}
                  handleChangeRememberMeForUser={handleChangeRememberMeForUser}
                  queryViewBy={queryViewBy}
                  isHeaderLoadingChanging={isHeaderLoadingChanging}
                />
              )}
              <RememberDropdownTable
                rememberMeData={rememberMeData}
                setRememberMeData={setRememberMeData}
                handleChangeRememberMeForUser={handleChangeRememberMeForUser}
                baseUrl={baseUrl}
                queryViewBy={queryViewBy}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userWhoseStatusChanging={userWhoseStatusChanging}
              />
            </>
          )}
          {isShowToggleModal && (
            <EnableToggleModal
              isShowToggleModal={isShowToggleModal}
              isToggleLoading={isToggleLoading}
              handleShowToggleModal={handleShowToggleModal}
              isRememberMeEnabled={isRememberMeEnabled}
              onToggleRememberMe={onToggleRememberMe}
            />
          )}
          {isShowEnableRememberMeForUser && (
            <EnableRememberMeForUserModal
              isOpen={isShowEnableRememberMeForUser}
              onRequestClose={handleCloseEnableRememberMeForUser}
              isChangingRememberMe={isChangingRememberMe}
              handleConfirmEnableRememberMe={handleConfirmEnableRememberMe}
              selectedUsers={selectedUsers}
            />
          )}
        </>
      )}
    </div>
  );
};

RememberMe.propTypes = {
  domain: PropTypes.object.isRequired,
  isCompanyPage: PropTypes.bool,
};

export default RememberMe;
