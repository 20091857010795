import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { Button, TextInput } from 'components';
import { ArrowLeftIcon, MsLogoIcon } from 'assets/img';
import { loginMsalConfig, loginRequest } from 'config/msal-config';
import { loginService } from 'services';
import { LoginContext, notify } from 'utils';
import styles from './LoginAzure.module.scss';

const LoginAzure = () => {
  const { email } = useContext(LoginContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const msalInstance = new PublicClientApplication(loginMsalConfig);

  const handleLogin = () => {
    setIsLoading(true);
    msalInstance.loginPopup(loginRequest)
      .then((response) => {
        if (response.accessToken) {
          loginService.azureAdLogin(email, response.accessToken)
            .then(() => window.location.replace('/'))
            .catch((err) => notify.error(err.response?.data.message || err.message));
        }
      })
      .catch((err) => {
        if (err.errorCode !== 'user_cancelled') {
          notify.error(err.response?.data.message || err.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.login}>
      <div className={styles.loginWrap}>
        <h3 className={styles.loginTitle}>
          <Button variant="link" icon={<ArrowLeftIcon />} onClick={() => history.goBack()} className={styles.loginBack} />
          <span>Login</span>
        </h3>
      </div>
      <TextInput
        id="enter-your-login-email"
        name="email"
        type="email"
        placeholder="Enter Your Login Email"
        value={email}
        isDisabled
        readOnly
      />
      <button id="login-azure" className={styles.azureLoginBtn} disabled={isLoading} type="button" onClick={handleLogin}>
        <MsLogoIcon title="Microsoft Sign in" />
        <span>{isLoading ? 'Loading...' : 'Sign in with Microsoft'}</span>
      </button>
    </div>
  );
};

export default LoginAzure;
