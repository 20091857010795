import PropTypes from 'prop-types';
import cn from 'classnames';
import { CircleCloseIcon, UploadIcon } from 'assets/img';
import { Button } from 'components';
import { toast } from 'react-toastify';
import styles from './UploadLogo.module.scss';

const UploadLogo = ({ updateBrandingInfo, brandingInfo }) => {
  const acceptedFileTypes = 'image/jpeg, image/gif, image/png';

  const onLogoUpload = (val) => {
    const { files } = val.nativeEvent.target;
    if (!files || !files.length) return;

    if (files.length > 1) {
      toast.error('Only one file is allowed', 3000);
      return;
    }

    const file = files[0];

    if (!file) {
      toast.error('No valid file selected', 3000);
      return;
    }

    if (!acceptedFileTypes.includes(file.type.toLowerCase())) {
      toast.error(`File should be one of such types: ${acceptedFileTypes.replace(/image\//gi, '.')}`, 3000);
      return;
    }

    if (file.size > 1048576) {
      toast.error('File should be up to 1 Mb', 3000);
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileBytes64 = e.target.result.replace(/^data:image\/[a-z]+;base64,/, '');
      const brandingDataNew = { ...brandingInfo.modified, logo: fileBytes64, logoName: file.name, hasLogoChanged: true };
      updateBrandingInfo({ modified: brandingDataNew, init: brandingInfo.init });
    };

    reader.readAsDataURL(file);
  };

  const onLogoRemove = () => {
    const brandingDataNew = { ...brandingInfo.modified, logo: null, logoName: 'default', hasLogoChanged: false };
    updateBrandingInfo({ modified: brandingDataNew, init: brandingInfo.init });
  };

  return (
    <div className={styles.uploadLogoRow}>
      <div className={styles.uploadLogoLabel}>
        <p className={styles.uploadLogoLabelTitle}>Logo</p>
        <p className={styles.uploadLogoLabelInfo}>JPG, PNG or GIF (max size: 1 Mb)</p>
      </div>
      {!brandingInfo.modified.logo ? (
        <>
          <label
            htmlFor="profile-logo-upload"
            className={styles.uploadLogoButton}
            title="Upload logo"
          >
            <UploadIcon /> Upload logo
          </label>
          <input
            id="profile-logo-upload"
            type="file"
            className={styles.uploadLogoInput}
            accept={acceptedFileTypes}
            onChange={onLogoUpload}
          />
        </>
      ) : (
        <div className={styles.uploadLogoRow}>
          <div className={cn(styles.uploadLogoButton, styles.uploaded)}>
            <img className={styles.uploadLogoImage} src={`data:image/png;base64,${brandingInfo.modified.logo}`} alt="Branding" />
            <Button variant="link" id="clear-logo" icon={<CircleCloseIcon />} className={styles.uploadLogoRemove} onClick={onLogoRemove} />
          </div>
        </div>
      )}
    </div>
  );
};

UploadLogo.propTypes = {
  updateBrandingInfo: PropTypes.func,
  brandingInfo: PropTypes.object,
};

export default UploadLogo;
