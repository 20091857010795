import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/Button/Button';
import styles from './RowNumbers.module.scss';

const RowNumbers = ({ pageSize, onPageChangeHandler }) => (
  <div className={styles.rows}>
    Rows:
    <div className={styles.rowsNumbers}>
      {[10, 50, 100].map((row) => (
        <Button
          variant="link"
          key={row}
          className={cn(styles.rowsNumbersItem, { [styles.rowsNumbersItemActive]: row === pageSize })}
          onClick={() => onPageChangeHandler(1, row)}
        >
          {row}
        </Button>
      ))}
    </div>
  </div>
);

RowNumbers.propTypes = {
  pageSize: PropTypes.number,
  onPageChangeHandler: PropTypes.func,
};

export default RowNumbers;
