import { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import Button from 'components/Button/Button';
import { AngleDownIcon, AngleUpIcon } from 'assets/img';
import styles from './BrandedLinks.module.scss';
import Contact from './Contact/Contact';
import Support from './Support/Support';
import Connect from './Connect/Connect';

const BrandedLinks = ({ contactInformation, isHeader, className }) => {
  const [contactsOpen, setContactsOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [connectOpen, setConnectOpen] = useState(false);

  const [contactsBottom, setContactsBottom] = useState(false);
  const [supportBottom, setSupportBottom] = useState(false);
  const [connectBottom, setConnectBottom] = useState(false);

  const { contactInfo, address, city, state, zipCode, country, phoneNumber, emailAddress, website, facebookLink, twitterLink, youtubeLink, linkedinLink } = contactInformation;

  const isShowContactDropdown = [contactInfo, address, city, state, zipCode, country].some((i) => i);
  const isShowSupportDropDown = [phoneNumber, emailAddress, website].some((i) => i);
  const isShowConnectDropDown = [facebookLink, twitterLink, youtubeLink, linkedinLink].some((i) => i);

  const contactsRef = useRef();
  const supportRef = useRef();
  const connectRef = useRef();

  const contactsNodeRef = useRef();
  const supportNodeRef = useRef();
  const connectNodeRef = useRef();

  const calculateDropdownPosition = (button, node, action) => {
    if (button && node) {
      const windowHeight = window.innerHeight;
      const menuHeight = node.getBoundingClientRect().height;
      const instOffsetWithMenu = button.getBoundingClientRect().bottom + menuHeight;
      return action(instOffsetWithMenu > windowHeight);
    }
    return true;
  };

  const toggleContactsOpen = () => setContactsOpen(!contactsOpen);
  const toggleSupportOpen = () => setSupportOpen(!supportOpen);
  const toggleConnectOpen = () => setConnectOpen(!connectOpen);

  useOutsideClick(contactsRef, () => contactsOpen && toggleContactsOpen());
  useOutsideClick(supportRef, () => supportOpen && toggleSupportOpen());
  useOutsideClick(connectRef, () => connectOpen && toggleConnectOpen());

  useLayoutEffect(() => {
    calculateDropdownPosition(contactsRef?.current, contactsNodeRef?.current, setContactsBottom);
    calculateDropdownPosition(supportRef?.current, supportNodeRef?.current, setSupportBottom);
    calculateDropdownPosition(connectRef?.current, connectNodeRef?.current, setConnectBottom);
    window.addEventListener('resize', () => {
      calculateDropdownPosition(contactsRef?.current, contactsNodeRef?.current, setContactsBottom);
      calculateDropdownPosition(supportRef?.current, supportNodeRef?.current, setSupportBottom);
      calculateDropdownPosition(connectRef?.current, connectNodeRef?.current, setConnectBottom);
    });
    return () => {
      window.removeEventListener('resize', calculateDropdownPosition);
    };
  }, []);

  return (
    <div className={cn(styles.links, { [className]: className })}>
      {isShowContactDropdown && (
        <div className={styles.linksWrap} ref={contactsRef}>
          <div
            id="contacts-dropdown"
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: contactsOpen,
              [styles.bottom]: contactsBottom,
            })}
            ref={contactsNodeRef}
          >
            <Contact contactInformation={contactInformation} />
          </div>
          <Button
            id="toggle-contact-dropdown"
            variant="link"
            onClick={toggleContactsOpen}
            className={cn(styles.linksButton, { [styles.active]: contactsOpen })}
          >
            Contact{contactsOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
      {isShowSupportDropDown && (
        <div className={styles.linksWrap} ref={supportRef}>
          <div
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: supportOpen,
              [styles.bottom]: supportBottom,
            })}
            ref={supportNodeRef}
          >
            <Support contactInformation={contactInformation} />
          </div>
          <Button
            id="toggle-contact-support"
            variant="link"
            onClick={toggleSupportOpen}
            className={cn(styles.linksButton, { [styles.active]: supportOpen })}
          >
            Support{supportOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
      {isShowConnectDropDown && (
        <div className={styles.linksWrap} ref={connectRef}>
          <div
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: connectOpen,
              [styles.bottom]: connectBottom,
            })}
            ref={connectNodeRef}
          >
            <Connect contactInformation={contactInformation} />
          </div>
          <Button
            variant="link"
            id="toggle-contact-connect"
            onClick={toggleConnectOpen}
            className={cn(styles.linksButton, { [styles.active]: connectOpen })}
          >
            Connect with us{connectOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
    </div>
  );
};

BrandedLinks.propTypes = {
  contactInformation: PropTypes.object,
  isHeader: PropTypes.bool,
  className: PropTypes.any,
};

export default BrandedLinks;
