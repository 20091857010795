import axios from 'config/customAxios';
import { replaceSpecChar } from 'helpers/replaceSpecChar';

const LoginService = () => {
  const login = async (email, hostName) => axios.post('/api/login/performSigninValidation', { email, hostName });
  const signIn = async (mfaPin, password, userId) =>
    axios.post('/api/login/authenticateUser', { mfaPin, password, userId });
  const sendSignInNotification = async (userId) => axios.get(`/api/auth/RequestMfaCode?userId=${userId}`);
  const getCountriesList = async () => axios.get('/api/Countries');
  const setMfa = async (pin, token, mfaType) => axios.post('/api/auth/mfa/setMfa', { pin, token, mfaType });
  const checkVerificationCode = async (mfaPin, mfaHash) =>
    axios.post('/api/auth/mfa/CheckVerificationCode', { mfaPin, mfaHash });
  const getLastPhoneDigits = async (userId) => axios.get(`/api/auth/mfa/GetLastTwoDigits?userId=${userId}`);
  const resetMfaWithPin = async (mfaPin, mfaHash) => axios.post('/api/auth/mfa/ResetWithPin', { mfaPin, mfaHash });
  const getResetMfaHash = async (email, code) =>
    axios.post(`/api/auth/mfa/GetPinByEmail?email=${replaceSpecChar(email)}&code=${code}`);
  const validatePasswordReset = async (token) => axios.get(`/api/RegisteredUser/ValidatePasswordReset?token=${token}`);
  const setNewPassword = async (token, newPassword) =>
    axios.post('/api/RegisteredUser/SetNewPassword', { token, newPassword });
  const getJustRegisteredCustomerEmail = async (customerId) =>
    axios.get(`/api/RegisteredUser/GetJustRegisteredCustomerEmail/?customerId=${customerId}`);
  const resendVerificationEmail = async (email) => axios.post('/api/auth/ResendVerificationEmail', { email });
  const resendVerificationEmailByToken = async (token) =>
    axios.post('/api/auth/ResendVerificationEmail', { VerificationToken: token });
  const requestVerificationCodeByEmail = async () => axios.get('/api/auth/mfa/RequestVerificationCodeByEmail');
  const requestVerificationCodeBySms = async (phone, code, isMfaSetup = true) =>
    axios.get(
      `/api/auth/mfa/RequestVerificationCodeByTextMessage?toPhone=${phone}&code=${code}&isMfaSetup=${isMfaSetup}`,
    );
  const changePassword = async (userId, oldPassword, newPassword) =>
    axios.post('/api/adPasswordReset/ChangePassword', { userId, oldPassword, newPassword });
  const azureAdLogin = async (email, token) => axios.post('/api/login/azureadlogin', { email, token });
  const validateByWhois = async (publicKey) => axios.post('/api/DomainValidation/ValidateByWhois', publicKey);
  const resendWhoisValidationEmail = async (publicKey) =>
    axios.get(`/api/DomainValidation/ResendWhoisValidationEmail?publicKey=${publicKey}`);
  const changeTempPassword = async (email, tempPassword, newPassword) =>
    axios.post('/api/RegisteredUser/ChangeTempPassword', { email, tempPassword, newPassword });
  const verifyPhoneNumber = async (phoneNumberLastDigits) =>
    axios.post('/api/auth/mfa/VerifyPhoneNumber', { phoneNumberLastDigits });
  const verifyEmail = async (email) => axios.post('/api/auth/mfa/VerifyEmail', { email });
  const processEmailVerification = async (token) => axios.post('/api/auth/processEmailVerification', token);
  const getAdPasswordReset = async (userId) => axios.get(`/api/adPasswordReset/ForgotPassword?userId=${userId}`);
  const resetPasswordVerifyMfaCode = async (userId, mfaCode) =>
    axios.post('/api/adPasswordReset/VerifyMfaCode', { userId, mfaCode });
  const resetPasswordVerifyPhone = async (userId, lastFour) =>
    axios.post('/api/AdPasswordReset/VerifyLastFourDigits', { userId, lastFour });
  const resetPasswordVerifySmsCode = async (userId, smsHash, smsCode) =>
    axios.post('/api/AdPasswordReset/VerifySmsCode', { userId, smsHash, smsCode });
  const resetPasswordSendResetEmail = async (userId, brandingData) =>
    axios.post(`/api/AdPasswordReset/SendResetEmail?isBranded=${brandingData}`, userId);
  const resetPasswordSetNewPassword = async (userId, password, isEmailRequest) =>
    axios.post(`/api/AdPasswordReset/ResetPassword?isEmailRequest=${isEmailRequest}`, { userId, password });
  const skipSetupPhoneForPasswordReset = async () => axios.post('api/auth/mfa/SkipSetupPhoneForPasswordReset');
  const getPersonalRegistrationData = async () => axios.get('/api/RegisteredUser/GetPersonalRegistrationData');
  const userCanBeRegistered = async (email) => axios.get(`/api/RegisteredUser/UserCanBeRegistered?email=${email}`);
  const logout = async () => axios.post('/api/auth/logout');
  const registerPersonalUser = async (
    email,
    firstName,
    lastName,
    password,
    isMonthlyPaymentPlan,
    paymentMethodNonce,
    trialPeriod,
    monthlyPrice,
    monthlyMachineCount,
    annualPrice,
    annualMachineCount,
  ) =>
    axios.post('/api/RegisteredUser/RegisterPersonalUser', {
      email,
      firstName,
      lastName,
      password,
      isMonthlyPaymentPlan,
      paymentMethodNonce,
      trialPeriod,
      monthlyPrice,
      monthlyMachineCount,
      annualPrice,
      annualMachineCount,
    });

  return {
    login,
    signIn,
    sendSignInNotification,
    getCountriesList,
    setMfa,
    checkVerificationCode,
    getLastPhoneDigits,
    resetMfaWithPin,
    getResetMfaHash,
    validatePasswordReset,
    setNewPassword,
    getJustRegisteredCustomerEmail,
    resendVerificationEmail,
    resendVerificationEmailByToken,
    requestVerificationCodeByEmail,
    requestVerificationCodeBySms,
    changePassword,
    azureAdLogin,
    validateByWhois,
    resendWhoisValidationEmail,
    changeTempPassword,
    verifyPhoneNumber,
    verifyEmail,
    processEmailVerification,
    getAdPasswordReset,
    resetPasswordVerifyMfaCode,
    resetPasswordVerifyPhone,
    resetPasswordVerifySmsCode,
    resetPasswordSendResetEmail,
    resetPasswordSetNewPassword,
    skipSetupPhoneForPasswordReset,
    getPersonalRegistrationData,
    userCanBeRegistered,
    registerPersonalUser,
    logout,
  };
};

export const loginService = LoginService();
