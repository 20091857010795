import axios from 'config/customAxios';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { BrandingContext } from 'utils';

const BrandingProvider = ({ children }) => {
  const [brandingData, setBrandingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { host } = window.location;
    const getBrandingData = () => {
      axios
        .get(`/api/Company/GetBrandingByHost?host=${host}`)
        .then(({ data }) => {
          setBrandingData(data);
          if (data.isActive) {
            document.title = 'Simple Workspaces';
          }
        })
        .catch((e) => {
          if (e && e.response.status === 406) {
            window.location.href = e?.response?.data;
          }
        })
        .finally(() => setIsLoading(false));
    };
    if (!process.env.REACT_APP_PUBLIC_URL.includes(host) && host !== 'localhost:3000') {
      getBrandingData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const contextValues = useMemo(
    () => ({
      brandingData,
      isLoading,
    }),
    [brandingData, isLoading],
  );

  return <BrandingContext.Provider value={contextValues}>{children}</BrandingContext.Provider>;
};
BrandingProvider.propTypes = {
  children: PropTypes.node,
};

export default BrandingProvider;
