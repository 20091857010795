import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader } from 'components';
import { queryFormatter } from 'utils';
import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import NoDevices from './components/NoDevices/NoDevices';
import DeviceMgmtDevices from './components/DeviceMgmtDevices/Devices';
import DeviceMgmtEncryption from './components/DeviceMgmtEncryption/Encryptions';
import SuspenededMessage from './components/SuspendedMessage';
import styles from './DeviceMgmt.module.scss';

const DeviceMgmt = ({ domain, userRole }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState(null);
  const [encrypts, setEncrypts] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const isVisibleNoLicensesMsg = [spTechAdmin, spAdmin, corpAdmin, corpTechAdmin, techAdmin, custAdmin].includes(userRole);
  const isCustTechAdmin = [techAdmin, custAdmin].includes(userRole);

  const DATA_MAP = useMemo(() => [
    {
      action: setDevices,
      baseUrl: `/api/IoTAgent/GetDevices?domainId=${domain.id}`,
      query: {
        pageNumber: 1, pageSize: 10, orderBy: 'dateAdded asc', deviceStatus: 'AllDevices', installationStatus: 'AllStatuses',
      },
    },
    {
      action: setEncrypts,
      baseUrl: `/api/IoTAgent/GetEncryptionData?domainId=${domain.id}`,
      query: {
        pageNumber: 1, pageSize: 10, orderBy: 'dateAdded asc', deviceStatus: 'AllDevices', installationStatus: 'AllStatuses',
      },
    },
    {
      action: setSubscriptionStatus,
      baseUrl: `/api/Chargify/GetSubscriptionActiveStatus?domainId=${domain.id}`,
      query: null,
    },
  ], [domain.id]);

  useEffect(() => {
    Promise.allSettled(DATA_MAP.map((item) => axios.get(queryFormatter(item.baseUrl, item.query))))
      .then((results) => {
        results.forEach((result, index) => {
          const stateAction = DATA_MAP[index].action;
          const queryConfig = DATA_MAP[index].query;
          const pageMeta = result.value?.headers['x-pagination'] ? JSON.parse(result.value.headers['x-pagination']) : null;

          if (result.status === 'fulfilled') {
            stateAction(index === 2 ? result.value.data : { data: result.value.data, pageMeta, queryConfig });
          } else {
            stateAction({ isError: true, error: result.reason.response.data || result.reason.message });
          }
        });
      })
      .then(() => setIsLoading(false));
  }, [DATA_MAP]);

  if (isLoading) return <Loader />;

  if (
    !devices.data?.length
    && !devices.queryConfig.searchQuery
    && devices.queryConfig.deviceStatus === 'AllDevices'
    && devices.queryConfig.installationStatus === 'AllStatuses'
    && subscriptionStatus
  ) {
    return <NoDevices domainId={domain.id} />;
  }

  return (
    <div className={styles.deviceMgmt}>
      <h3 className={styles.deviceMgmtTitle}>Manage BitLocker Devices</h3>
      <Tabs>
        {!subscriptionStatus && (
          <SuspenededMessage userRole={userRole} />
        )}
        <TabList>
          <Tab><span id="tab-devices">Devices</span></Tab>
          <Tab><span id="tab-encryption">Encryption</span></Tab>
        </TabList>

        <TabPanel>
          <DeviceMgmtDevices
            domainId={domain.id}
            isNoLicense={!subscriptionStatus}
            baseUrl={DATA_MAP[0].baseUrl}
            onUpdate={(devicesNew) => setDevices(devicesNew)}
            devices={devices}
            isVisibleNoLicensesMsg={isVisibleNoLicensesMsg}
            isCustTechAdmin={isCustTechAdmin}
          />
        </TabPanel>
        <TabPanel>
          <DeviceMgmtEncryption
            encrypts={encrypts}
            isNoLicense={!subscriptionStatus}
            baseUrl={DATA_MAP[1].baseUrl}
            onUpdate={(encryptsNew) => setEncrypts(encryptsNew)}
            devicesWithoutLicenses={devices.pageMeta.DevicesWithoutLicenses}
            isVisibleNoLicensesMsg={isVisibleNoLicensesMsg}
            isCustTechAdmin={isCustTechAdmin}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

DeviceMgmt.propTypes = {
  domain: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default DeviceMgmt;
