import cn from 'classnames';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { CloseIcon, MailIcon, PhoneIcon } from 'assets/img';
import Button from 'components/Button/Button';
import { getUserRole } from 'utils';
import styles from './ValidateModal.module.scss';

const ValidateModal = ({
  isShowValidateModal,
  toggleShowValidateModal,
  onModalOpen,
  emailValidationData,
  txtValidationData,
  daysLeft,
  user,
}) => {
  const role = getUserRole(user);
  const isAdmin = role === 'spAdmin' || role === 'corpAdmin';

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isShowValidateModal}
      onRequestClose={toggleShowValidateModal}
      className={cn(styles.modal, { [styles.short]: !isAdmin })}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={daysLeft > 0}
      shouldCloseOnEsc={daysLeft > 0}>
      <div className={styles.modalHeader}>
        <span>{isAdmin ? 'Validate your domain' : 'Contact your administrator'}</span>
        {daysLeft > 0 && (
          <Button
            id="close-modal"
            variant="link"
            className={styles.modalClose}
            icon={<CloseIcon />}
            onClick={toggleShowValidateModal}
          />
        )}
      </div>
      {isAdmin ? (
        <div className={styles.modalContent}>
          <div>Select one option to validate your domain</div>
          <div className={styles.modalContentOptions}>
            <Button type="button" variant="link" icon={<MailIcon />} onClick={() => onModalOpen(emailValidationData)}>
              WHOIS Email
            </Button>
            <span>or</span>
            <Button type="button" variant="link" icon={<PhoneIcon />} onClick={() => onModalOpen(txtValidationData)}>
              DNS TXT Record
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.modalContent}>
          <div>Please contact your administrator to continue using TruGrid</div>
        </div>
      )}
    </Modal>
  );
};

ValidateModal.propTypes = {
  isShowValidateModal: PropTypes.bool,
  toggleShowValidateModal: PropTypes.func,
  onModalOpen: PropTypes.func,
  emailValidationData: PropTypes.object,
  txtValidationData: PropTypes.object,
  daysLeft: PropTypes.number,
  user: PropTypes.object,
};

export default ValidateModal;
