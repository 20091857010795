/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CheckIcon } from 'assets/img';
import styles from './PasswordComplexity.module.scss';

const PASSWORD_REQUIREMENTS = [
  'at least 8 characters',
  'at least one number',
  'both lower and uppercase letters',
  <>at least one special character:<br /><b>(~!@#$%^&*_-+=`|\(){}[]:;"'&lt;&gt;,.?/)[space]</b></>,
];

const PasswordComplexity = memo(({ inputValue }) => {
  const [passwordComplexity, setPasswordComplexity] = useState([false, false, false, false]);

  const checkPassword = useCallback(() => {
    if (inputValue.length > 0) {
      const passComplexChanged = [...passwordComplexity];
      passComplexChanged[0] = /(?=.{8,})/.test(inputValue);
      passComplexChanged[1] = /(?=.*[0-9])/.test(inputValue);
      passComplexChanged[2] = /(?=.*[a-z])(?=.*[A-Z])/.test(inputValue);
      passComplexChanged[3] = /(?=.*[@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>_ ])/.test(inputValue);
      setPasswordComplexity(passComplexChanged);
    } else {
      setPasswordComplexity([false, false, false, false]);
    }
  }, [inputValue]);

  useEffect(() => {
    checkPassword();
  }, [checkPassword, inputValue]);

  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipTitle}>Password Requirements:</div>
      <ul className={styles.password}>
        {PASSWORD_REQUIREMENTS.map((item, index) => (
          <li key={item} className={cn(styles.passwordItem, { [styles.done]: passwordComplexity[index] })}>
            <span className={styles.passwordIcon}><CheckIcon /></span>
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
});

PasswordComplexity.propTypes = {
  inputValue: PropTypes.string,
};

export default PasswordComplexity;
