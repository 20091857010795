import { StatusMessage } from 'components';
import { corpTechAdmin, spTechAdmin, spTechUser, techAdmin } from 'consts';
import PropTypes from 'prop-types';
import EmptyPages from '../EmptyPages/EmptyPages';

const DomainsList = ({ myDomain, userRole, userData, children }) => {
  if (myDomain.isError) {
    return (
      <StatusMessage error>
        {myDomain.error}
      </StatusMessage>
    );
  }

  let isEmptyPage = !myDomain.data?.length && !myDomain.queryConfig?.searchQuery;

  if ([spTechAdmin, spTechUser, corpTechAdmin].includes(userRole)) {
    isEmptyPage = (myDomain.queryConfig.assigned.toLowerCase() === 'all'
    && !myDomain.pageMeta?.AllMachinesCount)
    || (myDomain.queryConfig.assigned.toLowerCase() === 'my'
    && !myDomain.pageMeta?.AssignedMachinesCount);
  }

  if (userRole === techAdmin) {
    isEmptyPage = !myDomain.pageMeta?.AssignedMachinesCount;
  }

  if (isEmptyPage) {
    return (
      <EmptyPages
        userData={userData}
        userRole={userRole}
        myDomain={myDomain}
      />
    );
  }

  return children;
};

DomainsList.propTypes = {
  myDomain: PropTypes.object,
  userRole: PropTypes.string,
  userData: PropTypes.object,
  children: PropTypes.node,
};

export default DomainsList;
