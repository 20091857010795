/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import cn from 'classnames';
import { CloseIcon } from 'assets/img';
import SwitchCheckbox from 'components/SwitchCheckbox/SwitchCheckbox';
import Button from '../Button/Button';
import styles from './Modal.module.scss';

const ModalComponent = ({
  isOpen,
  onRequestClose,
  contentLabel,
  contentSubtitle,
  contentClass,
  actionFunc,
  actionTitle,
  variantBtn = 'primary',
  shouldCloseOnOverlayClick = true,
  buttonClass,
  cancelButtonClass,
  cancelAction,
  cancelTitle = 'Cancel',
  actionLoading,
  actionDisabled,
  style,
  children,
  isSwitcherChecked,
  onChangeSwitcher,
  switcherTitle,
  enableOverflow,
  stickyFooter,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={contentLabel}
    ariaHideApp={false}
    closeTimeoutMS={360}
    style={style}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    className={cn(styles.modalPortal, { [styles.overflow]: enableOverflow })}
    overlayClassName={styles.modalOverlay}>
    <div className={styles.modalHeader}>
      {contentSubtitle ? (
        <div className={styles.modalHeaderTitle}>
          <span>{contentLabel}</span>
          <span className={styles.modalHeaderTitleSubtitle}>{contentSubtitle}</span>
        </div>
      ) : (
        <span>{contentLabel}</span>
      )}
      <Button
        id="close-modal"
        variant="link"
        className={cn(styles.modalClose, { [styles.modalCloseSubtitle]: contentSubtitle })}
        icon={<CloseIcon />}
        onClick={onRequestClose}
      />
    </div>
    <div
      className={cn(styles.modalContent, {
        [styles.modalContentActions]: !(actionFunc && actionTitle),
        [contentClass]: contentClass,
      })}>
      {children}
    </div>
    {actionFunc && actionTitle && (
      <div
        className={cn(styles.modalActions, {
          [styles.modalActionsWithSwitcher]: switcherTitle,
          [styles.sticky]: stickyFooter,
        })}>
        {switcherTitle && (
          <div className={styles.switcher}>
            <SwitchCheckbox checked={isSwitcherChecked} onChange={onChangeSwitcher} />
            <p className={styles.switcherTitle}>{switcherTitle}</p>
          </div>
        )}
        <div className={styles.modalActionsButtons}>
          <Button
            id="modal-close"
            className={cn(styles.modalActionsButton, { [cancelButtonClass]: cancelButtonClass })}
            onClick={cancelAction || onRequestClose}
            size="40"
            variant="secondary">
            {cancelTitle}
          </Button>
          <Button
            id="modal-action"
            className={cn(styles.modalActionsButton, { [buttonClass]: buttonClass })}
            onClick={actionFunc}
            size="40"
            variant={variantBtn}
            isLoading={actionLoading}
            isDisabled={actionDisabled}>
            {actionTitle}
          </Button>
        </div>
      </div>
    )}
  </Modal>
);

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.any,
  contentSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shouldCloseOnOverlayClick: PropTypes.bool,
  contentClass: PropTypes.any,
  cancelTitle: PropTypes.string,
  actionFunc: PropTypes.func,
  actionTitle: PropTypes.string,
  variantBtn: PropTypes.oneOf(['primary', 'primary2', 'delete', 'secondary', 'link', 'deleteOutline']),
  buttonClass: PropTypes.any,
  cancelButtonClass: PropTypes.any,
  cancelAction: PropTypes.func,
  actionLoading: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.node,
  isSwitcherChecked: PropTypes.bool,
  onChangeSwitcher: PropTypes.func,
  switcherTitle: PropTypes.string,
  enableOverflow: PropTypes.bool,
  stickyFooter: PropTypes.bool,
};

export default ModalComponent;
