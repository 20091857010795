/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DomainContext, MsalContext, notify } from 'utils';
import axios from 'config/customAxios';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import ActivationCode from 'components/ActivationCode/ActivationCode';
import Button from 'components/Button/Button';
import { AlertWarning } from 'assets/img';
import Loader from '../Loader/Loader';
import ResetAdModal from './components/ResetAdModal';
import DomainMgmtInfo from './components/DomainMgmtInfo/DomainMgmtInfo';
import styles from './ActiveDirectorySetup.module.scss';
import OnPremiseAD from './components/OnPremiseAD/OnPremiseAD';
import AzureAD from './components/AzureAD/AzureAD';

const ActiveDirectorySetup = ({ domain, userRole, isCompanyPage }) => {
  const [type, setType] = useState();
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [domainData, setDomainData] = useState({});
  const [isShowNotSyncMessage, setIsShowNotSyncMessage] = useState(false);

  const { domain: selectedDomain, onUpdateDomain } = useContext(DomainContext);

  const { isDomainHybryd } = getDomainADStatus(domainData);
  const { isDomainActive } = getDomainStatus(domainData);
  const { isLoading: isLoadingMs, disconnectAzureAD, getDomainToken, updateUser } = useContext(MsalContext);
  const { dnsName: domainName, id: domainId } = domain;

  const updateCustomerDomain = ({ status, origin, sentries }) => {
    const selectedDomainNew = JSON.parse(JSON.stringify(selectedDomain));
    selectedDomainNew.status = status;
    selectedDomainNew.origin = origin;
    selectedDomainNew.sentries = sentries;
    onUpdateDomain(selectedDomainNew);
  };

  const loadInitDomainData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/api/domains/GetDomainStatus?domainId=${domain.id}`);
      setDomainData(data);
      setIsShowNotSyncMessage(data.isAdSynced);
      if (!isCompanyPage) {
        updateCustomerDomain(data);
      }
    } catch (err) {
      toast.error(err?.response?.data || err.message, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInitDomainData();
  }, []);

  const toggleShowModal = (modalType) => {
    setType(modalType);
    setShowToggleModal(!showToggleModal);
  };

  const azureAdRefreshCall = (id, authToken) => {
    setIsLoading(true);
    axios
      .post('/api/Domains/ForcedAzureAdDataRefresh', { domainId: id, authToken })
      .then(() => updateUser())
      .then(() => toast.success('Entra ID re-authentication is successful', 3000))
      .catch((err) => {
        if (err.response?.data) {
          toast.warn(err.response.data, 3000);
        } else {
          toast.error(err.message, 3000);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const disconnectAzure = async () => {
    try {
      await disconnectAzureAD(isCompanyPage, domainId);
      if (!isCompanyPage) {
        await loadInitDomainData();
      }
      toast.success('Microsoft Entra ID is disconnected', 3000);
    } catch (err) {
      toast.error(err.response.data || err.message, 3000);
    } finally {
      setShowToggleModal(!showToggleModal);
    }
  };

  const refreshAd = async () => {
    try {
      await getDomainToken(domainId, domainName, azureAdRefreshCall);
    } catch (err) {
      if (err.errorCode === 'user_cancelled') {
        setIsShowNotSyncMessage(true);
      }
      notify.error(err.message);
    }
  };

  const disconnectOnPremise = () => {
    setIsResetLoading(true);
    axios
      .post('/api/DomainManagement/DisconnectOnPremiseAd', {
        domainId,
      })
      .then(async () => {
        setIsResetLoading(false);
        setShowToggleModal(!showToggleModal);
        if (isDomainHybryd) {
          await refreshAd();
        }
        await updateUser();
        toast.success('On-premises Active Directory is disconnected', 3000);
      })
      .catch((err) => {
        setIsResetLoading(false);
        toast.error(err?.response?.data || err.message, 3000);
      });
  };

  if (isLoading || isLoadingMs) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.domainMgmt}>
        <div className={styles.domainMgmtRow}>
          <h3 className={styles.domainMgmtTitle}>Active Directory Setup</h3>
          <ActivationCode
            activationCode={domain.activationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        </div>
        {!isShowNotSyncMessage && isDomainActive && (
          <div className={styles.domainMgmtAdSync}>
            <AlertWarning />{' '}
            <span>
              You are no longer connected to Active Directory. Please{' '}
              <Button variant="link" onClick={refreshAd} className={styles.domainMgmtAdSyncLink}>
                re-authenticate against Entra ID
              </Button>{' '}
              to continue to support user logins
            </span>
          </div>
        )}
        <table className={styles.domainMgmtTable}>
          <tbody>
            <OnPremiseAD
              domainData={domainData}
              userRole={userRole}
              isCompanyPage={isCompanyPage}
              toggleShowModal={toggleShowModal}
            />
            <AzureAD
              domainData={domainData}
              domain={domain}
              userRole={userRole}
              toggleShowModal={toggleShowModal}
              isCompanyPage={isCompanyPage}
              loadInitDomainData={loadInitDomainData}
            />
          </tbody>
        </table>
        <DomainMgmtInfo />
        <ReactTooltip id="reset-tooltip" type="light" place="bottom" html effect="solid" />
      </div>
      {showToggleModal && (
        <ResetAdModal
          type={type}
          isDomainHybryd={isDomainHybryd}
          domain={domain.dnsName}
          isResetLoading={isResetLoading}
          isOpen={showToggleModal}
          toggleShowModal={toggleShowModal}
          disconnectAzure={disconnectAzure}
          disconnectOnPremise={disconnectOnPremise}
        />
      )}
    </>
  );
};

ActiveDirectorySetup.propTypes = {
  domain: PropTypes.object,
  userRole: PropTypes.string,
  isCompanyPage: PropTypes.bool,
};

export default ActiveDirectorySetup;
