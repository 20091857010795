import { useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { MsalContext } from 'utils';
import { MsLogoIcon, ExternalLinkIcon } from 'assets/img';
import { toast } from 'react-toastify';
import styles from './MsLogin.module.scss';

const MsLogin = ({ isAzureAd, domainId, domainName, isActive, isOnboarding }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { connectAzureAD } = useContext(MsalContext);
  const isUpdateUser = ['workspace', 'company-management'].some((i) => window.location.hash.includes(i));

  const apiRequest = {
    scopes: ['User.Read.All', 'Group.Read.All'],
    prompt: 'select_account',
    state: JSON.stringify({ domainId, domainName, isUpdateUser }),
  };

  const onLogin = async () => {
    if (isAzureAd) return false;
    try {
      setIsLoading(true);
      await connectAzureAD(apiRequest);
      setIsLoading(false);
      toast.success('Successfully connected', 3000);
    } catch (err) {
      toast.error(err?.response?.data || err.message, 3000);
      setIsLoading(false);
    }
  };

  if (isOnboarding) {
    return (
      <button type="button" className={styles.msloginOnboarding} onClick={onLogin}>
        Microsoft Entra ID&nbsp;
        <ExternalLinkIcon />
      </button>
    );
  }

  return (
    <button type="button" className={cn(styles.mslogin, { [styles.msloginPositive]: isAzureAd })} onClick={onLogin}>
      <MsLogoIcon title="Microsoft Sign in" />
      <span>{isLoading && isActive ? 'Loading...' : isAzureAd ? 'Connected' : 'Connect to Entra ID'}</span>
    </button>
  );
};

MsLogin.propTypes = {
  isAzureAd: PropTypes.bool,
  domainId: PropTypes.string.isRequired,
  domainName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOnboarding: PropTypes.bool,
};

export default MsLogin;
