import PropTypes from 'prop-types';
import cn from 'classnames';
import { useObserver } from 'hooks';
import { useRef } from 'react';
import { Button, DropdownArray } from 'components';
import styles from './HeaderActions.module.scss';
import { handleUserCase } from '../../utils/shortcutUtils';

const HeaderActions = ({
  shortcutData,
  selectedUsers,
  setSelectedUsers,
  onChangeShortcutForUser,
  isHeaderBtnLoading,
  isDomainOnPremise,
  isShortcutMfaRequired,
}) => {
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  const getActionType = () => {
    switch (true) {
      case selectedUsers.every((i) => !i.isDesktopShortcutEnabled):
        return 'Enable Desktop Shortcut';
      case selectedUsers.every((i) => i.isDesktopShortcutEnabled):
        return 'Disable Desktop Shortcut';
      default:
        return 'Edit Desktop Shortcut';
    }
  };

  const actionType = handleUserCase(selectedUsers);

  const dropdownOptions = [];
  if (actionType === 'disabledAll') {
    if (isShortcutMfaRequired) {
      dropdownOptions.push({ id: 'isDesktopShortcutEnabled', value: 'Enable Desktop Shortcut' });
    } else {
      dropdownOptions.push(
        { id: 'isDesktopShortcutEnabled', value: 'Enable Desktop Shortcut' },
        {
          id: 'isShortcutMfaRequired',
          value: 'Enable Desktop Shortcut with MFA Code Required',
          extra: true,
        },
      );
    }
  }
  if (actionType === 'enabledAll') {
    dropdownOptions.push(
      { id: 'isDesktopShortcutEnabled', value: 'Disable Desktop Shortcut' },
      { id: 'isShortcutMfaRequired', value: 'Disable MFA Code' },
    );
  }
  if (actionType === 'enabledShortcut') {
    dropdownOptions.push(
      { id: 'isDesktopShortcutEnabled', value: 'Disable Desktop Shortcut' },
      { id: 'isShortcutMfaRequired', value: 'Require MFA Code' },
    );
  }

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{shortcutData.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button
          variant="secondary"
          size="40"
          onClick={() => setSelectedUsers([])}
          className={styles.headerActionsButton}>
          Unselect All
        </Button>
        {dropdownOptions.length > 1 && isDomainOnPremise ? (
          <DropdownArray
            id="settings-save"
            isLoading={isHeaderBtnLoading}
            isButton
            btnTitle="Manage Desktop Shortcut"
            options={dropdownOptions}
            width="15.8em"
            onChange={({ id, extra }) => {
              onChangeShortcutForUser(true, {}, id, actionType, extra);
            }}
            optionsClassName={cn(styles.dropdownOptions, {
              [styles.short]: actionType === 'enabledAll' || actionType === 'enabledShortcut',
            })}
          />
        ) : (
          <Button
            variant="primary"
            className={styles.headerActionsButton}
            isLoading={isHeaderBtnLoading}
            size="40"
            onClick={() => onChangeShortcutForUser(true, {}, 'isDesktopShortcutEnabled', actionType)}>
            {getActionType()}
          </Button>
        )}
      </div>
    </header>
  );
};

HeaderActions.propTypes = {
  shortcutData: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  onChangeShortcutForUser: PropTypes.func.isRequired,
  isHeaderBtnLoading: PropTypes.bool.isRequired,
  isDomainOnPremise: PropTypes.bool.isRequired,
  isShortcutMfaRequired: PropTypes.bool.isRequired,
};

export default HeaderActions;
