import PropTypes from 'prop-types';
import { getType } from 'helpers';
import FullScanCompleted from './components/FullScanCompleted';
import CompromisedAccDiscovered from './components/CompromisedAccDiscovered';
import DiscoveryScanCompleted from './components/DiscoveryScanCompleted';

const StatusCell = ({ item }) => {
  const { type, samples } = item;

  const { compromisedAccountsDiscovered, discoveryScanCompleted, fullScanCompleted } = getType(type);

  if (compromisedAccountsDiscovered) {
    return <CompromisedAccDiscovered item={item} />;
  }

  if (discoveryScanCompleted) {
    return <DiscoveryScanCompleted item={item} />;
  }

  if (fullScanCompleted) {
    return <FullScanCompleted item={item} />;
  }

  return samples;
};

StatusCell.propTypes = {
  item: PropTypes.object,
};

export default StatusCell;
