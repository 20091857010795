import PropTypes from 'prop-types';
import { SwitchCheckbox } from 'components';
import styles from './HeaderPassMgmt.module.scss';

const HeaderPassMgmt = ({ isEnabled, shouldUpdateSentry, handleShowToggleModal }) => (
  <header className={styles.header}>
    <div className={styles.headerRow}>
      <div className={styles.headerCol}>
        <h4 className={styles.headerTitle}>Forgot Password</h4>
        <p className={styles.headerSubtitle}>Settings apply to all users in domain</p>
      </div>
      <div className={styles.headerCol}>
        <div className={styles.headerRow}>
          <div className={styles.headerSwitch}>
            <SwitchCheckbox
              id="toggle-enable"
              checked={isEnabled}
              disabled={shouldUpdateSentry}
              onChange={handleShowToggleModal}
            />
          </div>
          <div className={styles.headerSwitchText}>
            <h4>Allow AD users to reset their password</h4>
            <p>Show Forgot Password link on login page</p>
          </div>
        </div>
      </div>
    </div>
  </header>
);

HeaderPassMgmt.propTypes = {
  isEnabled: PropTypes.bool,
  shouldUpdateSentry: PropTypes.bool,
  handleShowToggleModal: PropTypes.func,
};

export default HeaderPassMgmt;
