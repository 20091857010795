import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Loader } from 'components';
import StatusMessage from 'components/StatusMessage/StatusMessage';
import TruScanDeleteModal from './components/TruScanDeleteModal/TruScanDeleteModal';
import TruScanTable from './components/TruScanTable/TruScanTable';
import NoDomains from './components/NoDomains/NoDomains';
import styles from './TruScanMonitoring.module.scss';

const TruScanMonitoring = ({ domain }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [leakedRecords, setLeakedRecords] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [showTruScanDeleteModal, setShowTruScanDeleteModal] = useState(false);

  const toggleShowTruScanDeleteModal = () => {
    setShowTruScanDeleteModal(!showTruScanDeleteModal);
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(`/api/leakedrequests/GetForDomain/${domain.id}`);
      setLeakedRecords(data);
    } catch (err) {
      setLeakedRecords({ isError: true, error: err.message });
    } finally {
      setIsLoading(false);
    }
  }, [domain.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) return <Loader />;

  if (leakedRecords.isError) {
    return <StatusMessage error>{leakedRecords.error}</StatusMessage>;
  }

  return (
    <div className={styles.pageContainer}>
      <h3 className={styles.containerHeader}>TruScan ID Monitoring Reports ({leakedRecords.length})</h3>
      <div className={styles.content}>
        {!leakedRecords?.length ? (
          <NoDomains />
        ) : (
          <TruScanTable
            leakedRecords={leakedRecords}
            setShowTruScanDeleteModal={setShowTruScanDeleteModal}
            setModalData={setModalData}
          />
        )}
      </div>

      {showTruScanDeleteModal && (
        <TruScanDeleteModal
          showTruScanDeleteModal={showTruScanDeleteModal}
          toggleShowTruScanDeleteModal={toggleShowTruScanDeleteModal}
          data={modalData}
          setLeakedRecords={setLeakedRecords}
          leakedRecords={leakedRecords}
        />
      )}
    </div>
  );
};

TruScanMonitoring.propTypes = {
  domain: PropTypes.object.isRequired,
};

export default TruScanMonitoring;
