import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

const Loader = ({ style, id }) => (
  <div className={styles.loader} style={style} id={id || 'loading'}>
    <div className={styles.loaderIcon} />
    <div className={styles.loaderText}>Loading</div>
  </div>
);

Loader.propTypes = {
  style: PropTypes.any,
  id: PropTypes.string,
};

export default Loader;
