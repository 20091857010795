import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoginContext } from 'utils';
import ConfirmPhoneNumber from './pages/ConfirmPhoneNumber/ConfirmPhoneNumber';
import EnterValidateCode from './pages/EnterValidateCode/EnterValidateCode';

const ResetViaSMS = ({ setError }) => {
  const { signInData } = useContext(LoginContext);
  const { userId, phoneMask } = signInData;
  const { path, url } = useRouteMatch();

  const [smsHash, setSmsHash] = useState('');
  const [phoneDigits, setPhoneDigits] = useState('');

  return (
    <Switch>
      <Route path={path} exact>
        <ConfirmPhoneNumber
          userId={userId}
          phoneMask={phoneMask}
          setSmsHash={setSmsHash}
          setError={setError}
          phoneDigits={phoneDigits}
          setPhoneDigits={setPhoneDigits}
        />
      </Route>
      <Route path={`${url}/verification-code`}>
        <EnterValidateCode
          userId={userId}
          phoneDigits={phoneDigits}
          smsHash={smsHash}
          setSmsHash={setSmsHash}
          phoneMask={phoneMask}
          setError={setError}
        />
      </Route>
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

ResetViaSMS.propTypes = {
  setError: PropTypes.func,
};

export default ResetViaSMS;
