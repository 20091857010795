import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { BrandingContext, LoginContext, notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { Button, Loader } from 'components';
import { loginService } from 'services';
import styles from './ResetViaEmail.module.scss';

const ResetViaEmail = ({ setError }) => {
  const { signInData } = useContext(LoginContext);
  const { userId } = signInData;
  const { brandingData } = useContext(BrandingContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loginService.resetPasswordSendResetEmail(userId, !!brandingData)
      .then(() => notify.success('Reset link sent'))
      .catch((err) => {
        if (err.response.status === 403) {
          setError('exceeded');
        } else {
          notify.error(err.response.data || err.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [brandingData, setError, userId]);

  const backToLogin = () => history.replace('/login');

  return (
    <div className={styles.reset}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LoginHeader hideSteps title="Reset Password Email Sent" />
          <div className={styles.resetInfo}>Please check your inbox</div>
          <Button variant="primary" size="40" className={styles.resetButton} onClick={backToLogin}>Back to Login</Button>
        </>
      )}
    </div>
  );
};

ResetViaEmail.propTypes = {
  setError: PropTypes.func,
};

export default ResetViaEmail;
