import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import styles from './HeaderAction.module.scss';

const HeaderAction = ({
  selectedUsers,
  setSelectedUsers,
  onChangeStaySignedInForUsers,
  azureAdLoginData,
  isHeaderBtnLoading,
}) => {
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  const getActionType = () => {
    switch (true) {
      case selectedUsers.every((i) => !i.isAzureAdStaySignedInEnabled):
        return 'Enable Stay Signed-In';
      case selectedUsers.every((i) => i.isAzureAdStaySignedInEnabled):
        return 'Disable Stay Signed-In';
      default:
        return 'Update Stay Signed-In';
    }
  };

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{azureAdLoginData.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" id="unselect-users" size="40" onClick={() => setSelectedUsers([])}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={isHeaderBtnLoading}
          size="40"
          onClick={() => onChangeStaySignedInForUsers(true)}>
          {getActionType()}
        </Button>
      </div>
    </header>
  );
};
HeaderAction.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  onChangeStaySignedInForUsers: PropTypes.func.isRequired,
  azureAdLoginData: PropTypes.object.isRequired,
  isHeaderBtnLoading: PropTypes.bool.isRequired,
};

export default HeaderAction;
