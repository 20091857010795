import PropTypes from 'prop-types';
import { dateFormatterShort } from 'helpers';
import { Button } from 'components';
import styles from './LicensesInfo.module.scss';
import TooltipInfo from '../../../TooltipInfo/TooltipInfo';

const LicensesInfo = ({ licenses, licensesState, isUpdating, updateSubscription }) => (
  <>
    {!licenses.isSubscriptionActive && <div className={styles.totalsBackdrop} />}
    <div className={styles.totals}>
      <div className={styles.totalsSections}>
        <div className={styles.totalsSection}>
          <h4 className={styles.totalsSectionTitle}>Your next billing date</h4>
          <p className={styles.totalsSectionDate}>
            {dateFormatterShort(licenses.nextBillingDate)}
          </p>
        </div>

        <div className={styles.totalsSection}>
          <h4 className={styles.totalsSectionTitle}>Your next billing amount</h4>
          <div className={styles.totalsSectionDate}>
            <div>{(licenses.autoMonthlyTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
            <TooltipInfo
              name="billingAmount"
              content="Select <strong>View Billing Portal</strong> link below for invoice details"
              position="right"
              icon="question"
              isHtml
              className={styles.totalsSectionDateTooltip}
            />
          </div>
        </div>
      </div>
      <Button
        id="subs-primary-update"
        variant="primary"
        isLoading={isUpdating}
        isDisabled={JSON.stringify(licenses.spDomain) === JSON.stringify(licensesState.spDomain)}
        size="40"
        onClick={updateSubscription}
        style={{ width: '14em' }}
      >
        Update Subscription
      </Button>
    </div>
  </>
);

LicensesInfo.propTypes = {
  licenses: PropTypes.object,
  licensesState: PropTypes.object,
  isUpdating: PropTypes.bool,
  updateSubscription: PropTypes.func,
};

export default LicensesInfo;
