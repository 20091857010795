import PropTypes from 'prop-types';
import { Link, StatusMessage } from 'components';
import { getUserRole, UserContext } from 'utils';
import { useContext } from 'react';
import { corpAdmin, spAdmin } from 'consts';
import styles from '../../Branding.module.scss';

const BrandingNotAllowed = ({ errorType }) => {
  const { user } = useContext(UserContext);
  const role = getUserRole(user);
  const isAdmins = [spAdmin, corpAdmin].includes(role);

  return (
    <div className={styles.branding}>
      <div className={styles.brandingWrap}>
        <div className={styles.brandingHeader}>
          <h3 className={styles.brandingTitle}>Branding</h3>
        </div>
        <StatusMessage header="Company branding unavailable">
          {errorType === 'licensesNotAvaliable' ? (
            isAdmins ? (
              <>Please&nbsp;<Link to="/profile/subscription" bold>purchase license</Link>&nbsp;to proceed with Company Branding</>
            ) : (
              <>Please contact your company administrator for assistance</>
            )
          ) : (
            isAdmins ? (
              <>Please&nbsp;<Link to="/profile/subscription" bold>reactivate subscription</Link>&nbsp;to proceed with Company Branding</>
            ) : (
              <>Please contact your company administrator for assistance</>
            )
          )}
        </StatusMessage>
      </div>
    </div>
  );
};
BrandingNotAllowed.propTypes = {
  errorType: PropTypes.string,
};

export default BrandingNotAllowed;
