export const workspaceApi = {
  myDomain: {
    url: '/api/v2/Machines/DomainMachines',
    query: {
      pageNumber: 1, pageSize: 10, kind: 'Pooled', orderBy: 'hostname asc', searchQuery: '',
    },
  },
  myDomainCust: {
    url: '/api/v2/Machines/AssignedMachines',
    query: {
      pageNumber: 1, pageSize: 10, assigned: 'My', orderBy: 'hostname asc', searchQuery: '',
    },
  },
  myDomainPersonal: {
    url: '/api/v2/Machines/PersonalMachines',
    query: {
      pageNumber: 1, pageSize: 10, kind: 'All', orderBy: 'hostname asc', searchQuery: '',
    },
  },
  myDomainWithFilter: {
    url: '/api/v2/Machines/DomainMachines',
    query: {
      pageNumber: 1, pageSize: 10, kind: 'All', assigned: 'My', orderBy: 'hostname asc', searchQuery: '',
    },
  },
  myApps: {
    url: '/api/v2/MachinesApplications/GetAssignedPublishedApplications',
    query: {
      pageNumber: 1, pageSize: 10, orderBy: 'appname asc', searchQuery: '',
    },
  },
  custMachines: {
    url: '/api/v2/Machines/CustomerDomainMachines',
    query: {
      pageNumber: 1, pageSize: 10, kind: 'Pooled', orderBy: 'hostname asc', searchQuery: '',
    },
  },
  custApps: {
    url: '/api/v2/MachinesApplications/GetCustomerPublishedApplications',
    query: {
      pageNumber: 1, pageSize: 10, orderBy: 'appname asc', searchQuery: '',
    },
  },
};
