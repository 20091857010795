import { useEffect, useState } from 'react';

export const useObserver = (ref, condition, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (condition) {
      const cachedRef = ref.current;
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.intersectionRatio < 1);
      }, options);

      observer.observe(cachedRef);

      return () => observer.unobserve(cachedRef);
    }
  }, [condition, options, ref]);

  return isIntersecting;
};
