export const getDomainStatus = (domain) => {
  const { status, domainStatus } = domain;
  const stat = status || (status ?? domainStatus);

  const isDomainNotValidated = stat === 0;
  const isDomainNotActivated = stat === 1;
  const isDomainActive = stat === 2;

  return { isDomainNotValidated, isDomainNotActivated, isDomainActive };
};

export const getDomainADStatus = (domain) => {
  const { origin, domainOrigin } = domain;
  const org = origin || (domainOrigin ?? origin);

  const isDomainOnPremise = org === 1;
  const isDomainAzure = org === 2;
  const isDomainHybryd = org === 3;

  let ADStatusMessage = 'On-premises AD Activated';
  if (isDomainAzure) {
    ADStatusMessage = 'Entra ID Activated';
  }
  if (isDomainHybryd) {
    ADStatusMessage = 'Hybrid Authentication Activated';
  }
  return { isDomainOnPremise, isDomainAzure, isDomainHybryd, ADStatusMessage };
};

export const isSentryDeleted = (domain, sentries) => {
  const { isDomainOnPremise, isDomainHybryd } = getDomainADStatus(domain);
  const { isDomainActive } = getDomainStatus(domain);
  if (sentries) {
    return !sentries?.length && (isDomainOnPremise || isDomainHybryd) && isDomainActive;
  }
  return !domain.sentries?.length && (isDomainOnPremise || isDomainHybryd) && isDomainActive;
};
