import { QRCode, SetupMail, Smartphone } from 'assets/img';
import styles from '../MfaDetailsCell.module.scss';

export const mfaInfo = (mfaSetupType, userEmail, phone) => {
  let mfa;
  switch (mfaSetupType) {
    case 'email':
      mfa = (
        <span className={styles.mfaSetupTypeRow}>
          <SetupMail /> <span>{userEmail || 'N/A'}</span>
        </span>
      );
      break;
    case 'app':
      mfa = (
        <span className={styles.mfaSetupTypeRow}>
          <QRCode /> <span>{phone}</span>
        </span>
      );
      break;
    case 'sms':
      mfa = (
        <span className={styles.mfaSetupTypeRow}>
          <Smartphone /> <span>{phone}</span>
        </span>
      );
      break;
    default:
      mfa = (
        <span className={styles.mfaSetupTypeRow}>
          <strong>N/A</strong>{phone !== 'N/A' && phone}
        </span>
      );
  }
  return mfa;
};
