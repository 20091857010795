import { truncateString } from 'utils';

/**
 * Checks the action type for the selected users based on the type parameter.
 * @param {Array} selectedUsers - An array of selected user objects.
 * @param {Object} user - The user object.
 * @param {string} type - The type of action to check.
 * @returns {string} - A string indicating the action type for the selected users.
 */
export const checkActionType = (selectedUsers, user, type) => {
  if (type === 'isDesktopShortcutEnabled') {
    if (selectedUsers.length >= 1) {
      if (selectedUsers.length > 1) {
        let actionType = 'updated';
        switch (true) {
          case selectedUsers.every((i) => !i.isDesktopShortcutEnabled):
            actionType = 'enabled';
            break;
          case selectedUsers.every((i) => i.isDesktopShortcutEnabled):
            actionType = 'disabled';
            break;
          default:
            actionType = 'updated';
        }
        return `Desktop Shortcut is ${actionType} for selected users`;
      }
      const actionType = selectedUsers.every((i) => i.isDesktopShortcutEnabled) ? 'disabled' : 'enabled';
      return `Desktop Shortcut is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`;
    }
    return `Desktop Shortcut is ${user.isDesktopShortcutEnabled ? 'disabled' : 'enabled'} for ${truncateString(
      user.displayName,
      false,
    )}`;
  }
  if (type === 'isShortcutMfaRequired') {
    if (selectedUsers.length >= 1) {
      if (selectedUsers.length > 1) {
        let actionType;
        switch (true) {
          case selectedUsers.every((i) => !i.isShortcutMfaRequired):
            actionType = 'required';
            break;
          case selectedUsers.every((i) => i.isShortcutMfaRequired):
            actionType = 'not required';
            break;
          default:
            actionType = '';
        }
        return `MFA is ${actionType} for Desktop Shortcuts`;
      }
      const actionType = selectedUsers.every((i) => i.isShortcutMfaRequired) ? 'not required' : 'required';
      return `MFA is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`;
    }
    return `MFA is ${user.isShortcutMfaRequired ? 'not required' : 'required'} for ${truncateString(
      user.displayName,
      false,
    )}`;
  }
};

export const getFilterValue = (selectedShotcutStatus, selectedMfaStatus, isShowMFACodeStatusFilter) => {
  if (isShowMFACodeStatusFilter) {
    return [selectedShotcutStatus.value, selectedMfaStatus.value].join(', ');
  }
  return selectedShotcutStatus.value;
};

export const handleUserCase = (selectedUsers) => {
  const enabledShortcut = selectedUsers.every((user) => user.isDesktopShortcutEnabled && !user.isShortcutMfaRequired);
  const enabledAll = selectedUsers.every((user) => user.isDesktopShortcutEnabled && user.isShortcutMfaRequired);
  const disabledAll = selectedUsers.every((user) => !user.isDesktopShortcutEnabled && !user.isShortcutMfaRequired);
  const disabledShortcut = selectedUsers.every((user) => !user.isDesktopShortcutEnabled && user.isShortcutMfaRequired);

  if (enabledShortcut) {
    return 'enabledShortcut';
  }
  if (enabledAll) {
    return 'enabledAll';
  }
  if (disabledAll) {
    return 'disabledAll';
  }
  if (disabledShortcut) {
    return 'disabledShortcut';
  }

  return '';
};
