/* eslint-disable no-unsafe-optional-chaining */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { onChangeView } from 'utils';
import { Button, DropdownArray } from 'components';
import ManageBtnMfa from '../ManageBtnMfa/ManageBtnMfa';
import MfaInfo from '../MfaInfo/MfaInfo';
import styles from './MfaHeader.module.scss';

const MfaHeader = ({
  mfaData,
  onUpdate,
  baseUrl,
  counterRef,
  selectedUsers,
  setSelectedUsers,
  onModalOpen,
  isSticky,
  isSetupView,
  isDisabledView,
  isResetView,
  isAllView,
}) => {
  const paginationData = {
    queryConfig: mfaData?.queryConfig,
    updateAction: onUpdate,
    sortConfig: mfaData?.queryConfig?.orderBy?.split(/%20| /),
    baseUrl,
  };
  const FILTER_OPTIONS = ['All', 'MFA Enabled', 'MFA Setup Pending', 'MFA Disabled', 'MFA in Reset Mode'];

  const viewByFrmttd = mfaData.queryConfig.viewBy.split(/(?=[A-Z])/).join(' ');
  const filterValue = viewByFrmttd === 'All' ? viewByFrmttd : `MFA ${viewByFrmttd}`;
  const filterValueHtml = (
    <>
      {viewByFrmttd === 'All' ? viewByFrmttd : `MFA ${viewByFrmttd}`} <b>({mfaData.pageMeta.TotalCount})</b>
    </>
  );

  const countTotal = () => {
    if (isSetupView) {
      return mfaData?.pageMeta.TotalCount - mfaData?.data.filter((i) => i.isAdmin).length;
    }
    return mfaData?.pageMeta.TotalCount;
  };

  return (
    <header
      className={cn([styles.mfaHeader], { [styles.active]: selectedUsers.length }, { [styles.sticked]: isSticky })}
      ref={counterRef}>
      {selectedUsers.length ? (
        <div className={styles.mfaHeaderCounterActions}>
          <div className={styles.selectedCount}>
            {selectedUsers.length}/{countTotal()} Selected
          </div>
          <Button
            size="40"
            variant="secondary"
            id="unselect-users"
            onClick={() => setSelectedUsers([])}
            className={styles.deselectButton}>
            Unselect All
          </Button>
          <ManageBtnMfa
            user={false}
            isHeaderBtn
            isResetView={isResetView}
            isSetupView={isSetupView}
            isDisabledView={isDisabledView}
            selectedUsers={selectedUsers}
            onModalOpen={onModalOpen}
          />
        </div>
      ) : (
        <div className={styles.mfaHeaderFilter}>
          <DropdownArray
            id="sm-mfa-select"
            isSmall={false}
            isSearch={false}
            isFilter
            label="View by:"
            options={FILTER_OPTIONS}
            defaultValue={filterValue}
            defaultValueHtml={filterValueHtml}
            onChange={(value) => onChangeView(value, paginationData)}
            width="17.7em"
          />
          {!isAllView && <MfaInfo inHead name={viewByFrmttd} />}
        </div>
      )}
    </header>
  );
};

MfaHeader.propTypes = {
  mfaData: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  counterRef: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired,
  isSetupView: PropTypes.bool.isRequired,
  isDisabledView: PropTypes.bool.isRequired,
  isResetView: PropTypes.bool.isRequired,
  isAllView: PropTypes.bool.isRequired,
};

export default MfaHeader;
