import PropTypes from 'prop-types';
import { Button, Alert } from 'components';
import { AttentionIcon } from 'assets/img';
import styles from './AlertSentryUpdate.module.scss';

const AlertSentryUpdate = ({ customerDomain, sentries, toggleShowUpdateModal }) => {
  const outdatedSentries = customerDomain
    ? sentries
        .map((i) => i.sentries)
        .flat()
        .filter((sentry) => sentry.isOutdated).length
    : sentries.filter((sentry) => sentry.isOutdated).length;

  return (
    <div className={styles.alert}>
      <Alert type="warning" icon={<AttentionIcon />}>
        There {outdatedSentries === 1 ? 'is' : 'are'}&nbsp;<span className={styles.alertCount}>{outdatedSentries}</span>
        &nbsp;outdated Sentry software{customerDomain ? ' in Customer Domains' : ''}. Please&nbsp;
        <Button variant="link" onClick={toggleShowUpdateModal} style={{ fontWeight: 600 }}>
          update
        </Button>
        &nbsp;TruGrid {outdatedSentries === 1 ? 'Sentry' : 'Sentries'} to access the latest features
      </Alert>
    </div>
  );
};

AlertSentryUpdate.propTypes = {
  customerDomain: PropTypes.bool,
  toggleShowUpdateModal: PropTypes.func,
  sentries: PropTypes.array,
};

export default AlertSentryUpdate;
