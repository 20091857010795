import PropTypes from 'prop-types';
import { Modal } from 'components';

const ReactivateModal = ({ showReactivateModal, toggleShowReactivateModal, licenses, reactivateSubscription }) => (
  <Modal
    isOpen={showReactivateModal}
    contentLabel="Reactivate subscription"
    onRequestClose={toggleShowReactivateModal}
    actionFunc={reactivateSubscription}
    actionTitle="Confirm"
  >
    By clicking <b>CONFIRM</b> you agree to pay an outstanding
    total of <b>${licenses.reactivationCost}</b>. For invoice details select <b>View Billing Portal</b>
  </Modal>
);

ReactivateModal.propTypes = {
  showReactivateModal: PropTypes.bool,
  toggleShowReactivateModal: PropTypes.func,
  licenses: PropTypes.object,
  reactivateSubscription: PropTypes.func,
};

export default ReactivateModal;
