import PropTypes from 'prop-types';
import { Checkbox, Modal } from 'components';
import styles from './ToogleEnableModal.module.scss';

const ToogleEnableModal = ({ type, isLoading, onClose, checkedCountriesLength, setTypeEnableGeoBlocking, typeEnableGeoBlocking, onChangeGeoBlocking, dnsName }) => {
  const onCloseHandler = () => {
    if (isLoading) return false;
    onClose(type);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onCloseHandler}
      contentLabel={type ? 'Enable Geo-Blocking for domain?' : 'Disable Geo-Blocking for domain?'}
      actionLoading={isLoading}
      actionTitle={type ? 'Proceed' : 'Disable'}
      actionFunc={onChangeGeoBlocking}
    >
      {type ? (
        <ul>
          <li className={styles.checkBoxWrapper}>
            <Checkbox
              id="via-priorList"
              isCircle
              checked={typeEnableGeoBlocking === 'priorList'}
              onChange={() => setTypeEnableGeoBlocking('priorList')}
              label={<><span className={styles.checkBoxCheckboxText}>Continue with prior list</span> ({checkedCountriesLength} added {checkedCountriesLength > 1 ? 'countries' : 'country'})</>}
            />
          </li>
          <li>
            <Checkbox
              id="via-newList"
              isCircle
              checked={typeEnableGeoBlocking === 'newList'}
              onChange={() => setTypeEnableGeoBlocking('newList')}
              label={<span className={styles.checkBoxCheckboxText}>Create a new one</span>}
            />
          </li>
        </ul>
      ) : (
        <p>After disabling this option, users in <strong>{dnsName}</strong> domain will not have any login restrictions</p>
      )}
    </Modal>
  );
};

ToogleEnableModal.propTypes = {
  type: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  checkedCountriesLength: PropTypes.number,
  typeEnableGeoBlocking: PropTypes.string,
  setTypeEnableGeoBlocking: PropTypes.func,
  onChangeGeoBlocking: PropTypes.func,
  isLoading: PropTypes.bool,
  dnsName: PropTypes.string,
};

export default ToogleEnableModal;
