import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { dateFormatterShort, replaceSpecChar } from 'helpers';
import { TextInput, Loader, Button, PasswordComplexity, SwitchCheckbox } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { notify } from 'utils';
import PaymentModal from './components/PaymentModal';
import { formValidation } from './utils/formValidation';
import styles from './PersonalRegistration.module.scss';

const LONG_INPUTS_MAP = ['Enter Your Login Email Address', 'Enter New Password', 'Confirm New Password'];
const SHORT_INPUTS_MAP = ['First Name', 'Last Name'];

const PersonalRegistration = () => {
  const [registrationData, setRegistrationData] = useState();
  const [formValues, setFormValues] = useState(['', '', '', '', '', 'monthly']);
  const [formErrors, setFormErrors] = useState(['', '', '', '', '']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tooltipPassRef = useRef();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const toggleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const getPersonalRegistrationData = () => {
      loginService.getPersonalRegistrationData()
        .then(({ data }) => {
          setRegistrationData(data);
        })
        .catch((err) => notify.error(err.response.data.message || err.message, 3000))
        .finally(() => setIsLoadingPage(false));
    };
    getPersonalRegistrationData();
  }, []);

  const onInputChange = (value, index) => {
    const formValuesNew = formValues.map((i, ind) => {
      if (index === 1 || index === 2) {
        return index === ind ? value : i;
      }
      return index === ind ? value.trim() : i;
    });
    setFormValues(formValuesNew);
  };

  const onClearError = (index) => {
    if (index === 1) {
      setIsTooltipOpen(true);
    }
    if (!formErrors[index]) return false;
    const formErrorsNew = formErrors.map((i, ind) => (ind === index ? '' : i));
    setFormErrors(formErrorsNew);
  };

  const onRegister = () => {
    const isValid = formValidation(formValues, formErrors, setFormErrors);
    if (isValid) {
      setIsSubmitting(true);
      const email = replaceSpecChar(formValues[0]);
      loginService.userCanBeRegistered(email)
        .then(() => {
          setIsSubmitting(false);
          toggleShowModal();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (err.response.status === 403) {
            const formErrorsNew = [...formErrors];
            formErrorsNew[0] = 'User has been already registered';
            formErrorsNew[1] = '';
            formErrorsNew[2] = '';
            if (formErrorsNew.length) {
              setFormErrors(formErrorsNew);
            }
          } else {
            notify.error(err.response.data.message || err.message, 3000);
          }
        });
    }
  };

  return (
    <div className={styles.registation}>
      <LoginHeader title="Sign up for a TruGrid Personal account" hideBack hideSteps />
      {isLoadingPage ? (
        <Loader />
      ) : (
        <>
          {registrationData?.isPromo && (
            <div className={styles.registationPromo}>
              The promotion expires on {dateFormatterShort(registrationData?.promoEndDate)}. Please take action now
            </div>
          )}
          {LONG_INPUTS_MAP.map((item, index) => (
            <div className={styles.registationRow} key={item} ref={index === 1 ? tooltipPassRef : null}>
              <TextInput
                key={item}
                id={item.split(' ').join('-')}
                placeholder={item}
                type={[1, 2].includes(index) ? 'password' : 'text'}
                autoComplete={[1, 2].includes(index) ? 'new-password' : 'off'}
                hasView={[1, 2].includes(index)}
                value={formValues[index]}
                error={formErrors[index]}
                onChange={(e) => onInputChange(e.target.value, index)}
                onFocus={() => onClearError(index)}
                onClear={() => onInputChange('', index)}
              />
              {(index === 1 && isTooltipOpen) && (
                <PasswordComplexity inputValue={formValues[1]} />
              )}
            </div>
          ))}
          <div className={cn(styles.registationRow, styles.registationRowGroup)}>
            {SHORT_INPUTS_MAP.map((item, index) => {
              index += 3;
              return (
                <TextInput
                  key={item}
                  id={item.split(' ').join('-')}
                  autoComplete="off"
                  placeholder={item}
                  value={formValues[index]}
                  error={formErrors[index]}
                  onChange={(e) => onInputChange(e.target.value, index)}
                  onFocus={() => onClearError(index)}
                  onClear={() => onInputChange('', index)}
                />
              );
            })}
          </div>
          <div className={styles.registationCheckboxes}>
            <div className={styles.registationCheckbox}>
              <span
                className={cn(styles.registationCheckboxText, { [styles.bold]: formValues[5] === 'monthly' })}
                onClick={() => onInputChange('monthly', 5)}
              >
                Monthly
              </span>
              <SwitchCheckbox
                id="manage-plan"
                checked={formValues[5] === 'annual'}
                onChange={() => onInputChange(formValues[5] === 'monthly' ? 'annual' : 'monthly', 5)}
                className={styles.registationCheckboxSwitch}
              />
              <span
                className={cn(styles.registationCheckboxText, { [styles.bold]: formValues[5] === 'annual' })}
                onClick={() => onInputChange('annual', 5)}
              >
                Annual
              </span>
            </div>
          </div>

          {formValues[5] === 'monthly' ? (
            <div className={styles.registationInfo}>Manage and control up to <strong>{registrationData?.monthlyMachineCount} PCs</strong> at <strong>${registrationData?.monthlyPrice}</strong> per month</div>
          ) : (
            <div className={styles.registationInfo}>Manage and control up to <strong>{registrationData?.annualMachineCount} PCs</strong> at <strong>${registrationData?.annualPrice}</strong> per year</div>
          )}

          <Button
            id="login-personal-registration"
            onClick={onRegister}
            variant="primary"
            size="48"
            isLoading={isSubmitting}
            style={{ marginTop: '1.5em', width: '100%' }}
          >
            Pay and Register
          </Button>

          {isModalOpen && (
            <PaymentModal
              isModalOpen={isModalOpen}
              toggleShowModal={toggleShowModal}
              isLoading={isLoading}
              formValues={formValues}
              registrationData={registrationData}
            />
          )}
        </>
      )}
    </div>

  );
};

export default PersonalRegistration;
