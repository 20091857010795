/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, techAdmin } from 'consts';
import styles from './ExportDetailsModal.module.scss';
import CheckBoxesGroup from '../CheckBoxesGroup/CheckBoxesGroup';
import DomainsList from '../DomainsList/DomainsList';

const customAxios = axios.create({
  timeout: 10000,
});

const ExportDetailsModal = ({
  showExportDetailsModal,
  toggleExportDetailsModal,
  licenses,
  primaryDomain,
  userRole,
  setIsFastGenerate,
  isLoading,
  setIsLoading,
  saveFile,
  setLicensingReportData,
  isCompanyPage,
}) => {
  const [isMFAStatusChecked, setIsMFAStatusChecked] = useState(false);
  const [isLicenseChecked, setIsLicenseChecked] = useState(true);
  const [isADGroupChecked, setIsADGroupChecked] = useState(false);

  const primaryDomainData = licenses.find((el) => el.domainName.toLowerCase() === primaryDomain.toLowerCase());

  const [checkedDomains, setCheckedDomains] = useState([primaryDomainData.domainId]);

  const domainsForReport = licenses.filter((el) => {
    const { isDomainActive } = getDomainStatus(el);
    return isDomainActive;
  });

  const isVisibleMfaCheckbox = domainsForReport.some((item) => {
    const { isDomainOnPremise } = getDomainADStatus(item);
    return isDomainOnPremise;
  });

  const isHideDomainList = [techAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole) || !isCompanyPage;

  const toastMessage = <div><div>Your request is in progress</div><div>Please refresh this page in a few minutes</div></div>;

  const confirmResult = () => new Promise((resolve, reject) => {
    setIsLoading(true);
    const url = `/api/reports/GenerateDetailedReport?domainId=${primaryDomainData.domainId}`;
    customAxios.post(url, { domainIds: checkedDomains,
      IncludeMfaStatus: isMFAStatusChecked,
      IncludeLicenseIssueDate: isLicenseChecked,
      IncludeDirectGroup: isADGroupChecked }, {
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      setIsFastGenerate(true);
      setIsLoading(false);
      setLicensingReportData(res.data);
      toggleExportDetailsModal();
      saveFile(res.data);
      toast.success('Your report is generated', 3000);
      resolve(res);
    })
      .catch((err) => {
        if (err.name === 'AxiosError') {
          setIsFastGenerate(false);
        }
        toggleExportDetailsModal();
        toast.success(toastMessage, 3000);
        setIsLoading(false);
        reject(err);
      });
  });

  return (
    <Modal
      isOpen={showExportDetailsModal}
      onRequestClose={toggleExportDetailsModal}
      contentLabel="Generate Report"
      actionTitle="Export"
      actionDisabled={!checkedDomains.length}
      actionLoading={isLoading}
      actionFunc={confirmResult}
    >
      <div className={styles.modalContent}>
        <CheckBoxesGroup
          isMFAStatusChecked={isMFAStatusChecked}
          setIsMFAStatusChecked={setIsMFAStatusChecked}
          isLicenseChecked={isLicenseChecked}
          setIsLicenseChecked={setIsLicenseChecked}
          isADGroupChecked={isADGroupChecked}
          setIsADGroupChecked={setIsADGroupChecked}
          isVisibleMfaCheckbox={isVisibleMfaCheckbox}
        />
        <DomainsList
          licenses={domainsForReport}
          checkedDomains={checkedDomains}
          primaryDomainData={primaryDomainData}
          setNewCheckedDomains={(item) => setCheckedDomains(item)}
          isHideDomainList={isHideDomainList}
        />
      </div>
    </Modal>
  );
};

ExportDetailsModal.propTypes = {
  showExportDetailsModal: PropTypes.bool,
  toggleExportDetailsModal: PropTypes.func,
  licenses: PropTypes.array,
  userRole: PropTypes.string,
  primaryDomain: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsFastGenerate: PropTypes.func,
  setIsLoading: PropTypes.func,
  saveFile: PropTypes.func,
  setLicensingReportData: PropTypes.func,
  isCompanyPage: PropTypes.bool,
};

export default ExportDetailsModal;
