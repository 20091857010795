import PropTypes from 'prop-types';
import { Modal } from 'components';
import styles from './PreventLeaveModal.module.scss';

const PreventLeaveModal = ({ isOpen, closeModal, modalConfig, handleConfirmLeave }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel={modalConfig.title}
    cancelTitle={modalConfig.btnClose}
    cancelButtonClass={styles.modalCancel}
    actionFunc={handleConfirmLeave}
    actionTitle={modalConfig.btnAccept}
    variantBtn="deleteOutline">
    {modalConfig.message}
  </Modal>
);

PreventLeaveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    btnClose: PropTypes.string.isRequired,
    btnAccept: PropTypes.string.isRequired,
  }).isRequired,
  handleConfirmLeave: PropTypes.func.isRequired,
};

export default PreventLeaveModal;
