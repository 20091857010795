import PropTypes from 'prop-types';
import cn from 'classnames';
import { MsLogoIcon, NotAllowIcon, SuccesCircleIcon } from 'assets/img';
import { getDomainADStatus } from 'helpers';
import Button from 'components/Button/Button';
import { useContext, useState } from 'react';
import { MsalContext } from 'utils';
import { toast } from 'react-toastify';
import { corpTechAdmin, spTechAdmin } from 'consts';
import styles from '../../ActiveDirectorySetup.module.scss';

const AzureAD = ({ domainData, domain, userRole, toggleShowModal, isCompanyPage, loadInitDomainData }) => {
  const [isAzureLoading, setIsAzureLoading] = useState(false);
  const { connectAzureAD } = useContext(MsalContext);
  const { isDomainHybryd, isDomainAzure } = getDomainADStatus(domainData);
  const { dnsName: domainName } = domain;
  const { id: domainId } = domain;

  const connectToAzure = async () => {
    setIsAzureLoading(true);
    try {
      await connectAzureAD({
        scopes: ['User.Read.All', 'Group.Read.All'],
        prompt: 'select_account',
        state: JSON.stringify({ domainId, domainName, isUpdateUser: isCompanyPage }),
      });
      if (!isCompanyPage) {
        await loadInitDomainData();
      }
      setIsAzureLoading(false);
      toast.success('Successfully connected');
    } catch (err) {
      setIsAzureLoading(false);
      if (err.errorCode !== 'user_cancelled') {
        toast.error(err?.response?.data || err.message, 3000);
      }
    }
  };

  return (
    <tr className={styles.domainMgmtTableWrap}>
      <td className={styles.domainMgmtTableItem}>
        <div className={styles.domainMgmtTableItemName}>Microsoft Entra ID</div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        <div
          className={cn([styles.domainMgmtTableItemStatus], {
            [styles.notConnected]: !(isDomainAzure || isDomainHybryd),
          })}>
          {isDomainAzure || isDomainHybryd ? <SuccesCircleIcon /> : <NotAllowIcon />}
          <span>{isDomainAzure || isDomainHybryd ? 'Connected' : 'Not connected'}</span>
        </div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        {!(userRole === corpTechAdmin || (userRole === spTechAdmin && isCompanyPage)) &&
          (isDomainAzure || isDomainHybryd ? (
            <Button
              size="32"
              variant="secondary"
              icon={<MsLogoIcon />}
              onClick={() => toggleShowModal('azure')}
              className={styles.domainMgmtTableItemAzureButton}>
              Reset Entra ID
            </Button>
          ) : (
            <Button
              size="32"
              variant="secondary"
              icon={<MsLogoIcon />}
              isDisabled={isAzureLoading}
              onClick={connectToAzure}
              className={styles.domainMgmtTableItemAzureButton}>
              {isAzureLoading ? 'Connecting...' : 'Connect to Entra ID'}
            </Button>
          ))}
      </td>
    </tr>
  );
};

AzureAD.propTypes = {
  domainData: PropTypes.object,
  domain: PropTypes.object,
  userRole: PropTypes.string,
  isCompanyPage: PropTypes.bool,
  toggleShowModal: PropTypes.func,
  loadInitDomainData: PropTypes.func,
};

export default AzureAD;
