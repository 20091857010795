/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import styles from './AssignmentsList.module.scss';

const AssignmentsList = ({ assignments, assignmentsInit, searchValue, data, onToggleAll, onToggleItem }) => {
  let assignmentsNew = assignments;

  if (searchValue) {
    assignmentsNew = assignmentsNew.filter((i) => i.resourceName.toLowerCase().includes(searchValue.toLowerCase()));
    if (!assignmentsNew.length) {
      return (
        <div className={styles.list}>
          <div className={styles.listHeader} />
          <ul className={styles.listContent}>
            <li className={styles.listContentNoResults}>No results found</li>
          </ul>
        </div>
      );
    }
  }

  return (
    <div className={styles.list}>
      <div className={styles.listHeader}>
        <span className={styles.listHeaderTitle}>{`Select ${data.viewBy === 'Users' ? data.assignType : 'User'}`}</span>
        {assignments.filter((i) => i.isAssigned).length === assignments.length ? (
          <Button
            variant="link"
            id="unselect-all"
            className={styles.listHeaderButton}
            onClick={() => onToggleAll('unselect')}>
            Unselect All
          </Button>
        ) : (
          <Button
            variant="link"
            id="select-all"
            className={styles.listHeaderButton}
            onClick={() => onToggleAll('select')}>
            Select All
          </Button>
        )}
      </div>
      <ul className={styles.listContent}>
        {assignmentsNew.map((assignment, index) => {
          const isNew =
            assignmentsInit.find((i) => i.resourceName === assignment.resourceName).isAssigned !==
            assignment.isAssigned;
          return (
            <li
              key={assignment.resourceName + index}
              className={cn(styles.listContentItem, { [styles.listContentItemNew]: isNew })}>
              <Checkbox
                className={styles.listContentItemCheckbox}
                id={assignment.resourceName}
                checked={assignment.isAssigned}
                onChange={(e) => onToggleItem(assignment.resourceName, e.target.checked)}
                label={assignment.resourceName}
                labelPosition="left"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

AssignmentsList.propTypes = {
  assignments: PropTypes.array,
  assignmentsInit: PropTypes.array,
  searchValue: PropTypes.string,
  data: PropTypes.object,
  onToggleAll: PropTypes.func,
  onToggleItem: PropTypes.func,
};

export default AssignmentsList;
