import PropTypes from 'prop-types';
import { Link, StatusMessage } from 'components';

const TableEmpty = ({ isNoLicense }) => (
  <StatusMessage header="No devices reporting in" absolute={false} disabled={isNoLicense}>
    Change view or{' '}
    <Link href="https://www.trugrid.com/docs/adddevice" external bold>
      add device
    </Link>
  </StatusMessage>
);

TableEmpty.propTypes = {
  isNoLicense: PropTypes.bool,
};

export default TableEmpty;
