import axios from 'config/customAxios';

export const onChangeDomain = async (domainName, user, domains) => {
  const link =
    domainName === 'My Settings'
      ? `/api/Connectors/GetRdpSessionConfiguration?userId=${user.id}`
      : `/api/Connectors/GetRdpConfigurationForDomain?domainId=${domains[domainName].id}`;

  const response = await axios.get(link);
  const settings = domainName === 'My Settings' ? response.data.rdpConfiguration : response.data;

  return { settings, newDomainSettings: { isError: false, isModified: false, initial: settings, modified: settings } };
};
