import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import styles from './PasswordSuccessPage.module.scss';

const PasswordSuccessPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const message = state?.message;

  return (
    <>
      <LoginHeader title="Your password was successfully updated" hideBack hideSteps />
      {message && (
        <div className={styles.successMessage}>{message}</div>
      )}
      <Button
        variant="primary"
        size="40"
        style={{ width: '100%' }}
        onClick={() => history.replace('/login')}
      >
        Proceed to TruGrid
      </Button>
    </>
  );
};

export default PasswordSuccessPage;
