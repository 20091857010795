import LoginLayout from 'components/LoginLayout/LoginLayout';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <LoginLayout>
        <Component {...props} />
      </LoginLayout>
    )}
  />
);

LoginRoute.propTypes = {
  component: PropTypes.any,
};

export default LoginRoute;
