import PropTypes from 'prop-types';
import { Modal } from 'components';

const ToggleEnableModal = ({ isEnabled, showModal, handleShowModal, toggleEnablePassMgmt, isLoading }) => (
  <Modal
    isOpen={showModal}
    onRequestClose={handleShowModal}
    contentLabel={`${isEnabled ? 'Disable' : 'Enable'} Forgot Password for domain?`}
    actionFunc={toggleEnablePassMgmt}
    actionTitle={isEnabled ? 'Disable' : 'Enable'}
    actionLoading={isLoading}>
    {isEnabled
      ? "By disabling this option, end users won't be able to reset password"
      : 'By enabling this option, end users will be able to reset password'}
  </Modal>
);

ToggleEnableModal.propTypes = {
  isEnabled: PropTypes.bool,
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  toggleEnablePassMgmt: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ToggleEnableModal;
