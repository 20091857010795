import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CodeInput } from 'components';
import { LoginContext, notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import styles from './ResetViaMFA.module.scss';

const ResetViaMFA = ({ setError }) => {
  const { signInData } = useContext(LoginContext);
  const { userId } = signInData;
  const history = useHistory();

  const [codeValue, setCodeValue] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [isError, setIsError] = useState('');

  const clearError = () => setIsError(false);

  const handleSendVerificationCode = () => {
    setIsDisable(true);
    loginService.resetPasswordVerifyMfaCode(userId, codeValue)
      .then(() => {
        setIsDisable(false);
        history.replace('/login/forgot-password/set-password', { userId });
      })
      .catch((err) => {
        setIsDisable(false);
        if (err.response.status === 403) {
          setError('exceeded');
        } else if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <>
      <LoginHeader hideSteps title="Reset Your Password" />
      <div className={styles.resetMfaInfo}>Please enter the 6-digit code from your authenticator</div>
      <div className={styles.resetMfaWrap}>
        <div className={styles.resetMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={6}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isDisable}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
      </div>
    </>
  );
};

ResetViaMFA.propTypes = {
  setError: PropTypes.func,
};

export default ResetViaMFA;
