import { useContext, useEffect, useState } from 'react';
import * as signalR from 'aspnet-signalr-reactjs';
import { CodeInput } from 'components';
import { loginService } from 'services';
import { UserContext, notify } from 'utils';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import styles from './ScanQRCode.module.scss';

const ScanQRCode = () => {
  const { user, onUpdateUser } = useContext(UserContext);
  const isSetupMfaWithoutPhone = user.isMfaActive && !user.isPhoneMfaActive && !user.mustSetupMfa;

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');
  const [isDisable, setIsDisable] = useState(false);

  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError('');

  useEffect(() => {
    const connection = signalR.hubConnection();
    const contosoChatHubProxy = connection.createHubProxy('synchronizationHub');

    contosoChatHubProxy.on('onMfaSetup', (mfa) => {
      setCodeValue(mfa);
    });

    connection
      .start()
      .done(() => contosoChatHubProxy.invoke('registerClient', user.id))
      .fail(() => notify.error('SignalR connection error'));
  }, [user]);

  const handleSendVerificationCode = () => {
    setIsDisable(true);
    loginService.setMfa(codeValue, user.mfaToken)
      .then(() => {
        if (user.isPhoneMfaActive) {
          const userNew = JSON.parse(JSON.stringify(user));
          userNew.mustSetupMfa = false;
          userNew.isMfaActive = false;
          onUpdateUser(userNew);
          history.replace('/workspace');
          notify.success('Verification Successful. MFA Setup Completed');
        } else {
          history.replace(`${url}/verify-phone`);
        }
      })
      .catch((err) => {
        setIsDisable(false);
        if (err?.response?.data) {
          setIsError(err.response.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  if (isSetupMfaWithoutPhone) {
    return <Redirect to={`${url}/verify-phone`} />;
  }

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>Download the free <strong>TruGrid Authenticator App</strong> or your preferred authenticator app, then scan this QR code to set up your account</div>
      <img
        className={styles.setupMfaQrCode}
        src={`data:image/jpeg;charset=utf-8;base64,${user.mfaQrUrl}`}
        alt="application authenticator code"
      />
      <div className={styles.setupMfaInputTitle}>Enter Verification Code</div>
      <CodeInput
        fields={6}
        isError={!!isError}
        errorMessage={isError}
        clearError={clearError}
        isDisable={isDisable}
        value={codeValue}
        setValue={setCodeValue}
        handleSend={handleSendVerificationCode}
      />
    </div>
  );
};

export default ScanQRCode;
