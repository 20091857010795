import PropTypes from 'prop-types';
import { Modal } from 'components';
import { AttentionIcon } from 'assets/img';
import styles from './EnableRememberMeForUserModal.module.scss';

const EnableRememberMeForUserModal = ({
  isOpen,
  onRequestClose,
  isChangingRememberMe,
  handleConfirmEnableRememberMe,
  selectedUsers,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={<div className={styles.title}><AttentionIcon /> Enable Remember Me?</div>}
    actionTitle="Enable"
    variantBtn="delete"
    actionFunc={handleConfirmEnableRememberMe}
    actionLoading={isChangingRememberMe}
  >
    {selectedUsers.length > 1
      ? <>At least one of selected users doesn't have MFA enabled. For security reasons TruGrid does not recommend enabling <strong>Remember Me</strong> for a User without MFA</>
      : <>For security reasons TruGrid does not recommend enabling <strong>Remember Me</strong> for a User without MFA</>}
  </Modal>
);

EnableRememberMeForUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isChangingRememberMe: PropTypes.bool.isRequired,
  handleConfirmEnableRememberMe: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
};

export default EnableRememberMeForUserModal;
