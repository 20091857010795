import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Loader } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { SetupMail, Shield, Smartphone } from 'assets/img';
import { loginService } from 'services';
import { LoginContext } from 'utils';
import ResetVariant from './components/ResetVariant/ResetVariant';
import styles from './ResetPasswordChoice.module.scss';

const ResetPasswordChoice = ({ setError }) => {
  const { signInData } = useContext(LoginContext);
  const { userId } = signInData;
  const { url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState();
  const [variants, setVariants] = useState({});
  const history = useHistory();

  useEffect(() => {
    const getVariantsResetPassword = async () => {
      try {
        const { data } = await loginService.getAdPasswordReset(userId);
        setVariants(data);
        if (data.isResetByMfaAvailable) {
          setType('mfa');
        } else if (data.phoneMask) {
          setType('sms');
        } else if (data.emailMask) {
          setType('email');
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err?.response?.status === 403) {
          setError('exceeded');
        } else if (err?.response?.status === 406) {
          setError('offline');
        } else {
          toast.warning(err?.response?.data || err.message, 3000);
        }
      }
    };
    getVariantsResetPassword();
  }, [setError, userId]);

  const navigateToVariant = () => {
    history.push(`${url}/${type}`);
  };

  const isVariantOnlyOne = Object.values(variants).filter((variant) => !!variant).length === 1;
  const isVariantsExist = Object.values(variants).filter((variant) => !!variant).length > 0;

  return (
    <div className={styles.resetPass}>
      {isLoading ? (
        <Loader />
      ) : (
        isVariantsExist ? (
          <>
            <LoginHeader hideSteps title={isVariantOnlyOne ? 'Proceed with Reset Method' : 'Choose Reset Method'} />
            <div className={styles.resetPassInfo}>
              {isVariantOnlyOne ? 'Proceed with an option below for reset validation' : 'Please select your preferred option for reset validation'}
            </div>
            <ul className={styles.resetPassOptions}>
              {variants?.isResetByMfaAvailable && (
                <ResetVariant name="mfa" title="Use Authenticator" type={type} setType={setType} Icon={Shield} />
              )}
              {variants?.phoneMask && (
                <ResetVariant name="sms" title={<>Send SMS to <span>{variants?.phoneMask}</span></>} type={type} setType={setType} Icon={Smartphone} />
              )}
              {variants?.emailMask && (
                <ResetVariant name="email" title={<>Send email to <span>{variants?.emailMask}</span></>} type={type} setType={setType} Icon={SetupMail} />
              )}
            </ul>
            <Button variant="primary" size="48" className={styles.resetPassProceed} onClick={navigateToVariant}>Continue</Button>
            <div className={styles.resetPassSupport}>Need help? Contact your Administrator for assistance</div>
          </>
        ) : (
          <div className={styles.resetPassSupport}>To reset password, please contact your company administrator for assistance</div>
        )
      )}
    </div>
  );
};

ResetPasswordChoice.propTypes = {
  setError: PropTypes.func,
};

export default ResetPasswordChoice;
