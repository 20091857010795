import {
  spAdmin,
  spTechAdmin,
  spAdminNotActivated,
  techAdmin,
  spTechUser,
  custAdmin,
  custUser,
  corpAdmin,
  corpTechAdmin,
  corpTechUser,
  personalUser,
  freePersonalUser,
  trugridAdmin,
} from '../consts';

export const getUserRole = (user) => {
  switch (true) {
    case (user.roles.includes('mspadmin') && user.spApplicationWasApproved): return spAdmin;
    case user.roles.includes('spadminnotactivated'): return spAdminNotActivated;
    case (user.roles.includes('sptechadmin') && user.spApplicationWasApproved): return spTechAdmin;
    case (user.roles.includes('msptechnician') && user.canHaveCustomerElements): return techAdmin;
    case (user.roles.includes('msptechnician') && !user.canHaveCustomerElements && user.spApplicationWasApproved): return spTechUser;
    case user.roles.includes('customeradmin'): return custAdmin;
    case user.roles.includes('customeruser'): return custUser;
    case (user.roles.includes('mspadmin') && !user.spApplicationWasApproved): return corpAdmin;
    case (user.roles.includes('sptechadmin') && !user.spApplicationWasApproved): return corpTechAdmin;
    case (user.roles.includes('msptechnician') && !user.spApplicationWasApproved): return corpTechUser;
    case user.roles.includes('personaluser'): return personalUser;
    case user.roles.includes('freepersonaluser'): return freePersonalUser;
    case user.roles.includes('trugridadmin'): return trugridAdmin;
    default: return '';
  }
};
