import PropTypes from 'prop-types';
import { Modal } from 'components';

const ToggleEnableMfaModal = ({ toggleShowMfaModal, showEnableMfaModal, showDisableMfaModal, data, isLoading, onChangeMfa }) => {
  const user = data?.usersToUpdate.length > 1 ? 'selected users' : <b>{data?.usersToUpdate[0].displayName}</b>;

  const toggleMfa = () => onChangeMfa(data.type, data);

  const actionEnable = data.type === 'Enable MFA';

  return (
    <Modal
      isOpen={showEnableMfaModal || showDisableMfaModal}
      onRequestClose={() => toggleShowMfaModal(data.type)}
      contentLabel={actionEnable ? 'Enable MFA' : 'Disable MFA'}
      actionLoading={isLoading}
      actionTitle={actionEnable ? 'Enable MFA' : 'Disable MFA'}
      actionFunc={toggleMfa}
      variantBtn="primary"
    >
      <div style={{ display: 'flex' }}>
        <div className="truncate-text">Do you really want to {actionEnable ? 'enable' : 'disable'} MFA for {user}</div>?
      </div>
    </Modal>
  );
};

ToggleEnableMfaModal.propTypes = {
  toggleShowMfaModal: PropTypes.func,
  showEnableMfaModal: PropTypes.bool,
  showDisableMfaModal: PropTypes.bool,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  onChangeMfa: PropTypes.func,
};

export default ToggleEnableMfaModal;
