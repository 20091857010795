import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useContext, useState } from 'react';
import { LoginContext } from 'utils';
import ResetPasswordChoice from './pages/ResetPasswordChoice/ResetPasswordChoice';
import ResetViaEmail from './pages/ResetViaEmail/ResetViaEmail';
import ResetViaMFA from './pages/ResetViaMFA/ResetViaMFA';
import ResetViaSMS from './pages/ResetViaSMS/ResetViaSMS';
import SetNewPassword from './pages/SetNewPassword/SetNewPassword';
import ResetPasswordError from '../../components/ResetPasswordError/ResetPasswordError';

const ResetPassword = () => {
  const { signInData } = useContext(LoginContext);
  const id = signInData?.userId;

  const [error, setError] = useState('');
  const { path, url } = useRouteMatch();

  if (error) {
    return <ResetPasswordError error={error} />;
  }

  return (
    <Switch>
      <Route path={path} exact render={() => (id ? <ResetPasswordChoice setError={setError} /> : <Redirect to="/login" />)} />
      <Route path={`${url}/mfa`} render={() => (id ? <ResetViaMFA setError={setError} /> : <Redirect to="/login" />)} />
      <Route path={`${url}/sms`} render={() => (id ? <ResetViaSMS setError={setError} /> : <Redirect to="/login" />)} />
      <Route path={`${url}/email`} render={() => (id ? <ResetViaEmail setError={setError} /> : <Redirect to="/login" />)} />
      <Route
        path={`${url}/set-password/:token?`}
        render={({ location, match }) => {
          const token = match?.params?.token;
          const userId = location?.state?.userId;
          if (userId || token) {
            return (
              <SetNewPassword setError={setError} />
            );
          }
          return <Redirect to={path} />;
        }}
      />
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default ResetPassword;
