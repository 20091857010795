import { months } from '../consts/months';

export const dateFormatter = (date) =>
  new Date(Number(new Date(date)) - new Date(date).getTimezoneOffset() * 60000)
    .toLocaleDateString('en-EN', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .split('/')
    .join('-');

export const dateFormatterShort = (date) =>
  new Date(Number(new Date(date)) - new Date(date).getTimezoneOffset() * 60000).toLocaleDateString('en-EN', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

export const formattedDate = (date) => {
  if (!date) date = new Date();
  const t = new Date(date);
  const timeFormat = t.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const day = t.getDate();
  const month = months[t.getMonth()];
  const year = t.getFullYear();

  return `${month} ${day}, ${year} at ${timeFormat} UTC`;
};

export const dateFormatterUtc = (time, isLongFormat) => {
  if (!time) return '';
  time += time.indexOf('Z') !== -1 ? '' : 'Z';
  const date = new Date(time);

  const utcFullYear = date.getUTCFullYear();
  let utcMonth = isLongFormat ? months[date.getUTCMonth()] : date.getUTCMonth() + 1;
  let utcDate = date.getUTCDate();

  const hour = date.getUTCHours() === 0 ? 12 : date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours();
  const min = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
  const ampm = date.getUTCHours() < 12 ? 'AM' : 'PM';
  const utcTime = `${hour}:${min} ${ampm}`;

  if (utcMonth.toString().length < 2) utcMonth = `0${utcMonth}`;
  if (utcDate.toString().length < 2) utcDate = `0${utcDate}`;

  if (isLongFormat) {
    return `${utcMonth} ${utcDate}, ${utcFullYear} at ${utcTime} UTC`;
  }

  return `${utcMonth}-${utcDate}-${utcFullYear}, ${utcTime} UTC`;
};
