import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { useMemo, useState } from 'react';
import { Modal } from 'components';
import { notify } from 'utils';
import styles from './UpdateSentryModal.module.scss';
import { isAllChecked, onToggleAll, onToggleOne } from './utils/updateUtils';
import ModalHeader from './components/ModalHeader/ModalHeader';
import PrimaryItem from './components/PrimaryItem/PrimaryItem';
import CustomerItem from './components/CustomerItem/CustomerItem';

const UpdateSentryModal = ({ isOpen, onRequestClose, sentries, customerDomain }) => {
  const [checkedSentries, setCheckedSentries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleAll = (isChecked, items) => {
    const selectedItems = onToggleAll(isChecked, checkedSentries, items);
    setCheckedSentries(selectedItems);
  };

  const handleToggleOne = (checked, item) => {
    const selectedItems = onToggleOne(checked, item, checkedSentries);
    setCheckedSentries(selectedItems);
  };

  const onToggleMainCheckbox = () => {
    if (customerDomain) {
      const allSentries = sentries
        .map((i) => i.sentries)
        .flat()
        .filter((i) => i.isOnline);
      const isChecked = isAllChecked(checkedSentries, allSentries) === 'empty';
      handleToggleAll(isChecked, allSentries);
    } else {
      const isChecked = isAllChecked(checkedSentries, sentries) === 'empty';
      handleToggleAll(isChecked, sentries);
    }
  };
  const allSentries = useMemo(() => sentries.map((i) => i.sentries).flat(), [sentries]);
  const allChecked = isAllChecked(checkedSentries, customerDomain ? allSentries : sentries);

  const handleUpdateSentry = async () => {
    try {
      setIsLoading(true);
      const selectedIds = checkedSentries.map((i) => i.apiKey);
      await axios.post('api/sentry/UpdateSentries', selectedIds);
      notify.success(
        <>
          <div className={styles.toastTitle}>Your request is in progress</div>
          <div className={styles.toastMessage}>Please refresh this page in a few minutes</div>
        </>,
      );
      setIsLoading(false);
      onRequestClose();
    } catch (err) {
      setIsLoading(false);
      notify.error(err?.response?.data || err.message);
    }
  };

  const allCount = sentries.length;
  const customerSentries = sentries.map((i) => i.sentries).flat();
  const disableMainCheckbox = useMemo(
    () =>
      customerDomain
        ? customerSentries.filter((i) => i.isOnline).length === 0
        : sentries.filter((i) => i.isOnline).length === 0,
    [customerDomain, customerSentries, sentries],
  );
  const isButtonDisabled = checkedSentries.length === 0;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Update Sentry"
      actionFunc={handleUpdateSentry}
      actionDisabled={isButtonDisabled}
      actionLoading={isLoading}
      actionTitle="Update Now"
      enableOverflow
      stickyFooter>
      <div className={styles.modal}>
        <ModalHeader
          customerDomain={customerDomain}
          allChecked={allChecked}
          allCount={allCount}
          disableMainCheckbox={disableMainCheckbox}
          onToggleMainCheckbox={onToggleMainCheckbox}
        />
        <ul className={styles.modalList}>
          {sentries.map((item) =>
            customerDomain ? (
              <CustomerItem
                key={item.domainName}
                item={item}
                handleToggleOne={handleToggleOne}
                checkedSentries={checkedSentries}
                setCheckedSentries={setCheckedSentries}
              />
            ) : (
              <PrimaryItem
                key={item.apiKey}
                item={item}
                handleToggleOne={handleToggleOne}
                checkedSentries={checkedSentries}
              />
            ),
          )}
        </ul>
      </div>
    </Modal>
  );
};

UpdateSentryModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  sentries: PropTypes.array,
  customerDomain: PropTypes.bool,
};

export default UpdateSentryModal;
