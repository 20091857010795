import PropTypes from 'prop-types';
import { LogoIcon } from 'assets/img';
import { convertHex } from 'pages/Branding/helpers/colorsHelper';
import styles from './LoginPreview.module.scss';

const LoginPreview = ({ brandingInfo, invertedButtonColor }) => {
  const color = invertedButtonColor ? { color: '#000' } : { color: '#fff' };

  return (
    <div className={styles.preview}>
      <div className={styles.previewLogo}>
        {brandingInfo?.modified?.logo ? (
          <img
            src={`data:image/png;base64,${brandingInfo.modified.logo}`}
            className={styles.previewLogoCustom}
            width="46"
            alt=""
          />
        ) : (
          <LogoIcon className={styles.previewLogoImage} />
        )}
      </div>
      <div
        className={styles.previewHeader}
        style={
          brandingInfo?.modified?.backGroundColor
            ? { background: convertHex(brandingInfo.modified.backGroundColor?.slice(1)) }
            : {}
        }
      />
      <div className={styles.previewWrapper}>
        <div className={styles.previewWrapperTitle}>Login</div>
        <div className={styles.previewWrapperInput}>Enter your login email address</div>
        <div
          className={styles.previewWrapperButton}
          style={
            brandingInfo?.modified?.buttonColor
              ? {
                  background: convertHex(brandingInfo.modified.buttonColor?.slice(1)),
                  borderColor: convertHex(brandingInfo.modified.buttonColor?.slice(1)),
                  color: color.color,
                }
              : {}
          }>
          Continue
        </div>
      </div>
    </div>
  );
};

LoginPreview.propTypes = {
  brandingInfo: PropTypes.object,
  invertedButtonColor: PropTypes.bool,
};

export default LoginPreview;
