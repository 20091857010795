/* eslint-disable react/jsx-no-constructed-context-values */
import { useMemo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { PublicClientApplication } from '@azure/msal-browser';
import { DomainContext, MsalContext, UserContext } from 'utils';

const MsalProvider = ({ children, config }) => {
  const { onUpdateUser } = useContext(UserContext);
  const { onUpdateDomain: onUpdateSelectedDomain } = useContext(DomainContext);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const msalInstance = useMemo(() => new PublicClientApplication(config), [config]);

  const updateUser = useCallback(async () => {
    const { data } = await axios.get('/api/Account/GetInitialUserData');
    onUpdateUser(data);
  }, [onUpdateUser]);

  const changeDomainStatus = useCallback(
    ({ domainOrigin, domainStatus }) => {
      onUpdateSelectedDomain((domain) => ({
        ...domain,
        status: domainStatus,
        origin: domainOrigin,
      }));
    },
    [onUpdateSelectedDomain],
  );

  const connectToAzureAd = useCallback(
    async ({ domainId, domainName, isUpdateUser }, username, authToken, tenantId) => {
      if (domainName.toLowerCase() === username.split('@')[1].toLowerCase()) {
        setIsLoading(true);
        const { data } = await axios.post('/api/DomainManagement/ConnectToAzureAd', { authToken, domainId, tenantId });
        if (authToken) setToken({ [domainId]: authToken });
        if (isUpdateUser) {
          return updateUser();
        }
        return changeDomainStatus(data);
      }
      setIsLoading(false);
      return Promise.reject(new Error(`Please use account with ${domainName} domain`));
    },
    [changeDomainStatus, updateUser],
  );

  const connectAzureAD = useCallback(
    async (apiRequest) => {
      const { state, tenantId, accessToken, account } = await msalInstance.loginPopup(apiRequest);
      await connectToAzureAd(JSON.parse(state), account.username, accessToken, tenantId);
      setIsLoading(false);
    },
    [connectToAzureAd, msalInstance],
  );

  const disconnectAzureAD = useCallback(
    async (isCompanyPage, domainId) => {
      setIsLoading(true);
      const { data } = await axios.post('/api/DomainManagement/DisconnectAzureAd', { domainId });
      if (isCompanyPage) {
        updateUser();
      } else {
        changeDomainStatus(data);
      }
      setIsLoading(false);
    },
    [changeDomainStatus, updateUser],
  );

  const getTokenSilent = useCallback(
    async (domainId, domainName, callback) => {
      const account = msalInstance.getAllAccounts().filter((acc) => acc.username.split('@')[1] === domainName);
      if (account.length) {
        return msalInstance
          .acquireTokenSilent({
            scopes: ['User.Read.All', 'Group.Read.All'],
            account: account[0],
            forceRefresh: false,
          })
          .then((accessTokenResponse) => {
            const { accessToken } = accessTokenResponse;
            callback(domainId, accessToken);
          })
          .catch((error) => {
            if (
              error.errorCode === 'consent_required' ||
              error.errorCode === 'interaction_required' ||
              error.errorCode === 'login_required' ||
              error.errorCode === 'invalid_grant'
            ) {
              return msalInstance
                .acquireTokenPopup({
                  scopes: ['User.Read.All', 'Group.Read.All'],
                  prompt: 'select_account',
                  state: JSON.stringify({ domainId, domainName, isUpdateUser: false }),
                })
                .then((accessTokenResponse) => {
                  const { accessToken } = accessTokenResponse;
                  callback(domainId, accessToken);
                })
                .catch((err) => Promise.reject(new Error(err)));
            }
            return Promise.reject(new Error(error));
          });
      }
      return msalInstance
        .loginPopup({
          scopes: ['User.Read.All', 'Group.Read.All'],
          prompt: 'select_account',
          state: JSON.stringify({ domainId, domainName, isUpdateUser: false }),
        })
        .then((res) => {
          if (domainName.toLowerCase() === res.account.username.split('@')[1].toLowerCase()) {
            callback(domainId, res.accessToken);
          } else {
            return Promise.reject(new Error(`Please use account with ${domainName} domain`));
          }
        });
    },
    [msalInstance],
  );

  const getDomainToken = (domainId, domainName, callback) =>
    new Promise((resolve, reject) => {
      const loginRequest = {
        scopes: ['User.Read.All', 'Group.Read.All'],
        prompt: 'select_account',
        state: JSON.stringify({ domainId, domainName, isUpdateUser: true }),
      };
      msalInstance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          if (domainName.toLowerCase() === resp.account.username.split('@')[1].toLowerCase()) {
            callback(domainId, resp.accessToken);
          } else {
            return Promise.reject(new Error(`Please use account with ${domainName} domain`));
          }
        })
        .catch((err) => reject(err));
    });

  return (
    <MsalContext.Provider
      value={{
        token,
        connectAzureAD,
        isLoading,
        getTokenSilent,
        getDomainToken,
        disconnectAzureAD,
        changeDomainStatus,
        updateUser,
      }}>
      {children}
    </MsalContext.Provider>
  );
};

MsalProvider.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.any,
};

export default MsalProvider;
