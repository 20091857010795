import { Checkbox } from 'components';
import PropTypes from 'prop-types';
import styles from './CheckBoxesGroup.module.scss';

const CheckBoxesGroup = ({
  isMFAStatusChecked,
  setIsMFAStatusChecked,
  isLicenseChecked,
  setIsLicenseChecked,
  isADGroupChecked,
  setIsADGroupChecked,
  isVisibleMfaCheckbox,
}) => {
  const checkboxes = [
    {
      checked: true,
      isDisabled: true,
      label: 'UPN',
      show: true,
    }, {
      checked: isMFAStatusChecked,
      label: 'MFA Status',
      onChange: () => setIsMFAStatusChecked(!isMFAStatusChecked),
      show: isVisibleMfaCheckbox,
    }, {
      checked: isLicenseChecked,
      label: 'License issued date',
      onChange: () => setIsLicenseChecked(!isLicenseChecked),
      show: true,
    }, {
      checked: isADGroupChecked,
      label: 'AD Group Membership',
      onChange: () => setIsADGroupChecked(!isADGroupChecked),
      show: true,
    },
  ];
  return (
    <div className={styles.list}>
      <div className={styles.listColums}>Columns</div>
      <div className={styles.listContainer}>
        {checkboxes.map(({ checked, isDisabled, label, show, onChange }) => (
          show && (
            <div key={label} className={styles.listContainerItem}>
              <Checkbox
                className={styles.checkBoxWrapperCheckbox}
                checked={checked}
                isDisabled={isDisabled}
                label={label}
                onChange={onChange}
              />
            </div>
          )
        ))}
      </div>
    </div>
  );
};
CheckBoxesGroup.propTypes = {
  isMFAStatusChecked: PropTypes.bool,
  setIsMFAStatusChecked: PropTypes.func,
  isLicenseChecked: PropTypes.bool,
  setIsLicenseChecked: PropTypes.func,
  isADGroupChecked: PropTypes.bool,
  setIsADGroupChecked: PropTypes.func,
  isVisibleMfaCheckbox: PropTypes.bool,
};

export default CheckBoxesGroup;
