import PropTypes from 'prop-types';
import { CodeInput, ResendCode, Link } from 'components';
import { useTimer } from 'hooks';
import { useState } from 'react';
import { notify } from 'utils';
import { loginService } from 'services';
import { useHistory } from 'react-router-dom';
import styles from '../ResetViaEmail.module.scss';

const SendCode = ({ pinHash, setPinHash, emailMask, isAdmin }) => {
  const [codeValue, setCodeValue] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [isError, setIsError] = useState('');

  const history = useHistory();

  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = () => {
    setIsDisable(true);
    loginService.requestVerificationCodeByEmail()
      .then(({ data }) => {
        setPinHash(data.pinHash);
        setIsDisable(false);
        resetCountdown(60);
        notify.success('Email has been sent');
      })
      .catch((err) => {
        setIsDisable(false);
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/login');
        } else {
          notify.error(err?.response?.data || err.message);
        }
      });
  };

  const handleSendVerificationCode = () => {
    setIsDisable(true);
    loginService.resetMfaWithPin(codeValue, pinHash)
      .then(() => {
        notify.success('MFA has been successfully reset');
        setIsDisable(false);
        history.replace('/login');
      })
      .catch((err) => {
        setIsDisable(false);
        if (err?.response?.status === 405) {
          notify.error(err?.response?.data);
          history.replace('/login');
        }
        if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (

    <div className={styles.validateEmailForm}>
      <div className={styles.validateEmailInfo}>
        We've sent a verification code to<br /><strong>{emailMask}</strong>
      </div>
      <div>
        <div className={styles.validateEmailLabel}>Enter Verification Code</div>
        <CodeInput
          fields={6}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isDisable}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.validateEmailResend}>
          <ResendCode countDown={timeLeft} count={60} handleSend={resendVerificationCode} isDisabled={isDisable} />
        </div>
      </div>
      {isAdmin ? (
        <div className={styles.validateEmailInfo}>
          Email is unavailable?&nbsp;
          <Link href="https://www.trugrid.com/contact-us/" bold external>Contact Support</Link>
        </div>
      ) : (
        <div className={styles.validateEmailInfo}>
          Email is unavailable? Contact your Administrator for assistance
        </div>
      )}
    </div>
  );
};

SendCode.propTypes = {
  pinHash: PropTypes.string,
  setPinHash: PropTypes.func,
  emailMask: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default SendCode;
