import PropTypes from 'prop-types';
import { StatusMessage, SwitchCheckbox, Table } from 'components';
import styles from './TimeoutTable.module.scss';

const TimeoutTable = ({
  timeoutData,
  setTimeoutData,
  baseUrl,
  btnLoadingCurrent,
  selectedUsers,
  setSelectedUsers,
  onChangeTimeoutForUsers,
}) => {
  const columns = [
    {
      checkbox: true,
      name: 'User',
      data: ({ displayName, upn }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Session Timeout',
      data: (user) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeTimeoutForUsers(false, user)}
            checked={user.isDesktopTimeoutEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isDesktopTimeoutEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isDesktopTimeoutEnabled',
      width: '60%',
    },
  ];

  return timeoutData?.data?.users.length < 1 && !timeoutData?.queryConfig?.searchQuery ? (
    <div className={styles.statusMessage}>
      <StatusMessage header="No items available">Change view selection</StatusMessage>
    </div>
  ) : (
    <Table
      data={{ data: timeoutData.data.users, pageMeta: timeoutData.pageMeta, queryConfig: timeoutData.queryConfig }}
      updateData={setTimeoutData}
      columns={columns}
      id="timeout-me-table"
      dataKey="userId"
      baseUrl={baseUrl}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      className={styles.table}
    />
  );
};

TimeoutTable.propTypes = {
  timeoutData: PropTypes.object,
  setTimeoutData: PropTypes.func,
  baseUrl: PropTypes.string,
  btnLoadingCurrent: PropTypes.array,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  onChangeTimeoutForUsers: PropTypes.func,
};

export default TimeoutTable;
