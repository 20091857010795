import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';

const EnableMFACodeModal = ({ isOpen, onRequestClose, onToggleMfaRequiredForUser, user }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDisableMfaRequiredForUser = async () => {
    setIsLoading(true);
    await onToggleMfaRequiredForUser(!!Array.isArray(user), 'enable');
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Require MFA Code?"
      actionFunc={handleDisableMfaRequiredForUser}
      actionTitle="Confirm"
      variantBtn="primary"
      actionLoading={isLoading}>
      "Requiring MFA Code" will automatically enable MFA for selected users who do not already have MFA?
    </Modal>
  );
};

EnableMFACodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onToggleMfaRequiredForUser: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object], [PropTypes.array]),
};

export default EnableMFACodeModal;
