import PropTypes from 'prop-types';
import { ExternalLinkIcon, InfoActiveIcon } from 'assets/img';
import { Link } from 'components';
import styles from './LicensesLinks.module.scss';
import TooltipInfo from '../../../TooltipInfo/TooltipInfo';

const LicensesLinks = ({ licenses, showAllocationMessage }) => (
  <div>
    <div className={styles.links}>
      <div className={styles.linksItem}>
        <TooltipInfo
          name="updatePayment"
          content="Select to update payment methods"
          position="bottom"
          icon="info"
          isHtml={false}
        />
        <Link
          className={styles.linksItemLink}
          href={licenses.updatePaymentMethodLink}
          id="update-payment-method-link"
          external
          bold>
          Update payment details <ExternalLinkIcon />
        </Link>
      </div>
      <div className={styles.linksItem}>
        <TooltipInfo
          name="viewBilling"
          content="Select to view invoices"
          position="bottom"
          icon="info"
          isHtml={false}
        />
        <Link className={styles.linksItemLink} href={licenses.billingPortalLink} id="view-billing-portal" external bold>
          View Billing Portal <ExternalLinkIcon />
        </Link>
      </div>
    </div>
    {showAllocationMessage && (
      <div className={styles.linksInfo}>
        <InfoActiveIcon width="1em" height="1em" />
        Please adjust your licenses before you update your payment method
      </div>
    )}
  </div>
);

LicensesLinks.propTypes = {
  licenses: PropTypes.object,
  showAllocationMessage: PropTypes.bool,
};

export default LicensesLinks;
