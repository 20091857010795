import PropTypes from 'prop-types';
import cn from 'classnames';
import { WorkspaceIcon } from 'assets/img';
import Link from 'components/Link/Link';
import styles from '../NavbarItem.module.scss';

const WorkspaceItems = ({
  location,
  onNotCompanyClick,
  isBrandingActive,
  isInvertedColors,
  blockLeftMenu,
  brandingData,
}) => {
  const color = isInvertedColors ? { color: '#000' } : { color: '#fff' };
  return (
    <li
      className={cn(styles.item, {
        [styles.active]: location.pathname === '/workspace',
        [styles.inverted]: isBrandingActive && isInvertedColors,
        [styles.branding]: isBrandingActive && !isInvertedColors,
        [styles.itemDisabled]: blockLeftMenu,
      })}
      data-tip="Desktops, Apps and Resources"
      data-iscapture="true"
      onClick={onNotCompanyClick}>
      <span style={isBrandingActive && brandingData?.backGroundColor ? { background: color.color } : {}} />
      <Link to="/workspace" id="my-workspace" className={styles.itemLink}>
        <WorkspaceIcon /> My Workspace
      </Link>
    </li>
  );
};
WorkspaceItems.propTypes = {
  location: PropTypes.object,
  onNotCompanyClick: PropTypes.func,
  isBrandingActive: PropTypes.bool,
  isInvertedColors: PropTypes.bool,
  blockLeftMenu: PropTypes.bool,
  brandingData: PropTypes.object,
};

export default WorkspaceItems;
