import PropTypes from 'prop-types';
import { TriangleFilledIcon } from 'assets/img';
import { Alert, Link } from 'components';
import { corpAdmin, spAdmin } from 'consts';

const SuspenededMessage = ({ userRole }) => (
  <Alert icon={<TriangleFilledIcon />} absolute>
    {[spAdmin, corpAdmin].includes(userRole) ? (
      <>Subscription suspended. Go to&nbsp;<Link to="/profile/subscription" bold>Subscription Page</Link>&nbsp;to reactivate subscription</>
    ) : (
      'Subscription suspended. Please contact your company administrator for assistance'
    )}
  </Alert>
);

SuspenededMessage.propTypes = {
  userRole: PropTypes.string,
};

export default SuspenededMessage;
