import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'config/customAxios';
import { Modal, Loader } from 'components';
import { truncateString } from 'utils';
import ModalAssigmentsList from './components/ModalAssignmentsList/ModalAssignmentsList';
import EmptyAssigments from './components/EmptyAssigments/EmptyAssigments';
import styles from './ManageAssignmentsModal.module.scss';

const ManageAssignmentsModal = ({ isOpen, data, onUpdate, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [assignments, setAssignments] = useState(null);
  const [assignmentsInit, setAssignmentsInit] = useState(null);
  const isUpdateDisabled =
    isUpdating ||
    assignments?.map((i) => i.isAssigned).toString() === assignmentsInit?.map((i) => i.isAssigned).toString();

  useEffect(() => {
    axios
      .get(`/api/MSPCustomerAssignments/GetDirectCustomerDomainsAssignments?userId=${data.userId}`)
      .then((resp) => {
        setAssignments(resp.data);
        setAssignmentsInit(resp.data.map((i) => ({ ...i })));
      })
      .catch((err) => setAssignments({ isError: true, message: err.message }))
      .finally(() => setIsLoading(false));
  }, [data.userId]);

  const onUpdateAssignments = () => {
    setIsUpdating(true);
    axios
      .put(`/api/MSPCustomerAssignments/UpdateCustomerDomainsAssignments?userId=${data.userId}`, assignments)
      .then((resp) => {
        onUpdate(data.userId, resp.data);
        toast.success('Assignments updated', 3000);
      })
      .catch((err) => toast.error(err?.response?.data || err.message, 3000))
      .finally(() => {
        setIsUpdating(false);
        onClose();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Manage Assignments"
      contentSubtitle={
        data.isMfaEnabled && (
          <>
            for <b>{data.name || 'N/A'}</b>
          </>
        )
      }
      actionFunc={onUpdateAssignments}
      actionDisabled={isUpdateDisabled}
      actionLoading={isUpdating}
      actionTitle="Confirm">
      <div className={styles.assigmentsModal}>
        {isLoading ? (
          <Loader />
        ) : assignments?.length ? (
          <>
            {!data.isMfaEnabled && (
              <div className={styles.mfaEnabled}>
                Promoting users to Admin will automatically enable MFA. By clicking <b>CONFIRM</b>, you will enable MFA
                for <b>{truncateString(data.name, false) || 'N/A'}</b>
              </div>
            )}
            <ModalAssigmentsList
              assignments={assignments}
              setAssignments={setAssignments}
              assignmentsInit={assignmentsInit}
              isMfaEnabled={data.isMfaEnabled}
            />
          </>
        ) : (
          <EmptyAssigments />
        )}
      </div>
    </Modal>
  );
};

ManageAssignmentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageAssignmentsModal;
