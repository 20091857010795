/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { uuidv4 } from 'utils';
import { LoaderSmallIcon } from 'assets/img';
import styles from './CodeInput.module.scss';

const ENTER_KEY = 13;
const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

const CodeInput = ({ fields = 6, value, setValue, errorMessage, isError, clearError, autoFocus = true, isDisable, handleSend, sendOnKeyDown }) => {
  const [input, setInput] = useState([]);
  const uuid = uuidv4();
  const textInput = [];
  const firstInputRef = useRef(null);

  useEffect(() => {
    if (value.length === fields && typeof handleSend === 'function' && !sendOnKeyDown) {
      handleSend(value);
    }
  }, [value]);

  useEffect(() => {
    if (isError) {
      for (let i = 0; i < Number(fields); i += 1) {
        setInput((oldArray) => oldArray.map(() => ''));
      }
      firstInputRef.current.focus();
    }
  }, [isError]);

  useEffect(() => {
    if (input.length < 1) {
      for (let i = 0; i < Number(fields); i += 1) {
        setInput((oldArray) => [...oldArray, value[i] || '']);
      }
    }
  }, []);

  const handleSendOnKeyDown = () => {
    if (sendOnKeyDown && typeof handleSend === 'function' && value.length === fields) {
      handleSend(value);
    }
  };

  const handleChangeInput = (e) => {
    if (isError) clearError();

    let val = e.target.value;
    val = val.replace(/[^\d]/g, '');

    if (val !== '') {
      const newInput = input.slice();

      if (val.length > 1) {
        val.split('').forEach((chart, i) => {
          if (i < fields) {
            newInput[i] = chart;
          }
          return false;
        });
      } else {
        newInput[Number(e.target.dataset.id)] = val;
      }

      newInput.map((s, i) => {
        if (textInput[i]) {
          textInput[i].value = s;
        }
        return false;
      });

      const newTarget = textInput[e.target.dataset.id < input.length ? Number(e.target.dataset.id) + 1 : e.target.dataset.id];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      setValue(newInput.join(''));
      setInput(newInput);
    }
  };

  const handleKeyDown = (e) => {
    const target = Number(e.target.dataset.id);
    const nextTarget = textInput[target + 1];
    const prevTarget = textInput[target - 1];

    let newInput;
    let val;

    switch (e.keyCode) {
      case ENTER_KEY:
        e.preventDefault();
        handleSendOnKeyDown();
        break;

      case BACKSPACE_KEY:
        e.preventDefault();
        textInput[target].value = '';
        newInput = input.slice();
        newInput[target] = '';
        val = newInput.join('');

        setInput(newInput);
        setValue(val);

        if (textInput[target].value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }

        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY:
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className={styles.codeInputWrap}>
        {input.map((val, i) => (
          <input
            ref={(ref) => {
              textInput[i] = ref;
              if (i === 0) {
                firstInputRef.current = ref;
              }
            }}
            id={`${uuid}-${i}`}
            key={`input_${i}`}
            data-id={i}
            min={1}
            max={9}
            maxLength={input.length === i + 1 ? 1 : input.length}
            value={val}
            autoFocus={!!(autoFocus && i === 0)}
            className={cn(styles.codeInput, { [styles.disabled]: isDisable, [styles.error]: isError })}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
          />
        ))}
        {isDisable && (
          <div className={styles.codeInputLoader}><LoaderSmallIcon /></div>
        )}
      </div>
      {isError && (
        <div className={styles.codeInputError}>{errorMessage || 'The code you entered is invalid'}</div>
      )}
    </div>
  );
};

CodeInput.propTypes = {
  fields: PropTypes.number,
  value: PropTypes.string,
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  clearError: PropTypes.func,
  autoFocus: PropTypes.bool,
  isDisable: PropTypes.bool,
  handleSend: PropTypes.func,
  sendOnKeyDown: PropTypes.bool,
};

export default CodeInput;
