/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axios from 'config/customAxios';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import { queryFormatter, notify, onChangeView } from 'utils';
import { getDomainADStatus } from 'helpers';
import ReactTooltip from 'react-tooltip';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import NoUsers from './components/NoUsers/NoUsers';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ShortcutMgmtStatus from './components/ShortcutMgmtStatus/ShortcutMgmtStatus';
import ShortcutMgmtTable from './components/ShortcutMgmtTable/ShortcutMgmtTable';
import ShortcutMgmtHeader from './components/ShortcutMgmtHeader/ShortcutMgmtHeader';
import ShortcutMgmtTitle from './components/ShortcutMgmtTitle/ShortcutMgmtTitle';
import { checkActionType } from './utils/shortcutUtils';
import ToggleMfaRequiredModal from './components/ToggleMfaRequiredModal/ToggleMfaRequiredModal';
import ShortcutFilters from './components/ShortcutFilters/ShortcutFilters';
import DisableMFACodeModal from './components/DisableMFACodeModal/DisableMFACodeModal';
import EnableMFACodeModal from './components/EnableMFACodeModal/EnableMFACodeModal';
import styles from './ShortcutMgmt.module.scss';

const ShortcutMgmt = ({ domain, isCompanyPage }) => {
  const [shortcutData, setShortcutData] = useState(null);
  const [isShortcutEnabled, setIsShortcutEnabled] = useState(false);
  const [isShortcutMfaRequired, setIsShortcutMfaRequired] = useState(false);
  const [isNoUsers, setIsNoUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState([]);
  const [isHeaderBtnLoading, setIsHeaderBtnLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isShowToggleEnableModal, setIsShowToggleEnableModal] = useState(false);
  const toggleShowEnableModal = () => setIsShowToggleEnableModal(!isShowToggleEnableModal);

  const [isShowToggleMfaRequiredModal, setIsShowToggleMfaRequiredModal] = useState(false);
  const toggleShowToggleMfaRequiredModal = () => setIsShowToggleMfaRequiredModal(!isShowToggleMfaRequiredModal);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isShowDisableMFACodeModal, setIsShowDisableMFACodeModal] = useState(false);
  const toggleShowDisableMFACodeModal = () => setIsShowDisableMFACodeModal(!isShowDisableMFACodeModal);

  const [isShowEnableMFACodeModal, setIsShowEnableMFACodeModal] = useState(false);
  const toggleShowEnableMFACodeModal = () => setIsShowEnableMFACodeModal(!isShowEnableMFACodeModal);

  const baseUrl = `/api/SecurityManagement/DesktopShortcut/Get?domainId=${domain.id}`;

  const { isDomainOnPremise } = getDomainADStatus(domain);

  const paginationData = {
    queryConfig: shortcutData?.queryConfig,
    updateAction: setShortcutData,
    baseUrl,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let { desktopShortcutState, isShortcutMfaRequired: shortcutMfaRequired } = shortcutData?.queryConfig || {};
  if (shortcutMfaRequired === null) {
    shortcutMfaRequired = true;
  }
  shortcutMfaRequired = shortcutMfaRequired ? 0 : 1;

  const fetchData = async (queryConfig) => {
    try {
      const resp = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;
      if (resp.status === 204) {
        setIsNoUsers(true);
      }
      setShortcutData({ data: resp.data, pageMeta, queryConfig });
      setIsShortcutEnabled(resp.data.isShortcutEnabled);
      setIsShortcutMfaRequired(resp.data.isShortcutMfaRequired);
      setIsLoading(false);
    } catch (err) {
      notify.error(err?.response?.data || err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: '',
      orderBy: 'displayName asc',
      isShortcutMfaRequired: null,
      desktopShortcutState: 0,
    };
    fetchData(queryConfig);
  }, []);

  const onEnableShortcut = async (enableForAllUsers, expirationDays) => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
        isShortcutMfaRequired: null,
        desktopShortcutState: 0,
      };
      const url = `/api/SecurityManagement/DesktopShortcut/ChangeDomainStatus?domainId=${domain.id}&enableForAllUsers=${enableForAllUsers}&expirationDays=${expirationDays}`;
      await axios.post(url);
      await fetchData(queryConfig);
      notify.success('Desktop Shortcut is enabled for domain', 3000);
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      toggleShowEnableModal();
    }
  };

  const onDisableShortcut = async () => {
    try {
      const url = `/api/SecurityManagement/DesktopShortcut/ChangeDomainStatus?domainId=${domain.id}`;
      await axios.post(url);
      setShortcutData((prevData) => ({
        ...prevData,
        data: {
          ...prevData.data,
          pageMeta: null,
        },
      }));
      setIsShortcutEnabled(false);
      notify.success('Desktop Shortcut is disabled for domain', 3000);
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      toggleShowEnableModal();
    }
  };

  const onChangeShortcutForUser = async (isHeaderBtn, user, type, actionType, extra = false) => {
    try {
      if (isHeaderBtn) {
        if (actionType === 'enabledAll' && type === 'isShortcutMfaRequired') {
          setSelectedUser(selectedUsers);
          return toggleShowDisableMFACodeModal();
        }
        const isLeastOneUserHasMfaDisabled = selectedUsers.some((item) => item.isUserMfaDisabled);
        if (isLeastOneUserHasMfaDisabled && type === 'isShortcutMfaRequired') {
          setSelectedUser(selectedUsers);
          return toggleShowEnableMFACodeModal();
        }
        const selectedUserIds = selectedUsers.map((item) => item.userId);
        setIsHeaderBtnLoading(true);
        const url =
          type === 'isDesktopShortcutEnabled'
            ? '/api/SecurityManagement/DesktopShortcut/ChangeUserStatus'
            : '/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers';
        await axios.post(url, selectedUserIds);
        await fetchData(shortcutData.queryConfig);
        if (selectedUsers.length) setSelectedUsers([]);
        const notifyText = checkActionType(selectedUsers, user, extra ? 'isDesktopShortcutEnabled' : type);
        notify.success(notifyText);
        setIsHeaderBtnLoading(false);
      }
      if (!isHeaderBtn) {
        if (user.isDesktopShortcutEnabled && user.isShortcutMfaRequired && type === 'isShortcutMfaRequired') {
          setSelectedUser(user);
          return toggleShowDisableMFACodeModal();
        }
        if (user.isUserMfaDisabled && type === 'isShortcutMfaRequired') {
          setSelectedUser(user);
          return toggleShowEnableMFACodeModal();
        }
        setBtnLoadingCurrent((itemCurrent) => [...itemCurrent, user.userId]);
        const url =
          type === 'isDesktopShortcutEnabled'
            ? '/api/SecurityManagement/DesktopShortcut/ChangeUserStatus'
            : '/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers';
        await axios.post(url, [user.userId]);
        await fetchData(shortcutData.queryConfig);
        if (selectedUsers.length) setSelectedUsers([]);
        const notifyText = checkActionType(selectedUsers, user, type);
        notify.success(notifyText);
        setBtnLoadingCurrent((item) => item.filter((i) => i !== user.userId));
      }
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    }
  };

  const onChangeShortcutForDomain = (enableForAllUsers, expirationDays) => {
    if (isShortcutEnabled) {
      onDisableShortcut();
    } else {
      onEnableShortcut(enableForAllUsers, expirationDays);
    }
  };

  const onChangeMfaRequiredForDomain = async () => {
    try {
      await axios.post(`api/SecurityManagement/DesktopShortcut/ChangeMfaRequiredForDmain?domainId=${domain.id}`);
      const actionType = !isShortcutMfaRequired;
      setShortcutData((prevData) => ({
        ...prevData,
        isShortcutMfaRequired: !prevData.isShortcutMfaRequired,
        data: {
          ...prevData.data,
          users: prevData.data.users.map((item) => ({
            ...item,
            isShortcutMfaRequired: item.isDesktopShortcutEnabled ? actionType : item.isShortcutMfaRequired,
          })),
        },
      }));
      setIsShortcutMfaRequired((prevData) => !prevData);
      notify.success(
        actionType ? 'MFA is required for Desktop Shortcuts' : 'MFA is not required for Desktop Shortcuts',
      );
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    }
  };

  const onToggleMfaRequiredForUser = async (massUpdate, typeAction) => {
    try {
      if (massUpdate) {
        setIsHeaderBtnLoading(true);
        const selectedUserIds = selectedUsers.map((item) => item.userId);
        const isEnableShortcutWithMfa = selectedUsers.every((i) => !i.isDesktopShortcutEnabled);
        await axios.post('/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers', selectedUserIds);
        await fetchData(shortcutData.queryConfig);
        if (selectedUsers.length) setSelectedUsers([]);
        setBtnLoadingCurrent((item) => item.filter((i) => i !== selectedUser.userId));
        const notifyText = checkActionType(
          selectedUsers,
          selectedUser,
          isEnableShortcutWithMfa ? 'isDesktopShortcutEnabled' : 'isShortcutMfaRequired',
        );
        notify.success(notifyText);
        setIsHeaderBtnLoading(false);
      }
      if (!massUpdate) {
        const selectedUserIds = [selectedUser.userId];
        await axios.post('/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers', selectedUserIds);
        await fetchData(shortcutData.queryConfig);
        const notifyText = checkActionType(selectedUsers, selectedUser, 'isShortcutMfaRequired');
        notify.success(notifyText);
      }
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      if (typeAction === 'disable') {
        toggleShowDisableMFACodeModal();
      }
      if (typeAction === 'enable') {
        toggleShowEnableMFACodeModal();
      }
      setSelectedUser(null);
    }
  };

  return (
    <div className={styles.shortcut}>
      <ShortcutMgmtTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader />
      ) : isNoUsers ? (
        <NoUsers />
      ) : (
        <>
          <ShortcutMgmtStatus
            shortcutData={shortcutData}
            toggleShowEnableModal={toggleShowEnableModal}
            toggleShowToggleMfaRequiredModal={toggleShowToggleMfaRequiredModal}
            isShortcutEnabled={isShortcutEnabled}
            isShortcutMfaRequired={isShortcutMfaRequired}
            isDomainOnPremise={isDomainOnPremise}
            selectedUsers={selectedUsers}
          />
          {selectedUsers.length ? (
            <HeaderActions
              shortcutData={shortcutData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              onChangeShortcutForUser={onChangeShortcutForUser}
              isHeaderBtnLoading={isHeaderBtnLoading}
              isDomainOnPremise={isDomainOnPremise}
              isShortcutMfaRequired={isShortcutMfaRequired}
            />
          ) : (
            isShortcutEnabled && (
              <ShortcutFilters
                id="shortcut-filters"
                filterOptions={[desktopShortcutState, shortcutMfaRequired]}
                onFilter={(value) => onChangeView(value, paginationData)}
                isDomainOnPremise={isDomainOnPremise}
              />
            )
          )}
          {isShortcutEnabled ? (
            <ShortcutMgmtTable
              shortcutData={shortcutData}
              setShortcutData={setShortcutData}
              baseUrl={baseUrl}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeShortcutForUser={onChangeShortcutForUser}
              isDomainOnPremise={isDomainOnPremise}
            />
          ) : (
            <ShortcutMgmtHeader />
          )}

          {isShowToggleEnableModal && (
            <ToggleEnableModal
              isOpen={isShowToggleEnableModal}
              onRequestClose={toggleShowEnableModal}
              isShortcutEnabled={isShortcutEnabled}
              onChangeShortcutForDomain={onChangeShortcutForDomain}
            />
          )}

          {isShowToggleMfaRequiredModal && (
            <ToggleMfaRequiredModal
              isOpen={isShowToggleMfaRequiredModal}
              onRequestClose={toggleShowToggleMfaRequiredModal}
              isShortcutMfaRequired={isShortcutMfaRequired}
              onChangeMfaRequiredForDomain={onChangeMfaRequiredForDomain}
            />
          )}

          {isShowDisableMFACodeModal && (
            <DisableMFACodeModal
              isOpen={isShowDisableMFACodeModal}
              onRequestClose={toggleShowDisableMFACodeModal}
              onToggleMfaRequiredForUser={onToggleMfaRequiredForUser}
              user={selectedUser}
            />
          )}

          {isShowEnableMFACodeModal && (
            <EnableMFACodeModal
              isOpen={isShowEnableMFACodeModal}
              onRequestClose={toggleShowEnableMFACodeModal}
              onToggleMfaRequiredForUser={onToggleMfaRequiredForUser}
              user={selectedUser}
            />
          )}
        </>
      )}
      <ReactTooltip
        className={styles.infoTooltip}
        id="info-tooltip"
        type="light"
        place="bottom"
        offset={{ top: 0, left: 120 }}
        effect="solid"
      />
    </div>
  );
};

ShortcutMgmt.propTypes = {
  domain: PropTypes.object.isRequired,
  isCompanyPage: PropTypes.bool,
};

export default ShortcutMgmt;
