/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Loader, StatusMessage, Table } from 'components';
import { queryFormatter, onChangePage } from 'utils';
import ReactTooltip from 'react-tooltip';
import NoLicensesMessage from '../NoLicensesMessage/NoLicensesMessage';
import styles from './Encryptions.module.scss';
import DevicesSearchFilter from '../DeviceMgmtDevices/components/DevicesSearchFilter/DevicesSearchFilter';
import { TableColumns } from './components/TableColumns';
import EncryptDetailsModal from './components/EncryptDetailsModal/EncyptDetailsModal';
import EncryptPassModal from './components/EncryptPassModal/EncryptPassModal';
import TableEmpty from '../DeviceMgmtDevices/components/TableEmpty/TableEmpty';

const Encryptions = ({
  encrypts,
  baseUrl,
  isNoLicense,
  onUpdate,
  devicesWithoutLicenses,
  isVisibleNoLicensesMsg,
  isCustTechAdmin,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState('');
  const [isInfoActive, setIsInfoActive] = useState('');
  const [encryptDetailsModalShow, setEncryptDetailsModalShow] = useState(false);
  const [encryptPassModalShow, setEncryptPassModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  const sortConfig = encrypts?.queryConfig.orderBy.split(/%20| /);

  const paginationData = {
    queryConfig: encrypts?.queryConfig,
    updateAction: onUpdate,
    sortConfig,
    baseUrl,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (encrypts?.queryConfig?.searchQuery) {
      setIsLoading(true);
      const query = { ...encrypts.queryConfig, searchQuery: '' };
      onChangePage([queryFormatter(baseUrl, query), query], paginationData).then(() => setIsLoading(false));
    }
  }, []);

  const onUpdateDevice = (deviceNew) => {
    const index = encrypts.data.findIndex((item) => item.deviceId === deviceNew.deviceId);
    const updatedData = encrypts.data;
    updatedData[index] = deviceNew;
    onUpdate({
      data: updatedData,
      pageMeta: encrypts?.pageMeta,
      queryConfig: encrypts?.queryConfig,
    });
  };

  const onUpdateEncryption = (data, encrypt) => {
    const encryptStateNew = {
      ...encrypt,
      encryptionStatus: data.encryptionStatus,
      isLockedDrive: data.isLockedDrive,
    };
    onUpdateDevice(encryptStateNew);
  };

  const changeEncryptStatus = (id, status) => {
    const index = encrypts.data.findIndex((item) => item.deviceId === id);
    const updatedData = encrypts.data;
    updatedData[index].encryptionStatus = status;
    onUpdate({
      data: updatedData,
      pageMeta: encrypts?.pageMeta,
      queryConfig: encrypts?.queryConfig,
    });
  };

  const toggleEncryptDetailsModalShow = () => {
    setEncryptDetailsModalShow(!encryptDetailsModalShow);
  };

  const openEncryptPassModalShow = () => {
    setEncryptPassModalShow(true);
  };

  const closeEncryptPassModalShow = (status) => {
    setEncryptPassModalShow(false);
    setIsStatusLoading(false);
    setModalData(null);
    if (status) {
      changeEncryptStatus(modalData.encrypt.deviceId, modalData.encrypt.encryptionStatus);
    }
  };

  const onOpenEncryptDetails = (deviceId, hostname) => {
    setIsDetailsLoading(deviceId);

    axios
      .get(`/api/WindowsDrives/Get?deviceId=${deviceId}`)
      .then((resp) => {
        setModalData({
          hostname,
          discs: resp.data,
          deviceId,
        });
        toggleEncryptDetailsModalShow();
      })
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => setIsDetailsLoading(''));
  };

  const onToggleSwitch = (isChecked, encrypt) => {
    const { deviceId, isTpm } = encrypt;
    changeEncryptStatus(encrypt.deviceId, isChecked ? 2 : 3);
    setIsStatusLoading(deviceId);

    const url = isChecked ? '/api/IoTAgent/Encrypt' : '/api/IoTAgent/Decrypt';

    if (isTpm && isChecked) {
      axios
        .get(`/api/WindowsDrives/Get?deviceId=${deviceId}`)
        .then((resp) => {
          const isFewDiscs = resp.data.filter((i) => !i.name?.includes('System')).length;
          if (isFewDiscs) {
            setModalData({ encrypt });
            openEncryptPassModalShow();
          } else {
            return axios.put(url, { deviceId });
          }
        })
        .then((resp) => resp && onUpdateEncryption(resp.data, encrypt))
        .catch((err) => {
          toast.error(err.response?.data.message || err.message, 3000);
          changeEncryptStatus(encrypt.deviceId, encrypt.encryptionStatus);
        })
        .finally(() => setIsStatusLoading(null));
    } else if (isChecked) {
      setModalData({ encrypt });
      openEncryptPassModalShow();
    } else {
      axios
        .put(url, { deviceId })
        .then((resp) => onUpdateEncryption(resp.data, encrypt))
        .catch((err) => {
          toast.error(err.response?.data.message || err.message, 3000);
          changeEncryptStatus(encrypt.deviceId, encrypt.encryptionStatus);
        })
        .finally(() => setIsStatusLoading(null));
    }
  };

  const isDeviceWithoutLicense = ({ hasLicense }) =>
    cn({
      [styles.encryptsTableRowUnlicensed]: !hasLicense,
    });

  if (isLoading) {
    return <Loader />;
  }

  if (encrypts.isError) {
    return <StatusMessage error>{encrypts.error}</StatusMessage>;
  }

  return (
    <div className={styles.encrypts}>
      {isNoLicense && <div className={styles.encryptsTabBackdrop} />}
      {!!devicesWithoutLicenses && isVisibleNoLicensesMsg && (
        <NoLicensesMessage devicesWithoutLicenses={devicesWithoutLicenses} isCustTechAdmin={isCustTechAdmin} />
      )}
      <DevicesSearchFilter devices={encrypts} paginationData={paginationData} isEncryptions />

      <Table
        columns={TableColumns(
          isInfoActive,
          setIsInfoActive,
          isStatusLoading,
          onToggleSwitch,
          onOpenEncryptDetails,
          isDetailsLoading,
        )}
        data={encrypts}
        updateData={onUpdate}
        id="dm-encryption-table"
        noData={<TableEmpty isNoLicense={isNoLicense} />}
        dataKey="deviceId"
        baseUrl={baseUrl}
        className={styles.encryptsTable}
        classNameRow={(item) => isDeviceWithoutLicense(item)}
      />

      <ReactTooltip id="info-tooltip" type="light" effect="solid" delayShow={200} />

      {encryptDetailsModalShow && (
        <EncryptDetailsModal
          encryptDetailsModalShow={encryptDetailsModalShow}
          toggleEncryptDetailsModalShow={toggleEncryptDetailsModalShow}
          modalData={modalData}
        />
      )}
      {encryptPassModalShow && (
        <EncryptPassModal
          encryptPassModalShow={encryptPassModalShow}
          openEncryptPassModalShow={openEncryptPassModalShow}
          closeEncryptPassModalShow={closeEncryptPassModalShow}
          onUpdate={onUpdateEncryption}
          modalData={modalData}
        />
      )}
    </div>
  );
};

Encryptions.propTypes = {
  encrypts: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  isNoLicense: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  devicesWithoutLicenses: PropTypes.number,
  isVisibleNoLicensesMsg: PropTypes.bool,
  isCustTechAdmin: PropTypes.bool,
};

export default Encryptions;
