import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import { useOutsideClick } from 'hooks';
import { LoaderSmallIcon, FilterIcon } from 'assets/img';
import styles from './DevicesFilter.module.scss';

const DEVICE_STATUSES = ['All Devices', 'Licensed', 'Unlicensed'];
const INSTALL_STATUSES = ['All Statuses', 'Installed', 'Pending Uninstall', 'Uninstalled'];

const DevicesFilter = ({ id, filterOptions, onFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDevStatus, setSelectedDevStatus] = useState(filterOptions[0]);
  const [selectedInsStatus, setSelectedInsStatus] = useState(filterOptions[1]);
  const devicesFilterRef = useRef();
  const nodeRef = useRef();
  const filterValue = [selectedDevStatus, selectedInsStatus].join(', ');
  const isDisabledClear = selectedDevStatus === 'All Devices' && selectedInsStatus === 'All Statuses';
  const isDisabledDone = selectedDevStatus === filterOptions[0] && selectedInsStatus === filterOptions[1];

  const onClose = () => {
    setSelectedDevStatus(filterOptions[0]);
    setSelectedInsStatus(filterOptions[1]);
    setIsOpen(false);
  };

  const onClear = (e) => {
    e.target.blur();
    setSelectedDevStatus('All Devices');
    setSelectedInsStatus('All Statuses');
  };

  const onFilterHandler = () => {
    setIsLoading(true);
    onFilter([selectedDevStatus, selectedInsStatus]).then(() => setIsLoading(false));
    setIsOpen(false);
  };

  useOutsideClick(devicesFilterRef, () => isOpen && onClose());

  return (
    <div className={styles.devicesFilter} ref={devicesFilterRef}>
      <button
        type="button"
        id={id}
        className={cn(styles.devicesFilterButton, { [styles.devicesFilterButtonDisabled]: isLoading })}
        onClick={() => (isOpen ? onClose() : setIsOpen(true))}>
        <div className={cn(styles.devicesFilterSelect, { [styles.devicesFilterSelectActive]: isOpen })}>
          {isLoading ? <LoaderSmallIcon /> : <FilterIcon />}
          <span className={styles.devicesFilterValue}>{filterValue}</span>
        </div>
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.devicesFilterOptionsTransitionEnter,
          enterActive: styles.devicesFilterOptionsTransitionEnterActive,
          exit: styles.devicesFilterOptionsTransitionExit,
          exitActive: styles.devicesFilterOptionsTransitionExitActive,
        }}>
        <div className={styles.devicesFilterOptions} ref={nodeRef}>
          <div className={styles.devicesFilterOptionsHeader}>Device Status</div>
          <ul className={styles.devicesFilterOptionsList}>
            {DEVICE_STATUSES.map((status) => (
              <li key={status} className={styles.devicesFilterOptionsListItem}>
                <Checkbox
                  id={status}
                  label={status}
                  checked={selectedDevStatus === status}
                  isCircle
                  onChange={() => setSelectedDevStatus(status)}
                />
              </li>
            ))}
          </ul>

          <div className={cn(styles.devicesFilterOptionsHeader, styles.devicesFilterOptionsHeaderStatus)}>
            Installation Status
          </div>
          <ul className={styles.devicesFilterOptionsList}>
            {INSTALL_STATUSES.map((status) => (
              <li key={status} className={styles.devicesFilterOptionsListItem}>
                <Checkbox
                  id={status}
                  label={status}
                  checked={selectedInsStatus === status}
                  isCircle
                  onChange={() => setSelectedInsStatus(status)}
                />
              </li>
            ))}
          </ul>

          <div className={styles.devicesFilterOptionsActions}>
            <Button
              id="devices-filter-clear"
              type="button"
              variant="secondary"
              size="32"
              isDisabled={isDisabledClear}
              style={{ width: '48%' }}
              onClick={onClear}>
              Clear
            </Button>
            <Button
              id="devices-filter-done"
              type="button"
              variant="primary"
              size="32"
              isDisabled={isDisabledDone}
              style={{ width: '48%' }}
              onClick={onFilterHandler}>
              Done
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

DevicesFilter.propTypes = {
  id: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default DevicesFilter;
