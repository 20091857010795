import PropTypes from 'prop-types';
import cn from 'classnames';
import { ExclamationIcon, MinusIcon, PlusIcon } from 'assets/img';
import { Button } from 'components';
import styles from './InputControlsCustomer.module.scss';

const InputControlsCustomer = ({ warning, setWarning, licensesState, setLicensesState, initDomain, typeFunc, id, user }) => {
  const onIncreasingLicenses = (domainId, licenseType) => {
    const licensesStateNew = JSON.parse(JSON.stringify(licensesState));
    const licenseTypeAllocatedTotal = `totalAllocated${licenseType.slice(8)}`;

    if (licensesStateNew.spDomain[licenseType] > 0) {
      licensesStateNew[licenseTypeAllocatedTotal] += 1;
      licensesStateNew.spDomain[licenseType] -= 1;
      licensesStateNew.customerDomains.forEach((domain) => {
        if (domain.domainId === domainId) domain[licenseType] += 1;
      });

      setLicensesState(licensesStateNew);
    } else if (!warning.isActive) {
      const content = user.roles.includes('mspadmin')
        ? <><ExclamationIcon />All available licenses have been allocated. Select&nbsp;<b>Primary Domain</b>&nbsp;tab to purchase more licenses</>
        : <><ExclamationIcon />You have exceeded the number of available licenses. Contact your company administrator for assistance</>;

      setWarning({
        isActive: true,
        field: licenseType,
        content,
      });
      setTimeout(() => setWarning({ isActive: false }), 3000);
    }
  };

  const onDecreasingLicenses = (domainId, licenseType) => {
    const licensesStateNew = JSON.parse(JSON.stringify(licensesState));
    const licenseTypeAllocatedTotal = `totalAllocated${licenseType.slice(8)}`;
    const currentDomain = licensesStateNew.customerDomains.find((d) => d.domainId === domainId);

    if (currentDomain[licenseType] > 0) {
      licensesStateNew[licenseTypeAllocatedTotal] -= 1;
      licensesStateNew.spDomain[licenseType] += 1;
      licensesStateNew.customerDomains.forEach((domain) => {
        if (domain.domainId === domainId) domain[licenseType] -= 1;
      });

      setLicensesState(licensesStateNew);
    }
  };

  const onInputChangeLicenses = (domainId, licenseType, isValid, value) => {
    const licenseTypePurchasedTotal = `totalPurchased${licenseType.slice(8)}`;
    const licenseTypeAllocatedTotal = `totalAllocated${licenseType.slice(8)}`;
    const currentDomainVal = Number(licensesState.customerDomains.find((d) => d.domainId === domainId)[licenseType]);
    const isAllowedValue = +value + licensesState[licenseTypeAllocatedTotal] - currentDomainVal <= licensesState[licenseTypePurchasedTotal];

    if (isValid) {
      if (isAllowedValue) {
        const licensesStateNew = JSON.parse(JSON.stringify(licensesState));

        licensesStateNew[licenseTypeAllocatedTotal] += +value - currentDomainVal;
        licensesStateNew.spDomain[licenseType] += currentDomainVal - +value;
        licensesStateNew.customerDomains.forEach((domain) => {
          if (domain.domainId === domainId) domain[licenseType] = value === '' ? value : +value;
        });

        setLicensesState(licensesStateNew);
      } else {
        let totalAllocatedByType = 0;
        licensesState.customerDomains.forEach((d) => {
          if (d.domainId !== domainId) totalAllocatedByType += d[licenseType];
        });

        if (!warning.isActive) {
          setWarning({
            isActive: true,
            field: licenseType,
            content: <><ExclamationIcon />Maximum possible value: {licensesState[licenseTypePurchasedTotal] - totalAllocatedByType}</>,
          });
          setTimeout(() => setWarning({ isActive: false }), 3000);
        }
      }
    }
  };

  return (
    <div className={styles.controls}>
      <Button variant="link" className={styles.controlsButton} id="decreasing-licenses-count" onClick={() => onDecreasingLicenses(initDomain.domainId, typeFunc)}>
        <MinusIcon />
      </Button>
      <span className={styles.controlsInputWrap}>
        <input
          id={id}
          type="text"
          pattern="[0-9]*"
          maxLength="7"
          className={cn(styles.controlsInput, { [styles.controlsInputWarning]: warning.isActive && warning.field.includes(typeFunc) })}
          value={initDomain[typeFunc]}
          onChange={(e) => onInputChangeLicenses(initDomain.domainId, typeFunc, e.target.validity.valid, e.target.value)}
          onBlur={() => initDomain.numberOfRdpLicenses === '' && onInputChangeLicenses(initDomain.domainId, typeFunc, true, 0)}
          onKeyUp={(e) => e.key === 'Enter' && e.target.blur()}
        />
      </span>
      <Button variant="link" className={styles.controlsButton} id="increasing-licenses-count" onClick={() => onIncreasingLicenses(initDomain.domainId, typeFunc)}>
        <PlusIcon />
      </Button>
    </div>
  );
};

InputControlsCustomer.propTypes = {
  initDomain: PropTypes.object,
  licensesState: PropTypes.object,
  setLicensesState: PropTypes.func,
  warning: PropTypes.object,
  setWarning: PropTypes.func,
  typeFunc: PropTypes.string,
  id: PropTypes.string,
  user: PropTypes.object,
};

export default InputControlsCustomer;
