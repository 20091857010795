/* eslint-disable array-callback-return */
import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Checkbox, SearchInput } from 'components';
import styles from './DomainsList.module.scss';

const DomainsList = ({ licenses, primaryDomainData, checkedDomains, setNewCheckedDomains, isHideDomainList }) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [inputText, setInputText] = useState('');

  const inputHandler = (e) => {
    const lowerCase = e.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = licenses.filter((el) => {
    if (inputText === '') return el;
    return el.domainName.toLowerCase().includes(inputText);
  });

  const selectAllToggle = () => {
    const newList = [...checkedDomains];
    if (licenses.length === newList.length) {
      newList.splice(0, newList.length);
      setSelectedAll(false);
    } else {
      licenses.map((domain) => {
        const index = newList.findIndex((item) => item === domain.domainId);
        if (index === -1) {
          return newList.push(domain.domainId);
        }
      });
      setSelectedAll(true);
    }
    setNewCheckedDomains(newList);
  };

  const checkDomain = (el) => {
    const newList = [...checkedDomains];
    const index = newList.findIndex((item) => item === el.domainId);
    if (index === -1) {
      newList.push(el.domainId);
      if (licenses.length === newList.length) {
        setSelectedAll(true);
      }
    } else {
      newList.splice(index, 1);
      setSelectedAll(false);
    }
    setNewCheckedDomains(newList.sort());
  };

  const isOnlyOneDomain = licenses.length === 1;

  return (
    <div className={styles.domainList}>
      {isHideDomainList ? (
        <>
          <div className={styles.domainListTitle}>Domain</div>
          <div className={cn(styles.domainListPrimary, 'truncate-text')}>{primaryDomainData.domainName}</div>
        </>
      ) : (
        <>
          {!isOnlyOneDomain && (
            <SearchInput
              id="search-by-domain"
              isNotDebounce
              fullWidth
              placeholder="Find Domains"
              onChange={inputHandler}
              className={styles.domainListSearch}
              inputClassName={styles.domainListSearch}
            />
          )}
          {filteredData.length ? (
            <>
              <div className={styles.domainListRow}>
                <div className={styles.domainListTitle}>{isOnlyOneDomain ? 'Domain' : 'Domains'}</div>
                {!isOnlyOneDomain && (
                  <Button
                    type="button"
                    id="domain-toggle-all"
                    variant="link"
                    onClick={selectAllToggle}
                    className={styles.domainListButton}>
                    {selectedAll ? 'Unselect all' : 'Select all'}
                  </Button>
                )}
              </div>
              <div className={cn(styles.domainListWrap, { [styles.short]: isOnlyOneDomain })}>
                <ul className={styles.domainListList}>
                  {filteredData
                    .filter((item) => primaryDomainData === item)
                    .map((domain) =>
                      isOnlyOneDomain ? (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <span
                            style={{ display: 'block' }}
                            className={cn(styles.domainListListItemCheckbox)}>{`${domain.domainName} (Primary)`}</span>
                        </li>
                      ) : (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <Checkbox
                            className={cn(styles.domainListListItemCheckbox)}
                            id={domain.domainId}
                            label={`${domain.domainName} (Primary)`}
                            labelPosition="left"
                            checked={checkedDomains.includes(domain.domainId)}
                            onChange={() => checkDomain(domain)}
                          />
                        </li>
                      ),
                    )}
                  {filteredData
                    .filter((item) => primaryDomainData !== item && licenses.includes(item))
                    .map((domain) =>
                      isOnlyOneDomain ? (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <span style={{ display: 'block' }} className={cn(styles.domainListListItemCheckbox)}>
                            {domain.domainName}
                          </span>
                        </li>
                      ) : (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <Checkbox
                            className={cn(styles.domainListListItemCheckbox)}
                            id={domain.domainId}
                            label={domain.domainName}
                            labelPosition="left"
                            checked={checkedDomains.includes(domain.domainId)}
                            onChange={() => checkDomain(domain)}
                          />
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </>
          ) : (
            <div className={styles.domainListNoResults}>
              <p>No results found</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
DomainsList.propTypes = {
  licenses: PropTypes.array,
  primaryDomainData: PropTypes.object,
  checkedDomains: PropTypes.array,
  setNewCheckedDomains: PropTypes.func,
  isHideDomainList: PropTypes.bool,
};

export default DomainsList;
