import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from 'components/Layout/Layout';
import { mustSetupMfa, mustSetupPhone } from 'helpers';
import { UserContext } from 'utils';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);

  if (!user) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to="/login" />
        )}
      />
    );
  }

  if (mustSetupMfa(user)) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to="/setup-mfa" />
        )}
      />
    );
  }

  if (mustSetupPhone(user)) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to="/setup-phone" />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
