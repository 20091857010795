import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Modal } from 'components';
import { notify } from 'utils';

const ToggleEnableModal = ({
  showEnableModal,
  onRequestClose,
  domain,
  fetchData,
  setAzureAdLoginData,
  isAzureAdLoginEnabled,
  setIsAzureAdLoginEnabled,
}) => {
  const [isEnableForAll, setIsEnableForAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toogleSetIsEnableForAll = () => {
    setIsEnableForAll((val) => !val);
  };

  const onEnableStaySignin = async () => {
    const url = `/api/SecurityManagement/AzureAdLogin/ChangeDomainStatus?domainId=${domain.id}&enableForAllUsers=${isEnableForAll}`;
    try {
      await axios.post(url);
      await fetchData();
      notify.success('Stay Signed-In is enabled for domain');
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      setIsLoading(false);
      onRequestClose();
    }
  };

  const onDisableStaySignin = async () => {
    try {
      const url = `/api/SecurityManagement/AzureAdLogin/ChangeDomainStatus?domainId=${domain.id}`;
      await axios.post(url);
      setIsAzureAdLoginEnabled(false);
      setAzureAdLoginData({});
      notify.success('Stay Signed-In is disabled for domain');
    } catch (err) {
      notify.error(err?.response?.data || err.message);
    } finally {
      setIsLoading(false);
      onRequestClose();
    }
  };

  const onChangeStaySignedIn = async () => {
    setIsLoading(true);
    if (isAzureAdLoginEnabled) {
      await onDisableStaySignin();
    } else {
      await onEnableStaySignin();
    }
  };

  return (
    <Modal
      isOpen={showEnableModal}
      contentLabel={isAzureAdLoginEnabled ? 'Disable Stay Signed-In for domain?' : 'Enable Stay Signed-In for domain?'}
      onRequestClose={onRequestClose}
      actionLoading={isLoading}
      actionFunc={onChangeStaySignedIn}
      actionTitle={isAzureAdLoginEnabled ? 'Disable' : 'Enable'}
      isSwitcherChecked={isEnableForAll}
      onChangeSwitcher={toogleSetIsEnableForAll}
      switcherTitle={!isAzureAdLoginEnabled ? 'Enable for all users' : ''}>
      {!isAzureAdLoginEnabled ? (
        <>
          By enabling this option, Entra ID users will be able to reduce sign in frequency while using TruGrid Desktop
          Connectors
        </>
      ) : (
        <>
          By disabling this option, Entra ID users will be required to sign in every time they open TruGrid Desktop
          Connectors
        </>
      )}
    </Modal>
  );
};

ToggleEnableModal.propTypes = {
  showEnableModal: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  domain: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  setAzureAdLoginData: PropTypes.func.isRequired,
  isAzureAdLoginEnabled: PropTypes.bool.isRequired,
  setIsAzureAdLoginEnabled: PropTypes.func.isRequired,
};

export default ToggleEnableModal;
