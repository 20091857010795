export const addCustomResolutions = (tabsContent, data) => {
  if (data.desktopHeight && data.desktopWidth) {
    const dimensionsColl = tabsContent.general.dimension.collection;
    const customResPresentKey = Object.values(dimensionsColl).findIndex((i) => i[1].includes('custom'));
    const customResKey = customResPresentKey ? customResPresentKey + 1 : Object.keys(dimensionsColl).length;
    const customResVal = `${data.desktopWidth} x ${data.desktopHeight} (custom)`;
    const dimensionsCollNew = {
      ...dimensionsColl,
      [customResKey]: [`${customResKey} `, customResVal],
      [customResKey + 1]: ['customResLink', 'Change custom resolution'],
    };

    const tabsContentNew = JSON.parse(JSON.stringify(tabsContent));
    tabsContentNew.general.dimension.collection = dimensionsCollNew;

    return tabsContentNew;
  }

  const dimensionsColl = tabsContent.general.dimension.collection;
  const customResPresentKey = Object.keys(dimensionsColl).find((key) => dimensionsColl[key].includes('(custom)') && key);

  if (customResPresentKey) {
    const tabsContentNew = JSON.parse(JSON.stringify(tabsContent));

    delete tabsContentNew.general.dimension.collection[customResPresentKey];
    return tabsContentNew;
  }

  return tabsContent;
};
