/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { useHistory } from 'react-router-dom';
import SendCode from './components/SendCode';
import styles from './ResetViaEmail.module.scss';

const ResetViaEmail = ({ emailMask, isAdmin }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pinHash, setPinHash] = useState('');
  const history = useHistory();

  useEffect(() => {
    loginService.requestVerificationCodeByEmail()
      .then(({ data }) => {
        setPinHash(data.pinHash);
        notify.success('Email has been sent');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/login');
        } else {
          notify.error(err?.response?.data || err.message);
        }
      });
  }, []);

  return (
    <>
      <LoginHeader
        title="Reset Your MFA"
        hideSteps
      />
      {isLoading ? (
        <div className={styles.resetMfa}>
          <Loader />
        </div>
      ) : (
        <SendCode
          pinHash={pinHash}
          setPinHash={setPinHash}
          emailMask={emailMask}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};

ResetViaEmail.propTypes = {
  emailMask: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default ResetViaEmail;
