import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { DomainContext } from 'utils';

const DomainProvider = ({ children }) => {
  const [domains, setDomains] = useState(null);
  const [selectedCustDomain, setSelectedCustDomain] = useState(null);

  const domainValue = useMemo(() => ({
    domains,
    onUpdateDomains: setDomains,
    domain: selectedCustDomain,
    onUpdateDomain: setSelectedCustDomain,
  }), [domains, selectedCustDomain]);

  return (
    <DomainContext.Provider value={domainValue}>
      {children}
    </DomainContext.Provider>
  );
};

DomainProvider.propTypes = {
  children: PropTypes.node,
};

export default DomainProvider;
