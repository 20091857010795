import { memo } from 'react';
import PropTypes from 'prop-types';
import { SearchInput, DropdownArray } from 'components';
import { onChangeView, onSearch } from 'utils';
import { toast } from 'react-toastify';
import styles from './WorkspaceHeader.module.scss';

const WorkspaceHeader = memo(({
  isShowAll,
  isError,
  baseUrl,
  myDomainPage,
  queryConfig,
  totalCount,
  searchBy,
  allValue,
  onUpdate,
  isEmptyPage,
  isEmptyScreen,
  hasSecureConnects,
}) => {
  const filterValue = myDomainPage === 'myDomainWithFilter'
    ? `${queryConfig.assigned} Computers`
    : `${queryConfig.kind} Computers`;
  const filterValueHtml = myDomainPage === 'myDomainWithFilter'
    ? <>{queryConfig.assigned} Computers <b>({totalCount})</b></>
    : <>{queryConfig.kind} Computers <b>({totalCount})</b></>;
  const filterOptions = myDomainPage === 'myDomainWithFilter'
    ? ['My Computers', 'All Computers']
    : ['Pooled Computers', 'NonPooled Computers', 'All Computers'];
  const paginationData = {
    isKindField: myDomainPage !== 'myDomainWithFilter',
    updateAction: onUpdate,
    errorAction: toast.error,
    queryConfig,
    baseUrl,
  };

  return (
    <div className={styles.header}>
      {isShowAll ? (
        !isEmptyPage && (
          <div className={styles.headerTotal}>
            {allValue}
            <span className={styles.headerTotalCounter}> ({totalCount})</span>
          </div>
        )
      ) : (
        hasSecureConnects && (
          <DropdownArray
            id="workspace-select"
            isSmall={false}
            isSearch={false}
            isFilter
            isDisabled={isError}
            strightRight
            label="View by:"
            options={filterOptions}
            defaultValue={filterValue}
            defaultValueHtml={filterValueHtml}
            onChange={(value) => onChangeView(value, paginationData)}
            width="17.9em"
          />
        )
      )}
      {!isEmptyScreen && (
        <SearchInput
          id="ws-search"
          searchBy={searchBy}
          strightLeft={!isShowAll}
          onChange={(value) => onSearch(value, paginationData)}
        />
      )}
    </div>
  );
});

WorkspaceHeader.propTypes = {
  isShowAll: PropTypes.bool,
  isError: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  myDomainPage: PropTypes.string,
  queryConfig: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  searchBy: PropTypes.string.isRequired,
  allValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  isEmptyPage: PropTypes.bool,
  isEmptyScreen: PropTypes.bool,
  hasSecureConnects: PropTypes.bool,
};

WorkspaceHeader.displayName = 'WorkspaceHeader';

export default WorkspaceHeader;
