import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader, DomainStatusMsg, ADStatus } from 'components';
import { queryFormatter } from 'utils';
import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin, workspaceApi } from 'consts';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import AlertSentryUpdate from 'pages/Workspace/components/AlertSentryUpdate/AlertSentryUpdate';
import WorkspaceDomains from './components/WorkspaceDomains';
import WorkspaceApps from './components/WorkspaceApps';
import NoComputersMsg from './components/NoComputersMsg';
import styles from './CustomersWorkspace.module.scss';

const CustomersWorkspace = ({ domain, userRole, onUpdateUser, showSentryUpdateMessageForAdAdmins }) => {
  const [loadingCurrent, setLoadingCurrent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [custMachines, setCustMachines] = useState(null);
  const [custApps, setCustApps] = useState(null);

  const sentryDeleted = isSentryDeleted(domain);
  const { isDomainAzure } = getDomainADStatus(domain);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowUpdateModal = () => setShowUpdateModal(!showUpdateModal);

  useEffect(() => {
    const { id, machinesCount, applicationsCount, isAppPublishingEnabled } = domain;
    let fetchData = [
      {
        stateAction: setCustMachines,
        url: workspaceApi.custMachines.url,
        queryConfig: {
          ...workspaceApi.custMachines.query,
          domainId: id,
          kind: isDomainAzure || sentryDeleted ? 'All' : 'Pooled',
        },
      },
      {
        stateAction: setCustApps,
        url: workspaceApi.custApps.url,
        queryConfig: { ...workspaceApi.custApps.query, domainId: id },
      },
    ];

    if (!isAppPublishingEnabled || !applicationsCount || isDomainAzure) {
      fetchData.splice(-1, 1);
    }
    if (!machinesCount) {
      fetchData = [];
    }

    Promise.allSettled(fetchData.map(({ url, queryConfig }) => axios.get(queryFormatter(url, queryConfig))))
      .then((results) =>
        results.forEach((result, index) => {
          const { stateAction, queryConfig } = fetchData[index];
          const pageMeta = result.value?.headers['x-pagination']
            ? JSON.parse(result.value.headers['x-pagination'])
            : null;

          if (result.status === 'fulfilled') {
            stateAction({ data: result.value.data, pageMeta, queryConfig });
          } else {
            stateAction({ isError: true, error: result.reason.response.data || result.reason.message });
          }
        }),
      )
      .then(() => setIsLoading(false));
  }, [domain, isDomainAzure, sentryDeleted]);

  if (isLoading) return <Loader />;

  if (sentryDeleted && !custMachines?.pageMeta?.AllMachinesCount) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} />;
  }

  const isNoMachines = isDomainAzure
    ? !custMachines?.data.length && !custMachines?.queryConfig.searchQuery
    : !custMachines?.data.length && !custMachines?.queryConfig.searchQuery && !custMachines?.pageMeta.AllMachinesCount;

  const hasOutdatedSentry = domain.sentries?.some((sentry) => sentry.isOutdated);
  const outdatedSentries = domain.sentries?.filter((sentry) => sentry.isOutdated);

  const notShownForAdAdmin =
    [spTechAdmin, custAdmin, techAdmin].includes(userRole) && !showSentryUpdateMessageForAdAdmins;

  const showSentryUpdateAlert =
    [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(userRole) && hasOutdatedSentry && !notShownForAdAdmin;

  if (isNoMachines) {
    return <NoComputersMsg domain={domain} />;
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.workspaceWrap}>
        <h3 className={styles.workspaceTitle}>Domain Workspace</h3>
        {!!(domain.status && [spAdmin, corpAdmin, spTechAdmin, corpTechAdmin].includes(userRole)) && (
          <ADStatus link="domain-management" domain={domain} />
        )}
      </div>
      <Tabs>
        <TabList>
          <Tab>{isDomainAzure ? 'All' : ''} Computers</Tab>
          {!isDomainAzure && <Tab>Apps</Tab>}
        </TabList>
        <TabPanel>
          {showSentryUpdateAlert && (
            <AlertSentryUpdate sentries={domain.sentries} toggleShowUpdateModal={toggleShowUpdateModal} />
          )}
          <WorkspaceDomains
            domain={domain}
            custMachines={custMachines}
            baseUrl={workspaceApi.custMachines.url}
            onUpdate={setCustMachines}
            onUpdateUser={onUpdateUser}
            userRole={userRole}
            loadingCurrent={loadingCurrent}
            setLoadingCurrent={setLoadingCurrent}
          />
        </TabPanel>
        {!isDomainAzure && (
          <TabPanel>
            <WorkspaceApps
              custApps={custApps}
              baseUrl={workspaceApi.custApps.url}
              userRole={userRole}
              onUpdate={setCustApps}
              onUpdateUser={onUpdateUser}
              loadingCurrent={loadingCurrent}
              setLoadingCurrent={setLoadingCurrent}
            />
          </TabPanel>
        )}
      </Tabs>
      {showUpdateModal && (
        <UpdateSentryModal
          isOpen={showUpdateModal}
          onRequestClose={toggleShowUpdateModal}
          sentries={outdatedSentries}
        />
      )}
    </div>
  );
};

CustomersWorkspace.propTypes = {
  domain: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  onUpdateUser: PropTypes.func.isRequired,
  showSentryUpdateMessageForAdAdmins: PropTypes.bool,
};

export default CustomersWorkspace;
