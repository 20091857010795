import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Button, SwitchCheckbox, Table } from 'components';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { TrashIcon } from 'assets/img';
import { dateFormatterShort } from 'helpers';
import ToggleEnableModal from './components/ToogleEnableModal/ToogleEnableModal';
import ManageCountriesModal from './components/ManageCountriesModal/ManageCountriesModal';
import DeleteCountryModal from './components/DeleteCountryModal/DeleteCountryModal';
import styles from './GeoBlocking.module.scss';

const GeoBlocking = ({ dnsName, geoBlockingData, onUpdate, baseUrl, domainId, isCompanyPage }) => {
  const [modalData, setModalData] = useState(null);
  const [isGeoBlockingEnabledCurrent, setIsGeoBlockingEnabled] = useState(
    geoBlockingData.data?.isGeoBlockingEnabled || false,
  );
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [typeEnableGeoBlocking, setTypeEnableGeoBlocking] = useState('priorList');
  const [deleteCountryName, setDeleteCountryName] = useState('');
  const [showManageCountriesModal, setShowManageCountriesModal] = useState(false);
  const [showToogleModal, setShowToogleModal] = useState(false);
  const [showDeleteCountryModal, setShowDeleteCountryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isManageAllowedList, setIsManageAllowedList] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleShowEnableModal = () => {
    setShowToogleModal(!showToogleModal);
  };

  const toggleShowDeleteCountryModal = () => {
    setShowDeleteCountryModal(!showDeleteCountryModal);
  };

  const toggleManageCountriesModal = () => {
    setShowManageCountriesModal(!showManageCountriesModal);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const array = [];
    if (typeEnableGeoBlocking === 'priorList') {
      if (geoBlockingData.data?.countries.length !== 0) {
        geoBlockingData.data?.countries.map(({ countryName }) => {
          array.push(countryName);
          return setCheckedCountries(array);
        });
      }
    } else {
      setCheckedCountries([]);
    }
  }, [geoBlockingData.data?.countries, showManageCountriesModal, showDeleteCountryModal, typeEnableGeoBlocking]);

  const onSwitchGeoBlocking = (e) => {
    setModalData(e.target.checked);
    if (
      !isGeoBlockingEnabledCurrent &&
      (geoBlockingData.data?.countries === null || geoBlockingData.data?.countries.length === 0)
    ) {
      setTypeEnableGeoBlocking('newList');
      toggleManageCountriesModal();
    } else {
      setTypeEnableGeoBlocking('priorList');
      toggleShowEnableModal();
    }
  };

  const openDeleteCountryModal = (countryName) => {
    setDeleteCountryName(countryName);
    setShowDeleteCountryModal(true);
  };

  const onToggleGeoBlocking = () => {
    setIsLoading(true);
    const queryConfig = { ...geoBlockingData.queryConfig, pageNumber: 1 };
    const url = `/api/SecurityManagement/GeoBlocking/ChangeDomainStatus?domainId=${domainId}`;

    axios
      .post(url, checkedCountries, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        onUpdate({
          data: {
            countries: res.data.countries,
            isGeoBlockingEnabled: res.data.isGeoBlockingEnabled,
          },
          queryConfig,
        });
        setIsGeoBlockingEnabled((isGeoBlockingEnabled) => !isGeoBlockingEnabled);
        toast.success(`Geo-Blocking is ${res.data.isGeoBlockingEnabled ? 'enabled' : 'disabled'} for domain`, 3000);
      })
      .catch((err) => {
        toast.error(err.message, 3000);
        setIsGeoBlockingEnabled((isGeoBlockingEnabled) => !isGeoBlockingEnabled);
      })
      .finally(() => {
        if (isGeoBlockingEnabledCurrent) {
          toggleShowEnableModal();
          setIsManageAllowedList(false);
        } else {
          toggleManageCountriesModal();
          setIsManageAllowedList(false);
        }
        setTypeEnableGeoBlocking('priorList');
        setIsLoading(false);
      });
  };

  const onChangeGeoBlocking = () => {
    if (isGeoBlockingEnabledCurrent) {
      setIsLoading(true);
      onToggleGeoBlocking();
    } else {
      toggleShowEnableModal();
      toggleManageCountriesModal();
    }
  };

  const changeAllowedList = (newCheckedCountries) =>
    new Promise((resolve, reject) => {
      setIsLoading(true);
      const url = `/api/SecurityManagement/GeoBlocking/ChangeCountryAllowedList?domainId=${domainId}`;
      const queryConfig = { ...geoBlockingData.queryConfig, pageNumber: 1 };
      axios
        .post(url, newCheckedCountries, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((res) => {
          onUpdate({
            data: {
              countries: res.data.countries,
              isGeoBlockingEnabled: res.data.countries.length > 0,
            },
            queryConfig,
          });
          if (res.data.countries.length < 1) {
            setIsGeoBlockingEnabled(false);
            setCheckedCountries([]);
          }
          setIsLoading(false);
          resolve(res);
        })
        .catch((err) => {
          setIsLoading(false);
          reject(err);
        });
    });

  const geoBlockingFindValue = () => {
    const newData = geoBlockingData.data.countries.filter((el) => {
      if (searchValue === '') return el;
      return el.countryName.toLowerCase().includes(searchValue.toLowerCase());
    });
    return { data: newData, queryConfig: { ...geoBlockingData.queryConfig, searchQuery: searchValue } };
  };

  const columns = [
    {
      name: 'Country Name',
      data: 'countryName',
      search: 'Name',
      searchNotDebounce: setSearchValue,
      sortable: 'countryName',
      width: '45%',
    },
    {
      name: 'Date Added',
      data: ({ dateAdded }) => dateFormatterShort(dateAdded),
      sortable: 'dateAdded',
      width: '23%',
    },
    {
      name: !isCompanyPage && 'Added by',
      data: !isCompanyPage && 'addedBy',
      sortable: !isCompanyPage && 'addedBy',
      width: '23%',
    },
    {
      name: '',
      data: ({ countryName }) => (
        <Button
          type="button"
          variant="link"
          id="delete-country"
          className={styles.tableButton}
          onClick={() => openDeleteCountryModal(countryName)}>
          <span data-tip="Click to delete country" data-for="delete-tooltip">
            <TrashIcon />
          </span>
        </Button>
      ),
      width: '8%',
    },
  ];

  return (
    <>
      <header className={styles.geoBlockHeader}>
        <div className={styles.geoBlockActions}>
          <div className={styles.geoBlockSwitchCheckbox}>
            <p className={styles.geoBlockSwitchCheckboxTitle}>Geo-Blocking Status</p>
            <SwitchCheckbox
              id="toggle-enable-geoblocking"
              checked={isGeoBlockingEnabledCurrent}
              onChange={onSwitchGeoBlocking}
              className={styles.geoBlockSwitchCheckboxSlide}
            />
            <span
              className={cn(styles.geoBlockSwitchCheckboxText, {
                [styles.geoBlockSwitchCheckboxTextActive]: isGeoBlockingEnabledCurrent,
              })}>
              {isGeoBlockingEnabledCurrent ? 'ON' : 'OFF'}
            </span>
          </div>
          {isGeoBlockingEnabledCurrent && (
            <Button
              size="32"
              id="open-manage-country-list"
              type="button"
              variant="secondary"
              onClick={() => {
                setIsManageAllowedList(true);
                setTypeEnableGeoBlocking('priorList');
                toggleManageCountriesModal();
              }}>
              Manage country Allowed List
            </Button>
          )}
        </div>
      </header>

      {isGeoBlockingEnabledCurrent && geoBlockingData.data?.countries.length > 0 ? (
        <>
          <Table
            data={geoBlockingFindValue()}
            updateData={onUpdate}
            id="geoblocking-table"
            pagination={false}
            baseUrl={baseUrl}
            dataKey="countryName"
            columns={columns}
            className={styles.table}
          />
          <ReactTooltip
            id="delete-tooltip"
            type="light"
            place="top"
            html
            effect="solid"
            offset={{ top: 10, left: 50 }}
            className={styles.deleteTooltip}
          />
        </>
      ) : (
        <div className={styles.geoBlockInfo}>
          <h4 className={styles.geoBlockInfoTitle}>Geo-Blocking is not currently enabled</h4>
          <p className={styles.geoBlockInfoText}>
            Geo-Blocking allows Administrators to determine countries where users can connect from
          </p>
        </div>
      )}

      {showToogleModal && (
        <ToggleEnableModal
          typeEnableGeoBlocking={typeEnableGeoBlocking}
          setTypeEnableGeoBlocking={setTypeEnableGeoBlocking}
          checkedCountriesLength={geoBlockingData.data?.countries.length}
          type={modalData}
          onClose={toggleShowEnableModal}
          onChangeGeoBlocking={onChangeGeoBlocking}
          isLoading={isLoading}
          dnsName={dnsName}
        />
      )}

      {showManageCountriesModal && (
        <ManageCountriesModal
          changeAllowedList={changeAllowedList}
          isManageAllowedList={isManageAllowedList}
          setIsManageAllowedList={setIsManageAllowedList}
          typeEnableGeoBlocking={typeEnableGeoBlocking}
          checkedCountries={checkedCountries}
          setNewCheckedCountries={(items) => setCheckedCountries(items)}
          onToggleGeoBlocking={onToggleGeoBlocking}
          setShowManageCountriesModal={setShowManageCountriesModal}
          showManageCountriesModal={showManageCountriesModal}
          isLoading={isLoading}
        />
      )}

      {showDeleteCountryModal && (
        <DeleteCountryModal
          toggleShowDeleteCountryModal={toggleShowDeleteCountryModal}
          showDeleteCountryModal={showDeleteCountryModal}
          checkedCountries={checkedCountries}
          deleteCountryName={deleteCountryName}
          changeAllowedList={changeAllowedList}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

GeoBlocking.propTypes = {
  geoBlockingData: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  domainId: PropTypes.string.isRequired,
  dnsName: PropTypes.string,
  isCompanyPage: PropTypes.bool,
};

export default GeoBlocking;
