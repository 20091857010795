import PropTypes from 'prop-types';
import { Modal } from 'components';

const BrowserExtensionModal = ({ isOpen, onRequestClose, modalMessage }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Install Browser Extension"
  >
    {modalMessage}
  </Modal>
);

BrowserExtensionModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  modalMessage: PropTypes.any,
};

export default BrowserExtensionModal;
