export const goBackHandler = (currentStep, history, isSetupMfaWithoutPhone) => {
  if (isSetupMfaWithoutPhone) {
    if (currentStep === '1') {
      return window.location.replace('/login');
    }
    return history.goBack();
  }
  if (currentStep === '2') {
    return window.location.replace('/login');
  }
  return history.goBack();
};
