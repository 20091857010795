import cn from 'classnames';
import { InfoActiveIcon, InfoIcon } from 'assets/img';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import { techAdmin } from 'consts';
import DomainActions from './DomainActions/DomainActions';
import DomainInfoMsg from './DomainInfoMsg/DomainInfoMsg';
import styles from '../Customers.module.scss';

const STATUS_MAP = {
  0: { status: 'Pending domain validation' },
  1: { status: 'Pending Connection to a Directory Service' },
  2: { status: 'Active' },
};

export const tableColumns = (
  role,
  setDomainIdDelete,
  toggleShowDeleteDomainModal,
  onValidateModalOpen,
  infoStatusActive,
  setInfoStatusActive,
  showUserComputersColumn,
  showAppsColumn,
) => [
  {
    name: 'Domain',
    width: '42%',
    search: 'Domain Name',
    sortable: 'dnsname',
    data: 'dnsName',
  },
  {
    name: 'Domain Status',
    data: (domain) => {
      const { isDomainNotValidated, isDomainNotActivated, isDomainActive } = getDomainStatus(domain);
      const { isDomainAzure } = getDomainADStatus(domain);
      const isActive = isDomainActive && !isDomainAzure;
      const isOneSentryOff = domain.sentries?.length === 1 && !domain.sentries[0].isOnline;
      const infoIconColor = domain.sentries?.length
        ? domain.sentries.some((i) => !i.isOnline)
          ? styles.warning
          : styles.success
        : styles.error;
      return (
        <>
          <div
            className={cn(styles.customersDomainsStatus, {
              [styles.notValidated]: isDomainNotValidated,
              [styles.notActivated]: isDomainNotActivated,
              [styles.active]: isDomainActive,
            })}>
            {STATUS_MAP[domain.status].status}
            {isActive &&
              (infoStatusActive === domain.id ? (
                <InfoActiveIcon
                  className={cn(styles.customersDomainsStatus, infoIconColor)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfoStatusActive('');
                  }}
                />
              ) : (
                <InfoIcon
                  className={cn(styles.customersDomainsStatus, infoIconColor)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfoStatusActive(domain.id);
                  }}
                />
              ))}
          </div>
          {infoStatusActive === domain.id &&
            (domain.sentries?.length ? (
              <DomainInfoMsg
                header={isOneSentryOff ? 'Sentry Agent is curently offline' : 'Sentry Hosts'}
                data={domain.sentries}
                onClose={() => setInfoStatusActive('')}
              />
            ) : (
              <DomainInfoMsg
                type="assigned"
                data={['No Sentry Agent curently installed']}
                onClose={() => setInfoStatusActive('')}
              />
            ))}
        </>
      );
    },
    width: showAppsColumn || !showUserComputersColumn ? '28%' : '37%',
  },
  {
    name: 'Users',
    disabled: !showUserComputersColumn,
    data: ({ usersCount }) => (
      <p className={role !== techAdmin && styles.customersDomainsTableRowUsersCount}>{usersCount}</p>
    ),
    width: showAppsColumn ? '10%' : '7%',
  },
  {
    name: 'Computers',
    disabled: !showUserComputersColumn,
    data: (domain) => (
      <>
        <p className={role !== techAdmin && styles.customersDomainsTableRowComputersCount}>{domain.machinesCount}</p>
        {role !== techAdmin && !showAppsColumn && (
          <DomainActions
            domain={domain}
            role={role}
            setDomainIdDelete={setDomainIdDelete}
            toggleShowDeleteDomainModal={toggleShowDeleteDomainModal}
            onOpenValidationModal={onValidateModalOpen}
          />
        )}
      </>
    ),
    width: showAppsColumn ? '10%' : '14%',
  },
  {
    name: 'Apps',
    disabled: !showAppsColumn,
    data: (domain) => (
      <>
        <p className={role !== techAdmin && styles.customersDomainsTableRowComputersCount}>
          {domain.applicationsCount}
        </p>
        {role !== techAdmin && showAppsColumn && (
          <DomainActions
            domain={domain}
            role={role}
            setDomainIdDelete={setDomainIdDelete}
            toggleShowDeleteDomainModal={toggleShowDeleteDomainModal}
            onOpenValidationModal={onValidateModalOpen}
          />
        )}
      </>
    ),
    width: '10%',
  },
  {
    name: ' ',
    disabled: showUserComputersColumn,
    data: (domain) =>
      role !== techAdmin && (
        <DomainActions
          domain={domain}
          role={role}
          setDomainIdDelete={setDomainIdDelete}
          toggleShowDeleteDomainModal={toggleShowDeleteDomainModal}
          onOpenValidationModal={onValidateModalOpen}
        />
      ),
    width: '30%',
  },
];
