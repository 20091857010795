/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, TextInput } from 'components';
import { isFormValid } from 'helpers';
import { useOutsideClick } from 'hooks';
import { LoginContext, UserContext, notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import styles from './SetNewPassword.module.scss';

const SetNewPassword = ({ setError }) => {
  const { signInData } = useContext(LoginContext);
  const { user } = useContext(UserContext);
  const { token } = useParams();

  const isEmailRequest = !!token;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const [userId, setUserId] = useState(signInData?.userId || '');
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const tooltipPassRef = useRef();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const signOutUser = async () => {
    await loginService.logout();
  };

  useEffect(() => {
    if (user) {
      signOutUser();
    }
  }, [user]);

  useEffect(() => {
    const validateResetByEmail = () => {
      setIsLoading(true);
      axios
        .get(`/api/AdPasswordReset/ValidateResetByEmail?token=${token}`)
        .then(({ data }) => {
          setIsLoading(false);
          setUserId(data);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.data === 'Link is expired') {
            setError('expired');
          }
          if (err.response.data === 'Invalid link') {
            setError('used');
          } else {
            setError('error');
          }
        });
    };
    if (token) {
      validateResetByEmail();
    }
  }, [setError, token]);

  const onClearError = (index) => {
    if (index === 0) {
      setIsTooltipOpen(true);
    }
    setFormErrors(['', '']);
  };

  const onUpdate = (value, index) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearValue = (index, ref) => {
    ref.current.focus();

    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const onSetPassword = (e) => {
    e.preventDefault();

    const { errors, isValid } = isFormValid(formValues, formErrors);
    if (errors) {
      setFormErrors(errors);
    }

    if (isValid) {
      setIsUpdating(true);
      loginService
        .resetPasswordSetNewPassword(userId, formValues[0], isEmailRequest)
        .then(({ data }) => {
          setIsUpdating(false);
          history.replace('/password-success', { message: data });
        })
        .catch((err) => {
          setIsUpdating(false);
          if (err?.response?.status === 403) {
            setError('exceeded');
          } else if (err?.response?.status === 406) {
            setError('offline');
          } else {
            notify.error(err?.response?.data || err.message);
            setFormErrors([' ', ' ']);
          }
        });
    }
  };

  const passwordTooltip = () => (
    <>
      <h5>We recommend:</h5>
      <ul>
        <li>at least 10 characters</li>
        <li>both lower and uppercase letters</li>
        <li>numbers and special characters</li>
        <li>not repeating any previously used password</li>
      </ul>
    </>
  );

  if (isLoading) {
    return (
      <div className={styles.setPass}>
        <Loader />
      </div>
    );
  }

  return (
    <form method="POST" onSubmit={onSetPassword} className={styles.setPass} noValidate>
      <LoginHeader title="Set New Password" hideSteps idle back={() => history.replace('/login')} />
      <div className={styles.setPassWrap} ref={tooltipPassRef}>
        <TextInput
          ref={newPassRef}
          placeholder="Enter New Password"
          id="enter-new-password"
          type="password"
          autoComplete="new-password"
          hasView
          value={formValues[0]}
          error={formErrors[0]}
          onChange={(e) => onUpdate(e.target.value, 0)}
          onClear={() => onClearValue(0, newPassRef)}
          onFocus={() => onClearError(0)}
        />
        {isTooltipOpen && <div className={styles.setPassTooltip}>{passwordTooltip()}</div>}
      </div>
      <div className={styles.setPassWrap}>
        <TextInput
          ref={confirmPassRef}
          id="confirm-new-password"
          placeholder="Confirm New Password"
          autoComplete="new-password"
          type="password"
          hasView
          value={formValues[1]}
          error={formErrors[1]}
          onChange={(e) => onUpdate(e.target.value, 1)}
          onClear={() => onClearValue(1, confirmPassRef)}
          onFocus={() => onClearError(1)}
        />
      </div>
      <Button
        id="set-new-password"
        size="48"
        type="submit"
        variant="primary"
        isDisabled={formValues.includes('')}
        isLoading={isUpdating}
        className={styles.setPassButton}>
        Set New Password
      </Button>
    </form>
  );
};

SetNewPassword.propTypes = {
  setError: PropTypes.func,
};

export default SetNewPassword;
