import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CodeInput } from 'components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import { loginService } from 'services';
import styles from './ConfirmPhoneNumber.module.scss';

const ConfirmPhoneNumber = ({ userId, phoneMask, setSmsHash, setError, phoneDigits, setPhoneDigits }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError('');

  const handleVerifyPhoneNumber = () => {
    setIsDisable(true);
    loginService.resetPasswordVerifyPhone(userId, phoneDigits)
      .then(({ data }) => {
        notify.success('SMS has been sent');
        setSmsHash(data);
        setIsDisable(false);
        history.push(`${url}/verification-code`);
      })
      .catch((err) => {
        setIsDisable(false);
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/login/forgot-password');
        } else if (err.response.status === 403) {
          setError('exceeded');
        } else if (err.response.status === 406) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <div className={styles.resetPass}>
      <LoginHeader title="Validate Your Identity" stepCount="2" currentStep="1" />
      <div className={styles.resetPassInfo}>
        We will send a verification code to <strong>{phoneMask}</strong>. To verify that this is your phone number, enter the last 4 digits
      </div>
      <div className={styles.resetPassForm}>
        <div className={styles.resetPassLabel}>Enter Last 4 Digits</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isDisable}
          value={phoneDigits}
          setValue={setPhoneDigits}
          handleSend={handleVerifyPhoneNumber}
          sendOnKeyDown
        />
        <Button
          id="send-number-phone"
          className={styles.resetPassButton}
          onClick={handleVerifyPhoneNumber}
          size="40"
          variant="primary"
          isDisabled={phoneDigits.length < 4 || !!isError}
          isLoading={isDisable}
        >
          Send Code
        </Button>
      </div>
      <div className={styles.resetPassContactSupport}>
        Phone is unavailable? Contact your Administrator for assistance
      </div>
    </div>
  );
};

ConfirmPhoneNumber.propTypes = {
  userId: PropTypes.string,
  phoneMask: PropTypes.string,
  setSmsHash: PropTypes.func,
  setError: PropTypes.func,
  phoneDigits: PropTypes.string,
  setPhoneDigits: PropTypes.func,
};

export default ConfirmPhoneNumber;
