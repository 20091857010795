import PropTypes from 'prop-types';
import { Button, CountryListDropdown } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useContext, useState } from 'react';
import { loginService } from 'services';
import { UserContext, notify } from 'utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SkipForeverModal from '../components/SkipForeverModal/SkipForeverModal';
import styles from '../SetupPhone.module.scss';

const AddPhone = ({
  countriesList,
  dialCode,
  phoneNumber,
  setPhoneNumber,
  currentCountry,
  setCurrentCountry,
  setMfaHash,
  setPhoneMask,
}) => {
  const { user, onUpdateUser } = useContext(UserContext);
  const { url } = useRouteMatch();
  const history = useHistory();

  const [isSending, setIsSending] = useState(false);
  const [isSendingSkip, setIsSendingSkip] = useState(false);

  const [showSkipForeverModal, setShowSkipForeverModal] = useState(false);
  const toggleSkipForeverModal = () => setShowSkipForeverModal(!showSkipForeverModal);

  const handleSetCurrentCountry = (value) => setCurrentCountry(value);

  const signOut = async () => {
    await loginService.logout();
    history.replace('/login');
  };

  const onSubmitPhoneNumber = (e) => {
    e.preventDefault();
    if (phoneNumber && phoneNumber.length > 4) {
      setIsSending(true);
      loginService
        .requestVerificationCodeBySms(phoneNumber.replace(/\D+/g, ''), dialCode, false)
        .then(({ data }) => {
          setPhoneMask(data.phoneMask);
          setMfaHash(data.pinHash);
          setIsSending(false);
          notify.success('SMS has been sent');
          history.push(`${url}/verify-phone`);
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            notify.error(err?.response?.data);
            signOut();
          } else {
            notify.error(err?.response?.data || err.message);
          }
          setIsSending(false);
        });
    }
  };

  const skipForever = () => {
    setIsSendingSkip(true);
    loginService
      .skipSetupPhoneForPasswordReset()
      .then(() => {
        const userNew = JSON.parse(JSON.stringify(user));
        userNew.shouldSetupPhoneForPasswordReset = false;
        onUpdateUser(userNew);
        setIsSendingSkip(false);
        toggleSkipForeverModal();
      })
      .catch((err) => {
        setIsSendingSkip(false);
        notify.error(err.response?.data || err.message);
      });
  };

  return (
    <>
      <form className={styles.setup} onSubmit={onSubmitPhoneNumber} noValidate>
        <LoginHeader
          currentStep="1"
          stepCount="2"
          title="Add Password Reset Option"
          back={() => window.location.replace('/login')}
        />
        <div className={styles.setupInfo}>
          Please enter and validate your phone number so that you can reset your password in the future
        </div>
        <CountryListDropdown
          id="phone-code-dd"
          label="Enter Your Phone Number"
          options={countriesList}
          currentCountry={currentCountry}
          onChange={handleSetCurrentCountry}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <Button
          type="submit"
          variant="primary"
          size="40"
          className={styles.setupButton}
          isLoading={isSending}
          isDisabled={!(phoneNumber && phoneNumber.length > 4)}>
          Get a Code
        </Button>
        <Button variant="link" onClick={toggleSkipForeverModal} className={styles.setupLink}>
          Skip Forever
        </Button>
      </form>
      {showSkipForeverModal && (
        <SkipForeverModal
          isOpen={showSkipForeverModal}
          onClose={toggleSkipForeverModal}
          onSkipForever={skipForever}
          isLoading={isSendingSkip}
          email={user.userEmail}
        />
      )}
    </>
  );
};

AddPhone.propTypes = {
  countriesList: PropTypes.array,
  dialCode: PropTypes.string,
  currentCountry: PropTypes.string,
  setCurrentCountry: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setMfaHash: PropTypes.func,
  setPhoneMask: PropTypes.func,
};

export default AddPhone;
