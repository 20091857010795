import { Loader } from 'components';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { loginService } from 'services';
import { notify } from 'utils';
import AddPhone from './pages/AddPhone';
import VerifyPhone from './pages/VerifyPhone';
import styles from './SetupPhone.module.scss';

const SetupPhone = () => {
  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [countriesList, setCountriesList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentCountry, setCurrentCountry] = useState('US');
  const [phoneMask, setPhoneMask] = useState('');
  const [mfaHash, setMfaHash] = useState('');

  useEffect(() => {
    loginService.getCountriesList()
      .then((resp) => setCountriesList(JSON.parse(resp.data)))
      .catch((err) => notify.error(err.response?.data || err.message))
      .finally(() => setIsLoading(false));
  }, []);

  const dialCode = useMemo(() => (
    countriesList[currentCountry]?.dial_code
  ), [countriesList, currentCountry]);

  return (
    <div className={styles.setup}>
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route path={path} exact>
            <AddPhone
              countriesList={countriesList}
              dialCode={dialCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
              setMfaHash={setMfaHash}
              setPhoneMask={setPhoneMask}
            />
          </Route>
          <Route path={`${url}/verify-phone`}>
            <VerifyPhone
              phoneNumber={phoneNumber}
              mfaHash={mfaHash}
              setMfaHash={setMfaHash}
              dialCode={dialCode}
              phoneMask={phoneMask}
            />
          </Route>
          <Route path="*" render={() => <Redirect to={path} />} />
        </Switch>
      )}
    </div>
  );
};

export default SetupPhone;
