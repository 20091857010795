import axios from 'axios';

const customAxios = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

if (process.env.REACT_APP_LOCAL === 'true') {
  customAxios.defaults.baseURL = process.env.REACT_APP_PUBLIC_URL;
}

const onRequestSent = (config) => {
  const event = new CustomEvent('axios-request-sent', {
    detail: config,
  });
  window.dispatchEvent(event);
};

const onResponseReceived = (response) => {
  const event = new CustomEvent('axios-response-received', {
    detail: response,
  });
  window.dispatchEvent(event);
};

export const subscribeToRequestSent = (callback) => {
  window.addEventListener('axios-request-sent', (event) => {
    callback(event.detail);
  });
};

export const subscribeToResponseReceived = (callback) => {
  window.addEventListener('axios-response-received', (event) => {
    callback(event.detail);
  });
};

export const unsubscribeFromResponseReceived = (callback) => {
  window.removeEventListener('axios-response-received', callback);
};

export const unsubscribeFromRequestSent = (callback) => {
  window.removeEventListener('axios-request-sent', callback);
};

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => {
    onRequestSent(request);
    return request;
  },
  (error) => errorHandler(error),
);

customAxios.interceptors.response.use(
  (response) => {
    onResponseReceived(response);
    return response;
  },
  (error) => errorHandler(error),
);

export default customAxios;
