import cn from 'classnames';
import { CircleCloseIcon } from 'assets/img';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { convertHex, isColor } from 'pages/Branding/helpers/colorsHelper';
import styles from './SetButton.module.scss';

const SetButton = ({ updateBrandingInfo, brandingInfo, hexError, setHexError }) => {
  const [hexInput, setHexInput] = useState(brandingInfo?.modified?.buttonColor?.slice(1) || '');

  useEffect(() => {
    setHexError(!isColor(hexInput ? `#${hexInput}` : '#1f2536') || hexInput?.length === 4);
  }, [hexInput, setHexError]);

  useEffect(() => {
    setHexInput(brandingInfo?.modified?.buttonColor?.slice(1) || '');
  }, [brandingInfo?.modified?.buttonColor]);

  const handleInputBackground = (e) => {
    const val = e.target.value.replace('#', '').slice(0, 6);
    setHexInput(val);
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, buttonColor: `#${val}` },
      init: brandingInfo.init,
    }));
  };

  const updateBackgroundColor = (e) => {
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, buttonColor: e.target.value },
      init: brandingInfo.init,
    }));
  };

  const onClearRemove = () => {
    setHexError(false);
    setHexInput('');
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, buttonColor: null },
      init: brandingInfo.init,
    }));
  };

  return (
    <div className={styles.backgroundRow}>
      <div className={styles.backgroundLabel}>
        <p className={styles.backgroundLabelTitle}>Button Color</p>
        <p className={styles.backgroundLabelInfo}>Customize main buttons color</p>
      </div>
      <div>
        <div className={styles.backgroundWrap}>
          <label htmlFor="profile-button-color" className={cn(styles.backgroundButton, { [styles.error]: hexError })}>
            {brandingInfo?.modified?.buttonColor?.slice(1) &&
            brandingInfo?.modified?.buttonColor?.slice(1).length > 0 ? (
              <span
                className={styles.backgroundButtonColor}
                style={{ backgroundColor: convertHex(brandingInfo?.modified?.buttonColor?.slice(1)) }}
              />
            ) : (
              <span className={styles.backgroundButtonColor} style={{ background: '#4169b8' }} />
            )}
            <div className={styles.backgroundButtonColorWrap}>
              <span style={{ color: '#848A92' }}>#</span>
              <input
                type="text"
                className={styles.backgroundButtonColorInput}
                onChange={handleInputBackground}
                value={hexInput}
                placeholder="4169b8"
              />
            </div>
          </label>
          <input
            type="color"
            className={styles.backgroundInput}
            onChange={updateBackgroundColor}
            id="profile-button-color"
            name="profile-button-color"
            value={
              brandingInfo?.modified?.buttonColor ? convertHex(brandingInfo?.modified?.buttonColor.slice(1)) : '#1f2536'
            }
          />
          {!!brandingInfo?.modified?.buttonColor && (
            <Button
              variant="link"
              id="clear-background-color"
              icon={<CircleCloseIcon />}
              className={styles.backgroundClear}
              onClick={onClearRemove}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SetButton.propTypes = {
  updateBrandingInfo: PropTypes.func,
  brandingInfo: PropTypes.object,
  hexError: PropTypes.bool,
  setHexError: PropTypes.func,
};

export default SetButton;
