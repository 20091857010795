import PropTypes from 'prop-types';
import { Modal } from 'components';

const EnableToggleModal = ({
  isShowToggleModal,
  isToggleLoading,
  handleShowToggleModal,
  isRememberMeEnabled,
  onToggleRememberMe,
}) => (
  <Modal
    isOpen={isShowToggleModal}
    onRequestClose={handleShowToggleModal}
    contentLabel={isRememberMeEnabled ? 'Disable Remember Me for domain?' : 'Enable Remember Me for domain?'}
    actionTitle={isRememberMeEnabled ? 'Disable' : 'Enable'}
    actionLoading={isToggleLoading}
    variantBtn="primary"
    actionFunc={onToggleRememberMe}>
    {isRememberMeEnabled ? (
      <>
        <p>By disabling this option, all Users won’t have "Remember Me" on TruGrid Desktop Connectors</p>
        <br />
        <p>Disabling this option will override individual settings for this feature</p>
      </>
    ) : (
      <p>By enabling this option, you can allow users to use “Remember Me” on TruGrid Desktop Connectors</p>
    )}
  </Modal>
);

EnableToggleModal.propTypes = {
  isShowToggleModal: PropTypes.bool.isRequired,
  isToggleLoading: PropTypes.bool.isRequired,
  handleShowToggleModal: PropTypes.func.isRequired,
  isRememberMeEnabled: PropTypes.bool.isRequired,
  onToggleRememberMe: PropTypes.func.isRequired,
};

export default EnableToggleModal;
