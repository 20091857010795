export const onCustomResSave = (tabsContent, domainSettings, customRes) => {
  const dimensionsColl = tabsContent.general.dimension.collection;
  const customResPresentKey = Object.values(dimensionsColl).findIndex((i) => i[1].includes('custom'));
  const customResKey = customResPresentKey ? customResPresentKey + 1 : Object.keys(dimensionsColl).length;
  const customResVal = `${customRes.width} x ${customRes.height} (custom)`;
  const dimensionsCollNew = {
    ...dimensionsColl,
    [customResKey]: [`${customResKey}`, customResVal],
    [customResKey + 1]: ['customResLink', 'Change custom resolution'],
  };

  const tabsContentNew = JSON.parse(JSON.stringify(tabsContent));
  tabsContentNew.general.dimension.collection = dimensionsCollNew;

  const domainSettingsNew = JSON.parse(JSON.stringify(domainSettings));
  domainSettingsNew.isModified = true;
  domainSettingsNew.modified = {
    ...domainSettings.modified,
    desktopHeight: customRes.height,
    desktopWidth: customRes.width,
    dimension: customResKey,
  };

  return { tabsContentNew, domainSettingsNew };
};
