import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, CodeInput, Loader, ResendCode } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { useHistory } from 'react-router-dom';
import { UserContext, notify } from 'utils';
import { useTimer } from 'hooks';
import { loginService } from 'services';
import styles from './SetupViaEmail.module.scss';

const SetupViaEmail = () => {
  const { user, onUpdateUser } = useContext(UserContext);
  const history = useHistory();

  const { timeLeft, resetCountdown } = useTimer(60);

  const [emailMask, setEmailMask] = useState('');
  const [pinHash, setPinHash] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSendEmailAddress = useCallback(() => {
    loginService.requestVerificationCodeByEmail()
      .then(({ data }) => {
        setEmailMask(data.emailMask);
        setPinHash(data.pinHash);
        resetCountdown(60);
        notify.success('Email has been sent');
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/setup-mfa');
        }
        notify.error(err?.response?.data || err.message);
        if (err.response.status === 403) {
          history.goBack();
        }
        setIsLoading(false);
      });
  }, [history, resetCountdown]);

  useEffect(() => {
    handleSendEmailAddress();
  }, [handleSendEmailAddress]);

  const clearError = () => setIsError('');

  const goBack = () => history.goBack();

  const handleSendVerificationCode = () => {
    setIsDisable(true);
    loginService.checkVerificationCode(codeValue, pinHash)
      .then(() => {
        const userNew = JSON.parse(JSON.stringify(user));
        userNew.mustSetupMfa = false;
        userNew.isMfaActive = false;
        onUpdateUser(userNew);
        notify.success('Verification Successful. MFA Setup Completed');
        if (user.roles.includes('trugridadmin')) {
          return window.location.replace('/admin');
        }
      })
      .catch((err) => {
        setCodeValue('');
        setIsDisable(false);
        if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaWrap}>
        <h3 className={styles.setupMfaTitle}>
          <Button variant="link" icon={<ArrowLeftIcon />} onClick={goBack} className={styles.setupMfaBack} />
          <span>Verify Your Email</span>
        </h3>
      </div>
      {isLoading ? (
        <Loader style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <>
          <div className={styles.setupMfaInfo}>
            We’ve sent a verification code to<br /><strong>{emailMask}</strong>
          </div>
          <div className={styles.setupMfaInputTitle}>Enter Verification Code</div>
          <CodeInput
            fields={6}
            isError={!!isError}
            errorMessage={isError}
            clearError={clearError}
            isDisable={isDisable}
            value={codeValue}
            setValue={setCodeValue}
            handleSend={handleSendVerificationCode}
          />
          <div className={styles.setupMfaResend}>
            <ResendCode countDown={timeLeft} count={60} handleSend={handleSendEmailAddress} isDisabled={isDisable} />
          </div>
        </>
      )}
    </div>
  );
};

export default SetupViaEmail;
