/* eslint-disable jsx-a11y/no-autofocus */
import { useState, forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CloseIcon, ShowIconOff, ShowIconOn } from 'assets/img';
import Button from 'components/Button/Button';
import styles from './TextInput.module.scss';

const TextInput = forwardRef(({
  type = 'text',
  value,
  error,
  id,
  name,
  placeholder,
  autoFocus,
  readOnly,
  isDisabled,
  tabIndex,
  maxCharacters,
  autoComplete,
  hasView,
  onChange,
  onKeyDown,
  onFocus,
  onClear,
}, ref) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const setOnFocus = (e) => {
    setIsFocus(true);
    if (onFocus) onFocus(e);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const placeholderClasses = cn(styles.inputPlaceholder, {
    [styles.active]: isFocus || !!value,
    [styles.disabled]: isDisabled,
    [styles.error]: error,
  });

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputContainer}>
        <input
          id={id}
          ref={ref}
          className={cn(styles.input, {
            [styles.hasValue]: value?.length > 0,
            [styles.error]: !!error,
            [styles.disabled]: isDisabled,
            [styles.viewAction]: !isDisabled && value?.length > 0,
            [styles.password]: hasView,
          })}
          value={value}
          onChange={onChange}
          type={isShowPassword ? 'text' : type}
          autoFocus={autoFocus}
          tabIndex={isDisabled ? '-1' : tabIndex}
          maxLength={maxCharacters}
          readOnly={readOnly}
          onFocus={setOnFocus}
          onBlur={onBlur}
          autoComplete={autoComplete || 'off'}
          onKeyDown={onKeyDown}
          name={name && name}
        />
        <label className={placeholderClasses}>{placeholder}</label>

        {(!isDisabled && value) && (
          <div className={cn(styles.inputActions, { [styles.showPassIcon]: hasView })}>
            <Button
              variant="link"
              tabIndex="-1"
              className={styles.inputClear}
              icon={<CloseIcon />}
              onClick={onClear}
            />
            {hasView && (
              <Button
                variant="link"
                tabIndex="-1"
                className={styles.inputShowPass}
                onClick={handleShowPassword}
                icon={isShowPassword ? <ShowIconOff /> : <ShowIconOn />}
              />
            )}
          </div>
        )}
      </div>
      {error?.length > 1 && <div className={styles.inputErrorMessage}>{error}</div>}
    </div>
  );
});

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  tabIndex: PropTypes.string,
  maxCharacters: PropTypes.number,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  hasView: PropTypes.bool,
  autoComplete: PropTypes.string,
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClear: PropTypes.func,
};

export default TextInput;
