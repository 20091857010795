/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import { notify, queryFormatter, truncateString } from 'utils';
import axios from 'config/customAxios';
import TimeoutTitle from './components/TimeoutTitle/TimeoutTitle';
import NoUsers from './components/NoUsers/NoUsers';
import TimeoutStatus from './components/TimeoutStatus/TimeoutStatus';
import TimeoutHeader from './components/TimeoutHeader/TimeoutHeader';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import TimeoutTable from './components/TimeoutTable/TimeoutTable';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ChangeTimeoutPeriod from './components/ChangeTimeoutPeriod/ChangeTimeoutPeriod';
import styles from './TimeoutManagement.module.scss';

const TimeoutManagement = ({ domain, isCompanyPage }) => {
  const [timeoutData, setTimeoutData] = useState();
  const [isTimeoutEnabled, setIsTimeoutEnabled] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState([]);
  const [isHeaderLoadingChanging, setIsHeaderLoadingChanging] = useState(false);

  const [showToggleModal, setShowToggleModal] = useState(false);
  const handleShowToggleModal = () => setShowToggleModal(!showToggleModal);

  const [showChangePeriodModal, setShowChangePeriodModal] = useState(false);
  const handleShowChangePeriodModal = () => setShowChangePeriodModal(!showChangePeriodModal);

  const baseUrl = `/api/SecurityManagement/DesktopTimeout/Get?domainId=${domain.id}`;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
      };
      const res = await axios.get(queryFormatter(baseUrl, queryConfig));
      const pageMeta = res?.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
      if (res.status === 204) {
        setNoUsers(true);
      } else {
        setTimeoutData({ data: res.data, pageMeta, queryConfig });
        setIsTimeoutEnabled(res.data.isTimeoutEnabled);
      }
    } catch (err) {
      setTimeoutData({ isError: true, error: err?.response?.data || err.message });
    } finally {
      setIsLoading(false);
    }
  }, [baseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeDurationPeriod = (duration) => {
    const timeoutDataNew = JSON.parse(JSON.stringify(timeoutData));
    timeoutDataNew.data.timeoutDuration = duration;
    setTimeoutData(timeoutDataNew);
  };

  const onEnableTimeoutForDomain = async (enableForAllUsers, expirationMins) => {
    try {
      const url = `/api/SecurityManagement/DesktopTimeout/ChangeDomainStatus?domainId=${domain.id}&enableForAllUsers=${enableForAllUsers}&timeoutDuration=${expirationMins}`;
      await axios.post(url);
      await fetchData();
      notify.success('Session Timeout is enabled for domain');
    } catch (err) {
      notify.error(err.message);
    } finally {
      handleShowToggleModal();
    }
  };

  const onDisableTimeout = async () => {
    try {
      const url = `/api/SecurityManagement/DesktopTimeout/ChangeDomainStatus?domainId=${domain.id}`;
      await axios.post(url);
      setIsTimeoutEnabled(false);
      setTimeoutData({});
      notify.success('Session Timeout is disabled for domain');
    } catch (err) {
      notify.error(err.message, 3000);
    } finally {
      handleShowToggleModal();
    }
  };

  const onChangeTimeoutForUsers = (isHeaderBtn, user) => {
    if (isHeaderBtn) {
      setIsHeaderLoadingChanging(true);
    } else {
      setBtnLoadingCurrent((itemCurrent) => [...itemCurrent, user.userId]);
    }

    const url = '/api/SecurityManagement/DesktopTimeout/ChangeUserStatus';
    const selectedUserIds = isHeaderBtn ? selectedUsers.map((item) => item.userId) : [user.userId];

    axios
      .post(url, selectedUserIds)
      .then(() => {
        const timeoutDataNew = JSON.parse(JSON.stringify(timeoutData));

        timeoutDataNew.data.users.forEach((item) => {
          if (selectedUserIds.includes(item.userId)) {
            item.isDesktopTimeoutEnabled = !item.isDesktopTimeoutEnabled;
          }
        });

        setTimeoutData(timeoutDataNew);

        if (selectedUsers.length >= 1) {
          if (selectedUsers.length > 1) {
            let actionType = 'updated';
            switch (true) {
              case selectedUsers.every((i) => !i.isDesktopTimeoutEnabled):
                actionType = 'enabled';
                break;
              case selectedUsers.every((i) => i.isDesktopTimeoutEnabled):
                actionType = 'disabled';
                break;
              default:
                actionType = 'updated';
            }
            notify.success(`Session Timeout is ${actionType} for selected users`);
          } else {
            const actionType = selectedUsers.every((i) => i.isDesktopTimeoutEnabled) ? 'disabled' : 'enabled';
            notify.success(
              `Session Timeout is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`,
            );
          }
        } else {
          notify.success(
            `Session Timeout is ${user.isDesktopTimeoutEnabled ? 'disabled' : 'enabled'} for ${truncateString(
              user.displayName,
              false,
            )}`,
          );
        }
      })
      .catch((err) => notify.error(err.message))
      .finally(() => {
        if (isHeaderBtn) {
          setIsHeaderLoadingChanging(false);
        } else {
          setBtnLoadingCurrent((item) => item.filter((i) => i !== user.userId));
        }
        if (selectedUsers.length) setSelectedUsers([]);
      });
  };

  const onChangeTimeoutForDomain = (enableForAllUsers, expirationDays) => {
    if (isTimeoutEnabled) {
      onDisableTimeout();
    } else {
      onEnableTimeoutForDomain(enableForAllUsers, expirationDays);
    }
  };

  return (
    <div className={styles.timeout}>
      <TimeoutTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader />
      ) : noUsers ? (
        <NoUsers />
      ) : (
        <>
          {selectedUsers.length > 0 ? (
            <HeaderActions
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              timeoutData={timeoutData}
              isHeaderLoadingChanging={isHeaderLoadingChanging}
              onChangeTimeoutForUsers={onChangeTimeoutForUsers}
            />
          ) : (
            <TimeoutStatus
              handleShowToggleModal={handleShowToggleModal}
              isTimeoutEnabled={isTimeoutEnabled}
              handleShowChangePeriodModal={handleShowChangePeriodModal}
              timeoutDuration={timeoutData?.data?.timeoutDuration}
            />
          )}
          {!isTimeoutEnabled ? (
            <TimeoutHeader />
          ) : (
            <TimeoutTable
              timeoutData={timeoutData}
              setTimeoutData={setTimeoutData}
              baseUrl={baseUrl}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeTimeoutForUsers={onChangeTimeoutForUsers}
            />
          )}
          {showToggleModal && (
            <ToggleEnableModal
              isOpen={showToggleModal}
              onClose={handleShowToggleModal}
              onChangeTimeoutForDomain={onChangeTimeoutForDomain}
              isTimeoutEnabled={isTimeoutEnabled}
            />
          )}
          {showChangePeriodModal && (
            <ChangeTimeoutPeriod
              isOpen={showChangePeriodModal}
              onClose={handleShowChangePeriodModal}
              domainId={domain.id}
              timeoutDuration={timeoutData?.data?.timeoutDuration}
              handleChangeDurationPeriod={handleChangeDurationPeriod}
            />
          )}
        </>
      )}
    </div>
  );
};

TimeoutManagement.propTypes = {
  domain: PropTypes.object,
  isCompanyPage: PropTypes.bool,
};

export default TimeoutManagement;
