import { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import PropTypes from 'prop-types';
import IdleBox from 'components/IdleBox/IdleBox';
import { useOutsideClick } from 'hooks';
import { HeaderUserIcon } from 'assets/img';
import BrandedLinks from 'components/BrandedLinks/BrandedLinks';
import HeaderDropdown from './components/HeaderDropdown';
import styles from './Header.module.scss';

const Header = ({ user, brandingData }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const nodeRef = useRef();

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <header className={styles.header}>
      <IdleBox />
      <div className={styles.headerRow}>
        {brandingData?.isActive && (
          <BrandedLinks
            contactInformation={brandingData?.contactInformation}
            isHeader
            className={styles.navbarBrandedLinks}
          />
        )}
        <div
          id="user-menu"
          className={cn(styles.headerMenu, { [styles.headerMenuOpened]: isDropdownOpen })}
          ref={dropdownRef}
        >
          <button type="button" className={styles.headerMenuPhoto} onClick={toggleDropdown}>
            {user.photo === 'default' ? <HeaderUserIcon /> : <img src={`data:image/jpeg;charset=utf-8;base64,${user.photo}`} alt="User" />}
          </button>

          <CSSTransition
            nodeRef={nodeRef}
            in={isDropdownOpen}
            timeout={200}
            unmountOnExit
            classNames={{
              enter: styles.headerDropdownTransitionEnter,
              enterActive: styles.headerDropdownTransitionEnterActive,
              exit: styles.headerDropdownTransitionExit,
              exitActive: styles.headerDropdownTransitionExitActive,
            }}
          >
            <div className={styles.headerDropdown} ref={nodeRef}>
              <HeaderDropdown
                user={user}
                onClose={toggleDropdown}
              />
            </div>
          </CSSTransition>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  brandingData: PropTypes.object,
};

export default Header;
