import PropTypes from 'prop-types';
import { SwitchCheckbox, Table } from 'components';
import { truncateString } from 'utils';
import styles from './AzureAdLoginTable.module.scss';

const AzureAdLoginTable = ({
  azureAdLoginData,
  setAzureAdLoginData,
  baseUrl,
  selectedUsers,
  setSelectedUsers,
  btnLoadingCurrent,
  onChangeStaySignedInForUsers,
}) => {
  const columns = [
    {
      checkbox: true,
      name: 'User',
      data: ({ displayName, upn }) => (
        <div>
          <div className={styles.tableName}>{truncateString(displayName, false) || 'N/A'}</div>
          <div className={styles.tableUpn}>{truncateString(upn, false)}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Stay Signed-In',
      data: (user) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeStaySignedInForUsers(false, user)}
            checked={user.isAzureAdStaySignedInEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isAzureAdStaySignedInEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isAzureAdStaySignedInEnabled',
      width: '60%',
    },
  ];
  return (
    <Table
      data={{
        data: azureAdLoginData.data.users,
        pageMeta: azureAdLoginData.pageMeta,
        queryConfig: azureAdLoginData.queryConfig,
      }}
      updateData={setAzureAdLoginData}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="azure-ad-login-table"
      baseUrl={baseUrl}
      dataKey="userId"
      className={styles.table}
    />
  );
};

AzureAdLoginTable.propTypes = {
  azureAdLoginData: PropTypes.object.isRequired,
  setAzureAdLoginData: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  btnLoadingCurrent: PropTypes.array.isRequired,
  onChangeStaySignedInForUsers: PropTypes.func.isRequired,
};

export default AzureAdLoginTable;
