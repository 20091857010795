export const getStatusMessage = (verificationData) => {
  if (verificationData.status === 'Email validated successfully') {
    if (verificationData.isPersonalAccount) {
      return {
        title: 'Email validated successfully',
        message: 'You have activated your account',
      };
    }
    return {
      title: 'Email validated successfully',
      message: 'You have activated your account. Please check your email with a temporary password to proceed with the registration',
    };
  }
  if (verificationData.status === 'Email updated successfully') {
    return {
      title: 'Email updated successfully',
      message: 'Please use your new email address to login',
    };
  }
  if (verificationData.status === 'Link is expired') {
    return {
      title: 'Link is expired',
      message: <>Click <b>Resend Email</b> button to get a new verification link</>,
    };
  }
  if (verificationData.status === 'Invalid link') {
    return {
      title: 'Invalid link',
      message: <>This verification link has already been used.<br />Please try to log in again</>,
    };
  }
  return {
    title: 'Invalid link',
    message: '',
  };
};
