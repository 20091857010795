import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, PasswordComplexity, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { notify } from 'utils';
import { isFormValid } from './utils/isFormValid';
import ResetPasswordError from '../../components/ResetPasswordError/ResetPasswordError';
import styles from './ForgotPassword.module.scss';

const initFormData = {
  newPassword: '',
  confirmNewPassword: '',
};

const initFormErrors = {
  newPassword: '',
  confirmNewPassword: '',
};

const ForgotPassword = () => {
  const [formData, setFormData] = useState(initFormData);
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const history = useHistory();
  const tooltipPassRef = useRef();
  const { token } = useParams();

  useEffect(() => {
    const checkToken = async () => {
      loginService.validatePasswordReset(token)
        .catch((e) => {
          if (e.response.data.message === 'Link already has been used.') {
            return setError(e.response.data.message);
          } if (e.response.data.message === 'Link is expired.') {
            return setError(e.response.data.message);
          }
          return setError(e.response.data);
        })
        .finally(() => setIsLoading(false));
    };
    if (token) {
      checkToken();
    } else {
      setIsLoading(false);
      history.replace('/');
    }
  }, [history, token]);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const onChangeUseState = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputFocus = (e) => {
    const { name } = e.target;
    if (name === 'newPassword') {
      setIsTooltipOpen(true);
    }
    setFormErrors(initFormErrors);
  };

  const onClearValue = (input) => {
    setFormData((prevState) => ({ ...prevState, [input]: '' }));
  };

  const onSetPassword = (e) => {
    e.preventDefault();
    if (isFormValid(formData, formErrors, setFormErrors)) {
      setIsUpdating(true);
      const { newPassword } = formData;
      loginService.setNewPassword(token, newPassword)
        .then(() => {
          history.replace('/password-success');
        })
        .catch((err) => {
          notify.error(err?.response.data?.message || err.message);
          setIsUpdating(false);
        });
    }
  };

  if (isLoading) {
    return <div className={styles.formContainer}><Loader /></div>;
  }

  if (error) {
    return (
      <ResetPasswordError error={error === 'Link is expired.' ? 'expired' : error === 'Link already has been used.' ? 'used' : ''} />
    );
  }

  return (
    <div className={styles.formContainer}>
      <LoginHeader title="Reset Your Password" hideSteps idle back={() => history.replace('/')} />
      <form className={styles.form} onSubmit={onSetPassword} noValidate>
        <div className={styles.passwordFormWrap} ref={tooltipPassRef}>
          <TextInput
            type="password"
            id="enter-new-password"
            placeholder="Enter New Password"
            name="newPassword"
            autoComplete="new-password"
            hasView
            value={formData.newPassword}
            error={formErrors.newPassword}
            onChange={onChangeUseState}
            onClear={() => onClearValue('newPassword')}
            onFocus={onInputFocus}
          />
          {isTooltipOpen && (
            <PasswordComplexity inputValue={formData.newPassword} />
          )}
        </div>
        <div className={styles.passwordFormWrap}>
          <TextInput
            type="password"
            id="confirm-new-password"
            placeholder="Confirm New Password"
            name="confirmNewPassword"
            autoComplete="new-password"
            hasView
            value={formData.confirmNewPassword}
            error={formErrors.confirmNewPassword}
            onChange={onChangeUseState}
            onClear={() => onClearValue('confirmNewPassword')}
            onFocus={onInputFocus}
          />
        </div>
        <Button
          type="submit"
          id="set-new-password"
          size="48"
          className={styles.formButton}
          variant="primary"
          isDisabled={Object.values(formData).some((item) => !item)}
          isLoading={isUpdating}
        >
          Set New Password
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
