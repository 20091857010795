import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { checkIsPasswordValid } from 'helpers';
import { Modal, TextInput } from 'components';
import styles from './EncryptPassModal.module.scss';

const EncryptPassModal = ({ encryptPassModalShow, closeEncryptPassModalShow, modalData, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const { deviceId } = modalData.encrypt;

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];
    const isNewPassValid = checkIsPasswordValid(formValues[0]);
    const isConfirmPassValid = formValues[1] && formValues[0] === formValues[1];

    if (!isNewPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const onUpdateInput = (value, index) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value.trim() : i));
    setFormValues(passValuesNew);
  };

  const onClearError = (index) => {
    const passErrorsNew = formErrors.map((i, ind) => (index === ind ? '' : i));
    setFormErrors(passErrorsNew);
  };

  const onClearValue = (index, ref) => {
    ref.current.focus();

    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const createEncrypt = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsLoading(true);
      setDone(true);

      const password = formValues[0];
      const confirmedPassword = formValues[1];

      const params = {
        deviceId,
        password,
        confirmedPassword,
      };

      axios.post('/api/EncryptionPassword/Create', params)
        .then(() => axios.put('/api/IoTAgent/Encrypt', { deviceId: params.deviceId }))
        .then((resp) => {
          onUpdate(resp.data, modalData.encrypt);
          closeEncryptPassModalShow(done, deviceId);
        })
        .catch((err) => {
          setDone(false);
          toast.error(err.response?.data.message || err.message, 3000);
          closeEncryptPassModalShow(!done, deviceId);
        });
    }
  };

  return (
    <Modal
      isOpen={encryptPassModalShow}
      onRequestClose={() => closeEncryptPassModalShow(!done, deviceId)}
      contentLabel="Create encryption password for drives"
      style={{ content: { width: '39em' } }}
      actionTitle="Create"
      actionFunc={createEncrypt}
      actionLoading={isLoading}
      actionDisabled={formValues.includes('')}
    >
      <form className={styles.encryptPass} method="POST" onSubmit={createEncrypt}>
        <p className={styles.encryptPassTitle}>By creating an Encryption Password, you will be able to encrypt Drives on your PCs and make your data secure</p>
        <div className={styles.encryptPassWrap}>
          <TextInput
            ref={newPassRef}
            id={'enter new password'.split(' ').join('-')}
            type="password"
            hasView
            autoComplete="new-password"
            placeholder="Enter New Password"
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdateInput(e.target.value, 0)}
            onClear={() => onClearValue(0, newPassRef)}
            onFocus={() => onClearError(0)}
          />
        </div>
        <div className={styles.encryptPassWrap}>
          <TextInput
            ref={confirmPassRef}
            id={'confirm new password'.split(' ').join('-')}
            placeholder="Confirm New Password"
            type="password"
            hasView
            autoComplete="new-password"
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdateInput(e.target.value, 1)}
            onClear={() => onClearValue(1, confirmPassRef)}
            onFocus={() => onClearError(1)}
          />
        </div>
        <div className={styles.encryptPassPassInfo}>
          <p className={styles.encryptPassPassInfoTitle}>Encryption Password should contain:</p>
          <ul className={styles.encryptPassPassInfoList}>
            <li>- at least 8 characters</li>
            <li>- at least one number</li>
            <li>- both lower and uppercase letters</li>
            <li>- special characters</li>
          </ul>
        </div>
      </form>
    </Modal>
  );
};

EncryptPassModal.propTypes = {
  encryptPassModalShow: PropTypes.bool,
  closeEncryptPassModalShow: PropTypes.func,
  modalData: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default EncryptPassModal;
