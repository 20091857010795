import { Slide } from 'react-toastify';

export const toastSettings = {
  position: 'top-center',
  theme: 'colored',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
  delay: false,
  transition: Slide,
};
