import cn from 'classnames';
import { CheckIcon, CloseIcon, DeviceIcon, InfoActiveIcon, InfoIcon } from 'assets/img';
import windows from 'assets/img/windows-os.svg';
import mac from 'assets/img/mac-os.svg';
import { Button } from 'components';
import { formattedDate } from 'helpers';
import styles from '../Devices.module.scss';

const dataTipHandler = (osType, hasLicense, appVersion, osVersion) => {
  const icon = osType === 0 ? windows : mac;
  if (hasLicense) {
    return `
      <p>Agent Version: <span>${appVersion}</span></p>
      <p style="display: inline-flex;align-items: center;">OS Version: <span style="display: inline-flex;align-items: center;"><img src="${icon}" alt="" /> ${osVersion}</span></p>
    `;
  }
  return 'Unlicensed';
};

export const tableColumns = (isInfoActive, setIsInfoActive, installationStatus, openUninstallAgentsModalShow, openDeleteDevicesModalShow) => [
  {
    name: '',
    width: '6%',
    checkbox: ({ isOnline, installationStatus: status }) => !(status === 2 && isOnline),
  },
  {
    name: 'Hostname',
    sortable: 'hostname',
    width: () => (installationStatus === 'AllStatuses' ? '29%' : '23%'),
    data: ({ deviceId, osType, hasLicense, appVersion, osVersion, isOnline, hostname, installationStatus: status }) => (
      <div className={styles.name}>
        <span className={styles.nameHostname}>
          <DeviceIcon className={cn(
            styles.nameHostnameIcon,
            {
              [styles.nameHostnameIcon]: !hasLicense,
            },
            {
              [styles.nameHostnameIconOnline]: isOnline,
            },
          )}
          />
          <span className={styles.nameHostnameName}>{hostname}</span>
          <div
            className={styles.nameHostnameWrap}
            onMouseEnter={() => setIsInfoActive(deviceId)}
            onMouseLeave={() => setIsInfoActive('')}
          >
            <div
              data-tip={dataTipHandler(osType, hasLicense, appVersion, osVersion)}
              data-for="info-tooltip"
              data-iscapture="true"
              className={styles.nameHostnameTooltip}
            />
            {isInfoActive === deviceId ? <InfoActiveIcon /> : <InfoIcon />}
          </div>
        </span>
        <div className={styles.nameStatus}>
          {status === 0 ? (
            <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoInstalled)}><CheckIcon /> Installed</span>
          ) : (
            status === 1 ? (
              <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoUninstalled)}><CloseIcon /> Uninstalled</span>
            ) : (
              <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoPending)}><CloseIcon /> Pending uninstall</span>
            )
          )}
        </div>
      </div>
    ),
  },
  {
    name: 'Status',
    width: '9%',
    data: ({ isOnline }) => (isOnline ? (
      <p className={cn(styles.status, styles.statusOnline)}>Online</p>
    ) : (
      <p className={cn(styles.status, styles.statusOffline)}>Offline</p>
    )),
  },
  {
    name: 'Dates & Times',
    sortable: 'dateAdded',
    width: '30%',
    tooltip: {
      tip: 'Sort by Date Added',
      id: 'sort-by-date',
    },
    data: ({ dateAdded, lastConnectedDate }) => (
      <>
        <div className={styles.deviceInfo}>Added: <span>{formattedDate(dateAdded)}</span></div>
        <div className={styles.deviceInfo}>Last Connected: <span>{formattedDate(lastConnectedDate)}</span></div>
      </>
    ),
  },
  {
    name: 'IP Address',
    width: '22%',
    data: ({ externalIp, internalIp }) => (
      <>
        <div className={styles.deviceInfo}>External: <span>{externalIp}</span></div>
        <div className={styles.deviceInfo}>Internal: <span>{internalIp}</span></div>
      </>
    ),
  },
  {
    name: 'Actions',
    width: '10%',
    data: ({ installationStatus: status, isOnline, deviceId }) => (
      status === 0 ? (
        <Button
          variant="link"
          id="uninstall-device"
          className={cn(styles.deviceButton, styles.deviceButtonUninstall)}
          onClick={() => openUninstallAgentsModalShow(deviceId)}
        >
          Uninstall
        </Button>
      ) : !(status === 2 && isOnline) && (
      <Button
        variant="link"
        id="delete-device"
        className={cn(styles.deviceButton, styles.deviceButtonDelete)}
        onClick={() => openDeleteDevicesModalShow(deviceId)}
      >
        Delete
      </Button>
      )),
  },
];
