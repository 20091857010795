import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import VerificationCode from './pages/VerificationCode';
import ValidatePhone from './pages/ValidatePhone';

const ResetViaSMS = ({ phoneMask, isAdmin }) => {
  const { path, url } = useRouteMatch();

  const [mfaHash, setMfaHash] = useState('');
  const [phoneDigits, setPhoneDigits] = useState('');

  return (
    <Switch>
      <Route path={path} exact>
        <ValidatePhone
          setMfaHash={setMfaHash}
          phoneDigits={phoneDigits}
          setPhoneDigits={setPhoneDigits}
          isAdmin={isAdmin}
          phoneMask={phoneMask}
        />
      </Route>
      <Route path={`${url}/verification-code`}>
        <VerificationCode
          phoneDigits={phoneDigits}
          mfaHash={mfaHash}
          setMfaHash={setMfaHash}
          phoneMask={phoneMask}
        />
      </Route>
    </Switch>
  );
};

ResetViaSMS.propTypes = {
  phoneMask: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default ResetViaSMS;
