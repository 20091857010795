import PropTypes from 'prop-types';
import { ArrowLeftIcon } from 'assets/img';
import { Button, IdleBoxLogin } from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './LoginHeader.module.scss';

const LoginHeader = ({ hideSteps, hideBack, title, currentStep, stepCount, idle, back }) => {
  const history = useHistory();
  const { key } = useLocation();
  const goBack = () => (back ? back() : key ? history.goBack() : history.replace('/login'));

  return (
    <div className={styles.loginHeader}>
      {!hideBack && (
        <Button variant="link" icon={<ArrowLeftIcon />} onClick={goBack} className={styles.loginHeaderBack} />
      )}
      {!hideSteps && (
        <div className={styles.loginHeaderStep}>Step {currentStep} of {stepCount}</div>
      )}
      <h3 className={styles.loginHeaderTitle}>{title}</h3>
      {idle && (
        <div className={styles.loginHeaderIdle}>
          <IdleBoxLogin />
        </div>
      )}
    </div>
  );
};

LoginHeader.propTypes = {
  hideSteps: PropTypes.bool,
  hideBack: PropTypes.bool,
  title: PropTypes.string.isRequired,
  currentStep: PropTypes.string,
  stepCount: PropTypes.string,
  idle: PropTypes.bool,
  back: PropTypes.func,
};

export default LoginHeader;
