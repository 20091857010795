import { truncateString } from 'utils';
/**
 * Determines the action type for Entra ID Stay Signed-In feature based on selected users and user object.
 * @param {Array} selectedUsers - An array of user objects that are selected.
 * @param {Object} user - A user object.
 * @returns {string} - A string that describes the action type for Entra ID Stay Signed-In feature.
 */
export const checkActionType = (selectedUsers, user) => {
  if (selectedUsers.length >= 1) {
    if (selectedUsers.length > 1) {
      let actionType = 'updated';
      switch (true) {
        case selectedUsers.every((i) => !i.isAzureAdStaySignedInEnabled):
          actionType = 'enabled';
          break;
        case selectedUsers.every((i) => i.isAzureAdStaySignedInEnabled):
          actionType = 'disabled';
          break;
        default:
          actionType = 'updated';
      }
      return `Stay Signed-In is ${actionType} for selected users`;
    }
    const actionType = selectedUsers.every((i) => i.isAzureAdStaySignedInEnabled) ? 'disabled' : 'enabled';
    return `Stay Signed-In is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`;
  }
  return `Stay Signed-In is ${user.isAzureAdStaySignedInEnabled ? 'disabled' : 'enabled'} for ${truncateString(
    user.displayName,
    false,
  )}`;
};
