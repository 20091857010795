import { useState, useEffect, useContext } from 'react';
import axios from 'config/customAxios';
import { useHistory } from 'react-router-dom';
import { UserContext, getUserRole } from 'utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, Loader, StatusMessage } from 'components';
import { toast } from 'react-toastify';
import { ArrowLeftIcon } from 'assets/img';
import { corpAdmin, corpTechAdmin, corpTechUser, freePersonalUser, personalUser } from 'consts';
import PrimaryDomain from './components/PrimaryDomain/PrimaryDomain';
import CustomerDomain from './components/CustomerDomain/CustomerDomain';
import ReactivateModal from './components/ReactivateModal/ReactivateModal';
import SuspendedMessage from './components/SuspendedMessage/SuspendedMessage';
import styles from './Subscriptions.module.scss';

const Subscriptions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [licenses, setLicenses] = useState(null);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const role = getUserRole(user);
  const isPersonal = [personalUser, freePersonalUser].includes(role);
  const isNoTabs = [corpAdmin, corpTechAdmin, corpTechUser, personalUser, freePersonalUser].includes(role);

  const toggleShowReactivateModal = () => {
    setShowReactivateModal(!showReactivateModal);
  };

  useEffect(() => {
    const url = isPersonal ? '/api/SubscriptionPlan/Get' : '/api/Chargify/GetCurrentSubscriptionStatus';

    axios
      .get(url)
      .then((resp) => setLicenses(resp.data))
      .catch((error) => setLicenses({ isError: true, error: error.message }))
      .finally(() => setIsLoading(false));
  }, [isPersonal]);

  const reactivateSubscription = () => {
    toggleShowReactivateModal();
    setIsReactivating(true);

    axios
      .post('/api/Chargify/ReactivateSubscription')
      .then(({ data }) => {
        setLicenses(data);
        toast.success('Subscription reactivated', 3000);
      })
      .catch(() => toast.error('Subscription reactivation failed', 3000))
      .finally(() => setIsReactivating(false));
  };

  if (licenses?.isError) {
    return (
      <div className={styles.subscriptions}>
        <StatusMessage error>{licenses.error}</StatusMessage>
      </div>
    );
  }

  return (
    <div className={styles.subscriptions}>
      {licenses && !licenses.isSubscriptionActive && !isPersonal && (
        <SuspendedMessage
          role={role}
          isReactivating={isReactivating}
          toggleShowReactivateModal={toggleShowReactivateModal}
        />
      )}
      <div className={styles.subscriptionsHeader}>
        <Button
          variant="link"
          id="subs-go-back"
          className={styles.subscriptionsHeaderBack}
          icon={<ArrowLeftIcon />}
          onClick={() => history.goBack()}>
          Back
        </Button>
        <span className={styles.subscriptionsHeaderDomain}>{user.dnsDomainName && `(${user.dnsDomainName})`}</span>
      </div>
      <div className={styles.subscriptionsContent}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.subscriptionsTabs}>
            <Tabs>
              <div className={styles.subscriptionsTabsWrap}>
                <h2 className={styles.subscriptionsTabsTitle}>Subscriptions</h2>
                <TabList className={`react-tabs__tab-list ${isNoTabs ? 'no-tabs' : ''}`}>
                  <Tab disabled={!licenses.customerDomains?.length && !isPersonal}>
                    <span id="primary-domain">Primary Domain</span>
                  </Tab>
                  <Tab disabled={!licenses.customerDomains?.length && !isPersonal}>
                    <span id="customer-domains">Customer Domains</span>
                  </Tab>
                </TabList>
              </div>
              <TabPanel>
                <PrimaryDomain
                  role={role}
                  licenses={licenses}
                  email={user.email}
                  onUpdateLicenses={(data) => setLicenses(data)}
                />
              </TabPanel>
              <TabPanel>
                <CustomerDomain user={user} licenses={licenses} onUpdateLicenses={(data) => setLicenses(data)} />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
      {showReactivateModal && (
        <ReactivateModal
          showReactivateModal={showReactivateModal}
          toggleShowReactivateModal={toggleShowReactivateModal}
          licenses={licenses}
          reactivateSubscription={reactivateSubscription}
        />
      )}
    </div>
  );
};

export default Subscriptions;
