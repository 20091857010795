import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Loader, RouteLeavingGuard } from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserRole, notify, objectsAreNotEqual, queryFormatter, UserContext } from 'utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { corpAdmin, corpTechAdmin } from 'consts';
import { invertColor } from './helpers/colorsHelper';
import BrandingHeader from './components/BrandingHeader/BrandingHeader';
import BrandingFooter from './components/BrandingFooter/BrandingFooter';
import BrandingEdit from './components/BrandingEdit/BrandingEdit';
import BrandingPreview from './components/BrandingPreview/BrandingPreview';
import ModalBrandingClear from './components/ModalBrandingClear/ModalBrandingClear';
import ModalBrandingInfo from './components/ModalBrandingInfo/ModalBrandingInfo';
import BrandingNotAllowed from './components/BrandingNotAllowed/BrandingNotAllowed';
import BrandingCustomers from './components/BrandingCustomers/BrandingCustomers';
import styles from './Branding.module.scss';
import PreventLeaveModal from './components/PreventLeaveModal/PreventLeaveModal';

const Branding = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const role = getUserRole(user);
  const hideCustomersTab = role === corpAdmin || role === corpTechAdmin;

  const [isLoading, setIsLoading] = useState(true);
  const [brandingInfo, setBrandingInfo] = useState({ init: null, modified: null });
  const [brandingStatus, setBrandingStatus] = useState(brandingInfo?.init?.isActive);
  const [brandingError, setBrandingError] = useState({ error: false, type: null });
  const [hexError, setHexError] = useState(false);
  const [showModalBrandingClear, setShowModalBrandingClear] = useState(false);
  const [showModalBrandingInfo, setShowModalBrandingInfo] = useState(false);
  const invertedColor = invertColor(brandingInfo?.modified?.backGroundColor);
  const invertedButtonColor = invertColor(brandingInfo?.modified?.buttonColor);

  const [domains, setDomains] = useState({});
  const [domainsEmpty, setDomainsEmpty] = useState(false);
  const [isDomainsLoading, setIsDomainsLoading] = useState(true);
  const baseUrl = `/api/Company/GetCustomerDomainsBranding?domainId=${user.domainId}`;

  const isBrandingInfoChanged = objectsAreNotEqual({ initial: brandingInfo.init, modified: brandingInfo.modified });
  const isDisableClearBranding =
    !brandingInfo?.init?.backGroundColor &&
    !brandingInfo?.init?.buttonColor &&
    !brandingInfo?.init?.logo &&
    !brandingInfo?.init?.isSetContactInfo &&
    brandingInfo?.init?.applyToDesktopConnectors;

  const [isShowLeaveModal, setIsShowLeaveModal] = useState(false);
  const toggleShowLeaveModal = () => setIsShowLeaveModal(!isShowLeaveModal);

  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('tab') === 'customer-domains') {
      setTabIndex(1);
    }
  }, [search]);

  const onTabSelect = (index) => {
    if (isBrandingInfoChanged) {
      toggleShowLeaveModal();
      return;
    }
    const params = new URLSearchParams(search);
    if (params.get('tab')) {
      params.delete('tab');
      history.replace({
        search: params.toString(),
      });
    }
    setTabIndex(index);
  };

  const handleConfirmLeave = () => {
    setBrandingInfo((prevState) => ({ ...prevState, modified: prevState.init }));
    toggleShowLeaveModal();
    setTabIndex(1);
  };

  useEffect(() => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'dnsName asc',
      searchQuery: '',
      viewBy: 'All',
    };

    const fetchData = async () => {
      try {
        const res = await axios.get(queryFormatter(baseUrl, queryConfig));
        const pageMeta = res.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null;
        if (res.status === 204) {
          setDomainsEmpty(true);
        } else {
          setDomains({ data: res.data, pageMeta, queryConfig });
        }
      } catch (err) {
        notify.error(err.response.data.message || err.message);
      } finally {
        setIsDomainsLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, setDomains, user.domainId]);

  const toggleShowModalBrandingClear = () => setShowModalBrandingClear(!showModalBrandingClear);
  const toggleShowModalBrandingInfo = () => setShowModalBrandingInfo(!showModalBrandingInfo);

  useEffect(() => {
    setBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, useInvertedColorScheme: invertedColor || false },
      init: brandingInfo.init,
    }));
  }, [brandingInfo.init, invertedColor]);

  useEffect(() => {
    const getBrandingInfo = () => {
      axios
        .get(`/api/Company/GetBranding?domainId=${user.domainId}`)
        .then(({ data }) => {
          setBrandingInfo({ init: data, modified: data });
          setBrandingStatus(data.isActive);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setBrandingError({ error: true, type: 'licensesSuspended' });
          } else if (err.response.status === 406) {
            setBrandingError({ error: true, type: 'licensesNotAvaliable' });
          } else {
            toast.error(err.response.data.message || err.message, 3000);
          }
        })
        .finally(() => setIsLoading(false));
    };
    getBrandingInfo();
    return () => setIsLoading(false);
  }, [user.domainId]);

  const toggleBrandingStatus = () => {
    setBrandingStatus(!brandingStatus);
    axios
      .post('/api/Company/ToggleBrandingStatus', user.domainId)
      .then(() => {
        setBrandingInfo((prevState) => ({
          modified: { ...prevState.modified, isActive: !prevState.modified.isActive },
          init: { ...prevState.init, isActive: !prevState.init.isActive },
        }));
        notify.success(`Branding Status is ${brandingStatus ? 'disabled' : 'enabled'} for ${user.dnsDomainName}`);
      })
      .catch((err) => {
        setBrandingStatus(brandingStatus);
        toast.error(err.response.data || err.message, 3000);
      });
  };

  if (isLoading) {
    return (
      <div className={styles.branding}>
        <Loader id="branding-loader" />
      </div>
    );
  }

  if (brandingError.error) {
    return <BrandingNotAllowed errorType={brandingError.type} />;
  }

  return (
    <div className={styles.branding}>
      <div>
        <h3 className={styles.brandingTitle}>Branding</h3>
        <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
          <TabList className={`react-tabs__tab-list ${hideCustomersTab ? 'no-tabs' : ''}`}>
            <Tab>Primary Domain</Tab>
            {!hideCustomersTab && <Tab>Customer Domains</Tab>}
          </TabList>
          <TabPanel>
            <BrandingHeader
              brandingStatus={brandingStatus}
              toggleBrandingStatus={toggleBrandingStatus}
              brandingInfo={brandingInfo}
              updateBrandingInfo={(data) => setBrandingInfo(data)}
            />
            <main className={cn(styles.brandingMain, { [styles.disabled]: !brandingInfo.modified.isActive })}>
              <BrandingEdit
                updateBrandingInfo={(data) => setBrandingInfo(data)}
                brandingInfo={brandingInfo}
                toggleShowModalBrandingInfo={toggleShowModalBrandingInfo}
                hexError={hexError}
                setHexError={setHexError}
              />
              <BrandingPreview
                brandingInfo={brandingInfo}
                invertedColor={invertedColor}
                invertedButtonColor={invertedButtonColor}
              />
            </main>
            <div className={cn({ [styles.brandingFooterDisabled]: !brandingInfo.modified.isActive })}>
              <BrandingFooter
                brandingStatus={brandingStatus}
                isDisableClearBranding={isDisableClearBranding}
                toggleShowModalBrandingClear={toggleShowModalBrandingClear}
                brandingInfo={brandingInfo}
                updateBrandingInfo={(data) => setBrandingInfo(data)}
                isBrandingInfoChanged={isBrandingInfoChanged}
                hexError={hexError}
                domainName={user.dnsDomainName}
              />
            </div>
          </TabPanel>
          {!hideCustomersTab && (
            <TabPanel>
              <BrandingCustomers
                domains={domains}
                domainsEmpty={domainsEmpty}
                setDomains={setDomains}
                isLoading={isDomainsLoading}
                baseUrl={baseUrl}
              />
            </TabPanel>
          )}
        </Tabs>

        <RouteLeavingGuard
          when={isBrandingInfoChanged}
          navigate={({ pathname, state }) => history.push(pathname, state)}
          shouldBlockNavigation={() => isBrandingInfoChanged}
          modalConfig={{
            label: 'Leave Modal',
            title: 'Discard changes',
            message: 'Do you really want to leave and discard changes?',
            btnClose: 'Stay on Page',
            btnAccept: 'Leave',
          }}
        />
        {isShowLeaveModal && (
          <PreventLeaveModal
            isOpen={isShowLeaveModal}
            closeModal={toggleShowLeaveModal}
            handleConfirmLeave={handleConfirmLeave}
            modalConfig={{
              label: 'Leave Modal',
              title: 'Discard changes',
              message: 'Do you really want to leave and discard changes?',
              btnClose: 'Stay on Page',
              btnAccept: 'Leave',
            }}
          />
        )}
        {showModalBrandingInfo && (
          <ModalBrandingInfo
            isOpen={showModalBrandingInfo}
            onRequestClose={toggleShowModalBrandingInfo}
            updateBrandingInfo={(data) => setBrandingInfo(data)}
            domainId={user.domainId}
          />
        )}
        {showModalBrandingClear && (
          <ModalBrandingClear
            isOpen={showModalBrandingClear}
            onRequestClose={toggleShowModalBrandingClear}
            updateBrandingInfo={(data) => setBrandingInfo(data)}
            domainId={user.domainId}
            domainName={user.dnsDomainName}
          />
        )}
      </div>
    </div>
  );
};

export default Branding;
