import { useEffect, useState, useContext } from 'react';
import axios from 'config/customAxios';
import cn from 'classnames';
import { Loader } from 'components';
import { getUserRole, UserContext } from 'utils';
import { corpAdmin, spAdmin, spAdminNotActivated } from 'consts';
import styles from './MyProfile.module.scss';
import AccountInfo from './components/AccountInfo/AccountInfo';

const MyProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { user } = useContext(UserContext);
  const role = getUserRole(user);
  const isUpdateEmailHidden = ![spAdmin, spAdminNotActivated, corpAdmin].includes(role);

  useEffect(() => {
    axios.get('/api/account/GetUserData')
      .then(({ data }) => setUserData(data))
      .catch((err) => setUserData({ isError: true, error: err.response.data || err.message }))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <div className={styles.myProfile}><Loader /></div>;

  return (
    <div className={styles.myProfile}>
      <div>
        <h3 className={cn(styles.myProfileTitle, { [styles.personal]: isUpdateEmailHidden })}>
          My Profile
        </h3>
        <AccountInfo
          userData={userData}
          isUpdateEmailHidden={isUpdateEmailHidden}
        />
      </div>
    </div>
  );
};

export default MyProfile;
