import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Loader } from 'components';
import { AlertWarning } from 'assets/img';
import { checkIfNumberInRange } from 'helpers';
import axios from 'config/customAxios';
import { notify } from 'utils';
import styles from './ForgotPasswordMgmt.module.scss';
import ForgotPasswordMgmtHeader from './components/ForgotPasswordMgmtHeader/ForgotPasswordMgmtHeader';

const ForgotPasswordMgmt = ({ domain, isCompanyPage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [passData, setPassData] = useState(null);
  const [attempts, setAttempts] = useState(passData?.resetAttemptsCount);
  const [period, setPeriod] = useState(passData?.retryTimePeriod);
  const [duration, setDuration] = useState(passData?.lockoutDuration);
  const [formErrors, setFormErrors] = useState(['', '', '']);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(`/api/SecurityManagement/AdPasswordReset/Get?domainId=${domain.id}`);
      const { resetAttemptsCount, retryTimePeriod, lockoutDuration } = data;
      setPassData(data);
      setAttempts(resetAttemptsCount);
      setPeriod(retryTimePeriod);
      setDuration(lockoutDuration);
    } catch (error) {
      notify.error(error.response?.data.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [domain.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const checkIfSettingsChanged = () => {
    const modifiedData = {
      domainId: domain.id,
      lockoutDuration: Number(duration),
      retryTimePeriod: Number(period),
      resetAttemptsCount: Number(attempts),
    };
    return JSON.stringify(passData) === JSON.stringify(modifiedData);
  };

  const onChangeInput = (type) => (e) => {
    let { value } = e.target;
    const { maxLength } = e.target;
    value = value.slice(0, maxLength);
    if (value < 0) {
      return false;
    }
    if (type === 'attempts') {
      if (Number(value) === 0) {
        setFormErrors(['', '', '']);
      }
      setAttempts(value);
    }
    if (type === 'period') {
      setPeriod(value);
    }
    if (type === 'duration') {
      setDuration(value);
    }
  };

  const onClearError = (index) => {
    const passErrorsNew = formErrors.map((i, ind) => (index === ind ? '' : i));
    setFormErrors(passErrorsNew);
  };

  const checkIsValid = () => {
    const formErrorsNew = [...formErrors];
    if (!checkIfNumberInRange(attempts, 0, 99) || attempts === '') {
      formErrorsNew[0] = 'You can set from 0 to 99 attempts';
    }
    if (Number(attempts) !== 0) {
      if (!checkIfNumberInRange(Number(period), 5, 90)) {
        formErrorsNew[1] = 'You can set from 5 to 90 mins';
      }
      if (!checkIfNumberInRange(Number(duration), 5, 1440)) {
        formErrorsNew[2] = 'You can set from 5 to 1440 mins (24h)';
      }
    }
    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }
    return !formErrorsNew.some((i) => i);
  };

  const resetToDefault = () => {
    setFormErrors(['', '', '']);
    axios({
      method: 'post',
      url: '/api/SecurityManagement/AdPasswordReset/ResetToDefault',
      data: domain.id,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { lockoutDuration, resetAttemptsCount, retryTimePeriod } = data;
        setPassData(data);
        notify.success('Settings reset to default');
        setAttempts(resetAttemptsCount);
        setPeriod(retryTimePeriod);
        setDuration(lockoutDuration);
      })
      .catch((err) => notify.error(err.response?.data.message || err.message))
      .finally(() => setIsLoading(false));
  };

  const savePassMgmt = () => {
    if (checkIsValid()) {
      setIsButtonLoading(true);
      const data = {
        domainId: domain.id,
        lockoutDuration: Number(duration),
        retryTimePeriod: Number(period),
        resetAttemptsCount: Number(attempts),
      };
      axios
        .post('/api/SecurityManagement/AdPasswordReset/UpdateStatus', data)
        .then(() => {
          notify.success('Settings saved');
          setPassData(data);
        })
        .catch((err) => notify.error(err.response?.data.message || err.message))
        .finally(() => setIsButtonLoading(false));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.passMgmt}>
      <div className={styles.passMgmtContent}>
        <ForgotPasswordMgmtHeader domain={domain} isCompanyPage={isCompanyPage} />
        <h3 className={styles.passMgmtTitle}>Forgot Password Management</h3>

        <div className={styles.passMgmtList}>
          <div className={styles.passMgmtMessage}>
            <div className={styles.passMgmtMessageIcon}>
              <AlertWarning />
            </div>
            {String(attempts) !== '0' ? (
              <>
                After making more than <span>{attempts || passData.resetAttemptsCount}</span> attempts within{' '}
                <span>{period || passData.retryTimePeriod}</span> mins, AD user won't be able to reset password for{' '}
                <span>{duration || passData.lockoutDuration}</span> mins
              </>
            ) : (
              <>
                Setting <span>{attempts || passData.resetAttemptsCount}</span> attempt means failed resets will not be
                blocked
              </>
            )}
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Password Reset Attempts</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>0</strong> and <strong>99</strong> failed reset attempts
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    id="attempts"
                    type="number"
                    className={cn(styles.passMgmtInput, { [styles.passMgmtInputError]: formErrors[0] })}
                    value={attempts}
                    maxLength={2}
                    onChange={onChangeInput('attempts')}
                    onFocus={() => onClearError(0)}
                  />
                  {formErrors[0] && <div className={styles.passMgmtError}>{formErrors[0]}</div>}
                  <label className={styles.passMgmtLabel}>Attempts</label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Retry Time Period</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>5</strong> and <strong>90</strong> mins for retries
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    id="period"
                    type="number"
                    className={cn(styles.passMgmtInput, {
                      [styles.passMgmtInputError]: formErrors[1],
                      [styles.passMgmtInputDisabled]: Number(attempts) === 0,
                    })}
                    value={period}
                    maxLength={2}
                    disabled={Number(attempts) === 0}
                    onFocus={() => onClearError(1)}
                    onChange={onChangeInput('period')}
                  />
                  {formErrors[1] && <div className={styles.passMgmtError}>{formErrors[1]}</div>}
                  <label className={styles.passMgmtLabel}>Mins</label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Password Reset Lockout Duration</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>5</strong> and <strong>1440 mins (24h)</strong> for lockout duration
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    type="number"
                    id="duration"
                    className={cn(styles.passMgmtInput, {
                      [styles.passMgmtInputError]: formErrors[2],
                      [styles.passMgmtInputDisabled]: Number(attempts) === 0,
                    })}
                    value={duration}
                    maxLength={4}
                    disabled={Number(attempts) === 0}
                    onFocus={() => onClearError(2)}
                    onChange={onChangeInput('duration')}
                  />
                  {formErrors[2] && <div className={styles.passMgmtError}>{formErrors[2]}</div>}
                  <label className={styles.passMgmtLabel}>Mins</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.passMgmtActions}>
        <Button id="close-modal-enable" onClick={resetToDefault} size="40" variant="secondary">
          Reset to Default
        </Button>
        <Button
          id="enable-password-mgmt"
          onClick={savePassMgmt}
          size="40"
          variant="primary"
          isDisabled={checkIfSettingsChanged()}
          isLoading={isButtonLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};

ForgotPasswordMgmt.propTypes = {
  domain: PropTypes.object,
  isCompanyPage: PropTypes.object,
};

export default ForgotPasswordMgmt;
