import { useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { Table } from 'components';
import { custAdmin } from 'consts';
import LicensesActions from './components/LicensesActions/LicensesActions';
import ExportDetailsModal from './components/ExportDetailsModal/ExportDetailsModal';
import styles from './Licensing.module.scss';

const Licensing = ({
  licenses,
  primaryDomain,
  userRole,
  selectedLicenseType,
  onUpdateLicenseType,
  licensingReportData,
  setIsFastGenerate,
  isFastGenerate,
  setLicensingReportData,
  isCompanyPage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [findValue, setFindValue] = useState('');

  const allLicensesCount = licenses.reduce((prev, cur) => prev + cur.licensesCount, 0);

  const [showExportDetailsModal, setShowExportDetailsModal] = useState(false);

  const toggleExportDetailsModal = () => {
    setShowExportDetailsModal(!showExportDetailsModal);
  };

  const saveFile = (reportData) => {
    const fileName = reportData.downloadLink.split('/').slice(-1).toString();
    saveAs(
      `${reportData.downloadLink}`,
      `${fileName}`,
    );
  };

  const onChangeLicenseType = (selectedLicenseTypeNew) => {
    setIsLoading(true);
    onUpdateLicenseType(selectedLicenseTypeNew).then(() => setIsLoading(false));
  };

  const licensesFindValue = () => {
    let licensesNew = licenses;
    if (findValue) {
      licensesNew = licensesNew.filter((d) => d.domainName.toLowerCase().includes(findValue.toLowerCase()));
    }
    return { data: licensesNew, queryConfig: { searchQuery: findValue } };
  };

  const columns = [
    {
      name: 'Domains',
      width: '40%',
      data: ({ domainName }) => `${domainName} ${(domainName === primaryDomain && isCompanyPage && userRole !== custAdmin) ? '(Primary)' : ''}`,
      search: 'Domain Name',
      searchNotDebounce: setFindValue,
    },
    {
      name: 'Over/Under Allocated',
      width: '20%',
      data: ({ licensesCount, usedLicensesCount }) => licensesCount - usedLicensesCount,
    },
    {
      name: selectedLicenseType === 'SecureRDP' ? 'Users Reporting in' : 'Installed IoT Agents',
      width: '20%',
      data: 'usedLicensesCount',
    },
    {
      name: 'Licenses Allocated',
      width: '20%',
      data: 'licensesCount',
    },
  ];

  return (
    <>
      <LicensesActions
        isCompanyPage={isCompanyPage}
        selectedLicenseType={selectedLicenseType}
        onChangeLicenseType={onChangeLicenseType}
        isLoading={isLoading}
        licensesCount={allLicensesCount}
        userRole={userRole}
        setShowExportDetailsModal={setShowExportDetailsModal}
        isFastGenerate={isFastGenerate}
        saveFile={saveFile}
        licensingReportData={licensingReportData}
      />

      <Table
        columns={columns}
        data={licensesFindValue()}
        id="licensing-table"
        isLoading={isLoading}
        dataKey="domainId"
        pagination={false}
        className={styles.licensingTable}
        classNameRow={({ licensesCount, usedLicensesCount }) => {
          const diff = licensesCount - usedLicensesCount;
          return diff !== 0 ? diff < 0 ? styles.licensingTableRowNegative : styles.licensingTableRowPositive : '';
        }}
      />

      {showExportDetailsModal && (
        <ExportDetailsModal
          isFastGenerate={isFastGenerate}
          setIsFastGenerate={setIsFastGenerate}
          primaryDomain={primaryDomain}
          licenses={licenses}
          showExportDetailsModal={showExportDetailsModal}
          toggleExportDetailsModal={toggleExportDetailsModal}
          userRole={userRole}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          saveFile={saveFile}
          setLicensingReportData={setLicensingReportData}
          isCompanyPage={isCompanyPage}
        />
      )}
    </>
  );
};

Licensing.propTypes = {
  licenses: PropTypes.array.isRequired,
  primaryDomain: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  selectedLicenseType: PropTypes.string.isRequired,
  onUpdateLicenseType: PropTypes.func.isRequired,
  licensingReportData: PropTypes.object,
  isFastGenerate: PropTypes.bool,
  setIsFastGenerate: PropTypes.func,
  setLicensingReportData: PropTypes.func,
  isCompanyPage: PropTypes.bool,
};

export default Licensing;
