import { DropdownArray } from 'components';
import PropTypes from 'prop-types';
import { onChangeView } from 'utils';
import styles from './ViewBy.module.scss';

const ViewBy = ({ queryViewBy, totalCount, paginationData }) => (
  <div className={styles.dropdown}>
    <DropdownArray
      id="ra-app-select"
      isSmall={false}
      isSearch={false}
      isFilter
      label="View by:"
      options={['All', 'Branding Disabled', 'Branding Enabled']}
      defaultValueHtml={
        queryViewBy === 'All' ? (
          <>
            All <b>({totalCount})</b>
          </>
        ) : (
          <>
            Branding {queryViewBy} <b>({totalCount})</b>
          </>
        )
      }
      onChange={(value) => onChangeView(value, paginationData)}
      width="20.3125em"
    />
  </div>
);

ViewBy.propTypes = {
  queryViewBy: PropTypes.string,
  totalCount: PropTypes.number,
  paginationData: PropTypes.object,
};

export default ViewBy;
