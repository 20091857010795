import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import styles from './ResendCode.module.scss';

const ResendCode = ({ countDown, handleSend, message, isDisabled, className }) => (
  <div className={cn(styles.countDown, { [className]: className })}>
    <span>{message || 'Didn\'t receive a code?'}</span>
    <Button
      id="resend-sms-code"
      variant="link"
      isDisabled={isDisabled || countDown >= 1}
      onClick={handleSend}
      className={cn(styles.countDownButton, { [styles.disabled]: isDisabled })}
    >
      Resend Code
    </Button>
    {countDown >= 1 && (
      <div className={styles.countDownNumbers}>({countDown} sec)</div>
    )}
  </div>
);

ResendCode.propTypes = {
  countDown: PropTypes.number,
  handleSend: PropTypes.func,
  isDisabled: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.any,
};

export default ResendCode;
