import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import styles from './HeaderActions.module.scss';

const HeaderActions = ({
  selectedUsers,
  setSelectedUsers,
  domains,
  changeDomainBrandingStatus,
  queryViewBy,
  headerLoading,
}) => {
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  return (
    <div
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{domains.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" size="40" onClick={() => setSelectedUsers([])}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={headerLoading}
          size="40"
          onClick={() => changeDomainBrandingStatus(true, false)}>
          {queryViewBy === 'Enabled' ? 'Disable Branding' : 'Enable Branding'}
        </Button>
      </div>
    </div>
  );
};

HeaderActions.propTypes = {
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  domains: PropTypes.object,
  changeDomainBrandingStatus: PropTypes.func,
  queryViewBy: PropTypes.string,
  headerLoading: PropTypes.bool,
};

export default HeaderActions;
