/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
/**
 * Check and create method 'remove' for DOM Element if not exists
 */
(function (arr) {
  arr.forEach((item) => {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) { this.parentNode.removeChild(this); }
      },
    });
  });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));
