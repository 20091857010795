/* eslint-disable jsx-a11y/control-has-associated-label */
import { Fragment, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { AngleDownIcon, AngleRightIcon, InfoActiveIcon, InfoIcon, LoaderIcon } from 'assets/img';
import { Modal, ActivationCode } from 'components';
import { encryptStatuses } from 'consts';
import styles from './EncryptDetailsModal.module.scss';

const EncryptDetailsModal = ({ encryptDetailsModalShow, toggleEncryptDetailsModalShow, modalData }) => {
  const [isExpandedLoading, setIsExpandedLoading] = useState('');
  const [expandedList, setExpandedList] = useState({});
  const [isInfoActive, setIsInfoActive] = useState(false);

  const { hostname, discs, deviceId } = modalData;

  const onToggleDisc = (actionType, discId, isPass, isKey) => {
    if (actionType === 'open' && !isPass && !isKey) return false;

    const expandedListNew = JSON.parse(JSON.stringify(expandedList));

    if (actionType === 'open') {
      if (expandedListNew[discId]) {
        expandedListNew[discId].isOpen = true;
        setExpandedList(expandedListNew);
      } else {
        setIsExpandedLoading(discId);

        expandedListNew[discId] = { isOpen: true };

        const urls = [];
        if (isPass)
          urls.push({
            key: 'encryptPass',
            url: `/api/WindowsDrives/GetEncryptionPassword?driveId=${discId}&deviceId=${deviceId}`,
          });
        if (isKey)
          urls.push({
            key: 'encryptKey',
            url: `/api/WindowsDrives/GetRecoveryKey?driveId=${discId}&deviceId=${deviceId}`,
          });

        Promise.allSettled(urls.map((url) => axios.get(url.url)))
          .then((results) => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                expandedListNew[discId][urls[index].key] = result.value.data;
              } else {
                expandedListNew[discId][urls[index].key] = '';
                toast.error(result.reason.response.data || result.reason.message, 3000);
              }
            });

            setExpandedList(expandedListNew);
          })
          .then(() => setIsExpandedLoading(''));
      }
    } else {
      expandedListNew[discId].isOpen = false;
      setExpandedList(expandedListNew);
    }
  };

  const onOpenExpandedList = ({ id, isValidEncryptionPassword, isValidRecoveryKey }) => {
    if (expandedList[id]?.isOpen) {
      return onToggleDisc('close', id);
    }
    return onToggleDisc('open', id, isValidEncryptionPassword, isValidRecoveryKey);
  };

  const expandedListIcon = ({ id, isValidEncryptionPassword, isValidRecoveryKey }) => {
    if (isValidEncryptionPassword || isValidRecoveryKey) {
      if (isExpandedLoading === id) {
        return <LoaderIcon />;
      }
      if (expandedList[id]?.isOpen) {
        return <AngleDownIcon />;
      }
      return <AngleRightIcon />;
    }
  };

  return (
    <Modal
      isOpen={encryptDetailsModalShow}
      onRequestClose={toggleEncryptDetailsModalShow}
      contentLabel="Encryption Details"
      contentSubtitle={hostname}
      style={{ content: { width: '39em' } }}>
      <table className={styles.encryptTable}>
        <thead className={styles.encryptTableThead}>
          <tr className={styles.encryptTableTheadRow}>
            <th className={styles.encryptTableTheadCol} />
            <th className={styles.encryptTableTheadCol}>Drive</th>
            <th className={styles.encryptTableTheadCol}>Name</th>
            <th className={styles.encryptTableTheadCol}>Status</th>
          </tr>
        </thead>
        <tbody className={styles.encryptTableTbody}>
          {discs.map((disc) => {
            const statusCurrent = [0, 1, 2, 3, 4, 5, 6, 7].includes(disc.encryptionStatus) ? disc.encryptionStatus : 8;
            const { statusColor, statusTitle, StatusIcon } = encryptStatuses[statusCurrent];
            const isExpandedCurrent = expandedList[disc.id]?.isOpen;

            return (
              <Fragment key={disc.id}>
                <tr
                  className={cn(styles.encryptTableTbodyRow, {
                    [styles.encryptTableTbodyRowSelected]: isExpandedCurrent,
                  })}
                  onClick={() => onOpenExpandedList(disc)}>
                  <td className={styles.encryptTableTbodyCol}>{expandedListIcon(disc)}</td>
                  <td className={styles.encryptTableTbodyCol}>{disc.letter} </td>
                  <td className={styles.encryptTableTbodyCol}>{disc.name || 'N/A'}</td>
                  <td className={styles.encryptTableTbodyCol}>
                    <span
                      className={cn(styles.encryptTableTbodyColStatus, {
                        [styles.encryptTableTbodyColStatusGrey]: statusColor === 'grey',
                        [styles.encryptTableTbodyColStatusOrange]: statusColor === 'orange',
                        [styles.encryptTableTbodyColStatusBlue]: statusColor === 'blue',
                      })}>
                      {!(statusCurrent === 8 && !disc.showLockWarning) && <StatusIcon />}
                      <span>{statusTitle}</span>
                      {disc.showLockWarning && (
                        <span onMouseEnter={() => setIsInfoActive(disc.id)} onMouseLeave={() => setIsInfoActive('')}>
                          <span
                            data-tip="Please log into the computer, select the locked drive and enter a valid password to unlock it"
                            data-for="info-tooltip"
                            data-iscapture="true"
                            data-place="bottom"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            {isInfoActive === disc.id ? <InfoActiveIcon /> : <InfoIcon />}
                          </span>
                        </span>
                      )}
                    </span>
                  </td>
                </tr>
                {isExpandedCurrent && (
                  <>
                    <tr className={styles.encryptTableTbodyPassRow}>
                      <td>
                        <ActivationCode
                          activationCode={disc.isValidEncryptionPassword && expandedList[disc.id].encryptPass}
                          label="Encryption password"
                          id="encrypt-pass"
                          copyMessage="The password copied to clipboard"
                          isLongVersion
                          showCode={disc.isValidEncryptionPassword && expandedList[disc.id].encryptPass}
                        />
                      </td>
                    </tr>

                    {expandedList[disc.id].encryptKey && (
                      <tr className={styles.encryptTableTbodyPassRow}>
                        <td>
                          <ActivationCode
                            activationCode={expandedList[disc.id].encryptKey}
                            label="Recovery Key"
                            id="recovery-key"
                            copyMessage="Recovery Key copied to clipboard"
                            isLongVersion
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Modal>
  );
};

EncryptDetailsModal.propTypes = {
  encryptDetailsModalShow: PropTypes.bool,
  toggleEncryptDetailsModalShow: PropTypes.func,
  modalData: PropTypes.object,
};

export default EncryptDetailsModal;
