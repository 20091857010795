import PropTypes from 'prop-types';
import cn from 'classnames';
import { useObserver } from 'hooks';
import { useRef } from 'react';
import { Button } from 'components';
import styles from './HeaderActions.module.scss';
import { headerButtonAction } from '../../utils/phoneSetupUtils';

const HeaderActions = ({ phoneMgmtData, selectedUsers, setSelectedUsers, toggleShowModalChangeUser }) => {
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });
  const viewBy = phoneMgmtData?.queryConfig?.viewBy;

  const action = headerButtonAction(viewBy);

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{phoneMgmtData.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button
          variant="secondary"
          size="40"
          onClick={() => setSelectedUsers([])}
          className={styles.headerActionsButton}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          size="40"
          onClick={() => toggleShowModalChangeUser(selectedUsers, action.action)}>
          {action.label}
        </Button>
      </div>
    </header>
  );
};

HeaderActions.propTypes = {
  phoneMgmtData: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  toggleShowModalChangeUser: PropTypes.func.isRequired,
};

export default HeaderActions;
