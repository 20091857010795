import { DiscScanIcon, FileDownloadIcon } from 'assets/img';
import axios from 'config/customAxios';
import { Button } from 'components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { returnFileFromResponse } from 'utils';
import styles from '../StatusCell.module.scss';

const DiscoveryScanCompleted = ({ item }) => {
  const { typeStr, totalLeakedCnt, id } = item;

  const onDiscoveryCsvUpload = () => {
    axios.get(`/api/leakedrequests/ExportCsv?id=${id}`)
      .then((resp) => returnFileFromResponse(resp))
      .catch((err) => toast.error(err.message, 3000));
  };

  return (
    <div className={styles.statusCell}>
      <p className={styles.statusWrapper}><DiscScanIcon /><span className={styles.success}>{typeStr}</span></p>
      <p className={styles.statusCompleteRow}>Discovery scan completed</p>
      <p className={styles.statusTextRow}>
        <span>Found&nbsp;<span className={styles.accoutsCount}>{totalLeakedCnt.toLocaleString()}</span>&nbsp;compromised accounts.&nbsp;
          {totalLeakedCnt ? 'Download Discovery Scan CSV for details or purchase Full Domain Scan' : 'Purchase Full Domain Scan'}
        </span>
      </p>
      <div
        className={styles.statusTooltipWrapper}
        data-tip="No compromised accounts to export"
        data-for={!totalLeakedCnt && 'info-tooltip'}
        data-iscapture="true"
      >
        <Button
          variant="link"
          onClick={() => totalLeakedCnt && onDiscoveryCsvUpload()}
          isDisabled={!totalLeakedCnt}
          className={styles.statusLink}
          id={id}
          icon={<FileDownloadIcon />}
          dataTip="No compromised accounts to export"
          dataFor={!totalLeakedCnt && 'info-tooltip'}
        >
          Discovery Scan CSV
        </Button>
      </div>
    </div>
  );
};

DiscoveryScanCompleted.propTypes = {
  item: PropTypes.object,
};

export default DiscoveryScanCompleted;
