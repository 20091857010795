import PropTypes from 'prop-types';
import { Link, MsLogin, StatusMessage } from 'components';
import { getDomainADStatus } from 'helpers';
import { DownloadIcon } from 'assets/img';
import styles from './OnboardingMessage.module.scss';

const OnboardingMessage = ({ userData }) => {
  const { isDomainAzure } = getDomainADStatus(userData);
  return (
    <StatusMessage header="Your Domain is not Setup">
      <div className={styles.row}>
        <span>Please connect to&nbsp;</span>
        <MsLogin
          isAzureAd={isDomainAzure}
          domainId={userData.domainId}
          domainName={userData.dnsDomainName}
          isActive
          isOnboarding
        />
        &nbsp;&nbsp;or an&nbsp;&nbsp;<Link href={userData.downloadSentryLink} external bold>on-premises Active Directory<DownloadIcon /></Link>
      </div>
    </StatusMessage>
  );
};

OnboardingMessage.propTypes = {
  userData: PropTypes.object,
};

export default OnboardingMessage;
