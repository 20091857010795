export const daysBetweenDates = (date1) => {
  if (!date1) return 0;
  const d1 = new Date(date1);
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1;
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();
  const milliseconds = now.getUTCMilliseconds();
  const d2 = new Date(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);
  const timeDiff = d1 - d2;
  const daysDifference = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDifference;
};
