import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from 'assets/img';
import { Button } from 'components';
import { truncateString } from 'utils';
import styles from './ShortcutMgmtTitle.module.scss';

const ShortcutMgmtTitle = ({ isCompanyPage, domain }) => {
  const history = useHistory();

  const onBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/security-management?tab=desktop-management');
    }
    return history.push(`/customer-management/${domain.dnsName}/security-management?tab=desktop-management`);
  };

  return (
    <>
      <div className={styles.shortcutHead}>
        <div className={styles.shortcutHeadWrap}>
          <Button variant="link" icon={<ArrowLeftIcon />} className={styles.shortcutHeadWrapButton} onClick={onBack}>
            Back to Security Management
          </Button>
          {!isCompanyPage && (
            <h3 className={styles.shortcutHeadTitle}>({truncateString(domain?.dnsName, false, 80)})</h3>
          )}
        </div>
      </div>
      <h3 className={styles.shortcutTitle}>Desktop Shortcut</h3>
    </>
  );
};

ShortcutMgmtTitle.propTypes = {
  isCompanyPage: PropTypes.bool.isRequired,
  domain: PropTypes.object.isRequired,
};

export default ShortcutMgmtTitle;
