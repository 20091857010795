import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { ValidateDomainModal } from 'components';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import { UserContext } from 'utils';
import ValidateDomain from './components/ValidateDomain.js/ValidateDomain';
import SetupDomain from './components/SetupDomain/SetupDomain';
import styles from './Onboarding.module.scss';

const Onboarding = () => {
  const { user, onUpdateUser } = useContext(UserContext);
  const [modalData, setModalData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [setupType, setSetupType] = useState('');

  const { domainWasAutovalidated } = user;

  const { isDomainAzure } = getDomainADStatus(user);
  const { isDomainNotValidated, isDomainNotActivated } = getDomainStatus(user);

  const emailValidationData = {
    type: 'emailValidation',
    link: '/api/Domains/CreateNotValidSpDomain?activationType=1',
    domainId: user.domainId,
    domainName: user.dnsDomainName,
    upn: user.email,
  };
  const txtValidationData = {
    type: 'txtValidation',
    domainId: user.domainId,
    domainName: user.dnsDomainName,
  };

  const onSetupTypeChange = (type) => {
    if (type === setupType) {
      setSetupType('');
    } else {
      setSetupType(type);
    }
  };

  const onModalOpen = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalData(null);
    setModalOpen(false);
  };

  const onUpdateModal = (resp, domainId) => {
    const userNew = JSON.parse(JSON.stringify(user));
    const domainName = user.email.split('@')[1];

    userNew.domainId = domainId;
    userNew.domainActivationCode = resp.data.activationCode;
    userNew.domainStatus = 1;
    userNew.roles = ['mspadmin'];
    userNew.spApplicationWasApproved = resp.data.isMsp;
    userNew.downloadSentryLink = resp.data.sentryLink;
    userNew.downloadSecureConnectLink = resp.data.secureConnectLink;

    toast.success(`Domain ${domainName} has been validated`, 3000);

    onUpdateUser(userNew);
  };

  return (
    <div className={styles.onboarding}>
      <div className={styles.onboardingSteps}>
        Completed: {domainWasAutovalidated ? 0 : user.domainStatus}/{domainWasAutovalidated ? 1 : 2}
      </div>
      <h3 className={styles.onboardingTitle}>Let's Setup TruGrid</h3>
      {!domainWasAutovalidated && (
        <ValidateDomain
          isDomainNotValidated={isDomainNotValidated}
          isDomainNotActivated={isDomainNotActivated}
          onModalOpen={onModalOpen}
          emailValidationData={emailValidationData}
          txtValidationData={txtValidationData}
        />
      )}
      <SetupDomain
        user={user}
        isDomainNotActivated={isDomainNotActivated}
        domainWasAutovalidated={domainWasAutovalidated}
        setupType={setupType}
        onSetupTypeChange={onSetupTypeChange}
        isDomainAzure={isDomainAzure}
      />

      {modalData && (
        <ValidateDomainModal isOpen={modalOpen} data={modalData} onUpdateModal={onUpdateModal} onClose={onModalClose} />
      )}
    </div>
  );
};

export default Onboarding;
