/* eslint-disable jsx-a11y/no-autofocus */
import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { AngleUpIcon, AngleDownIcon, CheckIcon } from 'assets/img';
import { useOutsideClick } from 'hooks';
import 'flag-icons/css/flag-icons.min.css';
import styles from './CountryListDropdown.module.scss';

const CountryListDropdown = ({ id, options, label, currentCountry, onChange, phoneNumber, setPhoneNumber }) => {
  const [searchValue, setSearchValue] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef();
  const dropdownRef = useRef();
  const nodeRef = useRef();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => searchRef.current.focus(), 0);
    } else {
      setTimeout(() => setSearchValue(''), 100);
    }
  }, [isOpen]);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const filteredData = Object.entries(options).filter((country) => {
    if (searchValue === '') return country;
    return country[1].name.toLowerCase().includes(searchValue.toLowerCase()) || country[1].dial_code.includes(searchValue);
  });

  const toggleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(dropdownRef, () => isOpen && toggleOpenDropdown());

  const onSelectHandler = (value) => {
    onChange(value);
    toggleOpenDropdown();
  };

  const handleSetPhoneNumber = (e) => {
    const { value, maxLength } = e.target;
    const code = value.slice(0, maxLength);
    setPhoneNumber(code);
  };

  const toggleInputFocus = () => {
    if (!inputFocus) {
      setIsOpen(false);
      setInputFocus(true);
    } else {
      setInputFocus(false);
    }
  };

  return (
    <div id={id} className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.dropdownLabel}>{label}</div>
      <div className={cn(styles.dropdownWrap, { [styles.focus]: inputFocus || isOpen })}>
        <button type="button" onClick={toggleOpenDropdown} className={cn(styles.dropdownButton, { [styles.active]: isOpen })}>
          <span className={styles.dropdownButtonText}>
            <i className={cn('fi', `fi-${currentCountry.toLowerCase()}`)} />
          </span>
          <span className={styles.dropdownButtonIcon}>
            {isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </span>
          <span className={styles.dropdownButtonPlaceholder}>{options[currentCountry].dial_code}</span>
        </button>
        <input
          type="number"
          id="your-phone-number"
          className={styles.dropdownInput}
          value={phoneNumber}
          maxLength={11}
          onChange={handleSetPhoneNumber}
          onFocus={toggleInputFocus}
          onBlur={toggleInputFocus}
          autoFocus
        />
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}
      >
        <div className={styles.dropdownOptions} ref={nodeRef}>
          <div className={styles.dropdownOptionsInputWrap}>
            <input
              type="search"
              className={styles.dropdownOptionsInput}
              placeholder="Search..."
              value={searchValue}
              onChange={onSearch}
              ref={searchRef}
            />
          </div>
          <div className={styles.dropdownOptionsCountries}>
            {filteredData.length ? (
              filteredData.map((country) => (
                <button
                  type="button"
                  key={country[0]}
                  className={cn(styles.dropdownOptionsItem, { [styles.active]: country[0] === currentCountry })}
                  onClick={(e) => onSelectHandler(country[0], e)}
                >
                  <span className={styles.dropdownOptionsItemText}>
                    <i className={`fi fi-${country[0].toLowerCase()}`} />
                    <span>{country[1].name}</span>
                  </span>
                  <span className={styles.dropdownOptionsItemCode}>
                    {country[1].dial_code}
                  </span>
                  {country[0] === currentCountry && <span className={styles.dropdownOptionsItemIcon}><CheckIcon /></span>}
                </button>
              ))
            ) : (
              <div className={styles.dropdownOptionsInputNoResults}>No results found</div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

CountryListDropdown.propTypes = {
  id: PropTypes.string,
  options: PropTypes.object.isRequired,
  label: PropTypes.string,
  currentCountry: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
};

export default CountryListDropdown;
