import { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { notify, UserContext } from 'utils';
import { loginService } from 'services';
import { Loader } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import ScanQRCode from './components/ScanQRCode/ScanQRCode';
import VerifyPhoneNumber from './components/VerifyPhoneNumber/VerifyPhoneNumber';
import VerificationCode from './components/VerificationCode/VerificationCode';
import styles from './SetupViaApp.module.scss';
import { goBackHandler } from './utils/goBackHandler/goBackHandler';
import { currentStepHandler } from './utils/currentStepHandler/currentStepHandler';

const SetupViaApp = () => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [countriesList, setCountriesList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneMask, setPhoneMask] = useState('');
  const [mfaHash, setMfaHash] = useState('');

  useEffect(() => {
    loginService.getCountriesList()
      .then((resp) => setCountriesList(JSON.parse(resp.data)))
      .catch((err) => notify.error(err.response?.data || err.message))
      .finally(() => setIsLoading(false));
  }, []);

  const dialCode = useMemo(() => (
    countriesList[currentCountry]?.dial_code
  ), [countriesList, currentCountry]);

  const isSetupMfaWithoutPhone = useMemo(() => (
    user.isMfaActive && !user.isPhoneMfaActive && !user.mustSetupMfa
  ), [user.isMfaActive, user.isPhoneMfaActive, user.mustSetupMfa]);

  const currentStep = currentStepHandler(pathname, path, url, isSetupMfaWithoutPhone);
  const stepTitle = pathname === path ? 'Scan QR Code' : 'Verify Your Phone';
  const goBack = () => goBackHandler(currentStep, history, isSetupMfaWithoutPhone);

  return (
    <div className={cn(styles.setupMfa, { [styles.short]: currentStep !== 1 })}>
      <LoginHeader
        title={stepTitle}
        hideSteps={(user.isPhoneMfaActive && user.mustSetupMfa)}
        currentStep={currentStep}
        stepCount={isSetupMfaWithoutPhone ? '2' : '3'}
        back={goBack}
      />
      {isLoading ? (
        <Loader style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <Switch>
          <Route path={path} exact>
            <ScanQRCode />
          </Route>
          <Route path={`${url}/verify-phone`}>
            <VerifyPhoneNumber
              countriesList={countriesList}
              dialCode={dialCode}
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setMfaHash={setMfaHash}
              setPhoneMask={setPhoneMask}
            />
          </Route>
          <Route path={`${url}/verification-code`}>
            <VerificationCode
              phoneMask={phoneMask}
              phoneNumber={phoneNumber}
              mfaHash={mfaHash}
              setMfaHash={setMfaHash}
              dialCode={dialCode}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default SetupViaApp;
