import { MailIcon, PhoneIcon, TrashIcon } from 'assets/img';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './DomainActions.module.scss';

const DomainActions = ({ domain, role, setDomainIdDelete, toggleShowDeleteDomainModal, onOpenValidationModal }) => {
  const onGetValidationEmail = (domainId, domainName, e) => {
    e.stopPropagation();
    onOpenValidationModal({
      type: 'emailValidation', link: `/api/Domains/VerifyCustomerDomain?domainId=${domainId}`, domainId, domainName,
    });
  };

  const onGetValidationCode = (domainId, domainName, e) => {
    e.stopPropagation();
    onOpenValidationModal({ type: 'txtValidation', domainId, domainName });
  };

  const onDeleteDomain = (domainId, e) => {
    e.stopPropagation();
    setDomainIdDelete(domainId);
    toggleShowDeleteDomainModal();
  };

  const tooltipProps = {
    place: 'top', type: 'light', effect: 'solid', delayShow: 200,
  };

  const { id } = domain;

  const iconsMap = {
    invalid: [
      <div key={`mail-icon-${id}`}>
        <div
          className={styles.actionsWrapper}
          data-for={`mail-icon-${id}`}
          data-tip="Click to issue validation email"
          id="validate-via-email"
          onClick={(e) => onGetValidationEmail(domain.id, domain.dnsName, e)}
        >
          <MailIcon />
        </div>
        <ReactTooltip id={`mail-icon-${id}`} {...tooltipProps} />
      </div>,
      <div key={`phone-icon-${id}`}>
        <div
          className={styles.actionsWrapper}
          data-for={`phone-icon-${id}`}
          data-tip="Click to validate via DNS TXT record"
          id="validate-via-txt"
          onClick={(e) => onGetValidationCode(domain.id, domain.dnsName, e)}
        >
          <PhoneIcon />
        </div>
        <ReactTooltip id={`phone-icon-${id}`} {...tooltipProps} />
      </div>,
      <div key={`trash-icon-${id}`}>
        <div
          className={styles.actionsWrapper}
          data-for={`trash-icon-${id}`}
          data-tip="Click to delete domain"
          id="delete-domain"
          onClick={(e) => onDeleteDomain(domain.id, e)}
        >
          <TrashIcon />
        </div>
        <ReactTooltip id={`trash-icon-${id}`} {...tooltipProps} />
      </div>,
    ],
    valid: [
      <div key={`trash-icon-${id}`}>
        <div
          className={styles.actionsWrapper}
          data-for={`trash-icon-${id}`}
          data-tip="Click to delete domain"
          id="delete-domain"
          onClick={(e) => onDeleteDomain(domain.id, e)}
        >
          <TrashIcon />
        </div>
        <ReactTooltip id={`trash-icon-${id}`} {...tooltipProps} />
      </div>,
    ],
  };

  const currentSet = domain.status ? iconsMap.valid : iconsMap.invalid;

  if (role === 'techAdmin') {
    currentSet.pop();

    if (domain.status === 1) currentSet.shift();
  }

  return (
    <div className={styles.actions}>{currentSet}</div>
  );
};

DomainActions.propTypes = {
  domain: PropTypes.object,
  role: PropTypes.string,
  setDomainIdDelete: PropTypes.func,
  toggleShowDeleteDomainModal: PropTypes.func,
  onOpenValidationModal: PropTypes.func,
};

export default DomainActions;
