import { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import { AngleUpIcon, AngleDownIcon, CheckIcon } from 'assets/img';
import { CSSTransition } from 'react-transition-group';
import styles from './Dropdowns.module.scss';

const DropdownObject = ({
  id,
  label,
  options,
  onChange,
  defaultPos,
  isMulti,
  disabled,
  customField,
  onCustomEvent,
  width,
  className,
  optionsClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const nodeRef = useRef();

  const toggleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(dropdownRef, () => isOpen && toggleOpenDropdown());

  const onSelectHandler = (key) => {
    onChange(key);
    toggleOpenDropdown();
  };

  const onCustomEventHandler = () => {
    onCustomEvent();
    toggleOpenDropdown();
  };

  const wrapperClasses = cn(styles.dropdown, { [styles.disabled]: disabled });
  const selectClasses = cn(styles.dropdownSelect, { [styles.active]: isOpen, [className]: className });

  return (
    <div id={id} className={wrapperClasses} ref={dropdownRef}>
      {label && <div className={styles.dropdownLabel}>{label}</div>}
      <button
        type="button"
        className={selectClasses}
        disabled={disabled}
        style={{ width }}
        onClick={toggleOpenDropdown}>
        <span className={styles.dropdownValue}>
          {isMulti ? Object.values(options).find((i) => +i[0] === defaultPos)[1] : options[defaultPos]}
        </span>
        {isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}>
        <div className={cn(styles.dropdownOptions, { [optionsClassName]: optionsClassName })} ref={nodeRef}>
          {isMulti
            ? Object.values(options).map((option) => {
                if (option[0] === customField) {
                  return (
                    <button
                      type="button"
                      key={customField}
                      className={cn(styles.dropdownOptionsItem, styles.customField)}
                      onClick={onCustomEventHandler}>
                      {option[1]}
                    </button>
                  );
                }
                return (
                  <button
                    type="button"
                    key={option[0]}
                    onClick={(e) => onSelectHandler(option[0], e)}
                    className={cn(styles.dropdownOptionsItem, { [styles.active]: +option[0] === defaultPos })}>
                    {option[1]}
                    {+option[0] === defaultPos && <CheckIcon />}
                  </button>
                );
              })
            : Object.keys(options).map((key) => {
                if (key === customField) {
                  return (
                    <button
                      type="button"
                      key={customField}
                      className={cn(styles.dropdownOptionsItem, styles.customField)}
                      onClick={onCustomEventHandler}>
                      {options[customField]}
                    </button>
                  );
                }
                return (
                  <button
                    type="button"
                    key={options[key]}
                    onClick={(e) => onSelectHandler(key, e)}
                    className={cn(styles.dropdownOptionsItem, { [styles.active]: +key === defaultPos })}>
                    {options[key]}
                    {+key === defaultPos && <CheckIcon />}
                  </button>
                );
              })}
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownObject.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customField: PropTypes.any,
  onCustomEvent: PropTypes.func,
  options: PropTypes.object.isRequired,
  defaultPos: PropTypes.number.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.any,
  optionsClassName: PropTypes.any,
};

DropdownObject.displayName = 'DropdownObject';

export default memo(DropdownObject);
