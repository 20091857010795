import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import { AngleUpIcon, AngleDownIcon } from 'assets/img';
import { Button, Checkbox } from 'components';
import { CSSTransition } from 'react-transition-group';
import styles from './DropdownCheckUsers.module.scss';

const DropdownCheckUsers = ({ id, options, optionsChecked, valueKey, checkKey, defaultValue, onToggleCheck }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef();
  const nodeRef = useRef();

  useOutsideClick(dropdownRef, () => {
    setTimeout(() => {
      if (filteredOptions.length !== options.length) {
        setFilteredOptions(options);
      }
      setSearchValue('');
    }, 200);
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    if (isOpen) {
      setTimeout(() => {
        if (filteredOptions.length !== options.length) {
          setFilteredOptions(options);
        }
        setSearchValue('');
      }, 200);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onToggleAll = () => {
    const optionsCheckedNew = options.length === optionsChecked.length ? [] : options.map((i) => i[checkKey]);
    onToggleCheck(optionsCheckedNew);
  };

  const onToggle = (e, userId) => {
    e.stopPropagation();
    const optionsCheckedNew = optionsChecked.some((i) => i === userId) ? optionsChecked.filter((i) => i !== userId) : [...optionsChecked, userId];
    onToggleCheck(optionsCheckedNew);
  };

  const onSearchHandler = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value) {
      const result = options.filter((i) => i[valueKey].toLowerCase().includes(value.toLowerCase()));
      setFilteredOptions(result);
    } else {
      setFilteredOptions(options);
    }
  };

  return (
    <div id={id} className={styles.dropdown} ref={dropdownRef}>
      <button
        type="button"
        id="toggle-open-users"
        className={cn(styles.dropdownButton, { [styles.dropdownButtonActive]: isOpen })}
        onClick={toggleDropdown}
      >
        <span className={styles.dropdownValue}>{defaultValue}</span>
        {isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}
      >
        <div className={styles.dropdownOptions} ref={nodeRef}>
          <input
            type="search"
            id="search-by-users"
            className={styles.dropdownSearch}
            placeholder="Search..."
            onChange={onSearchHandler}
          />
          {!searchValue && (
            <div className={styles.dropdownHeader}>
              <span className={styles.dropdownHeaderTitle}>All Users</span>
              {options.length === optionsChecked.length ? (
                <Button type="button" id="select-all-users" variant="link" className={styles.dropdownHeaderSelect} onClick={() => onToggleAll('unselect')}>
                  Unselect All
                </Button>
              ) : (
                <Button type="button" id="unselect-all-users" variant="link" className={styles.dropdownHeaderSelect} onClick={() => onToggleAll('select')}>
                  Select All
                </Button>
              )}
            </div>
          )}
          {filteredOptions.length ? (
            filteredOptions.map((option) => (
              <Checkbox
                id={option[checkKey]}
                key={option[checkKey]}
                checked={optionsChecked.includes(option[checkKey])}
                onChange={(e) => onToggle(e, option[checkKey])}
                label={option[valueKey]}
                labelPosition="left"
                className={styles.dropdownCheckbox}
              />
            ))
          ) : (
            <div className={styles.dropdownNoResultsFound}>No results found</div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownCheckUsers.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionsChecked: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  checkKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.element,
  onToggleCheck: PropTypes.func.isRequired,
};

export default DropdownCheckUsers;
