import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import { MailIcon } from 'assets/img';
import { LoginContext, notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import styles from './EmailValidation.module.scss';

const EmailValidation = () => {
  const { email } = useContext(LoginContext);
  const { customerId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [emailValue, setEmailValue] = useState(email || '');

  useEffect(() => {
    if (customerId) {
      notify.success('Your sign up was successful', 3000);
      loginService.getJustRegisteredCustomerEmail(customerId)
        .then(({ data }) => {
          setIsLoading(false);
          setEmailValue(data);
        })
        .catch((err) => {
          setIsLoading(false);
          history.replace('/login');
          notify.error(err.response?.data.message || err.message, 3000);
        });
    } else {
      setIsLoading(false);
    }
  }, [history, customerId]);

  const onResendEmail = () => {
    setIsSending(true);
    loginService.resendVerificationEmail(emailValue)
      .then(() => {
        notify.success('The email has been sent', 3000);
        setIsSending(false);
      })
      .catch((err) => {
        setIsSending(false);
        notify.error(err.response?.data.message || err.message, 3000);
      });
  };

  return (
    <div className={styles.validation}>
      <LoginHeader title="Email validation" hideBack hideSteps />
      {isLoading ? (
        <Loader style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <>
          <div className={styles.validationInfo}>
            <div className={styles.validationInfoSent}>We have sent a message to</div>
            <div className={styles.validationInfoEmail}><MailIcon /> {emailValue}</div>
          </div>
          <div className={styles.validationInfo}>
            Please click the verification link in that email to prove ownership of this email address
          </div>
          <div className={styles.validationFooter}>
            <span>Can't find the email?</span>
            <Button
              onClick={onResendEmail}
              isDisabled={isSending}
              variant="link"
              id="resend-email"
              className={styles.validationFooterButton}
            >
              Resend Email
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailValidation;
