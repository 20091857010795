import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useHistory } from 'react-router-dom';
import { Button } from 'components';
import { UserContext } from 'utils';
import { signOut } from 'helpers';
import styles from './ResetPasswordError.module.scss';

const ResetPasswordError = ({ error }) => {
  const { user } = useContext(UserContext);
  const [errorTitle, setErrorTitle] = useState('Invalid link');
  const [errorText, setErrorText] = useState('The page is not available. Please try to login again');
  const history = useHistory();

  const backToLogin = () => (user ? signOut() : history.replace('/'));

  useEffect(() => {
    if (error === 'used') {
      setErrorTitle('Invalid link');
      setErrorText(<>Password reset link has already been used.<br />Please try to login again</>);
    }
    if (error === 'expired') {
      setErrorTitle('Link is expired');
      setErrorText(<>You have clicked an expired or invalid link.<br />Please try to login again</>);
    }
    if (error === 'offline') {
      setErrorTitle('Active Directory not accessible');
      setErrorText('Please contact your administrator for assistance');
    }
    if (error === 'exceeded') {
      setErrorTitle('Password reset attempts exceeded');
      setErrorText('To reset your password, please contact your company administrator for assistance');
    }
  }, [error]);

  return (
    <div className={styles.error}>
      <LoginHeader title={errorTitle} hideSteps hideBack />
      <div className={styles.errorInfo}>{errorText}</div>
      <Button variant="primary" size="40" className={styles.errorButton} onClick={backToLogin}>Back to Login</Button>
    </div>
  );
};

ResetPasswordError.propTypes = {
  error: PropTypes.string,
};

export default ResetPasswordError;
