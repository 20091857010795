import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { KeyIcon } from 'assets/img';
import { DropdownArray } from 'components';
import { notify, returnFileFromResponse, UserContext } from 'utils';
import axios from 'config/customAxios';
import styles from './IPWhitelist.module.scss';

const IPWhitelist = ({ domainId }) => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFile = async (value) =>
    new Promise((resolve, reject) => {
      const { id } = value;
      setIsLoading(true);
      axios
        .post('/api/SecurityManagement/IpWhitelist/GetIpWhiteListReport', {
          DomainId: domainId,
          UserName: user.email.split('@')[0],
          IsCsv: id === 'download-csv',
        })
        .then((res) => {
          returnFileFromResponse(res);
          notify.success(
            <>
              <p className={styles.whiteListNotifyTitle}>Preparing file for download ...</p>
              <p className={styles.whiteListNotifyText}>
                Your download will start in a few seconds. If not, please try again
              </p>
            </>,
          );
          setIsLoading(false);
          resolve();
        })
        .catch((err) => {
          notify.error(err?.response?.data.message || err?.response?.data || err?.message);
          setIsLoading(false);
          reject();
        });
    });

  return (
    <div className={styles.whiteList}>
      <div className={styles.whiteListInfo}>
        <div className={styles.whiteListInfoIcon}>
          <KeyIcon />
        </div>
        <div>
          <h4 className={styles.whiteListInfoTitle}>IP Whitelist</h4>
          <p className={styles.whiteListInfoText}>
            Please download the list of TruGrid IP addresses if you must whitelist outbound connections for TruGrid
            software.
          </p>
          <p className={styles.whiteListInfoText}>
            Please note that some of the IP addresses will change occasionally and will require you to conduct another
            download when that happens.
          </p>
        </div>
      </div>
      <DropdownArray
        id="dm-devices-enroll"
        isButton
        isDisabled={isLoading}
        btnTitle={isLoading ? 'Preparing file...' : 'Download'}
        options={[
          { value: 'Download in CSV', id: 'download-csv' },
          { value: 'Download in JSON', id: 'download-json' },
        ]}
        isFilter
        onChange={fetchFile}
        className={styles.whiteListDropdown}
        optionsClassName={styles.whiteListDropdownOptions}
        width="11em"
      />
    </div>
  );
};

IPWhitelist.propTypes = {
  domainId: PropTypes.string.isRequired,
};

export default IPWhitelist;
