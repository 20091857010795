import PropTypes from 'prop-types';
import { DropdownArray } from 'components';
import { onChangeView } from 'utils';
import styles from './ViewByFilter.module.scss';
import { FILTER_OPTIONS } from '../../utils/consts';

const ViewByFilter = ({ setPhoneMgmtData, phoneMgmtData, baseUrl }) => {
  const paginationData = {
    queryConfig: phoneMgmtData?.queryConfig,
    updateAction: setPhoneMgmtData,
    baseUrl,
  };

  const viewByFrmttd = phoneMgmtData?.queryConfig?.viewBy.split(/(?=[A-Z])/).join(' ');
  const viewByFrmttdHtml = (
    <>
      {viewByFrmttd} <b>({phoneMgmtData?.pageMeta?.TotalCount})</b>
    </>
  );

  const filterOptions = FILTER_OPTIONS(phoneMgmtData.pageMeta);

  return (
    <div className={styles.filter}>
      View by:
      <DropdownArray
        id="ra-app-select"
        isSmall={false}
        isSearch={false}
        isFilter
        options={filterOptions}
        defaultValue={viewByFrmttd}
        defaultValueHtml={viewByFrmttdHtml}
        onChange={(value) => onChangeView(value, paginationData)}
        className={styles.appsHeaderWrapDropdown}
        width="20em"
      />
    </div>
  );
};

ViewByFilter.propTypes = {
  setPhoneMgmtData: PropTypes.func,
  phoneMgmtData: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default ViewByFilter;
