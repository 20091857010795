import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { QuestionIcon, QuestionFilledIcon, InfoIcon, InfoActiveIcon } from 'assets/img';
import styles from './TooltipInfo.module.scss';

const ICONS = {
  question: {
    active: <QuestionFilledIcon />,
    default: <QuestionIcon />,
  },
  info: {
    active: <InfoActiveIcon width="1em" height="1em" />,
    default: <InfoIcon width="1em" height="1em" />,
  },
};

const TooltipInfo = ({ name, content, position, icon, isHtml, className }) => {
  const [isInfoActive, setIsInfoActive] = useState(false);

  return (
    <>
      <div
        className={cn(styles.wrapper, { [className]: className })}
        onMouseEnter={() => setIsInfoActive(true)}
        onMouseLeave={() => setIsInfoActive(false)}
      >
        <div
          data-for={`sit-${name}`}
          data-tip={content}
          data-iscapture="true"
          className={styles.wrapperTooltip}
        />
        {isInfoActive ? ICONS[icon].active : ICONS[icon].default}
      </div>
      <ReactTooltip
        id={`sit-${name}`}
        type="light"
        place={position}
        html={isHtml}
        effect="solid"
        delayShow={200}
      />
    </>
  );
};

TooltipInfo.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isHtml: PropTypes.bool.isRequired,
  className: PropTypes.any,
};

export default TooltipInfo;
