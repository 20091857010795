import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, SwitchCheckbox } from 'components';
import { EditIcon } from 'assets/img';
import styles from './TimeoutStatus.module.scss';

const TimeoutStatus = ({ handleShowToggleModal, isTimeoutEnabled, handleShowChangePeriodModal, timeoutDuration }) => (
  <div className={styles.timeoutStatus}>
    <div className={styles.timeoutStatusRow}>
      <div className={styles.timeoutWrap}>
        <span>Session Timeout Status</span>
      </div>
      <div className={styles.timeoutStatusWrap}>
        <SwitchCheckbox
          checked={isTimeoutEnabled}
          id="is-timeout-me-enabled"
          name="is-timeout-me-enabled"
          onChange={handleShowToggleModal}
          className={styles.timeoutWrapSwitch}
        />
        <span className={cn(styles.timeoutStatusWrapText, { [styles.disabled]: !isTimeoutEnabled })}>
          {isTimeoutEnabled ? 'ON' : 'OFF'}
        </span>
      </div>
    </div>
    {isTimeoutEnabled && (
      <div className={styles.timeoutPeriod}>
        <span>
          Timeout Period: <strong>{timeoutDuration} min</strong>
        </span>{' '}
        <Button
          variant="link"
          className={styles.timeoutPeriodButton}
          onClick={handleShowChangePeriodModal}
          icon={<EditIcon />}
        />
      </div>
    )}
  </div>
);

TimeoutStatus.propTypes = {
  handleShowToggleModal: PropTypes.func.isRequired,
  isTimeoutEnabled: PropTypes.bool,
  handleShowChangePeriodModal: PropTypes.func,
  timeoutDuration: PropTypes.number,
};

export default TimeoutStatus;
