/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { onChangePage, queryFormatter } from 'utils';
import { Button, StatusMessage, Table } from 'components';
import RemoteAppNotEnabled from './components/RemoteAppNotEnabled/RemoteAppNotEnabled';
import TableActions from './components/TableActions/TableActions';
import AppsHeader from './components/AppsHeader/AppsHeader';
import ToggleRemoteAppsModal from './components/ToggleRemoteAppsModal';
import styles from './AppAssignments.module.scss';
import NoApps from './components/NoApps';

const AppAssignments = ({
  appAssignments,
  baseUrl,
  domainData,
  refreshPageUrl,
  onUpdateAppPublishing,
  onUpdate,
  openManageAssignmentsModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showToggleRemoteAppsModal, setShowToggleRemoteAppsModal] = useState(false);

  const toggleShowToggleRemoteAppsModal = () => {
    setShowToggleRemoteAppsModal(!showToggleRemoteAppsModal);
  };

  const inputRef = useRef();
  const appPublishingCurrent =
    'appPublishingCurrent' in domainData ? domainData.appPublishingCurrent : domainData.isAppPublishingEnabled;
  const appPublishingInit = domainData.isAppPublishingEnabled;
  const queryViewBy = appAssignments?.queryConfig?.viewBy;
  const sortConfig = appAssignments?.queryConfig.orderBy.split(/%20| /);

  const paginationData = {
    queryConfig: appAssignments?.queryConfig,
    inputRef,
    updateAction: onUpdate,
    sortConfig,
    baseUrl,
  };

  useEffect(() => {
    if (appAssignments?.queryConfig?.searchQuery) {
      setIsLoading(true);
      const query = { ...appAssignments.queryConfig, searchQuery: '' };
      onChangePage([queryFormatter(baseUrl, query), query], paginationData).then(() => setIsLoading(false));
    }
  }, []);

  const updateAssignments = (resp, queryConfig) => {
    onUpdate({
      data: resp.data,
      pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
      queryConfig,
    });
  };

  const onRefreshDataFromAd = () =>
    new Promise((resolve, reject) =>
      axios
        .get(queryFormatter(baseUrl, appAssignments.queryConfig))
        .then((resp) => {
          updateAssignments(resp, { ...appAssignments.queryConfig });
          return resolve();
        })
        .catch((err) => reject(err)),
    );

  const onRemoteAppChange = () => {
    onUpdateAppPublishing(!appPublishingCurrent, true);

    const succMessage = (
      <p>
        <strong>Changes should be applied in a few minutes</strong>
        <br />
        Please refresh this page in 10 seconds
      </p>
    );

    axios
      .post('/api/domains/toggleAppPublishing', { id: domainData.id, flag: !appPublishingInit })
      .then(() => toast.success(succMessage, 3000))
      .catch((err) => toast.error(err.message, 3000))
      .finally(() => toggleShowToggleRemoteAppsModal());
  };

  const columns = [
    {
      name: queryViewBy === 'Users' ? 'User' : 'App',
      sortable: 'name',
      width: '38%',
      data: ({ name }) => name || 'N/A',
    },
    {
      name: queryViewBy === 'Users' ? 'Domain / UPN' : 'App Group',
      sortable: 'domainName',
      width: '38%',
      data: ({ domainName }) => domainName || 'N/A',
    },
    {
      name: `Assigned ${queryViewBy === 'Users' ? 'Apps' : 'Users'}`,
      width: '24%',
      data: ({ assignmentsCount, name, resourceId, isPooled }) => (
        <div>
          <span>{assignmentsCount}</span>
          <Button
            type="button"
            variant="primary"
            size="32"
            id="manage-assignments"
            className={styles.assignmentsButton}
            onClick={() =>
              openManageAssignmentsModal({
                type: 'manage',
                assignType: 'App',
                domainId: domainData.id,
                viewBy: queryViewBy,
                resourceName: name || 'N/A',
                resourceId,
                isPooled,
              })
            }>
            Manage assignments
          </Button>
        </div>
      ),
    },
  ];

  if (appAssignments?.isError) {
    return <StatusMessage error>{appAssignments.error}</StatusMessage>;
  }

  return (
    <>
      <AppsHeader
        appPublishingInit={appPublishingInit}
        appPublishingCurrent={appPublishingCurrent}
        isLoading={isLoading}
        domainData={domainData}
        onRefreshDataFromAd={onRefreshDataFromAd}
        onUpdateAppPublishing={onUpdateAppPublishing}
        refreshPageUrl={refreshPageUrl}
        toggleShowToggleRemoteAppsModal={toggleShowToggleRemoteAppsModal}
      />

      {appPublishingInit && (
        <TableActions
          appPublishingInit={appPublishingInit}
          appPublishingCurrent={appPublishingCurrent}
          queryViewBy={queryViewBy}
          paginationData={paginationData}
          inputRef={inputRef}
        />
      )}

      <div
        style={
          appAssignments?.data.length || appAssignments?.queryConfig?.searchQuery
            ? {}
            : { height: 'calc(100% - 9.5em)' }
        }>
        {appPublishingInit ? (
          <Table
            columns={columns}
            data={appAssignments}
            updateData={onUpdate}
            dataKey={({ resourceId, name }) => `${resourceId}-${name}`}
            noData={<NoApps />}
            baseUrl={baseUrl}
            className={styles.assignmentsTable}
          />
        ) : (
          <RemoteAppNotEnabled />
        )}
      </div>

      {showToggleRemoteAppsModal && (
        <ToggleRemoteAppsModal
          showToggleRemoteAppsModal={showToggleRemoteAppsModal}
          toggleShowToggleRemoteAppsModal={toggleShowToggleRemoteAppsModal}
          onRemoteAppChange={onRemoteAppChange}
          type={appPublishingCurrent}
        />
      )}
    </>
  );
};

AppAssignments.propTypes = {
  appAssignments: PropTypes.object,
  domainData: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  refreshPageUrl: PropTypes.string.isRequired,
  onUpdateAppPublishing: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  openManageAssignmentsModal: PropTypes.func,
};

export default AppAssignments;
