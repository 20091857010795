import PropTypes from 'prop-types';
import cn from 'classnames';
import { SwitchCheckbox } from 'components';
import ShortcutExpiration from '../ShortcutExpiration/ShortcutExpiration';
import styles from './ShortcutMgmtStatus.module.scss';

const ShortcutMgmtStatus = ({
  shortcutData,
  toggleShowEnableModal,
  toggleShowToggleMfaRequiredModal,
  isShortcutEnabled,
  isShortcutMfaRequired,
  isDomainOnPremise,
  selectedUsers,
}) => {
  const shortcutExpirationDays = shortcutData?.data?.shortcutExpirationDays;
  const expirationDaysMsg =
    shortcutExpirationDays === 0 ? 'Never' : `${shortcutExpirationDays} day${shortcutExpirationDays === 1 ? '' : 's'}`;

  return (
    <div className={styles.shortcutActions}>
      <div className={styles.shortcutActionsCheckbox}>
        <p className={styles.shortcutActionsCheckboxTitle}>Desktop Shortcut Status</p>
        <SwitchCheckbox
          id="toggle-shortcut-status"
          checked={isShortcutEnabled}
          onChange={toggleShowEnableModal}
          className={styles.shortcutActionsCheckboxSlide}
          disabled={selectedUsers.length > 0}
        />
        <span
          className={cn(styles.shortcutActionsCheckboxText, {
            [styles.active]: isShortcutEnabled,
            [styles.inactive]: selectedUsers.length > 0,
          })}>
          {isShortcutEnabled ? 'ON' : 'OFF'}
        </span>
      </div>

      <div className={cn(styles.shortcutActionsCheckbox, styles.mfa)}>
        {isShortcutEnabled && isDomainOnPremise && (
          <>
            <div className={styles.shortcutActionsCheckboxRow}>
              <p className={styles.shortcutActionsCheckboxTitle}>Require MFA Code for Shortcut</p>
              <SwitchCheckbox
                id="toggle-shortcut-status"
                checked={isShortcutMfaRequired}
                onChange={toggleShowToggleMfaRequiredModal}
                className={styles.shortcutActionsCheckboxSlide}
                disabled={selectedUsers.length > 0}
              />
              <span
                className={cn(styles.shortcutActionsCheckboxText, {
                  [styles.active]: isShortcutMfaRequired,
                  [styles.inactive]: selectedUsers.length > 0,
                })}>
                {isShortcutMfaRequired ? 'ON' : 'OFF'}
              </span>
            </div>
            <p className={styles.shortcutActionsCheckboxSubTitle}>
              Enabling this feature will require MFA when users use Desktop Shortcuts
            </p>
          </>
        )}
      </div>
      {isShortcutEnabled && !selectedUsers.length && <ShortcutExpiration expirationDaysMsg={expirationDaysMsg} />}
    </div>
  );
};

ShortcutMgmtStatus.propTypes = {
  shortcutData: PropTypes.object.isRequired,
  toggleShowEnableModal: PropTypes.func.isRequired,
  toggleShowToggleMfaRequiredModal: PropTypes.func.isRequired,
  isShortcutEnabled: PropTypes.bool.isRequired,
  isShortcutMfaRequired: PropTypes.bool.isRequired,
  isDomainOnPremise: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.array.isRequired,
};

export default ShortcutMgmtStatus;
