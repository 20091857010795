import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from 'assets/img';
import { Button } from 'components';
import { truncateString } from 'utils';
import styles from './PhoneSetupHeader.module.scss';

const PhoneSetupHeader = ({ domain, isCompanyPage }) => {
  const history = useHistory();

  const onBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/security-management?tab=password-management');
    }
    return history.push(`/customer-management/${domain.dnsName}/security-management?tab=password-management`);
  };

  return (
    <div className={styles.phoneSetupHead}>
      <div className={styles.phoneSetupHeadWrap}>
        <Button variant="link" icon={<ArrowLeftIcon />} className={styles.phoneSetupHeadWrapButton} onClick={onBack}>
          Back to Security Management
        </Button>
        {!isCompanyPage && (
          <h3 className={styles.phoneSetupHeadTitle}>({truncateString(domain?.dnsName, false, 80)})</h3>
        )}
      </div>
    </div>
  );
};

PhoneSetupHeader.propTypes = {
  domain: PropTypes.object,
  isCompanyPage: PropTypes.bool,
};

export default PhoneSetupHeader;
