import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Loader, ActivationCode, Alert, Button, ADStatus } from 'components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getUserRole, notify, queryFormatter } from 'utils';
import {
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  freePersonalUser,
  personalUser,
  spTechAdmin,
  spTechUser,
  techAdmin,
  workspaceApi,
} from 'consts';
import { TriangleFilledIcon, CloseIcon } from 'assets/img';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import OnboardingMessage from '../OnboardingMessage/OnboardingMessage';
import WorkspaceMyApps from './components/WorkspaceMyApps';
import WorkspaceMyDomain from './components/WorkspaceMyDomain';
import styles from './WorkspaceTabs.module.scss';

const WorkspaceTabs = ({ user, onUpdateUser, hasSecureConnects }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [myDomain, setMyDomain] = useState(null);
  const [sentries, setSentries] = useState([]);
  const [myApps, setMyApps] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [isExceedsLimit, setIsExceedsLimit] = useState(false);

  const { isDomainAzure, isDomainOnPremise, isDomainHybryd } = getDomainADStatus(user);
  const sentryDeleted = isSentryDeleted(user);

  const role = getUserRole(user);
  const [loadingCurrent, setLoadingCurrent] = useState('');
  const isCustomer = [custUser, custAdmin].includes(role);
  const isPersonal = [personalUser, freePersonalUser].includes(role);
  const isAdditionalFilter = [spTechAdmin, techAdmin, corpTechAdmin, spTechUser, corpTechUser].includes(role);
  const myDomainPage = isCustomer
    ? 'myDomainCust'
    : isPersonal
    ? 'myDomainPersonal'
    : isAdditionalFilter
    ? 'myDomainWithFilter'
    : 'myDomain';
  const isShowApps =
    myDomainPage !== 'myDomainPersonal' && !isDomainAzure && !(user.showOnboarding && hasSecureConnects);

  const fetchSentries = useCallback(async () => {
    try {
      const doNotSendRequest = isPersonal || isDomainAzure || [spTechUser, corpTechUser, custUser].includes(role);
      if (doNotSendRequest) return;
      const response = await axios.get(`/api/sentry/GetSentriesForDomain?domainId=${user.domainId}`);
      setSentries(response.data);
    } catch (error) {
      notify.error(error?.response?.data || error.message);
    }
  }, [isDomainAzure, isPersonal, role, user.domainId]);

  useEffect(() => {
    let DATA_MAP = [
      { action: setMyDomain, page: myDomainPage },
      { action: setMyApps, page: 'myApps' },
    ];

    if (isPersonal) DATA_MAP = DATA_MAP.filter((i) => i.page !== 'myApps');
    if (isDomainAzure || !user.isAppPublishingEnabled) DATA_MAP = DATA_MAP.filter((i) => i.page !== 'myApps');
    if (user.showOnboarding && !hasSecureConnects && !sentryDeleted)
      DATA_MAP = DATA_MAP.filter((i) => ![myDomainPage, 'myApps'].includes(i.page));

    fetchSentries().then(() => {
      Promise.allSettled(
        DATA_MAP.map((item) => {
          const { url } = workspaceApi[item.page];
          const { query } = workspaceApi[item.page];

          if (isDomainAzure || ((isDomainHybryd || isDomainOnPremise) && sentryDeleted)) query.kind = 'All';
          if (user.showOnboarding && hasSecureConnects) query.kind = 'All';

          return axios.get(queryFormatter(url, query));
        }),
      )
        .then((results) => {
          results.forEach((result, index) => {
            const stateAction = DATA_MAP[index].action;
            const queryConfig = workspaceApi[DATA_MAP[index].page].query;
            const pageMeta = result.value?.headers['x-pagination']
              ? JSON.parse(result.value.headers['x-pagination'])
              : null;

            if (result.status === 'fulfilled') {
              if (isPersonal && result.value.data.some((i) => i.exceedsLimit)) setIsExceedsLimit(true);

              stateAction({ data: result.value.data, pageMeta, queryConfig });
            } else {
              stateAction({
                isError: true,
                error: result.reason.response.data.message || result.reason.message,
                queryConfig,
              });
            }
          });

          if (isDomainAzure || !user.isAppPublishingEnabled) {
            setMyApps({ data: [], queryConfig: workspaceApi.myApps.query });
          }
        })
        .then(() => setIsLoading(false));
    });
  }, [
    isDomainAzure,
    isDomainHybryd,
    isDomainOnPremise,
    hasSecureConnects,
    isPersonal,
    myDomainPage,
    sentryDeleted,
    user.isAppPublishingEnabled,
    user.showOnboarding,
    fetchSentries,
  ]);

  if (user.showOnboarding && user.hasActiveCustomerDomains && !hasSecureConnects) {
    return <OnboardingMessage userData={user} />;
  }

  if (isPersonal) {
    return (
      <div className={styles.workspaceContent}>
        {isExceedsLimit && (
          <Alert
            icon={<TriangleFilledIcon />}
            action={
              <Button variant="link" onClick={() => setIsExceedsLimit(false)}>
                <CloseIcon />
              </Button>
            }
            absolute>
            You have more computers than your subscription allows. Please contact help@trugrid.com for more information
          </Alert>
        )}
        <div className={styles.workspaceContentHeader}>
          <h3 className={styles.workspaceContentTitle}>My Computers</h3>
          <ActivationCode
            activationCode={user.domainActivationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <WorkspaceMyDomain
            myDomain={myDomain}
            userData={user}
            userRole={role}
            myDomainPage={myDomainPage}
            baseUrl={workspaceApi[myDomainPage].url}
            onUpdate={setMyDomain}
            onUpdateUser={onUpdateUser}
            loadingCurrent={loadingCurrent}
            setLoadingCurrent={setLoadingCurrent}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.workspaceContent}>
      <div className={styles.workspaceContentHeader}>
        <h3 className={styles.workspaceContentTitle}>My Domain</h3>
        {user.showOnboarding && hasSecureConnects && (
          <ActivationCode
            activationCode={user.domainActivationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        )}
        {!!(user.domainOrigin && ['spAdmin', 'corpAdmin', 'spTechAdmin', 'corpTechAdmin'].includes(role)) && (
          <ADStatus link="/company-management/domain-management" domain={user} />
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <span id="all-computers-tab">All Computers</span>
            </Tab>
            {isShowApps && <Tab id="my-apps-tab">My Apps</Tab>}
          </TabList>
          <TabPanel>
            <WorkspaceMyDomain
              myDomain={myDomain}
              userData={user}
              userRole={role}
              myDomainPage={myDomainPage}
              baseUrl={workspaceApi[myDomainPage].url}
              onUpdate={setMyDomain}
              onUpdateUser={onUpdateUser}
              loadingCurrent={loadingCurrent}
              setLoadingCurrent={setLoadingCurrent}
              hasSecureConnects={!!myDomain?.pageMeta?.AllMachinesCount}
              sentries={sentries}
            />
          </TabPanel>
          {isShowApps && (
            <TabPanel>
              <WorkspaceMyApps
                myApps={myApps}
                baseUrl={workspaceApi.myApps.url}
                role={role}
                onUpdate={setMyApps}
                onUpdateUser={onUpdateUser}
                loadingCurrent={loadingCurrent}
                setLoadingCurrent={setLoadingCurrent}
              />
            </TabPanel>
          )}
        </Tabs>
      )}
    </div>
  );
};

WorkspaceTabs.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  hasSecureConnects: PropTypes.bool,
};

export default WorkspaceTabs;
