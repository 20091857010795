import { CloseIcon, TriangleIcon } from 'assets/img';
import { DropdownObject, SwitchCheckbox } from 'components';
import SettingsCustomRes from '../components/SettingsCustomRes/SettingsCustomRes';
import SettingsRedirectDrives from '../components/SettingsRedirectDrives/SettingsRedirectDrives';
import styles from '../Settings.module.scss';

export const renderTabContent = (data, onChangeSettings, onCustomResSave, domainCurrent, item, label, setIsCustomResOpen, isCustomResOpen, isHideZeroTrust, setIsZeroTrustMsgOpen, isZeroTrustMsgOpen) => {
  if (label === 'dimension') {
    return (
      <SettingsCustomRes
        key="item-custom-res"
        setIsCustomResOpen={setIsCustomResOpen}
        isCustomResOpen={isCustomResOpen}
        options={item.collection}
        defaultPos={+data.dimension}
        item={item}
        height={data.desktopHeight || ''}
        width={data.desktopWidth || ''}
        disabled={data.screenMode !== 1}
        onChange={(value) => onChangeSettings('dimension', value)}
        onSave={onCustomResSave}
      />
    );
  }

  if (label === 'sharedDrives') {
    return (
      <SettingsRedirectDrives
        key="item-custom-red"
        options={data.sharedDrives}
        item={item}
        isZeroTrustEnabled={data.isZeroTrustEnabled}
        disabled={item.dependsOnZTP && data.isZeroTrustEnabled}
        onChange={(value) => onChangeSettings('sharedDrives', value)}
      />
    );
  }

  if (label === 'isZeroTrustEnabled' && domainCurrent === 'My Settings' && isHideZeroTrust) {
    if (data[label] && isZeroTrustMsgOpen) {
      return (
        <div key={label} className={styles.settingsMessage}>
          <div>
            <TriangleIcon />
            <p>You have limited access due to administrator settings</p>
          </div>
          <CloseIcon id="set-is-zero-trust-msg-open" onClick={() => setIsZeroTrustMsgOpen(false)} />
        </div>
      );
    }
    return false;
  }

  return (
    <div key={label} className={styles.settingsRow}>
      <div className={styles.settingsRowInfo}>
        <span className={styles.settingsRowInfoTitle}>{item.title}</span>
        {item.desc && <span className={styles.settingsRowInfoDesc}>{item.desc}</span>}
      </div>
      {item.collection ? (
        <DropdownObject
          id={`setting-${label}`}
          options={item.collection}
          defaultPos={data[label]}
          onChange={(key) => onChangeSettings(label, key)}
          width="16em"
          disabled={label === 'screenMode' && data[label] === 1 && isCustomResOpen}
        />
      ) : (
        <SwitchCheckbox
          id={label}
          value={label}
          checked={!!data[label]}
          disabled={item.dependsOnZTP && data.isZeroTrustEnabled}
          onChange={(e) => onChangeSettings(label, e.target.checked)}
        />
      )}
    </div>
  );
};
