import axios from 'config/customAxios';
import { convertToCamelCase, replaceSpecChar } from 'helpers/replaceSpecChar';
import { queryFormatter } from './queryFormatter';
import { notify } from './notify';

export const onChangePage = (value, pageValues) =>
  new Promise((resolve) => {
    const { updateAction } = pageValues;

    axios
      .get(value[0])
      .then((resp) =>
        updateAction({
          data: resp.data,
          pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
          queryConfig: value[1],
        }),
      )
      .catch((err) => notify.error(err.message))
      .finally(() => resolve());
  });

export const onChangeView = (value, pageValues) =>
  new Promise((resolve) => {
    const { queryConfig, baseUrl, updateAction, isKindField } = pageValues;

    let queryConfigNew;

    if (baseUrl.includes('IoTAgent')) {
      const valuesFrmt = value.map((v) => v.replace(/\s/g, ''));
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        deviceStatus: valuesFrmt[0],
        installationStatus: valuesFrmt[1],
      };
    } else if (baseUrl.includes('RememberMe')) {
      const valueFrmt = value === 'All Users' ? 'All' : value.split('Remember Me ')[1].replace(/\s/g, '');
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'displayName asc',
      };
    } else if (baseUrl.includes('SecurityManagement/Mfa')) {
      const valueFrmt = value === 'All' ? 'All' : value.split('MFA ')[1].replace(/\s/g, '');
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'displayName asc',
      };
    } else if (baseUrl.includes('DomainMachines')) {
      const key = isKindField ? 'kind' : 'assigned';

      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        [key]: value.split(' ')[0],
      };
    } else if (baseUrl.includes('SecurityManagement/DesktopShortcut')) {
      const isShortcutMfaRequired = value[1] === null ? null : !value[1];
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        desktopShortcutState: value[0],
        isShortcutMfaRequired,
        orderBy: 'displayName asc',
      };
    } else if (baseUrl.includes('SecurityManagement/AdPasswordReset')) {
      const viewBy = convertToCamelCase(value.id);
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        viewBy,
        orderBy: 'displayName asc',
      };
    } else if (baseUrl.includes('GetCustomerDomainsBranding')) {
      const valueFrmt = value === 'All' ? 'All' : value.split('Branding ')[1].replace(/\s/g, '');
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'dnsName asc',
      };
    } else {
      queryConfigNew = {
        ...queryConfig,
        pageNumber: 1,
        viewBy: value,
        orderBy: 'name asc',
      };
    }

    axios
      .get(queryFormatter(baseUrl, queryConfigNew))
      .then((resp) => {
        updateAction({
          data: resp.data,
          pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
          queryConfig: queryConfigNew,
        });
      })
      .catch((err) => notify.error(err.message))
      .finally(() => resolve());
  });

export const onSearch = (value, pageValues) => {
  const { queryConfig, baseUrl, updateAction } = pageValues;
  const valueNew = replaceSpecChar(value);

  const queryConfigNew = { ...queryConfig, pageNumber: 1, searchQuery: valueNew };

  axios
    .get(queryFormatter(baseUrl, queryConfigNew))
    .then((resp) =>
      updateAction({
        data: resp.data,
        pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
        queryConfig: queryConfigNew,
      }),
    )
    .catch((err) => notify.error(err.message));
};

export const onSort = (value, pageValues) => {
  const { queryConfig, sortingAction, baseUrl, updateAction, sortConfig } = pageValues;

  sortingAction(value);

  const order = sortConfig[0] === value ? (sortConfig[1] === 'asc' ? 'desc' : 'asc') : 'asc';
  const queryConfigNew = { ...queryConfig, orderBy: `${value} ${order}` };

  axios
    .get(queryFormatter(baseUrl, queryConfigNew))
    .then((resp) =>
      updateAction({
        data: resp.data,
        pageMeta: resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null,
        queryConfig: queryConfigNew,
      }),
    )
    .catch((err) => notify.error(err.message))
    .finally(() => sortingAction(''));
};
