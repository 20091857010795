import PropTypes from 'prop-types';
import { getMfaStatus } from 'utils';
import styles from './MfaStatusCell.module.scss';

const MfaStatusCell = ({ mfaStatus }) => {
  const { isMfaEnabled, isMfaSetupPending, isMfaDisabled, isMfaResetMode } = getMfaStatus(mfaStatus);

  if (isMfaEnabled) {
    return <p className={styles.statusEnabled}>MFA Enabled</p>;
  }
  if (isMfaSetupPending) {
    return <p className={styles.statusPending}>MFA Enabled: Setup Pending</p>;
  }
  if (isMfaDisabled) {
    return <p className={styles.statusDisabled}>MFA Disabled</p>;
  }
  if (isMfaResetMode) {
    return <p className={styles.statusReset}>MFA in Reset Mode</p>;
  }
};

MfaStatusCell.propTypes = {
  mfaStatus: PropTypes.number,
};

export default MfaStatusCell;
