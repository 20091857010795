import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CodeInput, ResendCode } from 'components';
import { useTimer } from 'hooks';
import { notify } from 'utils';
import { loginService } from 'services';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import styles from './EnterValidateCode.module.scss';

const EnterValidateCode = ({ userId, phoneDigits, smsHash, setSmsHash, phoneMask, setError }) => {
  const [smsCode, setSmsCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = () => {
    setIsLoading(true);
    loginService.resetPasswordVerifyPhone(userId, phoneDigits)
      .then(({ data }) => {
        resetCountdown(60);
        setSmsHash(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/login/forgot-password');
        } else if (err.response.status === 403) {
          setError('exceeded');
        } else if (err.response.status === 406) {
          setIsError(err?.response?.data || err.message);
        } else {
          notify.error(err?.response?.data || err.message);
        }
      });
  };

  const handleSendVerificationCode = () => {
    setIsLoading(true);
    loginService.resetPasswordVerifySmsCode(userId, smsHash, smsCode)
      .then(() => {
        setIsLoading(false);
        history.replace('/login/forgot-password/set-password', { userId });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 403) {
          setError('exceeded');
        } else if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <>
      <LoginHeader title="Reset Your Password" stepCount="2" currentStep="2" />
      <div className={styles.resetPassInfo}>We’ve sent a verification code to your phone at <strong>{phoneMask}</strong></div>
      <div className={styles.resetPassForm}>
        <div className={styles.resetPassLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={smsCode}
          setValue={setSmsCode}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.resetPassResend}>
          <ResendCode countDown={timeLeft} count={60} handleSend={resendVerificationCode} isDisabled={isLoading} />
        </div>
      </div>
    </>
  );
};

EnterValidateCode.propTypes = {
  userId: PropTypes.string,
  phoneDigits: PropTypes.string,
  smsHash: PropTypes.string,
  setSmsHash: PropTypes.func,
  phoneMask: PropTypes.string,
  setError: PropTypes.func,
};

export default EnterValidateCode;
