import PropTypes from 'prop-types';
import { Modal } from 'components';
import { TriangleFilledIcon } from 'assets/img';
import styles from './ErrorModal.module.scss';

const ErrorModal = ({ toggleShowErrorModal, showErrorModal, errorInfo }) => (
  <Modal
    isOpen={showErrorModal}
    onRequestClose={toggleShowErrorModal}
    contentLabel={<div className={styles.modalTitle}><TriangleFilledIcon /> {errorInfo.title}</div>}
  >
    {errorInfo.message}
  </Modal>
);

ErrorModal.propTypes = {
  toggleShowErrorModal: PropTypes.func,
  showErrorModal: PropTypes.bool,
  errorInfo: PropTypes.object,
};

export default ErrorModal;
