import PropTypes from 'prop-types';
import cn from 'classnames';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import { DownloadIcon, NotAllowIcon, SuccesCircleIcon } from 'assets/img';
import { corpTechAdmin, spTechAdmin } from 'consts';
import styles from '../../ActiveDirectorySetup.module.scss';

const OnPremiseAD = ({ domainData, userRole, isCompanyPage, toggleShowModal }) => {
  const sentryDeleted = isSentryDeleted(domainData);
  const { isDomainHybryd, isDomainOnPremise } = getDomainADStatus(domainData);

  const tooltipInfo = `
    <div>You cannot Reset AD until you uninstall<br /> all Sentry instances</div>
    <div class="${styles.tooltipSentries}">
      ${domainData.sentries.map(({ hostName, version }) => `<div class="${styles.tooltipSentry}">${hostName} (${version})</div>`).join(' ')}
    </div>
  `;

  return (
    <tr className={styles.domainMgmtTableWrap}>
      <td className={styles.domainMgmtTableItem}>
        <div className={styles.domainMgmtTableItemName}>On-premises Active Directory</div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        <div className={cn([styles.domainMgmtTableItemStatus], {
          [styles.notConnected]: !(isDomainOnPremise || isDomainHybryd),
        })}
        >
          {(isDomainOnPremise || isDomainHybryd) ? <SuccesCircleIcon /> : <NotAllowIcon />}
          <span>{(isDomainOnPremise || isDomainHybryd) ? 'Connected' : 'Not connected'}</span>
        </div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        {!(userRole === corpTechAdmin || (userRole === spTechAdmin && isCompanyPage)) && (
          (isDomainOnPremise || isDomainHybryd) ? (
            sentryDeleted ? (
              <Button size="32" variant="secondary" className={styles.domainMgmtTableItemButton} onClick={() => toggleShowModal('on-premise')}>
                Reset AD
              </Button>
            ) : (
              <span
                className={styles.domainMgmtTableItemTooltip}
                data-tip={tooltipInfo}
                data-for="reset-tooltip"
              >
                Reset AD
              </span>
            )
          ) : (
            <Link className={styles.sentryLink} external bold href="https://www.trugrid.com/downloads/sentry">
              <DownloadIcon /><span>Sentry Installer</span>
            </Link>
          )
        )}
      </td>
    </tr>
  );
};

OnPremiseAD.propTypes = {
  domainData: PropTypes.object,
  userRole: PropTypes.string,
  isCompanyPage: PropTypes.bool,
  toggleShowModal: PropTypes.func,
};

export default OnPremiseAD;
