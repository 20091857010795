/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { CodeInput, ResendCode } from 'components';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loginService } from 'services';
import { notify } from 'utils';
import { useTimer } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import styles from '../ResetViaSMS.module.scss';

const VerificationCode = ({ phoneDigits, mfaHash, setMfaHash, phoneMask }) => {
  const [codeValue, setCodeValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = () => {
    setIsLoading(true);
    loginService.verifyPhoneNumber(phoneDigits)
      .then(({ data }) => {
        setMfaHash(data);
        notify.success('SMS has been sent');
      })
      .catch((err) => {
        if (err?.response?.status === 409 || err?.response?.status === 405) {
          notify.error(err?.response?.data);
          history.replace('/login');
        } else {
          notify.error(err?.response?.data || err.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
        resetCountdown(60);
      });
  };

  const handleSendVerificationCode = () => {
    setIsLoading(true);
    loginService.resetMfaWithPin(codeValue, mfaHash)
      .then(() => {
        setIsLoading(false);
        notify.success('MFA has been successfully reset');
        history.replace('/login');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 405) {
          notify.error(err?.response?.data);
          history.replace('/login');
        }
        if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <>
      <LoginHeader
        title="Reset Your MFA"
        stepCount="2"
        currentStep="2"
      />
      <div className={styles.resetMfaInfo}>We’ve sent a verification code to your phone at <strong>{phoneMask}</strong></div>
      <div className={styles.resetMfaForm}>
        <div className={styles.resetMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.resetMfaResend}>
          <ResendCode countDown={timeLeft} count={60} handleSend={resendVerificationCode} isDisabled={isLoading} />
        </div>
      </div>
    </>
  );
};

VerificationCode.propTypes = {
  phoneDigits: PropTypes.string,
  mfaHash: PropTypes.string,
  setMfaHash: PropTypes.func,
  phoneMask: PropTypes.string,
};

export default VerificationCode;
