import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import { useOutsideClick } from 'hooks';
import { LoaderSmallIcon, FilterIcon } from 'assets/img';
import { getFilterValue } from '../../utils/shortcutUtils';
import { MFA_STATUSES, SHORTCUT_STATUSES } from '../../consts/utilsConsts';
import styles from './ShortcutFilters.module.scss';

const ShortcutFilters = ({ id, filterOptions, onFilter, isDomainOnPremise }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShotcutStatus, setSelectedShotcutStatus] = useState(SHORTCUT_STATUSES[filterOptions[0]]);
  const [selectedMfaStatus, setSelectedMfaStatus] = useState(MFA_STATUSES[filterOptions[1]]);
  const shortcutFilterRef = useRef();
  const nodeRef = useRef();

  const isShowMFACodeStatusFilter = selectedShotcutStatus.key === 1 && isDomainOnPremise;

  const filterValue = getFilterValue(selectedShotcutStatus, selectedMfaStatus, isShowMFACodeStatusFilter);
  const isDisabledClear = selectedShotcutStatus.key === 0;
  const isDisabledDone =
    selectedShotcutStatus.key === SHORTCUT_STATUSES[filterOptions[0]].key &&
    selectedMfaStatus.key === MFA_STATUSES[filterOptions[1]].key;

  const onClose = () => {
    setSelectedShotcutStatus(SHORTCUT_STATUSES[filterOptions[0]]);
    setSelectedMfaStatus(MFA_STATUSES[filterOptions[1]]);
    setIsOpen(false);
  };

  const onClear = () => {
    setSelectedShotcutStatus(SHORTCUT_STATUSES[0]);
    setSelectedMfaStatus(MFA_STATUSES[0]);
  };

  const onFilterHandler = async () => {
    setIsLoading(true);
    onFilter([selectedShotcutStatus.key, isShowMFACodeStatusFilter ? selectedMfaStatus.key : null]).then(() =>
      setIsLoading(false),
    );
    setIsOpen(false);
  };

  useOutsideClick(shortcutFilterRef, () => isOpen && onClose());

  return (
    <div className={styles.shortcutFilter} ref={shortcutFilterRef}>
      <button
        type="button"
        id={id}
        className={cn(styles.shortcutFilterButton, { [styles.shortcutFilterButtonDisabled]: isLoading })}
        onClick={() => (isOpen ? onClose() : setIsOpen(true))}>
        <div className={cn(styles.shortcutFilterSelect, { [styles.shortcutFilterSelectActive]: isOpen })}>
          {isLoading ? <LoaderSmallIcon /> : <FilterIcon />}
          <span className={styles.shortcutFilterValue}>{filterValue}</span>
        </div>
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.shortcutFilterOptionsTransitionEnter,
          enterActive: styles.shortcutFilterOptionsTransitionEnterActive,
          exit: styles.shortcutFilterOptionsTransitionExit,
          exitActive: styles.shortcutFilterOptionsTransitionExitActive,
        }}>
        <div className={styles.shortcutFilterOptions} ref={nodeRef}>
          <div className={styles.shortcutFilterOptionsHeader}>Desktop Shortcut Status </div>
          <ul className={styles.shortcutFilterOptionsList}>
            {SHORTCUT_STATUSES.map((status) => (
              <li key={status.key} className={styles.shortcutFilterOptionsListItem}>
                <Checkbox
                  id={status.key}
                  label={status.value}
                  checked={selectedShotcutStatus.key === status.key}
                  isCircle
                  onChange={() => setSelectedShotcutStatus(status)}
                />
              </li>
            ))}
          </ul>

          {isShowMFACodeStatusFilter && (
            <>
              <div className={cn(styles.shortcutFilterOptionsHeader, styles.shortcutFilterOptionsHeaderStatus)}>
                MFA Code Status
              </div>
              <ul className={styles.shortcutFilterOptionsList}>
                {MFA_STATUSES.map((status) => (
                  <li key={status.key} className={styles.shortcutFilterOptionsListItem}>
                    <Checkbox
                      id={status.key}
                      label={status.value}
                      checked={selectedMfaStatus.key === status.key}
                      isCircle
                      onChange={() => setSelectedMfaStatus(status)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          <div className={styles.shortcutFilterOptionsActions}>
            <Button
              id="shortcut-filter-clear"
              type="button"
              variant="secondary"
              size="32"
              isDisabled={isDisabledClear}
              style={{ width: '48%' }}
              onClick={onClear}>
              Clear
            </Button>
            <Button
              id="shortcut-filter-done"
              type="button"
              variant="primary"
              size="32"
              isDisabled={isDisabledDone}
              style={{ width: '48%' }}
              onClick={onFilterHandler}>
              Done
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

ShortcutFilters.propTypes = {
  id: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  isDomainOnPremise: PropTypes.bool.isRequired,
};

export default ShortcutFilters;
