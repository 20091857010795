import cn from 'classnames';
import PropTypes from 'prop-types';
import Link from 'components/Link/Link';
import { getUserRole } from 'utils';
import styles from './FreeTrialEndedModal.module.scss';

const FreeTrialEndedModal = ({ user }) => {
  const role = getUserRole(user);
  const isAdmin = role === 'spAdmin' || role === 'corpAdmin';

  return (
    <div className={cn(styles.modal, { [styles.short]: !isAdmin })}>
      <div className={styles.modalHeader}>
        <span>Your free trial has ended</span>
      </div>
      <div className={styles.modalContent}>
        {isAdmin ? (
          <>
            <div className={styles.modalContentInfo}>Please follow the link below to update your payment method</div>
            <Link className={styles.modalContentLink} to="/profile/subscription">
              Update payment details
            </Link>
          </>
        ) : (
          <div className={styles.modalContentInfo}>Please contact your administrator</div>
        )}
      </div>
    </div>
  );
};

FreeTrialEndedModal.propTypes = {
  user: PropTypes.object,
};

export default FreeTrialEndedModal;
