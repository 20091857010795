/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DomainStatusMsg, Loader } from 'components';
import { PrepareTimeRangeConfiguration } from 'utils';
import { getDetailedReportStatus, getDomainStatus } from 'helpers';
import Authentication from './components/Authentication/Authentication';
import Licensing from './components/Licensing/Licensing';
import Assignments from './components/Assignments/Assignments';
import styles from './Reports.module.scss';

const LOGIN_REQUEST_TIMEOUT = 5000;

const Reports = ({ domain, userRole, isCompanyPage, isDomainNotActivated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [logins, setLogins] = useState(null);
  const [users, setUsers] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [reports, setReports] = useState(null);
  const [selectedRange, setSelectedRange] = useState('Last 7 days');
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedLicenseType, setSelectedLicenseType] = useState('SecureRDP');
  const timeRange = useMemo(() => PrepareTimeRangeConfiguration(), []);
  const [licensingReportData, setLicensingReportData] = useState();
  const [isFastGenerate, setIsFastGenerate] = useState(true);

  const [reportLoading, setReportLoading] = useState({
    userAssignments: false,
    machineAssignmnets: false,
    appAssignments: false,
  });

  const { isDomainActive } = getDomainStatus(domain);

  const getAssigmentsReports = async () => {
    try {
      const { data } = await axios.get(`/api/reports/GetAssignmentsReportsStatus?domainId=${domain.id}`);
      setReports(data);
    } catch (err) {
      setReports({ isError: true, error: err?.response?.data || err.message });
    }
  };

  useEffect(() => {
    const fetchReportStatusData = async () => {
      try {
        const res = await axios.get(`/api/reports/DetailedReportStatus?domainId=${domain.id}`);
        const { isReportGenerated, isReportGenerating } = getDetailedReportStatus(res.status);
        if (isReportGenerated) {
          setLicensingReportData(res.data);
        }
        if (isReportGenerating) {
          setIsFastGenerate(false);
        }
      } catch (err) {
        toast.error(err, 3000);
      }
    };

    const getLoginsData = async () => {
      try {
        const res = await axios.post(
          '/api/reports/logins',
          {
            domainId: domain.id,
            start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            end: new Date(new Date().getTime()),
          },
          {
            timeout: LOGIN_REQUEST_TIMEOUT,
          },
        );
        setLogins(res.data);
      } catch (err) {
        if (err.code === 'ECONNABORTED') {
          setLogins([]);
        } else {
          setLogins({ isError: true, error: err?.response?.data || err.message });
        }
      }
    };

    const getUsersData = async () => {
      try {
        const { data } = await axios.post('/api/reports/employees', { domainId: domain.id });
        setUsers(data);
        setSelectedUsers(data.map((i) => i.userId));
      } catch (err) {
        setUsers({ isError: true, error: err?.response?.data || err.message });
      }
    };

    const getLicensesData = async () => {
      try {
        const options = { domainId: domain.id, licenseType: selectedLicenseType };
        const { data } = await axios.post('/api/reports/licenses', options);
        data.sort((a, b) => (a.domainId === domain.id ? -1 : b.domainId === domain.id ? 1 : 0));
        setLicenses(data);
      } catch (err) {
        setLicenses({ isError: true, error: err?.response?.data || err.message });
      }
    };

    const fetchData = async () => {
      await Promise.all([
        getUsersData(),
        getLicensesData(),
        fetchReportStatusData(),
        getLoginsData(),
        getAssigmentsReports(),
      ]);
      setIsLoading(false);
    };

    if (isDomainActive) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getOptions = (selectedRangeNew = selectedRange, selectedUsersNew = selectedUsers) => {
    const rangeData = timeRange.find((i) => i.text === selectedRangeNew);
    return {
      domainId: domain.id,
      start: rangeData.startDate,
      end: rangeData.endDate,
      timeZone: new Date().getTimezoneOffset(),
      users: selectedUsersNew,
    };
  };

  const onUpdateUsers = async (selectedUsersNew) => {
    try {
      setSelectedUsers(selectedUsersNew);
      const { data } = await axios.post('/api/reports/logins', getOptions(undefined, selectedUsersNew), {
        timeout: LOGIN_REQUEST_TIMEOUT,
      });
      setLogins(data);
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        setLogins([]);
      } else {
        toast.error(err?.response?.data || err.message, 3000);
      }
    }
  };

  const onUpdateRange = async (selectedRangeNew) => {
    try {
      setSelectedRange(selectedRangeNew);
      const { data } = await axios.post('/api/reports/logins', getOptions(selectedRangeNew), {
        timeout: LOGIN_REQUEST_TIMEOUT,
      });
      setLogins(data);
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        setLogins([]);
      } else {
        toast.error(err?.response?.data || err.message, 3000);
      }
    }
  };
  const onUpdateLicenseType = (selectedLicenseTypeNew) => {
    setSelectedLicenseType(selectedLicenseTypeNew);

    return new Promise((resovle) =>
      axios
        .post('/api/reports/licenses', { domainId: domain.id, licenseType: selectedLicenseTypeNew })
        .then((resp) => {
          resp.data.sort((a, b) => (a.domainId === domain.id ? -1 : b.domainId === domain.id ? 1 : 0));
          setLicenses(resp.data);
        })
        .catch((err) => toast.error(err.message, 3000))
        .finally(() => resovle()),
    );
  };

  if (isLoading) return <Loader />;
  if (isDomainNotActivated) return <DomainStatusMsg domainData={domain} />;

  const activatedDomains = licenses.filter((el) => getDomainStatus(el).isDomainActive);

  return (
    <div className={styles.reports}>
      <h3 className={styles.reportsTitle}>Reports</h3>
      <Tabs>
        <TabList>
          <Tab>
            <span id="authentication-tab">Authentication</span>
          </Tab>
          <Tab>
            <span id="licensing-tab">Licensing</span>
          </Tab>
          <Tab>
            <span id="assignments-tab">Assignments</span>
          </Tab>
        </TabList>
        <TabPanel>
          <Authentication
            users={users}
            logins={logins?.dailyStats}
            selectedRange={selectedRange}
            selectedUsers={selectedUsers}
            updateRange={onUpdateRange}
            updateUsers={onUpdateUsers}
            timeRange={timeRange}
            apiOptions={getOptions()}
          />
        </TabPanel>
        <TabPanel>
          <Licensing
            setLicensingReportData={setLicensingReportData}
            licensingReportData={licensingReportData}
            licenses={licenses}
            primaryDomain={domain.dnsName}
            userRole={userRole}
            selectedLicenseType={selectedLicenseType}
            onUpdateLicenseType={onUpdateLicenseType}
            setIsFastGenerate={setIsFastGenerate}
            isFastGenerate={isFastGenerate}
            isCompanyPage={isCompanyPage}
          />
        </TabPanel>
        <TabPanel>
          <Assignments
            domain={domain}
            reports={reports}
            licenses={activatedDomains}
            userRole={userRole}
            isCompanyPage={isCompanyPage}
            getAssigmentsReports={getAssigmentsReports}
            reportLoading={reportLoading}
            setReportLoading={setReportLoading}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

Reports.propTypes = {
  domain: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  isCompanyPage: PropTypes.bool,
  isDomainNotActivated: PropTypes.bool,
};

export default Reports;
