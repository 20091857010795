import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, CountryListDropdown } from 'components';
import { loginService } from 'services';
import { notify } from 'utils';
import styles from './VerifyPhoneNumber.module.scss';

const VerifyPhoneNumber = ({ countriesList, dialCode, currentCountry, setCurrentCountry, phoneNumber, setPhoneNumber, setMfaHash, setPhoneMask }) => {
  const [isSending, setIsSending] = useState(false);

  const history = useHistory();

  const handleSetCurrentCountry = (value) => setCurrentCountry(value);

  const onSubmitPhoneNumber = (e) => {
    e.preventDefault();
    if ((phoneNumber && phoneNumber.length > 4)) {
      setIsSending(true);
      loginService.requestVerificationCodeBySms(phoneNumber.replace(/\D+/g, ''), dialCode)
        .then(({ data }) => {
          setPhoneMask(data.phoneMask);
          setMfaHash(data.pinHash);
          setIsSending(false);
          notify.success('SMS has been sent');
          history.push('/setup-mfa/authentication-app/verification-code');
        })
        .catch((err) => {
          setIsSending(false);
          if (err?.response?.status === 409) {
            notify.error(err?.response?.data);
            history.replace('/setup-mfa');
          } else {
            notify.error(err.response?.data || err.message);
          }
        });
    }
  };

  return (
    <form className={styles.setupMfa} onSubmit={onSubmitPhoneNumber} noValidate>
      <div className={styles.setupMfaInfo}>Receive a code via text to enter on the next screen</div>
      <CountryListDropdown
        id="phone-code-dd"
        label="Enter Your Phone Number"
        options={countriesList}
        currentCountry={currentCountry}
        onChange={handleSetCurrentCountry}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <Button
        type="submit"
        variant="primary"
        size="40"
        isLoading={isSending}
        isDisabled={!(phoneNumber && phoneNumber.length > 4)}
        className={styles.setupMfaButton}
      >
        Get a Code
      </Button>
    </form>
  );
};

VerifyPhoneNumber.propTypes = {
  countriesList: PropTypes.object,
  dialCode: PropTypes.string,
  currentCountry: PropTypes.string,
  setCurrentCountry: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setMfaHash: PropTypes.func,
  setPhoneMask: PropTypes.func,
};

export default VerifyPhoneNumber;
