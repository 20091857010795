import { forwardRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CloseIcon } from 'assets/img';
import styles from './ClearInput.module.scss';

const ClearInput = forwardRef(({
  id, type, value, min, max, placeholder, validationRule, onChange, onClear, className,
}, ref) => {
  const [isErrorMsg, setIsErrorMsg] = useState('');

  const onBlurHandler = (e) => {
    if (validationRule) {
      const isValidMsg = validationRule(e.target.value);
      setIsErrorMsg(isValidMsg);
    }
  };

  const onChangeHandler = (e) => {
    if (type === 'number' && e.target.value) {
      if (!e.target.value.match(/^\d+$/g)) {
        return false;
      }
    }
    const val = e.target.value.slice(0, max);
    onChange(val);
  };

  const onClearHandler = () => {
    setIsErrorMsg(false);
    onClear();
  };

  return (
    <div className={cn(styles.clearInput, { [styles.clearInputError]: isErrorMsg, [className]: className })}>
      <div className={styles.clearInputWrapper}>
        <input
          id={id}
          ref={ref}
          type={type}
          value={value === '0' ? '' : value}
          minLength={min || 0}
          maxLength={max}
          placeholder={placeholder}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          className={styles.clearInputWrapperInput}
        />
        {!!value && <CloseIcon onClick={onClearHandler} />}
      </div>
      {!!isErrorMsg && <span className={styles.clearInputErrorMsg}>{isErrorMsg}</span>}
    </div>
  );
});

ClearInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
  min: PropTypes.string,
  max: PropTypes.string,
  placeholder: PropTypes.string,
  validationRule: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  className: PropTypes.any,
};

ClearInput.displayName = 'ClearInput';

export default ClearInput;
