/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { queryFormatter, onChangePage, DomainContext } from 'utils';
import WorkspaceTable from 'pages/Workspace/components/WorkspaceTable/WorkspaceTable';
import WorkspaceHeader from 'pages/Workspace/components/WorkspaceHeader/WorkspaceHeader';
import { Link, StatusMessage } from 'components';
import { custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';

const WorkspaceApps = ({ custApps, baseUrl, userRole, onUpdateUser, onUpdate, loadingCurrent, setLoadingCurrent }) => {
  const { domain } = useContext(DomainContext);
  const isAdminRole = [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(userRole);
  const paginationData = useMemo(() => ({
    updateAction: onUpdate,
    errorAction: toast.error,
  }), [onUpdate]);

  useEffect(() => {
    if (custApps?.queryConfig?.searchQuery) {
      const query = { ...custApps.queryConfig, searchQuery: '' };
      const url = queryFormatter(baseUrl, query);

      onChangePage([url, query], paginationData);
    }
  }, []);

  const renderApps = () => {
    if (!custApps.data.length && !custApps.queryConfig.searchQuery) {
      return (
        <StatusMessage header="No assigned apps">
          Please check Active Directory or <Link href="https://www.trugrid.com/docs/addapp" external bold>add apps</Link>
        </StatusMessage>
      );
    }

    return (
      <WorkspaceTable
        isComputers={false}
        baseUrl={baseUrl}
        tableData={custApps}
        queryConfig={custApps.queryConfig}
        onUpdate={onUpdate}
        onUpdateUser={onUpdateUser}
        loadingCurrent={loadingCurrent}
        setLoadingCurrent={setLoadingCurrent}
      />
    );
  };

  if (isAdminRole && !custApps?.data?.length && !custApps?.queryConfig.searchQuery) {
    return (
      <StatusMessage header="No apps">
        Please go to <Link to={{ pathname: `/customer-management/${domain.dnsName}/resource-assignments`, search: '?tab=app' }} bold>Resource Assignment</Link> in order to manage applications
      </StatusMessage>
    );
  }

  if (custApps.isError) {
    return (
      <StatusMessage error>
        {custApps.error}
      </StatusMessage>
    );
  }

  return (
    <>
      <WorkspaceHeader
        isShowAll
        baseUrl={baseUrl}
        queryConfig={custApps.queryConfig}
        totalCount={custApps.pageMeta.TotalCount}
        searchBy="App Title"
        allValue="Assigned Apps"
        onUpdate={onUpdate}
      />

      { renderApps() }
    </>
  );
};

WorkspaceApps.propTypes = {
  custApps: PropTypes.object,
  baseUrl: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  loadingCurrent: PropTypes.string,
  setLoadingCurrent: PropTypes.func,
};

export default WorkspaceApps;
