import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { RefreshIcon } from 'assets/img';
import { Button, SwitchCheckbox } from 'components';
import axios from 'config/customAxios';
import AssignmentsRefresh from '../../../AssignmentsRefresh/AssignmentsRefresh';
import styles from './AppsHeader.module.scss';

const AppsHeader = ({
  appPublishingInit,
  appPublishingCurrent,
  isLoading,
  refreshPageUrl,
  domainData,
  onRefreshDataFromAd,
  onUpdateAppPublishing,
  toggleShowToggleRemoteAppsModal,
}) => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  const onRefreshPage = () => {
    setIsRefreshLoading(true);

    axios.get(refreshPageUrl)
      .then((resp) => {
        const isAppPublishingEnabled = refreshPageUrl.includes('GetInitialUserData')
          ? resp.data.isAppPublishingEnabled
          : resp.data.find((i) => i.id === domainData.id).isAppPublishingEnabled;

        if (isAppPublishingEnabled === appPublishingCurrent) {
          onUpdateAppPublishing(isAppPublishingEnabled);
          setIsRefreshLoading(false);
        } else {
          setIsRefreshLoading(false);
          toast.warn('Data is not updated yet...', 3000);
        }
      })
      .catch((err) => toast.error(err.message, 3000));
  };

  return (
    <div className={styles.appsHeader}>
      <div className={styles.appsHeaderWrap}>
        <div className={styles.appsHeaderStatus}>
          <p>RemoteApp Status</p>
          <SwitchCheckbox
            id="toggle-app-publishing"
            checked={appPublishingCurrent}
            disabled={appPublishingInit !== appPublishingCurrent || isLoading}
            onChange={toggleShowToggleRemoteAppsModal}
            className={styles.appsHeaderCheckbox}
          />
          <span className={cn(styles.appsHeaderCheckboxText, { [styles.appsHeaderCheckboxTextActive]: appPublishingCurrent })}>
            {appPublishingCurrent ? 'ON' : 'OFF'}
          </span>
        </div>
      </div>

      {appPublishingInit === appPublishingCurrent ? (
        appPublishingInit && (
          <AssignmentsRefresh
            domainData={domainData}
            isAppsPage
            title="Refresh available applications"
            onUpdate={onRefreshDataFromAd}
          />
        )
      ) : (
        <Button
          className={cn(styles.appsHeaderRefresh, { [styles.appsHeaderRefreshLoading]: isRefreshLoading })}
          id="refresh-apps"
          type="button"
          variant="primary"
          size="40"
          icon={<RefreshIcon />}
          onClick={onRefreshPage}
        >
          Refresh data
        </Button>
      )}
    </div>
  );
};

AppsHeader.propTypes = {
  appPublishingInit: PropTypes.bool,
  appPublishingCurrent: PropTypes.bool,
  isLoading: PropTypes.bool,
  refreshPageUrl: PropTypes.string,
  domainData: PropTypes.object,
  onRefreshDataFromAd: PropTypes.func,
  onUpdateAppPublishing: PropTypes.func,
  toggleShowToggleRemoteAppsModal: PropTypes.func,
};

export default AppsHeader;
