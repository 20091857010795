import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import { signOut } from 'helpers';
import { LoginContext } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { getStatusMessage } from './utils/getStatusMessage';
import styles from './EmailValidationResult.module.scss';

const EmailValidationResult = () => {
  const { user, setEmail } = useContext(LoginContext);
  const history = useHistory();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [verificationData, setVerificationData] = useState({ email: '', status: '', isPersonalAccount: false });

  useEffect(() => {
    loginService.processEmailVerification(token)
      .then(({ data }) => {
        const { title: status, email, isPersonalAccount } = data;
        if (typeof setEmail === 'function') {
          setEmail(email);
        }
        setVerificationData({ email, status, isPersonalAccount });
      })
      .catch((err) => setError({ status: true, message: err.response?.data.message || err.message }))
      .finally(() => setIsLoading(false));
  }, [setEmail, token]);

  const onResendEmail = () => {
    setIsSending(true);
    loginService.resendVerificationEmailByToken(token)
      .then(() => toast.success('The email has been sent', 3000))
      .catch((err) => setError({ status: true, message: err.response?.data.message || err.message }))
      .finally(() => setIsSending(false));
  };

  const renderButton = () => {
    if (verificationData.status === 'Email validated successfully' || verificationData.status === 'Email updated successfully') {
      return (
        <Button
          id="proceed-with-truGrid"
          onClick={() => (user ? signOut() : history.replace('/'))}
          size="48"
          variant="primary"
          className={styles.validationButton}
        >
          Proceed to TruGrid
        </Button>
      );
    }
    if (verificationData.status === 'Link is expired') {
      return (
        <Button
          id="resend-email"
          onClick={onResendEmail}
          size="48"
          variant="primary"
          isLoading={isSending}
          className={styles.validationButton}
        >
          Resend Email
        </Button>
      );
    }
    if (verificationData.status === 'Invalid link') {
      return (
        <Button
          id="back-to-login"
          onClick={() => history.replace('/login')}
          size="48"
          variant="primary"
          isLoading={isSending}
          className={styles.validationButton}
        >
          Back to Login
        </Button>
      );
    }
  };

  if (error.status) {
    return (
      <div>
        <LoginHeader title="Invalid link" hideBack hideSteps />
        <div className={styles.validationInfo}>The page is not available</div>
      </div>

    );
  }

  const { title, message } = getStatusMessage(verificationData);

  return (
    <div className={styles.validation}>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <LoginHeader title={title} hideBack hideSteps />
          <div className={styles.validationInfo}>{message}</div>
          {renderButton()}
        </div>
      )}
    </div>
  );
};

export default EmailValidationResult;
