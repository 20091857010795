import PropTypes from 'prop-types';
import { Modal } from 'components';

const ModalResetSingleDomain = ({ isOpen, onRequestClose, onResetSingleSettings, isResetting }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Reset settings"
    actionFunc={onResetSingleSettings}
    actionTitle="Reset"
    actionLoading={isResetting}
    variantBtn="deleteOutline"
  >
    Do you really want to reset all settings to default?
  </Modal>
);

ModalResetSingleDomain.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onResetSingleSettings: PropTypes.func,
  isResetting: PropTypes.bool,
};

export default ModalResetSingleDomain;
