import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { CodeInput, ResendCode } from 'components';
import { useTimer } from 'hooks';
import { loginService } from 'services';
import { notify, UserContext } from 'utils';
import { useHistory } from 'react-router-dom';
import styles from './VerificationCode.module.scss';

const VerificationCode = ({ phoneNumber, mfaHash, setMfaHash, dialCode, phoneMask }) => {
  const { user, onUpdateUser } = useContext(UserContext);
  const history = useHistory();

  const [codeValue, setCodeValue] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState('');

  const clearError = () => setIsError('');
  const { timeLeft, resetCountdown } = useTimer(60);

  const onResendPhoneNumber = () => {
    setIsSending(true);
    loginService.requestVerificationCodeBySms(phoneNumber.replace(/\D+/g, ''), dialCode)
      .then(({ data }) => {
        resetCountdown(60);
        notify.success('SMS has been sent');
        setMfaHash(data.pinHash);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/setup-mfa');
        } else {
          notify.error(err.response?.data || err.message);
        }
      })
      .finally(() => setIsSending(false));
  };

  const onSubmitCode = () => {
    setIsSending(true);

    loginService.checkVerificationCode(codeValue, mfaHash)
      .then(() => {
        const userNew = JSON.parse(JSON.stringify(user));
        userNew.mustSetupMfa = false;
        userNew.isPhoneMfaActive = true;
        userNew.isMfaActive = false;
        onUpdateUser(userNew);
        notify.success('Verification Successful. MFA Setup Completed');
        if (user.roles.includes('trugridadmin')) {
          return window.location.replace('/admin');
        }
      })
      .catch((err) => {
        setIsSending(false);
        if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err.message);
        }
      });
  };

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>We've sent a verification code to your phone at <strong>{phoneMask}</strong></div>
      <div className={styles.setupMfaWrap}>
        <div className={styles.setupMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isSending}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={onSubmitCode}
        />
        <div className={styles.setupMfaResend}>
          <ResendCode countDown={timeLeft} count={60} handleSend={onResendPhoneNumber} isDisabled={isSending} />
        </div>
      </div>
    </div>
  );
};

VerificationCode.propTypes = {
  phoneNumber: PropTypes.string,
  mfaHash: PropTypes.string,
  setMfaHash: PropTypes.func,
  dialCode: PropTypes.string,
  phoneMask: PropTypes.string,
};

export default VerificationCode;
