import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from 'react-router-dom';
import { Loader, ActivationCode, DomainStatusMsg } from 'components';
import { queryFormatter } from 'utils';
import { getDomainADStatus, getDomainStatus, isSentryDeleted } from 'helpers';
import DesktopAssignments from './components/DesktopAssignments/DesktopAssignments';
import AssignmentsEmpty from './components/AssignmentsEmpty/AssignmentsEmpty';
import AppAssignments from './components/AppAssignments/AppAssignments';
import ManageAssignmentsModal from './components/ManageAssignmentsModal/ManageAssignmentsModal';
import styles from './ResourceAssignments.module.scss';

const ResourceAssignments = ({ domain, isCompanyPage, refreshPageUrlCustomers, onUpdateAppPublishing, isDomainNotActivated }) => {
  const sentryDeleted = isSentryDeleted(domain);
  const { isDomainAzure } = getDomainADStatus(domain);
  const { isDomainActive } = getDomainStatus(domain);

  const [isLoading, setIsLoading] = useState(true);
  const [deskAssignments, setDeskAssignments] = useState(null);
  const [appAssignments, setAppAssignments] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { search } = useLocation();
  const [modalData, setModalData] = useState(null);
  const [showManageAssignmentsModal, setShowManageAssignmentsModal] = useState(false);

  const openManageAssignmentsModal = (data) => {
    setModalData(data);
    setShowManageAssignmentsModal(true);
  };
  const closeManageAssignmentsModal = () => {
    setShowManageAssignmentsModal(false);
  };

  const DATA_MAP = useMemo(() => [
    {
      action: setDeskAssignments,
      baseUrl: `/api/ResourceAssignments/GetDesktopAssignments?domainId=${domain.id}`,
      query: {
        pageNumber: 1, pageSize: 10, viewBy: 'Users', orderBy: 'name asc', searchQuery: '',
      },
    },
    {
      action: setAppAssignments,
      baseUrl: `/api/ResourceAssignments/GetAppAssignments?domainId=${domain.id}`,
      query: {
        pageNumber: 1, pageSize: 10, viewBy: 'Users', orderBy: 'name asc', searchQuery: '',
      },
    },
  ], [domain.id]);

  const fetchData = useCallback((isFetchDesks, isFetchApps) => new Promise((resolve) => {
    const data = [];

    if (isFetchDesks) data.push(DATA_MAP[0]);
    if (isFetchApps) data.push(DATA_MAP[1]);

    Promise.allSettled(data.map((item) => axios.get(queryFormatter(item.baseUrl, item.query))))
      .then((results) => {
        results.forEach((result, index) => {
          const stateAction = data[index].action;
          const queryConfig = data[index].query;
          const pageMeta = result.value?.headers['x-pagination'] ? JSON.parse(result.value.headers['x-pagination']) : null;
          if (result.status === 'fulfilled') {
            stateAction({ data: result.value.data, pageMeta, queryConfig });
          } else {
            stateAction({ isError: true, error: result.reason.response.data || result.reason.message });
          }
        });
      })
      .then(() => resolve());
  }), [DATA_MAP]);

  useEffect(() => {
    const isFetchApps = !isDomainAzure && domain.isAppPublishingEnabled;
    if ((!sentryDeleted || isDomainAzure) && isDomainActive) {
      fetchData(true, isFetchApps)
        .then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [domain.isAppPublishingEnabled, fetchData, isDomainActive, isDomainAzure, sentryDeleted]);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.get('tab') === 'app') {
      setTabIndex(1);
    }
  }, [search]);

  const updateAssignmentsModal = (assignmentsCountNew, resourceId, type) => {
    const assignmentsNew = JSON.parse(JSON.stringify(type === 'App' ? appAssignments : deskAssignments));

    assignmentsNew.data.forEach((assignment) => {
      if (assignment.resourceId === resourceId) {
        assignment.assignmentsCount = assignmentsCountNew;
      }
    });
    if (type === 'App') {
      setAppAssignments(assignmentsNew);
    } else {
      setDeskAssignments(assignmentsNew);
    }
  };

  const onUpdateAppPublishingHandler = (status, isCurrent) => {
    if (isCurrent) {
      onUpdateAppPublishing(status, isCurrent);
    } else if (status) {
      setIsLoading(true);

      fetchData(false, true)
        .then(() => onUpdateAppPublishing(status))
        .then(() => setIsLoading(false));
    } else {
      onUpdateAppPublishing(status);
      setAppAssignments(null);
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  if (isDomainNotActivated || sentryDeleted) {
    return (
      <DomainStatusMsg
        domainData={domain}
        isSentryDeleted={sentryDeleted}
        pageName="Resource Assignment"
      />
    );
  }

  const refreshPageUrl = isCompanyPage ? '/api/Account/GetInitialUserData?clickOnceExists=true' : refreshPageUrlCustomers;
  const isEmpty = !deskAssignments?.data.length && !deskAssignments?.queryConfig.searchQuery && deskAssignments?.queryConfig.viewBy !== 'Computers';

  return (
    <div className={styles.resourceAssignments}>
      <div className={styles.resourceAssignmentsHeader}>
        <h3 className={styles.resourceAssignmentsTitle}>Manage resource and user associations</h3>
        <ActivationCode
          activationCode={domain.activationCode}
          label="Activation Code:"
          copyMessage="Activation code was saved to clipboard"
        />
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab><span id="tab-desktop-assignment">Desktop Assignment</span></Tab>
          {!isDomainAzure && (
            <Tab><span id="tab-app-assignment">App Assignment</span></Tab>
          )}
        </TabList>

        <TabPanel>
          {isEmpty ? (
            <AssignmentsEmpty domainData={domain} fetchData={fetchData} />
          ) : (
            <DesktopAssignments
              deskAssignments={deskAssignments}
              domainData={domain}
              baseUrl={DATA_MAP[0].baseUrl}
              onUpdate={(deskAssignmentsNew) => setDeskAssignments(deskAssignmentsNew)}
              openManageAssignmentsModal={openManageAssignmentsModal}
            />
          )}
        </TabPanel>
        {!isDomainAzure && (
          <TabPanel>
            <AppAssignments
              appAssignments={appAssignments}
              domainData={domain}
              baseUrl={DATA_MAP[1].baseUrl}
              refreshPageUrl={refreshPageUrl}
              onUpdateAppPublishing={onUpdateAppPublishingHandler}
              onUpdate={(assignmentsNew) => setAppAssignments(assignmentsNew)}
              openManageAssignmentsModal={openManageAssignmentsModal}
            />
          </TabPanel>
        )}
      </Tabs>
      {showManageAssignmentsModal && (
        <ManageAssignmentsModal
          showManageAssignmentsModal={showManageAssignmentsModal}
          closeManageAssignmentsModal={closeManageAssignmentsModal}
          data={modalData}
          onUpdate={updateAssignmentsModal}
        />
      )}
    </div>
  );
};

ResourceAssignments.propTypes = {
  domain: PropTypes.object,
  isCompanyPage: PropTypes.bool,
  onUpdateAppPublishing: PropTypes.func,
  refreshPageUrlCustomers: PropTypes.string,
  isDomainNotActivated: PropTypes.bool,
};

export default ResourceAssignments;
