import { forwardRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { SearchIcon } from 'assets/img';
import styles from './SearchInput.module.scss';

const SearchInput = forwardRef(({ id, className, inputClassName, fullWidth, strightLeft, searchBy, isNotDebounce, onChange, placeholder }, ref) => {
  const [inputValue, setValue] = useState('');
  const onSearchDebounced = useDebouncedCallback((value) => {
    onChange(value); setValue(value);
  }, 1000);

  const handleChangeValue = (e) => {
    const { value } = e.target;
    if (isNotDebounce) {
      setValue(value);
      onChange(value);
    } else {
      setValue(value);
      onSearchDebounced(value);
    }
  };

  return (
    <div className={cn(styles.search, { [className]: className })}>
      <input
        type="search"
        value={inputValue}
        id={id}
        ref={ref}
        className={cn(styles.searchInput, 'input-ad-search', {
          [styles.searchInputFullWidth]: fullWidth,
          [styles.searchInputStrightLeft]: strightLeft,
          [inputClassName]: inputClassName,
        })}
        placeholder={searchBy ? `Search by ${searchBy}` : placeholder}
        onChange={handleChangeValue}
      />
      <SearchIcon className={styles.searchIcon} />
    </div>
  );
});

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  strightLeft: PropTypes.bool,
  searchBy: PropTypes.string,
  isNotDebounce: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchInput;
