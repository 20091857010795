import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Loader } from 'components';
import { notify } from 'utils';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import UpdateSentryAlert from './components/UpdateSentryAlert/UpdateSentryAlert';
import HeaderPassMgmt from './components/HeaderPassMgmt/HeaderPassMgmt';
import LinkItem from './components/LinkItem/LinkItem';
import styles from './SecurityMgmtPass.module.scss';

const SecurityMgmtPass = ({ domainId }) => {
  const [passMgmtState, setPassMgmtState] = useState({
    isAnyUser: false,
    isEnabled: false,
  });
  const [shouldUpdateSentry, setShouldUpdateSentry] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [showToggleModal, setShowToggleModal] = useState(false);
  const handleShowToggleModal = () => setShowToggleModal(!showToggleModal);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/SecurityManagement/AdPasswordReset/GetPasswordResetState?domainId=${domainId}`);
      setPassMgmtState(res.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 403) {
        setShouldUpdateSentry(true);
      } else {
        notify.error(error.response?.data.message || error.message);
      }
      setIsLoading(false);
    }
  }, [domainId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleEnablePassMgmt = async () => {
    try {
      setIsLoadingButton(true);
      const res = await axios.post('/api/SecurityManagement/AdPasswordReset/ChangeStatus', domainId);
      setPassMgmtState(res.data);
      notify.success(`Forgot Password is ${passMgmtState.isEnabled ? 'enabled' : 'disabled'} for domain`);
      handleShowToggleModal();
    } catch (err) {
      notify.error(err.response?.data.message || err.message);
    } finally {
      setIsLoadingButton(false);
    }
  };

  if (isLoading) {
    return <Loader id="password-mgmt-loading" />;
  }

  return (
    <>
      <div className={styles.passMgmt}>
        {shouldUpdateSentry && <UpdateSentryAlert />}
        <HeaderPassMgmt
          isEnabled={passMgmtState.isEnabled}
          shouldUpdateSentry={shouldUpdateSentry}
          handleShowToggleModal={handleShowToggleModal}
        />
        {passMgmtState.isEnabled && <LinkItem passMgmtState={passMgmtState} />}
      </div>
      {showToggleModal && (
        <ToggleEnableModal
          isEnabled={passMgmtState.isEnabled}
          showModal={showToggleModal}
          handleShowModal={handleShowToggleModal}
          toggleEnablePassMgmt={toggleEnablePassMgmt}
          isLoading={isLoadingButton}
        />
      )}
    </>
  );
};

SecurityMgmtPass.propTypes = {
  domainId: PropTypes.string,
};

export default SecurityMgmtPass;
