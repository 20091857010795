/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import { TriangleFilledIcon } from 'assets/img';
import styles from './ValidateDomainModal.module.scss';

const ValidateDomainModal = ({ isOpen, data, onUpdateModal, onClose, isCustomerDomain }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnAction, setIsBtnAction] = useState(false);
  const [validationEmails, setValidationEmails] = useState('');
  const [validationData, setValidationData] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (data.type === 'emailValidation') {
      axios
        .get(`/api/Domains/GetWhoisDomainEmails?domain=${isCustomerDomain ? data.domainName : data.upn.split('@')[1]}`)
        .then((resp) => setValidationEmails(resp.data.join(', ')))
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    } else if (data.type === 'txtValidation') {
      if (isCustomerDomain) {
        axios
          .get(`/api/DomainValidation/GetValidationCode?domainId=${data.domainId}`)
          .then((resp) => setValidationData(resp.data))
          .catch(() => setIsError(true))
          .finally(() => setIsLoading(false));
      } else if (data.autoValidated) {
        axios
          .get(data.link)
          .then((resp) => setValidationData(resp.data))
          .catch(() => setIsError(true))
          .finally(() => setIsLoading(false));
      } else {
        axios
          .post('/api/Domains/CreateNotValidSpDomain?activationType=2')
          .then((resp) => setValidationData(resp.data))
          .catch(() => setIsError(true))
          .finally(() => setIsLoading(false));
      }
    }
  }, [data, isCustomerDomain]);

  const onValidateViaEmail = () => {
    setIsBtnAction(true);

    axios
      .post(data.link)
      .then(() => toast.success('Domain validation email has been sent', 3000))
      .then(() => onClose())
      .catch(() => setIsError(true));
  };

  const onValidateViaTxt = () => {
    setIsBtnAction(true);

    let link;
    if (isCustomerDomain) {
      link = `/api/DomainValidation/validateByTxtRecord?domainId=${data.domainId}&dnsName=${data.domainName}&validationCode=${validationData.code}`;
    } else if (data.autoValidated) {
      link = `/api/DomainValidation/validateByTxtRecord?domainId=${data.domainId}&dnsName=${data.domainName}&validationCode=${validationData.code}`;
    } else {
      link = `/api/DomainValidation/validateByTxtRecord?domainId=${validationData.id}&dnsName=${validationData.dnsName}&validationCode=${validationData.validationCode}`;
    }

    axios
      .post(link)
      .then((resp) => onUpdateModal(resp, data.domainId || validationData.id, data.domainName))
      .then(() => onClose())
      .catch(() => setIsError(true));
  };

  const modalTitle = () => {
    if (data.type === 'emailValidation') {
      if (isError) {
        return (
          <span className={styles.modalHeader}>
            <TriangleFilledIcon /> WHOIS Validation Issue
          </span>
        );
      }
      return 'Send a validation email?';
    }
    if (data.type === 'txtValidation') {
      if (isError) {
        return (
          <span className={styles.modalHeader}>
            <TriangleFilledIcon />
            Did not find the correct TXT record ({validationData?.code || validationData?.validationCode})
          </span>
        );
      }
      return 'Validate domain';
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={!isLoading ? modalTitle() : ''}
      actionTitle={!isLoading && !isError ? (data.type === 'emailValidation' ? 'Send' : 'Validate') : ''}
      actionFunc={!isLoading && !isError && data.type === 'emailValidation' ? onValidateViaEmail : onValidateViaTxt}
      actionLoading={isBtnAction}
      onRequestClose={onClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data.type === 'emailValidation' &&
            (isError ? (
              <>
                There appears to be a problem performing WHOIS validation on your domain. Sometimes this occurs for
                private WHOIS registrations. Please use the DNS TXT validation approach instead
              </>
            ) : (
              <>
                By clicking <b>SEND</b>, a validation email will be sent to owner <b>{validationEmails}</b>
              </>
            ))}
          {data.type === 'txtValidation' &&
            (isError ? (
              <>Please update your DNS, wait for it to propagate further, or use email WHOIS validation instead.</>
            ) : (
              <>
                <p>
                  1. Add a TXT record to <b>{data.domainName}</b>'s public DNS with a value of{' '}
                  <b>{validationData.code || validationData.validationCode}</b>
                </p>
                <br />
                <p>
                  2. Once the TXT record is added and propagated via DNS, then click <b>VALIDATE</b> to validate your
                  domain
                </p>
              </>
            ))}
        </>
      )}
    </Modal>
  );
};

ValidateDomainModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onUpdateModal: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isCustomerDomain: PropTypes.bool,
};

export default ValidateDomainModal;
