import { memo, useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { SwitchCheckbox, Checkbox, Button } from 'components';
import { PlusIcon, MinusIcon } from 'assets/img';
import styles from './SettingsRedirectDrives.module.scss';

const DRIVES = new Array(27)
  .fill('DynamicDrives')
  .map((item, index) => (index ? `${String.fromCharCode(65 + index - 1)}:` : item));

const SettingsRedirectDrives = memo(({ options, item, isZeroTrustEnabled, disabled, onChange }) => {
  const [isCustomRedOpen, setIsCustomRedOpen] = useState(false);
  const renderOptions = options === '*' ? [...DRIVES] : options.split(';');

  const toggleIsCustomRedOpen = () => setIsCustomRedOpen(!isCustomRedOpen);

  useEffect(() => {
    if (isZeroTrustEnabled && isCustomRedOpen) setIsCustomRedOpen(false);
  }, [isZeroTrustEnabled, isCustomRedOpen]);

  const onMainChange = (checked) => {
    if (checked) {
      onChange('*');
    } else {
      onChange('');
    }
  };

  const onItemChange = (drive, checked) => {
    let optionsNew = [...renderOptions];

    if (optionsNew.includes('DynamicDrives') && drive !== 'DynamicDrives') {
      optionsNew.splice(optionsNew.indexOf('DynamicDrives'), 1);
      if (checked) {
        optionsNew.push(drive);
      } else {
        optionsNew.splice(optionsNew.indexOf(drive), 1);
      }
      optionsNew.sort();
      optionsNew = ['DynamicDrives', ...optionsNew];
    } else {
      if (checked) {
        optionsNew.push(drive);
      } else {
        optionsNew.splice(optionsNew.indexOf(drive), 1);
      }
      optionsNew.sort();
    }

    onChange(optionsNew.join(';'));
  };

  return (
    <div className={styles.sharedDrives}>
      <div className={styles.sharedDrivesWrapper}>
        <div className={styles.sharedDrivesInfo}>
          <span className={styles.sharedDrivesInfoTitle}>
            {isCustomRedOpen ? (
              <Button id="close-shared-device" variant="link" icon={<MinusIcon />} onClick={toggleIsCustomRedOpen} className={styles.sharedDrivesInfoButton}>
                {item.title}
              </Button>
            ) : (
              <Button id="open-shared-device" variant="link" icon={<PlusIcon />} isDisabled={disabled} onClick={toggleIsCustomRedOpen} className={cn(styles.sharedDrivesInfoButton, { [styles.disabled]: disabled })}>
                {item.title}
              </Button>
            )}
          </span>
        </div>
        <div className={styles.sharedDrivesValue}>
          <SwitchCheckbox
            id="settings-redirect-drives"
            value={item.label}
            checked={options === '*' || renderOptions?.length === 27}
            disabled={disabled}
            onChange={(e) => onMainChange(e.target.checked)}
          />
        </div>
      </div>
      {isCustomRedOpen && (
        <div className={styles.sharedDrivesList}>
          <ul>
            {DRIVES.map((drive) => (
              <li key={drive} className={styles.sharedDrivesListItem}>
                <Checkbox
                  id={`setting-redirect-${drive.toLowerCase()}`}
                  checked={renderOptions.includes(drive)}
                  onChange={(e) => onItemChange(drive, e.target.checked)}
                  label={drive === 'DynamicDrives' ? 'Drives that I plug in later' : drive}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

SettingsRedirectDrives.propTypes = {
  options: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  isZeroTrustEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SettingsRedirectDrives.displayName = 'SettingsRedirectDrives';

export default SettingsRedirectDrives;
