import { FileDownloadIcon, FullScanIcon } from 'assets/img';
import { Button } from 'components';
import PropTypes from 'prop-types';
import styles from '../StatusCell.module.scss';

const FullScanCompleted = ({ item }) => {
  const { typeStr, totalLeakedCnt, id, scannedDomain, lastScanAt, csvBlobId } = item;

  const onFullCsvUpload = () => {
    const link = document.createElement('a');
    const fileFormat = 'csv';
    link.href = `/Download/DownloadBlob/${csvBlobId}`;
    link.id = 'tempdownloadlink';

    let filename = `TruScan ${typeStr} Results - ${scannedDomain} - ${new Date(lastScanAt).toLocaleString()}`;
    const re = new RegExp(`.${filename}+$`, 'i');
    if (!re.test(filename)) filename += `.${fileFormat}`;

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  return (
    <div className={styles.statusCell}>
      <p className={styles.statusWrapper}><FullScanIcon /><span className={styles.success}>{typeStr}</span></p>
      <p className={styles.statusCompleteRow}>Full scan completed</p>
      <p className={styles.statusTextRow}>
        <span>Found&nbsp;<span className={styles.accoutsCount}>{totalLeakedCnt.toLocaleString()}</span>&nbsp;compromised accounts
          {totalLeakedCnt ? '. Download Full Scan CSV for details' : null}
        </span>
      </p>
      <div
        className={styles.statusTooltipWrapper}
        data-tip="No compromised accounts to export"
        data-for={!totalLeakedCnt && 'info-tooltip'}
        data-iscapture="true"
      >
        <Button
          variant="link"
          id={id}
          onClick={() => totalLeakedCnt && onFullCsvUpload()}
          isDisabled={!totalLeakedCnt}
          className={styles.statusLink}
          icon={<FileDownloadIcon />}
        >
          Full Scan CSV
        </Button>
      </div>
    </div>
  );
};

FullScanCompleted.propTypes = {
  item: PropTypes.object,
};

export default FullScanCompleted;
