import { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import axios from 'config/customAxios';
import { queryFormatter } from 'utils';
import { getDomainStatus, isSentryDeleted } from 'helpers';
import { Loader, Table, Button, StatusMessage, DomainStatusMsg } from 'components';
import NoData from './components/NoData/NoData';
import NoCustDomainsMessage from './components/NoCustDomainsMessage/NoCustDomainsMessage';
import ManageAssignmentsModal from './components/ManageAssignmentsModal/ManageAssignmentsModal';
import styles from './ActiveDirectory.module.scss';

const ActiveDirectory = ({ domain, isDomainNotActivated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [isNoCustomerDomains, setIsNoCustomerDomains] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const sentryDeleted = isSentryDeleted(domain);
  const baseUrl = '/api/MSPCustomerAssignments/GetCustomerDomainsAssignments';

  const { isDomainActive } = getDomainStatus(domain);

  const fetchData = () => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'name asc',
      searchQuery: '',
    };

    axios
      .get(queryFormatter(baseUrl, queryConfig))
      .then((resp) => {
        if (resp.status === 204) {
          setUsers({ isNoData: true, value: 'no data' });
        } else if (resp.status === 206 && !resp.data.length) {
          setUsers({ isNoData: true, value: 'no users' });
        } else if (resp.status === 206 && resp.data.length) {
          const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;

          setUsers({ data: resp.data, pageMeta, queryConfig });
          setIsNoCustomerDomains(true);
        } else {
          const pageMeta = resp.headers['x-pagination'] ? JSON.parse(resp.headers['x-pagination']) : null;

          setUsers({ data: resp.data, pageMeta, queryConfig });
        }
      })
      .catch((err) => setUsers({ isError: true, message: err.message }))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDomainActive && !sentryDeleted) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [isDomainActive, sentryDeleted]);

  const onUpdateModal = (userId, assignmentsCountNew) => {
    const usersNew = JSON.parse(JSON.stringify(users));

    usersNew.data.forEach((i) => {
      if (i.userId === userId) i.assignmentsCount = assignmentsCountNew;
    });

    setUsers(usersNew);
  };

  const onModalOpen = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalData(null);
    setModalOpen(false);
  };

  if (isDomainNotActivated || sentryDeleted) {
    return (
      <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Active Directory Federation" />
    );
  }

  if (isLoading) return <Loader />;

  if (users.isError) {
    return <StatusMessage error>{users.message}</StatusMessage>;
  }

  if (users.isNoData) {
    return <NoData users={users} />;
  }

  const columns = [
    {
      name: 'Name',
      sortable: 'name',
      width: '30%',
      search: 'User Name',
      data: ({ name }) => name || 'N/A',
    },
    {
      name: 'Assigned Domains',
      width: '30%',
      data: 'assignmentsCount',
    },
    {
      name: '',
      width: '40%',
      data: (user) =>
        !isNoCustomerDomains && (
          <Button
            id="manage-assignments"
            type="button"
            variant="primary"
            size="32"
            onClick={() => onModalOpen(user)}
            className={styles.activeDirectoryButton}>
            Manage Assignments
          </Button>
        ),
    },
  ];

  return (
    <div className={styles.activeDirectory}>
      {isNoCustomerDomains && <NoCustDomainsMessage />}
      <h3 className={styles.activeDirectoryTitle}>Assign internal users to customer domains</h3>
      <div className={styles.activeDirectoryCounter}>
        <span>Employees </span>
        <b id="active-directory-counter">({users.pageMeta?.TotalCount || users.data.length || 0})</b>
      </div>

      <Table
        columns={columns}
        data={users}
        updateData={setUsers}
        id="active-directory-table"
        dataKey="userId"
        noData={!users.data.length && users.queryConfig.searchQuery}
        baseUrl={baseUrl}
        className={styles.activeDirectoryTable}
      />

      {modalData && (
        <ManageAssignmentsModal isOpen={modalOpen} data={modalData} onUpdate={onUpdateModal} onClose={onModalClose} />
      )}
    </div>
  );
};

ActiveDirectory.propTypes = {
  domain: PropTypes.object.isRequired,
  isDomainNotActivated: bool,
};

export default ActiveDirectory;
