/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { queryFormatter, onChangePage } from 'utils';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import {
  corpAdmin,
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  freePersonalUser,
  personalUser,
  spAdmin,
  spTechAdmin,
  spTechUser,
  techAdmin,
} from 'consts';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import OnboardingMessage from '../../OnboardingMessage/OnboardingMessage';
import AlertNoSentryInstalled from '../../AlertNoSentryInstalled/AlertNoSentryInstalled';
import WorkspaceHeader from '../../WorkspaceHeader/WorkspaceHeader';
import DomainsList from '../../DomainsList/DomainsList';
import WorkspaceTable from '../../WorkspaceTable/WorkspaceTable';
import AlertSentryUpdate from '../../AlertSentryUpdate/AlertSentryUpdate';

const WorkspaceMyDomain = ({
  myDomain,
  userData,
  userRole,
  myDomainPage,
  baseUrl,
  onUpdate,
  onUpdateUser,
  loadingCurrent,
  setLoadingCurrent,
  hasSecureConnects,
  sentries,
}) => {
  const { isDomainAzure, isDomainHybryd, isDomainOnPremise } = getDomainADStatus(userData);
  const sentryDeleted = isSentryDeleted(userData, sentries);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowUpdateModal = () => setShowUpdateModal(!showUpdateModal);

  const paginationData = {
    updateAction: onUpdate,
    errorAction: toast.error,
  };

  useEffect(() => {
    if (myDomain?.queryConfig.searchQuery) {
      const query = { ...myDomain.queryConfig, searchQuery: '' };
      const url = queryFormatter(baseUrl, query);
      onChangePage([url, query], paginationData);
    }
  }, []);

  const isEmptyScreen =
    !myDomain.data.length && !myDomain.queryConfig.searchQuery && ![spTechAdmin, corpTechAdmin].includes(userRole);

  if (userData.showOnboarding && !hasSecureConnects) {
    return <OnboardingMessage userData={userData} />;
  }

  const isShowAll =
    [custUser, custAdmin, techAdmin, spTechUser, corpTechUser, personalUser, freePersonalUser].includes(userRole) ||
    (userData.roles.includes('mspadmin') && isDomainAzure) ||
    ((isDomainHybryd || isDomainOnPremise) && sentryDeleted) ||
    (userData.showOnboarding && hasSecureConnects);

  let isEmptyPage = !myDomain.data?.length && !myDomain.queryConfig?.searchQuery;

  if ([spTechAdmin, spTechUser, corpTechAdmin].includes(userRole)) {
    isEmptyPage =
      (myDomain.queryConfig.assigned.toLowerCase() === 'all' && !myDomain.pageMeta?.AllMachinesCount) ||
      (myDomain.queryConfig.assigned.toLowerCase() === 'my' && !myDomain.pageMeta?.AssignedMachinesCount);
  }

  if (userRole === techAdmin) {
    isEmptyPage = !myDomain.pageMeta?.AssignedMachinesCount;
  }

  const { showSentryUpdateMessageForAdAdmins } = userData;

  const hasOutdatedSentry = sentries?.some((sentry) => sentry.isOutdated);
  const outdatedSentries = sentries?.filter((sentry) => sentry.isOutdated);

  const notShownForAdAdmin =
    [spTechAdmin, corpTechAdmin, custAdmin, techAdmin].includes(userRole) && !showSentryUpdateMessageForAdAdmins;

  const showSentryUpdateAlert =
    [spAdmin, corpAdmin, spTechAdmin, corpTechAdmin, custAdmin].includes(userRole) &&
    hasOutdatedSentry &&
    !notShownForAdAdmin;

  return (
    <>
      {[spAdmin, corpAdmin].includes(userRole) && !isDomainAzure && !!myDomain.data.length && sentryDeleted && (
        <AlertNoSentryInstalled sentries={sentries} userData={userData} />
      )}
      {showSentryUpdateAlert && <AlertSentryUpdate sentries={sentries} toggleShowUpdateModal={toggleShowUpdateModal} />}
      <WorkspaceHeader
        isShowAll={isShowAll}
        isError={!!myDomain.isError}
        baseUrl={baseUrl}
        myDomainPage={myDomainPage}
        queryConfig={myDomain.queryConfig}
        totalCount={myDomain.pageMeta?.TotalCount || 0}
        searchBy="Computer Name"
        allValue={`${myDomain.queryConfig.assigned || myDomain.queryConfig.kind} Computers`}
        onUpdate={onUpdate}
        isEmptyScreen={isEmptyScreen}
        isEmptyPage={isEmptyPage}
        hasSecureConnects={hasSecureConnects}
      />

      <DomainsList myDomain={myDomain} userRole={userRole} userData={userData}>
        <WorkspaceTable
          tableData={myDomain}
          isComputers
          baseUrl={baseUrl}
          myDomainPage={myDomainPage}
          tableItems={myDomain.data}
          isAzureAd={isDomainAzure}
          queryConfig={myDomain.queryConfig}
          onUpdate={onUpdate}
          onUpdateUser={onUpdateUser}
          loadingCurrent={loadingCurrent}
          setLoadingCurrent={setLoadingCurrent}
        />
      </DomainsList>
      {showUpdateModal && (
        <UpdateSentryModal
          isOpen={showUpdateModal}
          onRequestClose={toggleShowUpdateModal}
          sentries={outdatedSentries}
        />
      )}
    </>
  );
};

WorkspaceMyDomain.propTypes = {
  myDomain: PropTypes.object,
  myDomainPage: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  loadingCurrent: PropTypes.string,
  setLoadingCurrent: PropTypes.func,
  hasSecureConnects: PropTypes.bool,
  sentries: PropTypes.array,
};

export default WorkspaceMyDomain;
