/* eslint-disable quote-props */
/* eslint-disable no-tabs */
const SPECIAL_CHARS_MAP = {
  ' ': '%20',
  '!': '%21',
  '"': '%22',
  '#': '%23',
  '$': '%24',
  '%': '%25',
  '&': '%26',
  '(': '%28',
  ')': '%29',
  '*': '%2A',
  '+': '%2B',
  ',': '%2C',
  '-': '%2D',
  '.': '%2E',
  ':': '%3A',
  ';': '%3B',
  '<': '%3C',
  '=': '%3D',
  '>': '%3E',
  '?': '%3F',
  '@': '%40',
  '[': '%5B',
  ']': '%5D',
  '^': '%5E',
};

export const replaceSpecChar = (value) => value.replace(/[ !"#$%&()*+,\-.:;<=>?@[\]^]/g, (i) => SPECIAL_CHARS_MAP[i]);

export const convertToCamelCase = (inputString) => {
  const words = inputString.split(' ');
  const camelCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  return camelCaseString;
};
