import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InfoActiveIcon, InfoIcon } from 'assets/img';
import { useOutsideClick } from 'hooks';
import styles from './InfoPopover.module.scss';

const InfoPopover = ({ title, children, className, closeOutside = true, position = 'right' }) => {
  const [isInfoActive, setIsInfoActive] = useState(false);

  const toggleSetActive = () => {
    setIsInfoActive(!isInfoActive);
  };

  const infoRef = useRef();
  useOutsideClick(infoRef, closeOutside ? () => toggleSetActive() : () => {});

  return (
    <div className={cn([styles.infoWrapper], { [className]: className })}>
      {isInfoActive ? (
        <>
          <InfoActiveIcon onClick={toggleSetActive} />
          <div
            className={cn([styles.infoContent], {
              [styles.left]: position === 'left',
              [styles.right]: position === 'right',
              [styles.down]: position === 'down',
            })}
            ref={infoRef}
          >
            {title && (
              <h5 className={styles.infoTitle}>{title}</h5>
            )}
            {children}
          </div>
        </>
      ) : (
        <InfoIcon onClick={toggleSetActive} />
      )}
    </div>
  );
};

InfoPopover.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
  closeOutside: PropTypes.bool,
  children: PropTypes.any,
};

export default InfoPopover;
