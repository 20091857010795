import { Button, Loader, RouteLeavingGuard } from 'components';
import axios from 'config/customAxios';
import cn from 'classnames';
import BrandingEdit from 'pages/Branding/components/BrandingEdit/BrandingEdit';
import BrandingFooter from 'pages/Branding/components/BrandingFooter/BrandingFooter';
import BrandingHeader from 'pages/Branding/components/BrandingHeader/BrandingHeader';
import BrandingNotAllowed from 'pages/Branding/components/BrandingNotAllowed/BrandingNotAllowed';
import BrandingPreview from 'pages/Branding/components/BrandingPreview/BrandingPreview';
import ModalBrandingClear from 'pages/Branding/components/ModalBrandingClear/ModalBrandingClear';
import ModalBrandingInfo from 'pages/Branding/components/ModalBrandingInfo/ModalBrandingInfo';
import { invertColor } from 'pages/Branding/helpers/colorsHelper';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { notify, objectsAreNotEqual } from 'utils';
import { ArrowLeftIcon } from 'assets/img';
import styles from '../../../../Branding.module.scss';

const CustomerManagement = () => {
  const history = useHistory();
  const { state } = useLocation();
  const domain = state?.domain;

  const goBack = () =>
    history.push({
      pathname: '/profile/branding',
      search: '?tab=customer-domains',
    });

  const [isLoading, setIsLoading] = useState(true);
  const [brandingInfo, setBrandingInfo] = useState({ init: null, modified: null });
  const [brandingStatus, setBrandingStatus] = useState(brandingInfo?.init?.isActive);
  const [brandingError, setBrandingError] = useState({ error: false, type: null });
  const [hexError, setHexError] = useState(false);
  const [showModalBrandingClear, setShowModalBrandingClear] = useState(false);
  const [showModalBrandingInfo, setShowModalBrandingInfo] = useState(false);
  const invertedColor = invertColor(brandingInfo?.modified?.backGroundColor);
  const invertedButtonColor = invertColor(brandingInfo?.modified?.buttonColor);

  const isBrandingInfoChanged = objectsAreNotEqual({ initial: brandingInfo.init, modified: brandingInfo.modified });
  const isDisableClearBranding =
    !brandingInfo?.init?.backGroundColor &&
    !brandingInfo?.init?.buttonColor &&
    !brandingInfo?.init?.logo &&
    !brandingInfo?.init?.isSetContactInfo &&
    brandingInfo?.init?.applyToDesktopConnectors;

  const toggleShowModalBrandingClear = () => setShowModalBrandingClear(!showModalBrandingClear);
  const toggleShowModalBrandingInfo = () => setShowModalBrandingInfo(!showModalBrandingInfo);

  useEffect(() => {
    setBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, useInvertedColorScheme: invertedColor || false },
      init: brandingInfo.init,
    }));
  }, [brandingInfo.init, invertedColor]);

  useEffect(() => {
    const getBrandingInfo = () => {
      axios
        .get(`/api/Company/GetBranding?domainId=${domain?.domainId}`)
        .then(({ data }) => {
          setBrandingInfo({ init: data, modified: data });
          setBrandingStatus(data.isActive);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setBrandingError({ error: true, type: 'licensesSuspended' });
          } else if (err.response.status === 406) {
            setBrandingError({ error: true, type: 'licensesNotAvaliable' });
          } else {
            toast.error(err.response.data.message || err.message, 3000);
          }
        })
        .finally(() => setIsLoading(false));
    };
    if (domain?.domainId) {
      getBrandingInfo();
    } else {
      setIsLoading(false);
    }
    return () => setIsLoading(false);
  }, [domain?.domainId]);

  const toggleBrandingStatus = () => {
    setBrandingStatus(!brandingStatus);
    axios
      .post('/api/Company/ToggleBrandingStatus', domain?.domainId)
      .then(() => {
        setBrandingInfo((prevState) => ({
          modified: { ...prevState.modified, isActive: !prevState.modified.isActive },
          init: { ...prevState.init, isActive: !prevState.init.isActive },
        }));
        notify.success(`Branding Status is ${brandingStatus ? 'disabled' : 'enabled'} for ${domain?.dnsName}`);
      })
      .catch((err) => {
        setBrandingStatus(!brandingStatus);
        notify.error(err.response.data || err.message);
      });
  };

  if (!domain) {
    return <Redirect to="/profile/branding" />;
  }

  if (isLoading) {
    return (
      <div className={styles.branding}>
        <Loader id="branding-loader" />
      </div>
    );
  }

  if (brandingError.error) {
    return <BrandingNotAllowed errorType={brandingError.type} />;
  }

  return (
    <div className={styles.branding}>
      <h3 className={cn(styles.brandingTitle, { [styles.customer]: true })}>
        <Button variant="link" icon={<ArrowLeftIcon />} onClick={goBack} />
        Branding <span>({domain.dnsName})</span>
      </h3>
      <BrandingHeader
        brandingStatus={brandingStatus}
        toggleBrandingStatus={toggleBrandingStatus}
        brandingInfo={brandingInfo}
        updateBrandingInfo={(data) => setBrandingInfo(data)}
        isCustomer
      />
      <main className={cn(styles.brandingMain, { [styles.disabled]: !brandingInfo.modified.isActive })}>
        <BrandingEdit
          updateBrandingInfo={(data) => setBrandingInfo(data)}
          brandingInfo={brandingInfo}
          toggleShowModalBrandingInfo={toggleShowModalBrandingInfo}
          hexError={hexError}
          setHexError={setHexError}
        />
        <BrandingPreview
          brandingInfo={brandingInfo}
          invertedColor={invertedColor}
          invertedButtonColor={invertedButtonColor}
        />
      </main>
      <div className={cn({ [styles.brandingFooterDisabled]: !brandingInfo.modified.isActive })}>
        <BrandingFooter
          brandingStatus={brandingStatus}
          isDisableClearBranding={isDisableClearBranding}
          toggleShowModalBrandingClear={toggleShowModalBrandingClear}
          brandingInfo={brandingInfo}
          updateBrandingInfo={(data) => setBrandingInfo(data)}
          isBrandingInfoChanged={isBrandingInfoChanged}
          hexError={hexError}
          domainName={domain.dnsName}
        />
      </div>
      <RouteLeavingGuard
        when={isBrandingInfoChanged}
        navigate={({ pathname }) => {
          if (pathname === '/profile/branding') {
            return history.push(`${pathname}?tab=customer-domains`);
          }
          return history.push(pathname);
        }}
        shouldBlockNavigation={() => isBrandingInfoChanged}
        modalConfig={{
          label: 'Leave Modal',
          title: 'Discard changes',
          message: 'Do you really want to leave and discard changes?',
          btnClose: 'Stay on Page',
          btnAccept: 'Leave',
        }}
      />
      {showModalBrandingInfo && (
        <ModalBrandingInfo
          isOpen={showModalBrandingInfo}
          onRequestClose={toggleShowModalBrandingInfo}
          updateBrandingInfo={(data) => setBrandingInfo(data)}
          domainId={domain.domainId}
        />
      )}
      {showModalBrandingClear && (
        <ModalBrandingClear
          isOpen={showModalBrandingClear}
          onRequestClose={toggleShowModalBrandingClear}
          updateBrandingInfo={(data) => setBrandingInfo(data)}
          domainId={domain.domainId}
          domainName={domain.dnsName}
        />
      )}
    </div>
  );
};

export default CustomerManagement;
