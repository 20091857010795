/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, SearchInput } from 'components';
import { onSearch } from 'utils';
import { useObserver } from 'hooks';
import styles from './RolesHeader.module.scss';

const RolesHeader = ({
  assignments,
  setAssignments,
  baseUrl,
  selectedUsers,
  totalRef,
  unselectAllUsers,
  isUpdating,
  onUpdateUserRoles,
}) => {
  const paginationData = {
    queryConfig: assignments?.queryConfig,
    updateAction: setAssignments,
    baseUrl,
  };

  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedUsers.length, { threshold: 1 });

  const renderBtnText = () => {
    const statuses = selectedUsers.reduce(
      (prev, cur) => {
        cur.isAssigned ? prev.true++ : prev.false++;
        return prev;
      },
      { true: 0, false: 0 },
    );

    if (statuses.true && !statuses.false) {
      return 'Remove Admin';
    }
    if (!statuses.true && statuses.false) {
      return 'Make Admin';
    }
    return 'Switch Roles';
  };
  return (
    <div
      className={cn(styles.rolesHeader, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      {selectedUsers.length > 0 && (
        <div className={styles.rolesHeaderActions}>
          <div className={styles.rolesHeaderActionsCount}>
            {selectedUsers.length}/{totalRef.current} Selected
          </div>
          <Button
            type="button"
            id="unselect-all"
            size="40"
            variant="secondary"
            className={styles.rolesHeaderActionsBtnSecondary}
            onClick={unselectAllUsers}>
            Unselect All
          </Button>
          <Button
            type="button"
            id="action-button"
            size="40"
            variant="primary"
            className={styles.rolesHeaderActionsBtnPrimary}
            isDisabled={isUpdating}
            onClick={onUpdateUserRoles}>
            {renderBtnText()}
          </Button>
        </div>
      )}
      <div className={cn(styles.rolesHeaderWrap, { [styles.hidden]: selectedUsers.length > 0 })}>
        <span className={styles.rolesHeaderAllUsers}>All Users </span>
        <b>({totalRef.current})</b>
        <SearchInput
          id="rm-search"
          searchBy="User Name"
          onChange={(value) => onSearch(value, paginationData)}
          className={styles.rolesHeaderSearch}
        />
      </div>
    </div>
  );
};

RolesHeader.propTypes = {
  assignments: PropTypes.object,
  setAssignments: PropTypes.func,
  baseUrl: PropTypes.string,
  selectedUsers: PropTypes.array,
  totalRef: PropTypes.any,
  unselectAllUsers: PropTypes.func,
  isUpdating: PropTypes.bool,
  onUpdateUserRoles: PropTypes.func,
};

export default RolesHeader;
