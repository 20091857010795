import PropTypes from 'prop-types';
import { CopyIcon, DownloadIcon } from 'assets/img';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import { copyToClipboard } from 'utils';
import styles from './NoSentryInstalled.module.scss';

const NoSentryInstalled = ({ pageName, activationCode, downloadSentryLink }) => {
  const copyActivationCode = () => copyToClipboard(activationCode, 'The code copied to clipboard');

  return (
    <div className={styles.domainMessage}>
      <h4 className={styles.domainMessageTitle}>No Sentry agent currently installed</h4>
      <p className={styles.domainMessageInfo}>Please download and install TruGrid Sentry with activation code {pageName && (`to proceed with ${pageName}`)}</p>
      <div className={styles.domainMessageActions}>
        <Link className={styles.domainMessageActionsLink} external href={downloadSentryLink}>
          <DownloadIcon /> Download Sentry
        </Link>
        <Button variant="secondary" size="40" icon={<CopyIcon />} onClick={copyActivationCode}>
          Activation Code
        </Button>
      </div>
    </div>
  );
};

NoSentryInstalled.propTypes = {
  pageName: PropTypes.string,
  activationCode: PropTypes.string,
  downloadSentryLink: PropTypes.string,
};

export default NoSentryInstalled;
