import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DropdownObject, Modal } from 'components';
import axios from 'config/customAxios';
import { notify } from 'utils';
import styles from './ChangeTimeoutPeriod.module.scss';

const TIMEOUT_PERIOD_OPTIONS = {
  0: [5, '5 min'],
  1: [10, '10 min'],
  2: [15, '15 min (default)'],
  3: [30, '30 min'],
  4: [0, 'Set custom'],
};

const ChangeTimeoutPeriod = ({ isOpen, onClose, domainId, timeoutDuration, handleChangeDurationPeriod }) => {
  const [isPeriodChanging, setIsPeriodChanging] = useState(false);
  const [isCustomPeriodActive, setIsCustomPeriodActive] = useState(false);
  const [timeoutPeriod, setTimeoutPeriod] = useState(TIMEOUT_PERIOD_OPTIONS);
  const [selectedPeriod, setSelectedPeriod] = useState(timeoutDuration);
  const [customPeriod, setCustomPeriod] = useState('');
  const [customPeriodError, setCustomPeriodError] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if (![5, 10, 15, 30].includes(timeoutDuration)) {
      const newTimeoutPeriod = {
        0: [5, '5 min'],
        1: [10, '10 min'],
        2: [15, '15 min (default)'],
        3: [30, '30 min'],
        4: [timeoutDuration, `${timeoutDuration} min (custom)`],
        5: [0, 'Change custom'],
      };
      setTimeoutPeriod(newTimeoutPeriod);
    }
  }, [timeoutDuration]);

  const handleChangeDropdownValue = (value) => {
    setSelectedPeriod(value);
    if (value) {
      setCustomPeriod('');
      setIsCustomPeriodActive(false);
    }
  };

  const onCustomPeriodOpen = () => {
    setSelectedPeriod(0);
    setIsCustomPeriodActive(true);
    setTimeout(() => inputRef.current.focus(), 150);
  };

  const handleInputValue = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCustomPeriod(inputValue.slice(0, e.target.maxLength));
    }
  };

  const onChangePeriod = async () => {
    if (isCustomPeriodActive && (!Number(customPeriod) || (Number(customPeriod) < 1 || Number(customPeriod) > 1440))) {
      setCustomPeriodError('You can set between 1 to 1440 min');
    } else {
      setIsPeriodChanging(true);
      try {
        await axios.post(`/api/SecurityManagement/DesktopTimeout/ChangeTimeoutDuration?domainId=${domainId}&timeoutDuration=${customPeriod || selectedPeriod}`);
        handleChangeDurationPeriod(Number(customPeriod || selectedPeriod));
        notify.success('Timeout period is changed');
        setIsPeriodChanging(false);
        onClose();
      } catch (error) {
        notify.error(error.response.data || error.message);
        setIsPeriodChanging(false);
      }
    }
  };

  const onNewDomainKeyDown = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setCustomPeriodError('');
    }
    if (event.key === 'Enter') {
      onChangePeriod();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Change timeout period"
      actionTitle="Change"
      actionFunc={onChangePeriod}
      actionLoading={isPeriodChanging}
      actionDisabled={selectedPeriod === timeoutDuration}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.timeoutPeriodInfo}>Select the desired timeout period</div>
      <div className={styles.timeoutPeriodWrap}>
        <DropdownObject
          id="enable-timeout-select"
          options={timeoutPeriod}
          defaultPos={selectedPeriod}
          isMulti
          onChange={handleChangeDropdownValue}
          customField={0}
          onCustomEvent={onCustomPeriodOpen}
          className={styles.timeoutPeriodDropdown}
          optionsClassName={styles.timeoutPeriodDropdownOptions}
        />
        {isCustomPeriodActive && (
          <div className={styles.timeoutPeriodInputWrap}>
            <input
              id="enter-custom-period"
              type="text"
              name="custom-period"
              ref={inputRef}
              placeholder="Add 1 to 1440 mins"
              value={customPeriod}
              maxLength={4}
              onChange={handleInputValue}
              onFocus={() => setCustomPeriodError('')}
              onKeyDown={onNewDomainKeyDown}
              className={cn(styles.timeoutPeriodInput, {
                [styles.hasValue]: customPeriod?.length > 0,
                [styles.error]: !!customPeriodError,
              })}
            />
            {customPeriodError && <div className={styles.timeoutPeriodInputError}>{customPeriodError}</div>}
          </div>
        )}
      </div>
    </Modal>
  );
};

ChangeTimeoutPeriod.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  domainId: PropTypes.string,
  timeoutDuration: PropTypes.number,
  handleChangeDurationPeriod: PropTypes.func,
};

export default ChangeTimeoutPeriod;
