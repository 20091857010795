import PropTypes from 'prop-types';
import { Modal } from 'components';

const SkipForeverModal = ({ isOpen, onClose, onSkipForever, isLoading, email }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Skip Phone Setup?"
    actionTitle="Skip"
    actionFunc={onSkipForever}
    actionLoading={isLoading}>
    {email
      ? 'You will be able to reset your password only with email. Are you sure you want to skip this step?'
      : "You won't be able to reset your password without assistance. Are you sure you want to skip this step?"}
  </Modal>
);

SkipForeverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSkipForever: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default SkipForeverModal;
