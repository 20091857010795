import styles from './RememberMeHeader.module.scss';

const RememberMeHeader = () => (
  <div className={styles.rememberHeader}>
    <h4 className={styles.rememberHeaderTitle}>Remember Me is not currently enabled</h4>
    <p className={styles.rememberHeaderDesc}>Allow users to remember login and password. When turned on, the REMEMBER ME feature is only enabled for users with MFA by default.</p>
  </div>
);

export default RememberMeHeader;
