import PropTypes from 'prop-types';
import { Modal } from 'components';
import { useState } from 'react';
import { AttentionIcon } from 'assets/img';
import styles from './ToggleMfaRequiredModal.module.scss';

const ToggleMfaRequiredModal = ({ isOpen, onRequestClose, isShortcutMfaRequired, onChangeMfaRequiredForDomain }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeMfaRequiredForDomain = async () => {
    setIsLoading(true);
    await onChangeMfaRequiredForDomain();
    setIsLoading(false);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.title}>
          {isShortcutMfaRequired ? (
            <>Disable "Require MFA for Shortcut"?</>
          ) : (
            <>
              <AttentionIcon />
              Enable "Require MFA for Shortcut"?
            </>
          )}
        </div>
      }
      actionFunc={handleChangeMfaRequiredForDomain}
      actionTitle={isShortcutMfaRequired ? 'Disable' : 'Enable'}
      variantBtn={!isShortcutMfaRequired ? 'delete' : 'primary'}
      actionLoading={isLoading}>
      {isShortcutMfaRequired ? (
        <p>
          Disabling this feature will no longer require MFA code when using Shortcuts. Are you sure you wish to
          continue?
        </p>
      ) : (
        <p>Enabling this feature will turn on MFA for all user logins that have Shortcuts enabled</p>
      )}
    </Modal>
  );
};

ToggleMfaRequiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isShortcutMfaRequired: PropTypes.bool.isRequired,
  onChangeMfaRequiredForDomain: PropTypes.func.isRequired,
};

export default ToggleMfaRequiredModal;
