import PropTypes from 'prop-types';
import { AttentionIcon } from 'assets/img';
import Checkbox from 'components/Checkbox/Checkbox';
import styles from '../../UpdateSentryModal.module.scss';

const ModalHeader = ({ customerDomain, allChecked, disableMainCheckbox, allCount, onToggleMainCheckbox }) => (
  <div className={styles.modalHeader}>
    <div className={styles.modalInfo}>
      <AttentionIcon />
      <span>
        During Sentry update, all current RDP sessions will be dropped
        {customerDomain && (
          <>
            <br />
            Expand list to see the exceptions.
          </>
        )}
      </span>
    </div>
    <div className={styles.modalSelectAll}>
      <div className={styles.modalSelectAllWrap}>
        <Checkbox
          checked={allChecked}
          isDisabled={disableMainCheckbox}
          onChange={onToggleMainCheckbox}
          className={styles.tableCheckbox}
        />
        {customerDomain ? (
          <span className={styles.modalListCount}>Select All Domains</span>
        ) : (
          <span>
            Select All (<b>{allCount}</b>)
          </span>
        )}
      </div>
      {customerDomain && <div className={styles.modalListCount}>Outdated Sentry</div>}
    </div>
  </div>
);

ModalHeader.propTypes = {
  customerDomain: PropTypes.bool,
  allChecked: PropTypes.string,
  disableMainCheckbox: PropTypes.bool,
  allCount: PropTypes.number,
  onToggleMainCheckbox: PropTypes.func,
};

export default ModalHeader;
