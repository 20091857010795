import PropTypes from 'prop-types';
import cn from 'classnames';
import { SwitchCheckbox } from 'components';
import styles from './AzureAdLoginStatus.module.scss';

const AzureAdLoginStatus = ({ isAzureAdLoginEnabled, toggleShowEnableModal }) => (
  <div className={styles.azureAdLoginStatus}>
    <div className={styles.azureAdLoginStatusSwitchCheckbox}>
      <p className={styles.azureAdLoginStatusSwitchCheckboxTitle}>Stay Signed-In Status</p>
      <SwitchCheckbox
        id="toggle-enable"
        checked={isAzureAdLoginEnabled}
        onChange={toggleShowEnableModal}
        className={styles.azureAdLoginStatusSwitchCheckboxSlide}
      />
      <span className={cn(styles.azureAdLoginStatusSwitchCheckboxText, { [styles.active]: isAzureAdLoginEnabled })}>
        {isAzureAdLoginEnabled ? 'ON' : 'OFF'}
      </span>
    </div>
  </div>
);

AzureAdLoginStatus.propTypes = {
  isAzureAdLoginEnabled: PropTypes.bool.isRequired,
  toggleShowEnableModal: PropTypes.func.isRequired,
};

export default AzureAdLoginStatus;
