import PropTypes from 'prop-types';
import { Modal } from 'components';
import { getDomainStatus } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, techAdmin } from 'consts';
import { useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { notify } from 'utils';
import ReportColumns from '../ReportColumns/ReportColumns';
import ReportDomains from '../ReportDomains/ReportDomains';

const GenerateReportModal = ({
  isOpen,
  onRequestClose,
  type,
  isDomainAzure,
  licenses,
  userRole,
  isCompanyPage,
  isOnlyOneDomainHasApps,
  isLeastOneDomainHasApps,
  checkBoxesChecked,
  toggleCheckBoxesChecked,
  checkedDomains,
  setCheckedDomains,
  primaryDomainData,
  getAssigmentsReports,
  setReportLoading,
  setCheckBoxesChecked,
  generateUserAssignmentsReport,
  generateMachineAssignmentsReport,
  generateApplicationAssignmentsReport,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isHideDomainList = [techAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole) || !isCompanyPage;
  const disableAppColumn = useMemo(() => checkedDomains.every((el) => !el.isAppPublishingEnabled), [checkedDomains]);

  useEffect(() => {
    if (type === 'appAssignments') {
      setCheckedDomains((prevValue) => prevValue.filter((el) => el.isAppPublishingEnabled));
    }
  }, [licenses, setCheckedDomains, type]);

  useEffect(() => {
    if (checkedDomains.length > 0 && disableAppColumn) {
      setCheckBoxesChecked((prevValue) => ({ ...prevValue, application: false, computersComputerGroups: true }));
    }
    if (checkedDomains.length < 1 && disableAppColumn) {
      setCheckBoxesChecked((prevValue) => ({ ...prevValue, application: false }));
    }

    if (type === 'appAssignments') {
      setCheckBoxesChecked((prevValue) => ({ ...prevValue, application: true }));
    }
  }, [checkedDomains, disableAppColumn, setCheckBoxesChecked, type]);

  const domainsForReport = licenses.filter((el) => {
    const { isDomainActive } = getDomainStatus(el);
    return isDomainActive;
  });

  const saveFile = (link) => {
    const fileName = link.split('/').slice(-1).toString();
    saveAs(`${link}`, `${fileName}`);
  };

  const confirmResult = async () => {
    try {
      setIsLoading(true);
      if (type === 'userAssignments') {
        const { data } = await generateUserAssignmentsReport();
        saveFile(data.downloadLink);
      }
      if (type === 'machineAssignmnets') {
        const { data } = await generateMachineAssignmentsReport();
        saveFile(data.downloadLink);
      }
      if (type === 'appAssignments') {
        const { data } = await generateApplicationAssignmentsReport();
        saveFile(data.downloadLink);
      }
      getAssigmentsReports();
      notify.success('Your report is generated');
      onRequestClose();
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        setReportLoading((prevValue) => ({ ...prevValue, [type]: true }));
        onRequestClose();
        notify.success(
          <div>
            <div>Your request is in progress</div>
            <div>Please refresh this page in a few minutes</div>
          </div>,
        );
      } else {
        notify.error(err?.response?.data?.message || err?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const disabled =
    !checkedDomains.length ||
    (type === 'userAssignments' &&
      !checkBoxesChecked.computersComputerGroups &&
      !checkBoxesChecked.application &&
      !isDomainAzure);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Generate Report"
      actionTitle="Export"
      actionDisabled={disabled}
      actionLoading={isLoading}
      enableOverflow
      actionFunc={confirmResult}>
      <ReportColumns
        checkBoxesChecked={checkBoxesChecked}
        toggleCheckBoxesChecked={toggleCheckBoxesChecked}
        type={type}
        isDomainAzure={isDomainAzure}
        isOnlyOneDomainHasApps={isOnlyOneDomainHasApps}
        isLeastOneDomainHasApps={isLeastOneDomainHasApps}
        checkedDomains={checkedDomains}
      />
      <ReportDomains
        licenses={domainsForReport}
        type={type}
        checkedDomains={checkedDomains}
        primaryDomainData={primaryDomainData}
        setNewCheckedDomains={(item) => setCheckedDomains(item)}
        isHideDomainList={isHideDomainList}
      />
    </Modal>
  );
};

GenerateReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  type: PropTypes.string,
  isDomainAzure: PropTypes.bool,
  licenses: PropTypes.array,
  userRole: PropTypes.string,
  isCompanyPage: PropTypes.bool,
  isOnlyOneDomainHasApps: PropTypes.bool,
  isLeastOneDomainHasApps: PropTypes.bool,
  checkBoxesChecked: PropTypes.object,
  toggleCheckBoxesChecked: PropTypes.func,
  checkedDomains: PropTypes.array,
  setCheckedDomains: PropTypes.func,
  setCheckBoxesChecked: PropTypes.func,
  primaryDomainData: PropTypes.object,
  getAssigmentsReports: PropTypes.func,
  setReportLoading: PropTypes.func,
  generateUserAssignmentsReport: PropTypes.func,
  generateMachineAssignmentsReport: PropTypes.func,
  generateApplicationAssignmentsReport: PropTypes.func,
};

export default GenerateReportModal;
