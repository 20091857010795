import { ActiveDirectorySetup } from 'components';
import PropTypes from 'prop-types';

const DomainMgmt = ({ domain, userRole, isCompanyPage }) => (
  <ActiveDirectorySetup
    domain={domain}
    userRole={userRole}
    isCompanyPage={isCompanyPage}
  />
);

DomainMgmt.propTypes = {
  domain: PropTypes.object,
  userRole: PropTypes.string,
  isCompanyPage: PropTypes.bool,
};

export default DomainMgmt;
