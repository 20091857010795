import PropTypes from 'prop-types';
import MfaInfo from '../MfaInfo/MfaInfo';
import styles from './MfaRoleCell.module.scss';

const MfaRoleCell = ({ user }) => {
  const { isAdmin, promotedFromAdFederation, promotedFromRolesMngmt } = user;

  if (isAdmin) {
    return (
      <div className={styles.admin}>
        <span className={styles.adminText}>Admin</span>
        <MfaInfo isAllView name="Enabled" promotedFromAdFederation={promotedFromAdFederation} promotedFromRolesMngmt={promotedFromRolesMngmt} />
      </div>
    );
  }
  return (
    <span className={styles.user}>User</span>
  );
};

MfaRoleCell.propTypes = {
  user: PropTypes.object,
};

export default MfaRoleCell;
