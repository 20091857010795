import PropTypes from 'prop-types';
import { Modal } from 'components';
import { truncateString } from 'utils';

const UpdateUserModal = ({ showUpdateUserModal, toggleUpdateUserModal, selectedUsers, isUpdating, onUpdateUsers }) => (
  <Modal
    isOpen={showUpdateUserModal}
    onRequestClose={toggleUpdateUserModal}
    contentLabel="Promote to Admin?"
    actionFunc={onUpdateUsers}
    actionTitle="Confirm"
    variantBtn="primary"
    actionLoading={isUpdating}
    actionDisabled={isUpdating}
  >
    Promoting users to Admin will automatically enable MFA. By clicking <b>CONFIRM</b>,
    you will enable MFA for {selectedUsers.length > 1 ? 'selected users' : <b>{truncateString(selectedUsers[0]?.name)}</b>}
  </Modal>
);

UpdateUserModal.propTypes = {
  showUpdateUserModal: PropTypes.bool,
  toggleUpdateUserModal: PropTypes.func,
  selectedUsers: PropTypes.array,
  isUpdating: PropTypes.bool,
  onUpdateUsers: PropTypes.func,
};

export default UpdateUserModal;
