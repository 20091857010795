import { useMemo, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { DownloadIcon, FileIcon } from 'assets/img';
import { Button } from 'components';
import { dateFormatterUtc, getDomainADStatus } from 'helpers';
import ReactTooltip from 'react-tooltip';
import axios from 'config/customAxios';
import { notify } from 'utils';
import { corpAdmin, corpTechAdmin, custAdmin } from 'consts';
import styles from './Assignments.module.scss';
import GenerateReportModal from './components/GenerateReportModal/GenerateReportModal';

const REPORT_TYPES = {
  userAssignments: 'userAssignments',
  machineAssignmnets: 'machineAssignmnets',
  appAssignments: 'appAssignments',
};

const Assignments = ({
  domain,
  reports,
  licenses,
  userRole,
  isCompanyPage,
  getAssigmentsReports,
  reportLoading,
  setReportLoading,
}) => {
  const primaryDomainData = licenses.find((el) => el.domainName.toLowerCase() === domain.dnsName.toLowerCase());
  const [checkedDomains, setCheckedDomains] = useState([primaryDomainData]);

  const isDomainAzure = useMemo(() => licenses.every((el) => getDomainADStatus(el).isDomainAzure), [licenses]);
  const isAllDomainsAppsDisabled = useMemo(() => licenses.every((el) => !el.isAppPublishingEnabled), [licenses]);
  const isLeastOneDomainHasApps = useMemo(() => licenses.some((el) => el.isAppPublishingEnabled), [licenses]);
  const isOnlyOneDomainHasApps = useMemo(
    () => licenses.filter((el) => el.isAppPublishingEnabled).length === 1,
    [licenses],
  );
  const disableAppColumn = useMemo(() => checkedDomains.every((el) => !el.isAppPublishingEnabled), [checkedDomains]);

  const [checkBoxesChecked, setCheckBoxesChecked] = useState({
    usersUserGroups: true,
    application: !disableAppColumn,
    computersComputerGroups: true,
  });

  const toggleCheckBoxesChecked = (key) => {
    setCheckBoxesChecked({
      ...checkBoxesChecked,
      [key]: !checkBoxesChecked[key],
    });
  };

  const [showExportDetailsModal, setShowExportDetailsModal] = useState(false);
  const [reportType, setReportType] = useState(null);
  const toggleShowExportDetailsModal = (type) => {
    if (showExportDetailsModal) {
      setShowExportDetailsModal(false);
      setReportType(null);
      setCheckBoxesChecked({
        usersUserGroups: true,
        application: !disableAppColumn,
        computersComputerGroups: true,
      });
    } else {
      setCheckedDomains([primaryDomainData]);
      setReportType(type);
      setShowExportDetailsModal(true);
    }
  };

  const { isAppPublishingEnabled } = domain;

  const saveFile = (link) => {
    const fileName = link.split('/').slice(-1).toString();
    saveAs(`${link}`, `${fileName}`);
  };

  const items = [
    {
      title: isDomainAzure ? 'Users' : 'Users or User Groups',
      description: isDomainAzure
        ? 'This report shows resources assigned to specific users'
        : 'This report shows resources assigned to specific users or user groups',
      type: REPORT_TYPES.userAssignments,
      report: reports?.userAssignments?.downloadLink,
      reportDate: reports?.userAssignments?.createdAt,
      isLoading: reportLoading.userAssignments,
    },
    {
      title: isDomainAzure ? 'Computers' : 'Computers or Computer Groups',
      description: isDomainAzure
        ? 'This report shows list of Users assigned to computers'
        : 'This report shows list of Users or User Groups assigned to computers or computer groups',
      type: REPORT_TYPES.machineAssignmnets,
      report: reports?.machineAssignmnets?.downloadLink,
      reportDate: reports?.machineAssignmnets?.createdAt,
      isLoading: reportLoading.machineAssignmnets,
    },
    {
      title: 'Applications',
      description: 'This report shows list of Users or User Groups assigned to specific applications',
      type: REPORT_TYPES.appAssignments,
      report: reports?.appAssignments?.downloadLink,
      reportDate: reports?.appAssignments?.createdAt,
      isLoading: reportLoading.appAssignments,
    },
  ];

  if (isAllDomainsAppsDisabled) {
    items.splice(2, 2);
  }

  const generateUserAssignmentsReport = async (item) => {
    const exportReport = (userRole === custAdmin || userRole === corpAdmin) && !isAppPublishingEnabled;
    const domainIds = item ? [item.domainId] : checkedDomains.map((el) => el.domainId);
    return axios.post(
      `/api/reports/GenerateUserAssignmentsReport?domainId=${domain.id}`,
      {
        domainIds,
        includeMachines: isDomainAzure || exportReport ? true : checkBoxesChecked.computersComputerGroups,
        includeApplications: checkBoxesChecked.application,
      },
      { timeout: 10000 },
    );
  };

  const generateMachineAssignmentsReport = async (item) => {
    const domainIds = item ? [item.domainId] : checkedDomains.map((el) => el.domainId);
    return axios.post(
      `/api/reports/GenerateMachineAssignmentsReport?domainId=${domain.id}`,
      { domainIds },
      { timeout: 10000 },
    );
  };

  const generateApplicationAssignmentsReport = async (item) => {
    const domainIds = item ? [item.domainId] : checkedDomains.map((el) => el.domainId);
    return axios.post(
      `/api/reports/GenerateApplicationAssignmentsReport?domainId=${domain.id}`,
      { domainIds },
      { timeout: 10000 },
    );
  };

  const confirmResult = async (type, item) => {
    try {
      setReportLoading((prevValue) => ({ ...prevValue, [type]: true }));
      if (type === 'userAssignments') {
        const { data } = await generateUserAssignmentsReport(item);
        saveFile(data.downloadLink);
      }
      if (type === 'machineAssignmnets') {
        const { data } = await generateMachineAssignmentsReport(item);
        saveFile(data.downloadLink);
      }
      if (type === 'appAssignments') {
        const { data } = await generateApplicationAssignmentsReport(item);
        saveFile(data.downloadLink);
      }
      await getAssigmentsReports();
      notify.success('Your report is generated');
      setReportLoading((prevValue) => ({ ...prevValue, [type]: false }));
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        setReportLoading((prevValue) => ({ ...prevValue, [type]: true }));
        notify.success(
          <div>
            <div>Your request is in progress</div>
            <div>Please refresh this page in a few minutes</div>
          </div>,
        );
      } else {
        setReportLoading((prevValue) => ({ ...prevValue, [type]: false }));
        notify.error(err?.response?.data?.message || err?.message);
      }
    }
  };

  const buttonAction = (type) => {
    const exportReport =
      userRole === custAdmin || userRole === corpAdmin || userRole === corpTechAdmin || !isCompanyPage;
    const exportUserReport = !(exportReport && type === 'userAssignments' && isAppPublishingEnabled);
    if (type === 'appAssignments' && isOnlyOneDomainHasApps) {
      const item = licenses.find((el) => el.isAppPublishingEnabled);
      setCheckedDomains([item]);
      return confirmResult(type, item);
    }
    if (exportReport && exportUserReport) {
      confirmResult(type);
    } else {
      toggleShowExportDetailsModal(type);
    }
  };

  return (
    <>
      <div className={styles.assigments}>
        <ul className={styles.assigmentsList}>
          {items.map((item) => (
            <li key={item.title} className={styles.assigmentsListItem}>
              <div>
                <h3 className={styles.assigmentsListTitle}>{item.title}</h3>
                <p className={styles.assigmentsListDescription}>{item.description}</p>
              </div>
              <div className={styles.assigmentsListActions}>
                <Button
                  className={cn(styles.assigmentsReportButton, {
                    [styles.loading]: item.isLoading,
                    [styles.noReport]: !item.report,
                  })}
                  id="generate-report"
                  variant="secondary"
                  size="32"
                  isLoading={item.isLoading}
                  onClick={() => buttonAction(item.type)}
                  icon={!item.isLoading && <FileIcon />}>
                  {item.isLoading ? 'Generating Report...' : 'Generate Report'}
                </Button>
                {item.report && (
                  <>
                    <Button
                      type="button"
                      id="download-report"
                      variant="link"
                      onClick={() => saveFile(item.report)}
                      className={styles.licensesActionsButtonsDownload}>
                      <span data-for="download" data-tip={dateFormatterUtc(item.reportDate, true)}>
                        <DownloadIcon />
                      </span>
                    </Button>
                    <ReactTooltip
                      id="download"
                      type="light"
                      place="top"
                      offset={{ left: 60, top: 5 }}
                      effect="solid"
                      className={styles.tooltip}
                    />
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {showExportDetailsModal && (
        <GenerateReportModal
          isOpen={showExportDetailsModal}
          onRequestClose={() => toggleShowExportDetailsModal()}
          type={reportType}
          isDomainAzure={isDomainAzure}
          licenses={licenses}
          userRole={userRole}
          isCompanyPage={isCompanyPage}
          isOnlyOneDomainHasApps={isOnlyOneDomainHasApps}
          isLeastOneDomainHasApps={isLeastOneDomainHasApps}
          checkBoxesChecked={checkBoxesChecked}
          setCheckBoxesChecked={setCheckBoxesChecked}
          toggleCheckBoxesChecked={toggleCheckBoxesChecked}
          checkedDomains={checkedDomains}
          setCheckedDomains={setCheckedDomains}
          primaryDomainData={primaryDomainData}
          getAssigmentsReports={getAssigmentsReports}
          setReportLoading={setReportLoading}
          generateUserAssignmentsReport={generateUserAssignmentsReport}
          generateMachineAssignmentsReport={generateMachineAssignmentsReport}
          generateApplicationAssignmentsReport={generateApplicationAssignmentsReport}
        />
      )}
    </>
  );
};

Assignments.propTypes = {
  domain: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired,
  licenses: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  isCompanyPage: PropTypes.bool,
  getAssigmentsReports: PropTypes.func.isRequired,
  reportLoading: PropTypes.object.isRequired,
  setReportLoading: PropTypes.func.isRequired,
};

export default Assignments;
