/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import cn from 'classnames';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { UserContext, checkClickOnceXtnLinks, runConnector, onChangeView, getUserRole } from 'utils';
import { Button, Link, StatusMessage, Table } from 'components';
import {
  GroupMachineIcon,
  SingleMachineIcon,
  AppDefaultIcon,
  SecureConnectIcon,
  AzureJoinedMashineIcon,
} from 'assets/img';
import { corpTechUser, custUser, personalUser, spTechUser, techAdmin } from 'consts';
import WorkspaceEula from './components/WorkspaceEula/WorkspaceEula';
import styles from './WorkspaceTable.module.scss';
import BrowserExtensionModal from './components/BrowserExtensionModal';
import DropdownFilterBy from './components/DropdownFilterBy/DropdownFilterBy';

const WorkspaceTable = ({
  tableData,
  isComputers,
  baseUrl,
  myDomainPage,
  isAzureAd,
  queryConfig,
  onUpdate,
  onUpdateUser,
  loadingCurrent,
  setLoadingCurrent,
}) => {
  const [webRdpExtnId, setWebRdpExtnId] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [idForEula, setIdForEula] = useState([]);
  const [showBrowserExtensionModal, setShowBrowserExtensionModal] = useState(false);
  const { user } = useContext(UserContext);

  const sortConfig = queryConfig.orderBy.split(/%20| /);
  const role = getUserRole(user);
  const isAssignedVisible = ![spTechUser, custUser, corpTechUser, techAdmin].includes(role);
  const showConnectButton = (exceedsLimit) => (personalUser === role ? !exceedsLimit : true);

  const paginationData = {
    isKindField: true,
    updateAction: onUpdate,
    queryConfig,
    sortConfig,
    baseUrl,
  };

  useEffect(() => {
    const savedWebRdpExtnId = sessionStorage.getItem('webRdpExtnId');
    if (!savedWebRdpExtnId) {
      const { host } = window.location;
      axios
        .get('/api/extensions/GetRdpExtnId', {
          params: {
            brandingHost: !process.env.REACT_APP_PUBLIC_URL.includes(host) ? host : null,
          },
        })
        .then(({ data }) => {
          setWebRdpExtnId(data);
          sessionStorage.setItem('webRdpExtnId', data);
        });
    } else {
      setWebRdpExtnId(savedWebRdpExtnId);
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const openBrowserExtensionModal = () => {
    setShowBrowserExtensionModal(true);
  };

  const closeBrowserExtensionModal = () => {
    setShowBrowserExtensionModal(false);
    setModalMessage('');
  };

  const startRunConnector = (machineId, appName, appId) => {
    setLoadingCurrent(appName ? appId : machineId);

    let options = {
      machineId,
      userLogin: localStorage['TG.SSO.LOGIN'],
      passwordHash: localStorage['TG.SSO.PWD'],
    };

    if (appName) options = { ...options, appName };

    axios
      .post('/api/Connectors/GetConnectorUri', options)
      .then((resp) => runConnector(resp.data.connectorUri))
      .catch((error) => {
        // we should display this message in case response if 'Forbidden'
        const message403 =
          'All licenses are currently in use for Remote Sessions. Please contact your Company Administrator to add more licenses for your domain';
        // we should display this message in case response if 'NotAcceptable', which means that user performed too many connections attempts
        const message406 = 'Rapid clicks are disabled. Please wait 20 seconds to try again or contact Customer Care';

        if (error.response.status === 403) toast.error(message403, 6000);
        if (error.response.status === 406) toast.error(message406, 6000);
        if (error.response.status === 405) toast.error(error.response.data || error.message, 6000);
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingCurrent('');
        }, 20000);
      });
  };

  const connectDevice = (machineId, appName, appId, confirmedModal) => {
    if (user.isEulaAccepted || confirmedModal) {
      checkClickOnceXtnLinks(
        webRdpExtnId,
        () => startRunConnector(machineId, appName, appId),
        (msg) => {
          setModalMessage(msg);
          openBrowserExtensionModal();
        },
      );
    } else {
      setIdForEula([machineId, appName, appId]);
    }
  };

  const onEulaConfirm = () => {
    const idForEulaCopy = [...idForEula];

    setIdForEula([]);

    axios
      .post('/api/Account/UpdateUserEula?eula=true')
      .then(() => {
        const userNew = JSON.parse(JSON.stringify(user));
        userNew.isEulaAccepted = true;
        onUpdateUser(userNew);
      })
      .then(() => connectDevice(...idForEulaCopy, true))
      .catch((err) => toast.error(err.message, 3000));
  };

  const computersColumns = [
    {
      name: 'Computer Name',
      nameFilter: myDomainPage === 'myDomainWithFilter' &&
        !isAzureAd &&
        role !== corpTechUser &&
        role !== spTechUser && (
          <DropdownFilterBy
            id="workspace-select-filter-by"
            options={['Pooled Computers', 'NonPooled Computers', 'All Computers']}
            defaultValue={`${queryConfig.kind} Computers`}
            onChange={(value) => onChangeView(value, paginationData, false)}
          />
        ),
      width: '48%',
      sortable: 'hostname',
      data: ({ isPooled, reportedBy, isAzureJoined, hostname }) => {
        const itemIcon = isPooled ? (
          <GroupMachineIcon />
        ) : !reportedBy ? (
          <SingleMachineIcon />
        ) : isAzureJoined ? (
          <AzureJoinedMashineIcon />
        ) : (
          <SecureConnectIcon />
        );
        return (
          <span className={styles.workspaceTableHostname}>
            {itemIcon} {hostname}
          </span>
        );
      },
    },
    {
      name: 'Status',
      width: '15%',
      data: ({ isActive }) =>
        isActive ? (
          <span className={cn(styles.workspaceTableStatus, styles.workspaceTableStatusOnline)}>Online</span>
        ) : (
          <span className={cn(styles.workspaceTableStatus, styles.workspaceTableStatusOffline)}>Offline</span>
        ),
    },
    {
      name: 'IP',
      width: '15%',
      data: ({ ip }) => ip || 'N/A',
    },
    {
      name: '',
      width: '22%',
      data: ({ id, exceedsLimit }) =>
        !(loadingCurrent && loadingCurrent !== id) &&
        showConnectButton(exceedsLimit || false) && (
          <Button
            type="button"
            id="run-rdp-connection"
            size="32"
            variant="primary"
            isDisabled={!!loadingCurrent}
            isLoading={loadingCurrent === id}
            onClick={() => connectDevice(id, null)}
            className={styles.workspaceTableButton}>
            Connect
          </Button>
        ),
    },
  ];

  const appColumns = [
    {
      name: 'App Name',
      data: ({ icon, appName }) => (
        <span className={styles.workspaceTableIcon}>
          {icon ? <img src={`data:image/png;base64,${icon}`} alt="App Icon" /> : <AppDefaultIcon />}{' '}
          {appName || 'Noname App'}
        </span>
      ),
      sortable: 'appname',
      width: '48%',
    },
    {
      name: 'Group Name',
      data: ({ name, resourceName }) => name || resourceName,
      sortable: 'resourcename',
      width: '20%',
    },
    {
      name: '',
      data: ({ id, resourceId, appName }) =>
        !(loadingCurrent && loadingCurrent !== id) && (
          <Button
            type="button"
            id="run-app-connection"
            size="32"
            variant="primary"
            isDisabled={!!loadingCurrent}
            isLoading={loadingCurrent === id}
            onClick={() => connectDevice(resourceId, appName, id)}
            className={styles.workspaceTableButton}>
            Connect
          </Button>
        ),
      width: '32%',
    },
  ];

  const classNameRow = ({ id }) =>
    cn({
      [styles.workspaceTableRowLoading]: loadingCurrent === id,
    });

  const noData = (
    <StatusMessage header="No computers reporting in">
      {isAssignedVisible ? (
        <p>
          Change view or{' '}
          <Link href="https://www.trugrid.com/docs/installsentry" external bold>
            add computers
          </Link>
        </p>
      ) : (
        <p>Change view</p>
      )}
    </StatusMessage>
  );

  return (
    <>
      <Table
        data={tableData}
        updateData={onUpdate}
        columns={isComputers ? computersColumns : appColumns}
        id="workspace-table"
        noData={noData}
        dataKey="id"
        baseUrl={baseUrl}
        className={styles.workspaceTable}
        classNameRow={(item) => classNameRow(item)}
        rowTip={({ isActive }) =>
          !isActive && isComputers
            ? 'This computer is marked OFFLINE either because it is shut down or RDP is not accessible'
            : null
        }
      />

      <ReactTooltip id="info-tooltip" type="light" effect="solid" delayShow={200} offset={{ top: '-16' }} />

      {!!idForEula.length && (
        <WorkspaceEula isOpen={!!idForEula.length} onClose={() => setIdForEula([])} onConfirm={onEulaConfirm} />
      )}

      {showBrowserExtensionModal && (
        <BrowserExtensionModal
          isOpen={showBrowserExtensionModal}
          onRequestClose={closeBrowserExtensionModal}
          modalMessage={modalMessage}
        />
      )}
    </>
  );
};

WorkspaceTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  isComputers: PropTypes.bool.isRequired,
  myDomainPage: PropTypes.string,
  isAzureAd: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  queryConfig: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  loadingCurrent: PropTypes.string,
  setLoadingCurrent: PropTypes.func,
};

WorkspaceTable.displayName = 'WorkspaceTable';

export default WorkspaceTable;
