/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { checkIsPasswordValid } from 'helpers';
import { Button, PasswordComplexity, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import { loginService } from 'services';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { LoginContext, UserContext, notify } from 'utils';
import styles from './ChangeTempPassword.module.scss';

const ChangeTempPassword = () => {
  const { authUser } = useContext(UserContext);
  const { email, signInData } = useContext(LoginContext);
  const { mfaShouldBeSetup } = signInData;

  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(['', '', '']);
  const [formErrors, setFormErrors] = useState(['', '', '']);
  const tempPassRef = useRef();
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const tooltipPassRef = useRef();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  useEffect(() => {
    if (email) {
      setTimeout(() => tempPassRef.current?.focus(), 0);
      setTimeout(() => notify.success('Your account is activated. Please check email to proceed with the registration'), 500);
    }
  }, []);

  const onUpdate = (value, index) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearError = (index) => {
    if (index === 1) {
      setIsTooltipOpen(true);
    }
    if (index === 1 || index === 2) {
      const passErrorsNew = [...formErrors];
      passErrorsNew[1] = '';
      passErrorsNew[2] = '';
      setFormErrors(passErrorsNew);
    } else {
      const passErrorsNew = formErrors.map((i, ind) => (index === ind ? '' : i));
      setFormErrors(passErrorsNew);
    }
  };

  const onClearValue = (index, ref) => {
    ref.current.focus();
    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];

    const isNewPassValid = checkIsPasswordValid(formValues[1]);
    const isConfirmPassValid = formValues[2] && formValues[1] === formValues[2];

    if (!isNewPassValid) {
      formErrorsNew[1] = ' ';
      formErrorsNew[2] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[1] = ' ';
      formErrorsNew[2] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const loginUser = async () => {
    try {
      await authUser();
      if (mfaShouldBeSetup) {
        return history.replace('/setup-mfa');
      }
      return history.replace('/workspace');
    } catch (error) {
      notify.error(error.message);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsSubmitting(true);
      loginService.changeTempPassword(email, formValues[0], formValues[1])
        .then(async () => {
          notify.success('Your password was successfully updated');
          setIsSubmitting(false);
          await loginUser();
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (err.response.data.message) {
            const formErrorsNew = [...formErrors];
            formErrorsNew[0] = err.response.data.message;
            if (formErrorsNew.length) {
              setFormErrors(formErrorsNew);
            }
          } else {
            notify.error(err.message);
          }
        });
    }
  };

  return (
    <div className={styles.password}>
      <LoginHeader title="Update your password" hideSteps back={() => history.replace('/')} />
      <form method="POST" onSubmit={onLogin}>
        <div className={styles.passwordInputWrap}>
          <TextInput
            ref={tempPassRef}
            id="enter-temporary-password"
            placeholder="Enter Current Password"
            type="password"
            hasView
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdate(e.target.value, 0)}
            onClear={() => onClearValue(0, tempPassRef)}
            onFocus={() => onClearError(0)}
            autoComplete="current-password"
          />
        </div>
        <div className={styles.passwordInputWrap} ref={tooltipPassRef}>
          <TextInput
            ref={newPassRef}
            id="enter-new-password"
            placeholder="Enter New Password"
            type="password"
            hasView
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdate(e.target.value, 1)}
            onClear={() => onClearValue(1, newPassRef)}
            onFocus={() => onClearError(1)}
            autoComplete="new-password"
          />
          {isTooltipOpen && (
            <PasswordComplexity inputValue={formValues[1]} />
          )}
        </div>
        <div className={styles.passwordInputWrap}>
          <TextInput
            ref={confirmPassRef}
            id="confirm-new-password"
            placeholder="Confirm New Password"
            type="password"
            hasView
            value={formValues[2]}
            error={formErrors[2]}
            onChange={(e) => onUpdate(e.target.value, 2)}
            onClear={() => onClearValue(2, confirmPassRef)}
            onFocus={() => onClearError(2)}
            autoComplete="new-password"
          />
        </div>
        <Button
          id="update-temp-password"
          size="48"
          variant="primary"
          type="submit"
          isDisabled={formValues.includes('')}
          isLoading={isSubmitting}
          className={styles.passwordButton}
        >
          Update
        </Button>
      </form>
    </div>
  );
};

export default ChangeTempPassword;
