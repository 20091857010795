/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { loginService } from 'services';
import { LoginContext, UserContext, notify } from 'utils';
import ResetPasswordError from 'pages/Login/components/ResetPasswordError/ResetPasswordError';
import styles from './UpdatePassword.module.scss';

const UpdatePassword = () => {
  const { authUser } = useContext(UserContext);
  const { email, signInData, password: oldPassword, mfaShouldBeSetup } = useContext(LoginContext);
  const { userId } = signInData;

  const history = useHistory();
  const [error, setError] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const tooltipPassRef = useRef();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const onClearError = (index) => {
    if (index === 0) {
      setIsTooltipOpen(true);
    }
    setFormErrors(['', '']);
  };

  const onUpdate = (value, index) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearValue = (index, ref) => {
    ref.current.focus();

    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];
    const isNewPassValid = formValues[0].length > 0;
    const isConfirmPassValid = formValues[1] && formValues[0] === formValues[1];

    if (!isNewPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const loginUser = async () => {
    try {
      await authUser();
      if (mfaShouldBeSetup) {
        return history.replace('/setup-mfa');
      }
      return history.replace('/workspace');
    } catch (err) {
      notify.error(err.message);
    }
  };

  const onSetPassword = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsUpdating(true);
      loginService
        .changePassword(userId, oldPassword, formValues[0])
        .then(async ({ data }) => {
          if (data) {
            localStorage.setItem('TG.SSO.LOGIN', email);
            localStorage.setItem('TG.SSO.PWD', data);
          }
          setIsUpdating(false);
          notify.success('Your password was successfully updated');
          await loginUser();
          history.replace('/workspace');
        })
        .catch((err) => {
          setIsUpdating(false);
          if (err.response.status === 403) {
            setError('exceeded');
          } else if (err.response.status === 406) {
            setError('offline');
          } else {
            notify.warning(err?.response?.data || err.message);
            setFormErrors([' ', ' ']);
          }
        });
    }
  };

  const passwordTooltip = () => (
    <>
      <h5>We recommend:</h5>
      <ul>
        <li>at least 10 characters</li>
        <li>both lower and uppercase letters</li>
        <li>numbers and special characters</li>
        <li>not repeating any previously used password</li>
      </ul>
    </>
  );

  if (error) {
    return <ResetPasswordError error={error} />;
  }

  return (
    <div className={styles.setPass}>
      <LoginHeader title="Update your password" hideSteps back={() => history.replace('/')} />
      <p className={styles.setPassInfo}>
        Your company requires you to update your password. <br />
        Create a new password according to your company's requirements
      </p>
      <form className={styles.setPassForm} method="POST" onSubmit={onSetPassword}>
        <div className={styles.setPassWrap} ref={tooltipPassRef}>
          <TextInput
            ref={newPassRef}
            id="enter-new-password"
            autoComplete="new-password"
            placeholder="Enter New Password"
            type="password"
            hasView
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdate(e.target.value, 0)}
            onClear={() => onClearValue(0, newPassRef)}
            onFocus={() => onClearError(0)}
          />
          {isTooltipOpen && <div className={styles.setPassTooltip}>{passwordTooltip()}</div>}
        </div>
        <div className={styles.setPassWrap}>
          <TextInput
            ref={confirmPassRef}
            type="password"
            id="confirm-new-password"
            autoComplete="new-password"
            placeholder="Confirm New Password"
            hasView
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdate(e.target.value, 1)}
            onClear={() => onClearValue(1, confirmPassRef)}
            onFocus={() => onClearError(1)}
          />
        </div>
        <Button
          id="set-new-password"
          size="48"
          type="submit"
          variant="primary"
          isDisabled={formValues.includes('')}
          isLoading={isUpdating}>
          Set New Password
        </Button>
      </form>
    </div>
  );
};

export default UpdatePassword;
