import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'utils';
import axios from 'config/customAxios';
import { mustSetupMfa, signOut } from 'helpers';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const authUser = useCallback(async () => {
    setIsLoading(true);
    axios.get('/api/Account/GetInitialUserData')
      .then(({ data }) => {
        setUser(data);
        if (data.roles.includes('trugridadmin') && !mustSetupMfa(data)) {
          return window.location.replace('/admin');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setIsLoading(false);
          window.location.replace('/');
        }
        if (err?.response?.status === 406) {
          setIsLoading(false);
          signOut();
        }
      });
  }, []);

  const checkIsAuth = useCallback(async () => {
    try {
      const { data } = await axios.get('/isauthenticated');
      if (data) {
        await authUser();
      } else {
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  }, [authUser]);

  useEffect(() => {
    checkIsAuth();
    return () => setIsLoading(false);
  }, [checkIsAuth]);

  const userValue = useMemo(() => ({
    user,
    onUpdateUser: setUser,
    authUser,
    isLoading,
  }), [authUser, isLoading, user]);

  return (
    <UserContext.Provider value={userValue}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
