/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { Button, CodeInput, Link } from 'components';
import { useState } from 'react';
import { loginService } from 'services';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import styles from '../ResetViaSMS.module.scss';

const ValidatePhone = ({ setMfaHash, phoneDigits, setPhoneDigits, isAdmin, phoneMask }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState('');
  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError(false);

  const handleGetResetMfaHash = () => {
    setIsSubmitting(true);
    loginService.verifyPhoneNumber(phoneDigits)
      .then(({ data }) => {
        notify.success('SMS has been sent');
        setMfaHash(data);
        setIsSubmitting(false);
        history.push(`${url}/verification-code`);
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err?.response?.status === 405 || err?.response?.status === 409) {
          notify.error(err?.response?.data);
          history.replace('/login');
        } else if (err?.response?.data) {
          setIsError(err?.response?.data);
        } else {
          notify.error(err?.response?.data || err.message);
        }
      });
  };

  return (
    <div className={styles.resetMfa}>
      <LoginHeader
        title="Validate Your Phone"
        stepCount="2"
        currentStep="1"
      />
      <div className={styles.resetMfaInfo}>
        We will send a verification code to <strong>{phoneMask}</strong>. Please enter the last 4 digits of your phone number to confirm your identity.
      </div>
      <div className={styles.resetMfaForm}>
        <div className={styles.resetMfaLabel}>Enter Last 4 Digits</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isSubmitting}
          value={phoneDigits}
          setValue={setPhoneDigits}
          handleSend={handleGetResetMfaHash}
          sendOnKeyDown
        />
        <Button
          id="send-number-phone"
          className={styles.resetMfaButton}
          onClick={handleGetResetMfaHash}
          size="40"
          variant="primary"
          isDisabled={phoneDigits.length < 4 || !!isError}
          isLoading={isSubmitting}
        >
          Send Code
        </Button>
      </div>
      <div className={styles.resetMfaContactSupport}>
        {isAdmin ? (
          <>
            Phone is unavailable?&nbsp;
            <Link href="https://www.trugrid.com/contact-us/" external bold className={styles.contactSupportLink}>Contact Support</Link>
          </>
        ) : (
          <>Phone is unavailable? Contact your Administrator for assistance</>
        )}
      </div>
    </div>
  );
};

ValidatePhone.propTypes = {
  setMfaHash: PropTypes.func,
  phoneDigits: PropTypes.string,
  setPhoneDigits: PropTypes.func,
  isAdmin: PropTypes.bool,
  phoneMask: PropTypes.string,
};

export default ValidatePhone;
