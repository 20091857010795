import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import { Modal } from 'components';
import { notify } from 'utils';

const TruScanDeleteModal = ({
  showTruScanDeleteModal,
  toggleShowTruScanDeleteModal,
  data,
  leakedRecords,
  setLeakedRecords,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateDelete = (recordId) => {
    const leakedRecordsNew = leakedRecords.filter((record) => record.id !== recordId);
    setLeakedRecords(leakedRecordsNew);
  };

  const onDeleteRecord = () => {
    setIsLoading(true);

    axios
      .delete(`/api/leakedrequests/Delete/${data.recordId}`)
      .then((resp) => {
        onUpdateDelete(resp.data);
        notify.success('Entry Deleted');
      })
      .catch((err) => notify.error(err.message))
      .finally(() => {
        setIsLoading(false);
        toggleShowTruScanDeleteModal();
      });
  };

  return (
    <Modal
      isOpen={showTruScanDeleteModal}
      onRequestClose={toggleShowTruScanDeleteModal}
      contentLabel="Delete Entry"
      actionFunc={onDeleteRecord}
      actionLoading={isLoading}
      actionTitle="Delete"
      variantBtn="deleteOutline">
      Are you sure you want to delete this record? This cannot be undone
    </Modal>
  );
};

TruScanDeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  showTruScanDeleteModal: PropTypes.bool,
  toggleShowTruScanDeleteModal: PropTypes.func,
  leakedRecords: PropTypes.array,
  setLeakedRecords: PropTypes.func,
};

export default TruScanDeleteModal;
