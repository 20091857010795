/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const liveChat_element_selector = '.crisp-client';
export default class LiveChat extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validateLiveChat = this.validateLiveChat.bind(this);
    this.checkExternalChatLoaded = this.checkExternalChatLoaded.bind(this);

    this.state = {
      isChatLoaded: false,
      isUserDataUpdated: false,
    };
  }

  checkExternalChatLoaded() {
    const chatEl = document.querySelector(liveChat_element_selector);
    const delay = 700;
    if (!chatEl) {
      setTimeout(this.checkExternalChatLoaded, delay);
      return;
    }
    this.validateLiveChat();
  }

  componentDidMount() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '710355c5-b792-4ce9-ba54-31d2253039d8';
    (() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();

    this.checkExternalChatLoaded();
  }

  validateLiveChat() {
    const { companyBranding, user } = this.props;
    const { isUserDataUpdated } = this.state;

    const chatEl = document.querySelector(liveChat_element_selector);
    const hiddenClass = 'hidden';
    const isChatHidden = !chatEl || chatEl.classList.contains(hiddenClass);

    const isLoadedBrandingImg = companyBranding;
    const isRoleAllowed = ['mspadmin', 'sptechadmin', 'msptechnician', 'personaluser', 'freepersonaluser'].some((i) => user.roles.includes(i));

    const isLiveChatAllowed = isLoadedBrandingImg && isRoleAllowed;

    if (isLiveChatAllowed && chatEl && !isUserDataUpdated) {
      window.$crisp.push(['set', 'user:email', [`${user.email}`, `${user.userIdHash}`]]);
      window.$crisp.push(['set', 'user:nickname', [user.displayName]]);
      this.setState({ isUserDataUpdated: true });
    }

    if (isLiveChatAllowed && isChatHidden) {
      document.querySelector(liveChat_element_selector).classList.remove(hiddenClass);
    }

    if (!isLiveChatAllowed && !isChatHidden) {
      document.querySelector(liveChat_element_selector).classList.add(hiddenClass);
    }

    if (!isLoadedBrandingImg) {
      setTimeout(this.validateLiveChat, 500);
    }
  }

  render() {
    return (<div />);
  }
}

LiveChat.propTypes = {
  user: PropTypes.object.isRequired,
  companyBranding: PropTypes.object.isRequired,
};
