import PropTypes from 'prop-types';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon, LoaderIcon } from 'assets/img';
import Checkbox from 'components/Checkbox/Checkbox';
import { onChangePage, onSearch, onSort, truncateString } from 'utils';
import SearchInput from 'components/SearchInput/SearchInput';
import Pagination from 'components/Pagination/Pagination';
import ReactTooltip from 'react-tooltip';
import { memo, useState } from 'react';
import styles from './Table.module.scss';

const Table = ({
  columns = [],
  data = [],
  updateData,
  selectedItems = [],
  setSelectedItems,
  ruleForSelectItems,
  ruleForToggleAll,
  id,
  isLoading,
  dataKey,
  noData,
  baseUrl,
  pagination = true,
  onClickRow,
  className,
  classNameRow,
  onMouseEnter,
  onMouseLeave,
  rowTip,
}) => {
  const [sortingCurrent, setSortingCurrent] = useState('');
  const sortConfig = data?.queryConfig?.orderBy?.split(/%20| /);

  const paginationData = {
    queryConfig: data?.queryConfig,
    sortingAction: setSortingCurrent,
    updateAction: updateData,
    sortConfig,
    baseUrl,
  };

  const getDataValue = (item, dataIndex) => {
    if (typeof dataIndex === 'function') {
      return dataIndex(item);
    }
    return item[dataIndex];
  };

  const getFuncValue = (func, item) => {
    if (typeof func === 'function') {
      return func(item);
    }
    return func;
  };

  const getSortingIcons = (sortable) => {
    if (sortingCurrent === sortable) {
      return <LoaderIcon className={styles.tableColSortingLoad} />;
    }
    if (sortConfig[0] === sortable) {
      if (sortConfig[1] === 'asc') {
        return <ArrowDownIcon className={cn(styles.tableColSortingIcon, styles.iconActive)} />;
      }
      return <ArrowUpIcon className={cn(styles.tableColSortingIcon, styles.iconActive)} />;
    }
    return <ArrowDownIcon className={styles.tableColSortingIcon} />;
  };

  const isAllChecked = () => {
    if (data.queryConfig.searchQuery && !data.data.length) return false;

    const selectedItemsIds = selectedItems.map((i) => i[dataKey]);
    let currentPageItemsIds = data.data.map((i) => i[dataKey]);

    if (ruleForToggleAll) {
      currentPageItemsIds = ruleForToggleAll;
    }

    const allSelected = currentPageItemsIds.every((i) => selectedItemsIds.includes(i));
    const someSelected = currentPageItemsIds.some((i) => selectedItemsIds.includes(i));

    if (allSelected) {
      return 'checked'; // All items are selected
    }
    if (someSelected) {
      return 'partial'; // Some items are selected
    }
    return 'empty'; // No items are selected
  };

  const isOneChecked = (item) => !!selectedItems.find((value) => value[dataKey] === item[dataKey]);

  const onToggleOne = (checked, item) => {
    if (checked) {
      return setSelectedItems([...selectedItems, item]);
    }
    const selectedItemsNew = selectedItems.filter((selected) => selected[dataKey] !== item[dataKey]);
    return setSelectedItems(selectedItemsNew);
  };

  const onToggleAll = (isChecked) => {
    if (!data.data.length) return false;

    let selectedItemsNew = selectedItems.map((i) => ({ ...i }));

    if (isChecked) {
      data.data.forEach((a) => {
        if (
          !selectedItemsNew.some((i) => i[dataKey] === a[dataKey]) &&
          (typeof ruleForSelectItems === 'function' ? ruleForSelectItems(a) : true)
        ) {
          selectedItemsNew.push(a);
        }
      });
    } else {
      selectedItemsNew = selectedItemsNew.filter((s) => !data.data.some((i) => i[dataKey] === s[dataKey]));
    }
    return setSelectedItems(selectedItemsNew);
  };

  const onToggleMainCheckbox = () => {
    const isChecked = isAllChecked() === 'empty';
    onToggleAll(isChecked);
  };

  const renderMainCheckbox = () => {
    const allChecked = isAllChecked();

    if (data.queryConfig.searchQuery && !data.data.length) return null;

    if (allChecked === 'checked') {
      return <Checkbox checked onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
    }
    if (allChecked === 'partial') {
      return <Checkbox checked="partial" onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
    }
    return <Checkbox checked={false} onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
  };

  return (
    <>
      <table
        className={cn(styles.table, {
          [styles.full]: data.data?.length > 6,
          [styles.noData]: !data?.data.length && !data?.queryConfig?.searchQuery && !!noData,
          [styles.loading]: isLoading,
          [className]: className,
        })}
        id={id}>
        {!data?.data.length && !data?.queryConfig?.searchQuery && !!noData ? null : (
          <thead>
            <tr className={styles.tableRow}>
              {columns.map(
                ({
                  name,
                  nameFilter,
                  sortable,
                  search,
                  searchNotDebounce,
                  checkbox,
                  tooltip,
                  width,
                  disabled,
                  extraContent,
                }) =>
                  !disabled && (
                    <th
                      key={name}
                      className={cn(styles.tableCol, styles.tableColHead, { [styles.tableColSortable]: !!sortable })}
                      style={{ width: getFuncValue(width) }}>
                      {checkbox && renderMainCheckbox()}
                      {sortable ? (
                        <span
                          className={styles.tableColSorting}
                          data-tip={tooltip && tooltip?.tip}
                          data-for={tooltip && tooltip?.id}
                          data-iscapture={tooltip && true}>
                          {tooltip ? (
                            <>
                              <button
                                type="button"
                                onClick={() => data.data.length && onSort(sortable, paginationData)}
                                className={styles.tableColSortingButton}>
                                {name}
                                {getSortingIcons(sortable)}
                              </button>
                              {nameFilter && nameFilter}
                              {extraContent && extraContent}
                              <ReactTooltip
                                id={tooltip?.id}
                                place="right"
                                type="light"
                                effect="solid"
                                multiline
                                delayShow={200}
                              />
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                onClick={() => data.data.length && onSort(sortable, paginationData)}
                                className={styles.tableColSortingButton}>
                                {name}
                                {getSortingIcons(sortable)}
                              </button>
                              {nameFilter && nameFilter}
                              {extraContent && extraContent}
                            </>
                          )}
                        </span>
                      ) : (
                        name
                      )}
                      {search && (
                        <div className={styles.tableColSearch}>
                          {searchNotDebounce ? (
                            <SearchInput
                              id={`search-by-${search.split(' ').join('-').toLowerCase()}`}
                              searchBy={search}
                              isNotDebounce
                              onChange={(value) => searchNotDebounce(value)}
                            />
                          ) : (
                            <SearchInput
                              id={`search-by-${search.split(' ').join('-').toLowerCase()}`}
                              searchBy={search}
                              onChange={(value) => onSearch(value, paginationData)}
                            />
                          )}
                        </div>
                      )}
                    </th>
                  ),
              )}
            </tr>
          </thead>
        )}
        <tbody>
          {!data?.data.length && !data?.queryConfig?.searchQuery && !!noData ? (
            <tr className={styles.tableNoData}>
              <td>{noData}</td>
            </tr>
          ) : !data?.data?.length && !!data?.queryConfig?.searchQuery ? (
            <tr className={styles.tableNoData}>
              <td>No results found</td>
            </tr>
          ) : (
            data.data.map((item) => (
              <tr
                className={cn(styles.tableRow, styles.tableRowBody, {
                  [styles.tableRowBodySelected]: isOneChecked && isOneChecked(item),
                  [getFuncValue(classNameRow, item)]: getFuncValue(classNameRow, item),
                })}
                key={typeof dataKey === 'function' ? dataKey(item) : item[dataKey]}
                onClick={() => onClickRow && onClickRow(item)}
                onMouseEnter={onMouseEnter ? (e) => onMouseEnter(e, item) : null}
                onMouseLeave={onMouseLeave ? (e) => onMouseLeave(e, item) : null}
                data-tip={getFuncValue(rowTip, item)}
                data-for="info-tooltip">
                {Object.values(columns).map(
                  (value) =>
                    !value.disabled && (
                      <td
                        className={cn(styles.tableCol, styles.tableColBody)}
                        style={{ width: getFuncValue(value.width, item) }}
                        key={value.name}>
                        {getFuncValue(value?.checkbox, item) && (
                          <Checkbox
                            checked={isOneChecked(item)}
                            onChange={(e) => onToggleOne(e.target.checked, item)}
                            className={styles.tableCheckbox}
                            isDisabled={getFuncValue(value?.checkboxDisabled, item)}
                          />
                        )}
                        {truncateString(getDataValue(item, value.data))}
                      </td>
                    ),
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {pagination && data.pageMeta?.TotalCount > 10 && (
        <Pagination
          baseUrl={baseUrl}
          pageMeta={data.pageMeta}
          queryConfig={data.queryConfig}
          onUpdate={(value) => onChangePage(value, paginationData)}
        />
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  updateData: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  ruleForSelectItems: PropTypes.func,
  ruleForToggleAll: PropTypes.array,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  noData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  baseUrl: PropTypes.string,
  pagination: PropTypes.bool,
  onClickRow: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.any,
  classNameRow: PropTypes.any,
  rowTip: PropTypes.func,
};

export default memo(Table);
