import PropTypes from 'prop-types';
import { Button, DropdownArray } from 'components';
import cn from 'classnames';
import styles from './ManageBtnMfa.module.scss';

const ManageBtnMfa = ({
  isDisabled,
  isSetupView,
  isDisabledView,
  isResetView,
  selectedUsers,
  user,
  onModalOpen,
  isHeaderBtn,
}) => {
  if (!isSetupView && !isDisabledView && !isResetView) {
    if ((isHeaderBtn && selectedUsers.some((i) => i.isAdmin)) || user.isAdmin) {
      return (
        <Button
          variant="primary"
          id="reset-mfa"
          size={isHeaderBtn ? '40' : '32'}
          isDisabled={isDisabled}
          onClick={(e) => onModalOpen('Reset MFA', user, isHeaderBtn, e)}>
          Reset MFA
        </Button>
      );
    }
    return (
      <DropdownArray
        isButton
        isButton32={!isHeaderBtn}
        id="manage-mfa"
        btnTitle="Manage MFA"
        options={['Disable MFA', 'Reset MFA']}
        onChange={(value) => onModalOpen(value, user, isHeaderBtn)}
        className={cn(styles.dropdown, 'dropdown-component__select')}
        allowPositionTop
        optionsClassName={styles.dropdownOptions}
      />
    );
  }
  if (isSetupView) {
    return (
      <Button
        size={isHeaderBtn ? '40' : '32'}
        isDisabled={isDisabled}
        onClick={(e) => onModalOpen('Disable MFA', user, isHeaderBtn, e)}>
        Disable MFA
      </Button>
    );
  }
  if (isResetView) {
    if (!user.isAdmin) {
      return (
        <Button
          size={isHeaderBtn ? '40' : '32'}
          isDisabled={isDisabled}
          onClick={(e) => onModalOpen('Disable MFA', user, isHeaderBtn, e)}>
          Disable MFA
        </Button>
      );
    }
    return '';
  }
  return (
    <Button
      size={isHeaderBtn ? '40' : '32'}
      variant="primary"
      isDisabled={isDisabled}
      onClick={(e) => {
        onModalOpen('Enable MFA', user, isHeaderBtn, e);
      }}>
      Enable MFA
    </Button>
  );
};

ManageBtnMfa.propTypes = {
  isDisabled: PropTypes.bool,
  isSetupView: PropTypes.bool,
  isDisabledView: PropTypes.bool,
  isResetView: PropTypes.bool,
  selectedUsers: PropTypes.array,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onModalOpen: PropTypes.func,
  isHeaderBtn: PropTypes.bool,
};

export default ManageBtnMfa;
