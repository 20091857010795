import { memo, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { queryFormatter } from 'utils/queryFormatter';
import { ArrowLeftIcon } from 'assets/img';
import Button from 'components/Button/Button';
import styles from './Pagination.module.scss';
import RowNumbers from './components/RowNumbers/RowNumbers';
import PageNumbers from './components/PageNumbers/PageNumbers';

const Pagination = memo(({ baseUrl, pageMeta, queryConfig, onUpdate }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onPageChangeHandler = (page, pageSize) => {
    setIsDisabled(true);

    const query = { ...queryConfig, pageNumber: page, pageSize };
    const url = queryFormatter(baseUrl, query);

    onUpdate([url, query]).then(() => setIsDisabled(false));
  };

  return (
    <div className={cn(styles.pagination, { [styles.paginationLoad]: isDisabled })}>
      <div className={styles.paginationEmpty} />
      <div className={styles.paginationPages}>
        <Button
          variant="link"
          icon={<ArrowLeftIcon />}
          className={cn(styles.paginationFlip, { [styles.paginationFlipActive]: pageMeta.PreviousPageLink })}
          onClick={() => onPageChangeHandler(pageMeta.CurrentPage - 1, pageMeta.PageSize)}
        >
          Prev
        </Button>
        <PageNumbers
          totalPages={pageMeta.TotalPages}
          currentPage={pageMeta.CurrentPage}
          pageSize={pageMeta.PageSize}
          onPageChangeHandler={onPageChangeHandler}
        />
        <Button
          variant="link"
          icon={<ArrowLeftIcon />}
          className={cn(styles.paginationFlip, styles.paginationFlipNext, { [styles.paginationFlipActive]: pageMeta.NextPageLink })}
          onClick={() => onPageChangeHandler(pageMeta.CurrentPage + 1, pageMeta.PageSize)}
        >
          Next
        </Button>
      </div>
      <RowNumbers
        pageSize={pageMeta.PageSize}
        onPageChangeHandler={onPageChangeHandler}
        isDisabled={isDisabled}
      />
    </div>
  );
});

Pagination.displayName = 'Pagination';

Pagination.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  pageMeta: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Pagination;
