export const onChangeSettings = (label, value, domainSettings) => {
  value = typeof value === 'boolean' || label === 'sharedDrives' ? value : +value;
  const domainSettingsNew = JSON.parse(JSON.stringify(domainSettings));

  if (label === 'isZeroTrustEnabled' && value) {
    const { modified } = domainSettingsNew;
    const devices = [
      'isSharedPrinters', 'isSharedPosDevices', 'isSharedComPorts',
      'isSharedSmartCards', 'isSharedCameras', 'isSharedDevices', 'isSharedClipboard',
    ];

    Object.keys(modified).forEach((key) => {
      if (key === 'isZeroTrustEnabled') {
        modified[key] = value;
        return;
      }

      if (key === 'sharedDrives') {
        modified[key] = '';
        return;
      }

      if (devices.includes(key)) {
        modified[key] = false;
      }
    });
  } else {
    domainSettingsNew.isModified = true;
    domainSettingsNew.modified[label] = value;
  }

  return domainSettingsNew;
};
