import axios from 'config/customAxios';

export const onResetSingleSettings = async (user, isCustomDomain, domains, domainCurrent) => {
  let applyTo = [user.id];
  if (isCustomDomain) {
    applyTo = [domains[domainCurrent].id];
  }

  const response = await axios({
    method: 'post',
    url: '/api/Connectors/ResetRdpConfiguration',
    data: applyTo,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const newDomainSettings = {
    isError: false, isModified: false, initial: { ...response.data }, modified: { ...response.data },
  };
  return newDomainSettings;
};
