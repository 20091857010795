import PropTypes from 'prop-types';
import { Modal } from 'components';
import { useState } from 'react';
import { AttentionIcon } from 'assets/img';
import { truncateString } from 'utils';
import styles from './DisableMFACodeModal.module.scss';

const DisableMFACodeModal = ({ isOpen, onRequestClose, onToggleMfaRequiredForUser, user }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDisableMfaRequiredForUser = async () => {
    setIsLoading(true);
    await onToggleMfaRequiredForUser(!!Array.isArray(user), 'disable');
    setIsLoading(false);
  };

  const message =
    Array.isArray(user) && user.length > 1 ? (
      'selected users'
    ) : Array.isArray(user) && user.length === 1 ? (
      <span className={styles.selectedUser}>{truncateString(user[0].displayName, false)}</span>
    ) : (
      <span className={styles.selectedUser}>{truncateString(user.displayName, false)}</span>
    );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.title}>
          <AttentionIcon />
          Disable MFA Code?
        </div>
      }
      actionFunc={handleDisableMfaRequiredForUser}
      actionTitle="Disable"
      variantBtn="delete"
      actionLoading={isLoading}>
      Do you really want to disable MFA Code for Desktop Shortcut for {message}?
    </Modal>
  );
};

DisableMFACodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onToggleMfaRequiredForUser: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object], [PropTypes.array]),
};

export default DisableMFACodeModal;
