import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import styles from './DevicesBulkActions.module.scss';

const DevicesBulkActions = ({
  devices,
  selectedDeviceIds,
  setSelectedDeviceIds,
  openUninstallAgentsModalShow,
  openDeleteDevicesModalShow,
}) => {
  const { installationStatus } = devices.queryConfig || {};
  const totalDevices = devices.pageMeta?.TotalCount || devices.length;
  const counterRef = useRef();
  const isSticky = useObserver(counterRef, selectedDeviceIds.length, { threshold: 1 });

  return (
    <div
      className={cn(styles.bulkActions, { [styles.active]: selectedDeviceIds.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.bulkActionsWrap}>
        <div className={styles.bulkActionsCount}>
          {selectedDeviceIds.length}/{totalDevices} Selected
        </div>
        <div className={styles.bulkActionsButtonsWrap}>
          <Button
            type="submit"
            id="unselect-all"
            variant="secondary"
            size="40"
            style={{ width: '7.75em' }}
            onClick={() => setSelectedDeviceIds([])}>
            Unselect All
          </Button>
          {installationStatus === 'Installed' ? (
            <Button
              type="submit"
              id="uninstall-devices"
              variant="primary"
              size="40"
              style={{ width: '7.75em' }}
              onClick={() => openUninstallAgentsModalShow(selectedDeviceIds)}>
              Uninstall
            </Button>
          ) : (
            <Button
              type="submit"
              id="delete-devices"
              variant="deleteOutline"
              size="40"
              style={{ width: '7.75em' }}
              onClick={() => openDeleteDevicesModalShow(selectedDeviceIds)}>
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

DevicesBulkActions.propTypes = {
  devices: PropTypes.object,
  selectedDeviceIds: PropTypes.array,
  setSelectedDeviceIds: PropTypes.func,
  openUninstallAgentsModalShow: PropTypes.func,
  openDeleteDevicesModalShow: PropTypes.func,
};

export default DevicesBulkActions;
