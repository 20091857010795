import cn from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';
import styles from './ResetVariant.module.scss';

const ResetVariant = ({ name, title, type, setType, Icon }) => (
  <button type="button" className={cn(styles.variant, { [styles.active]: type === name })}>
    <Checkbox
      id={`via-${name}`}
      isCircle
      checked={type === name}
      onChange={() => setType(name)}
      label={(
        <>
          <Icon />
          <span className={styles.variantName}>{title}</span>
        </>
      )}
      labelPosition="left"
    />
  </button>
);

ResetVariant.propTypes = {
  name: PropTypes.string,
  title: PropTypes.any,
  type: PropTypes.string,
  setType: PropTypes.func,
  Icon: PropTypes.any,
};

export default ResetVariant;
