const getFileName = (response) => {
  let filename;
  const contentDisposition = response.headers['content-disposition'];

  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches !== null && matches[1]) filename = matches[1].replace(/['"]/g, '');
  }

  return filename;
};

export const returnFileFromResponse = (resp) => {
  const fileName = getFileName(resp);
  const url = window.URL.createObjectURL(new Blob([resp.data]));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  link.remove();
};

export const runConnector = (href) => {
  const link = document.createElement('a');
  link.id = 'temp_download_link';
  link.href = href;
  document.body.appendChild(link);
  link.click();
  const tempAnchor = document.getElementById('temp_download_link');
  tempAnchor.parentElement.removeChild(tempAnchor);
};
